export const dashboardInfo = {
    welcomeScreen: {
        "welcomeNote": "Welcome",
        "offeringHeading": "Offering Subscribed",
        "subscribedFor": "Advanced + Summary",
        "welcomeScreenBackgroundImg": "https://niroggyan.s3.ap-south-1.amazonaws.com/React-Billing-Dashboard-files/WelcomeScreen+Files/welcomeScreenSvg.svg",
        "planInfo": {
            planCardHeading: "Plan Status",
            planPlaceHolder: "Your Plan is",
            planActiveStatus: "Active",
            planCardMoreInfo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut praesent aliquet donec ac ridiculus iaculis. Nulla est."
        }

    }
}