import React, { useState, useEffect } from "react";
import { Grid, Menu, Segment, Table, Label, Dropdown } from "semantic-ui-react";
import { Form, Modal, Button, Row, Col, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  completePackageData,
  completePackageDataForFemale,
} from "./dummyTestData";
import { cloneDeepWithoutLoc } from "@babel/types";
import { useAlert } from "react-alert";
import JsZip from "jszip";
import { saveAs } from "file-saver";
import AlertTemplate from "react-alert-template-basic";
import NavBar from "./NavBar";
import SideNavbar from "./SideNavbar";
import { Stack } from "@mui/material";
import NavbarComponent from "../NavbarComponent";
import PageTitle from "../../common/PageTitle";

const ProfileTests = () => {
  let findGender = JSON.parse(localStorage.getItem("patientDetails"));
  var dataSelection;

  if (findGender.gender === "female") {
    var dataSelection = completePackageDataForFemale;
    console.log("choosing female");
  } else {
    var dataSelection = completePackageData;
    console.log("choosing male");
  }
  const updatingTestDetails = (data) => {
    let storedTestDetails = JSON.parse(
      localStorage.getItem("storedTestDetails")
    )
      ? JSON.parse(localStorage.getItem("storedTestDetails"))
      : [];
    // console.log('storedTestDetails', storedTestDetails);
    if (storedTestDetails.length > 0) {
      return [
        {
          packageName: data[0].packageName,
          results: data[0].results.map((eachProfile) => {
            let tests = eachProfile.investigation.map((eachTest) => {
              const requiredTest = storedTestDetails.filter(
                (eachstored) => eachstored.test === eachTest.test
              );
              // console.log(requiredTest[0] ? requiredTest[0] : '');
              return {
                test: requiredTest[0] ? requiredTest[0].test : eachTest.test,
                low: requiredTest[0] ? requiredTest[0].low : eachTest.low,
                high: requiredTest[0] ? requiredTest[0].high : eachTest.high,
                unit: requiredTest[0] ? requiredTest[0].unit : eachTest.unit,
                result: requiredTest[0]
                  ? requiredTest[0].result
                  : eachTest.result,
                method: "",
                pastObservation: requiredTest[0]
                  ? requiredTest[0].pastObservation
                  : eachTest.pastObservation,
                observation_time: requiredTest[0]
                  ? requiredTest[0].observation_time
                  : eachTest.observation_time,
              };
            });
            // console.log(eachProfile);
            return { ...eachProfile, investigation: [...tests] };
          }),
        },
      ];
    }

    return data;
  };

  var completePackageDataByGender = updatingTestDetails(dataSelection)
    ? updatingTestDetails(dataSelection)
    : dataSelection;

  console.log("completePackageDataByGender\n", completePackageDataByGender);

  const alert = useAlert();
  const navigate = useNavigate();
  // const now = new Date();
  // const dateFormat = `${now.getDate()}/${now.getMonth()}/${now.getFullYear()}`;
  const today = new Date().toISOString().slice(0, 10);
  console.log("today", today);

  const [page1Data, setPage1Data] = useState("");
  const [patientDetails, setPatientDetails] = useState("");
  const [activeItem, setActiveItem] = useState("");

  const [test, setTest] = useState("");
  const [method, setMethod] = useState("");
  const [low, setLow] = useState("");
  const [high, setHigh] = useState("");
  const [unit, setUnit] = useState("");
  const [result, setResult] = useState("");
  const [currentTestData, setCurrentTestData] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [finalRecord, setFinalRecord] = useState("");
  const [sendReportStatus, setSendReportStatus] = useState(true);
  const [bodyMassIndex, setBodyMassIndex] = useState("");
  const [pdfLinks, setPdfLinks] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [myData, setMyData] = useState([]);
  const [searchStatus, setsearchStatus] = useState(false);
  const [cloneTest, setCloneTest] = useState("");
  const [goDownStatus, setGoDownStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [newDate, setNewDate] = useState(today);
  const [historicActive, setHistoricActive] = useState(false);

  // credentials check
  useEffect(() => {
    const loginData = localStorage.getItem("credentials");
    if (JSON.parse(loginData)) {
      //
    } else {
      navigate("/login");
    }
  }, []);

  // modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let suggestableTests = [];

  for (let j = 0; j < completePackageDataByGender.length; j++) {
    let profileAndTestRecord = completePackageDataByGender[j].results;
    for (let k = 0; k < profileAndTestRecord.length; k++) {
      let allTestsInfo = profileAndTestRecord[k].investigation;
      for (let l = 0; l < allTestsInfo.length; l++) {
        let theTestName = allTestsInfo[l].test;
        if (suggestableTests.includes(theTestName)) {
          // do nothing
        } else {
          suggestableTests.push(theTestName);
        }
      }
    }
  }

  const stateOptions = suggestableTests.map((each, index) => ({
    key: index,
    text: each,
    value: each,
  }));

  let matchingProfile = JSON.parse(
    localStorage.getItem("profileWithSimilarResult")
  );

  useEffect(() => {
    const data1 = localStorage.getItem("page1Data");
    const data2 = localStorage.getItem("currentTestData");
    const data3 = localStorage.getItem("patientDetails");
    if (data1) {
      setPage1Data(JSON.parse(data1));
    }
    if (data2) {
      setCurrentTestData(JSON.parse(data2));
    }
    if (data3) {
      setPatientDetails(JSON.parse(data3));
    }
  }, []);

  useEffect(() => {
    setsearchStatus(!searchStatus);

    let mySearchData = localStorage.getItem("profileWithSimilarResult");
    matchingProfile = JSON.parse(mySearchData);
  }, [term]);

  useEffect(() => {
    localStorage.setItem("currentTestData", JSON.stringify(currentTestData));
  }, []);

  //const unitsData = [];

  // for (const key in TEST_DATABASE) {
  //     let unit = TEST_DATABASE[key].unit
  //     if (unitsData.includes(unit) || unit === undefined || unit === "") {
  //         // nothing
  //     }
  //     else {
  //         unitsData.push(unit)
  //     }
  // }

  ///////////

  // Units Array
  // let theResultsData = dummyTestData.results;

  // for (let i = 0; i < theResultsData.length; i++) {
  //   let investigationData = theResultsData[i].investigation;
  //   for (let j = 0; j < investigationData.length; j++) {
  //     let theUnit = investigationData[j].unit;
  //     if (
  //       unitsData.includes(theUnit) ||
  //       theUnit === "" ||
  //       theUnit === undefined
  //     ) {
  //       // do nothing
  //     } else {
  //       unitsData.push(theUnit);
  //     }
  //   }
  // }

  // Units Identifier
  const unitsData = [];
  let allUnitsData = completePackageDataByGender;

  for (let i = 0; i < allUnitsData.length; i++) {
    let getPackageResults = allUnitsData[i].results;

    for (let j = 0; j < getPackageResults.length; j++) {
      let getProfileTests = getPackageResults[j].investigation;

      for (let k = 0; k < getProfileTests.length; k++) {
        let getTest = getProfileTests[k].unit;

        if (
          getTest === undefined ||
          getTest === "" ||
          unitsData.includes(getTest)
        ) {
          //
        } else {
          unitsData.push(getProfileTests[k].unit);
        }
      }
    }
  }

  /////////

  const activeItemHandler = (each) => {
    setActiveItem(each);

    if (page1Data && page1Data.selectionChoice === "package") {
      if (count < 1) {
        getData();
        setCount(count + 1);
      }
    }
    if (page1Data && page1Data.selectionChoice === "manual") {
      manualDataLogic();
      if (count < 1) {
        manualDataLogic();
        setCount(count + 1);
      }
    }
  };

  // all tests record
  let allTestsnames = [];
  let testWithAllFields = [];
  const [allTestsRecord, setAllTestsRecord] = useState([]);

  useEffect(() => {
    for (let k = 0; k < completePackageDataByGender.length; k++) {
      let profileResults = completePackageDataByGender[k].results;
      for (let p = 0; p < profileResults.length; p++) {
        let profileInvestigation = profileResults[p].investigation;
        for (let q = 0; q < profileInvestigation.length; q++) {
          let thisTestName = profileInvestigation[q].test;
          let thisTestLow = profileInvestigation[q].low;
          let thisTestHigh = profileInvestigation[q].high;
          let thisTestUnit = profileInvestigation[q].unit;

          if (allTestsnames.includes(thisTestName)) {
            //
          } else {
            allTestsnames.push(thisTestName);
            testWithAllFields.push({
              test: thisTestName,
              low: thisTestLow,
              high: thisTestHigh,
              unit: thisTestUnit,
            });
          }
        }
      }
    }

    setAllTestsRecord(testWithAllFields);
    console.log("allTestsRecord", allTestsRecord);
  }, []);

  // console.log("allTestsRecord", allTestsRecord)

  const testsSubmitHandler = (e) => {
    e.preventDefault();

    setPdfLinks([]);
    setSendReportStatus(false);

    let matchingTest = {
      test: "",
      low: "",
      high: "",
      unit: "",
    };

    for (let p = 0; p < allTestsRecord.length; p++) {
      let currentTestObj = allTestsRecord[p].test;
      if (currentTestObj.length === test.length && currentTestObj === test) {
        console.log("recommend test to fill\n", allTestsRecord[p]);
        matchingTest.test = allTestsRecord[p].test;
        matchingTest.low = allTestsRecord[p].low;
        matchingTest.high = allTestsRecord[p].high;
        matchingTest.unit = allTestsRecord[p].unit;
      }
    }

    console.log("matching test at this point", matchingTest);

    let currentTest = {
      test: matchingTest.test,
      method: method !== "" ? method : "",
      low: matchingTest.low,
      high: matchingTest.high,
      unit: matchingTest.unit,
      result: result !== "" ? result : "",
    };

    console.log("current test\n", currentTest);

    let packageInfo = {
      packageName: page1Data ? page1Data.value : "",
      profile: activeItem,
      profileTest: currentTest,
    };
    setCurrentTestData([...currentTestData, packageInfo]);

    setTest("");
    setMethod("");
    setLow("");
    setHigh("");
    setUnit("");
    setResult("");
  };

  const testDeleteHandler = (wholeObject) => {
    setSendReportStatus(false);
    setPdfLinks([]);
    let testName = wholeObject.profileTest.test;
    let updates = currentTestData.filter(
      (each, idx) => each.profileTest.test !== testName
    );
    updateTests(updates);
  };

  const updateTests = (mydata) => {
    setCurrentTestData(mydata);
  };

  for (let i = 0; i < currentTestData.length; i++) {
    let currentProfile = currentTestData[i].profile;
    if (selectedProfiles.includes(currentProfile)) {
      //
    } else {
      setSelectedProfiles([...selectedProfiles, currentProfile]);
    }
  }

  const submitReportHandler = async () => {
    setPdfLinks([]);
    let myData = {};
    let myArray = [];

    for (let i = 0; i < selectedProfiles.length; i++) {
      let thetest = selectedProfiles[i];
      let newData = currentTestData.filter((each) => each.profile === thetest);

      let onlyTests = newData.map((each) => each.profileTest);
      myData = {
        profilename: thetest,
        investigation: onlyTests,
      };
      myArray.push(myData);
    }
    //setFinalRecord(myArray)

    // modify dates
    let newDate1 = "";
    let newDate2 = "";
    let newDate3 = "";
    let newDate4 = "";

    let date1 = patientDetails.registeredDate
      ? patientDetails.registeredDate
      : "";
    let date2 = patientDetails.collectionDate
      ? patientDetails.collectionDate
      : "";
    let date3 = patientDetails.recievedDate ? patientDetails.recievedDate : "";
    let date4 = patientDetails.reportedDate ? patientDetails.reportedDate : "";

    let date1TimeStamp = patientDetails
      ? patientDetails.timeStampOfRegisteredDate
      : "";
    let date2TimeStamp = patientDetails
      ? patientDetails.timeStampOfCollectionDate
      : "";
    let date3TimeStamp = patientDetails
      ? patientDetails.timeStampOfRecievedDate
      : "";
    let date4TimeStamp = patientDetails
      ? patientDetails.timeStampOfReportedDate
      : "";

    // modify date order and join timestamp
    if (date1 !== "" || date1 !== undefined) {
      let leftContentDate1 = date1.split("T")[0].split("-").reverse().join("-");
      let rightContentDate1 = date1.split("T")[1];
      newDate1 = `${leftContentDate1} ${
        rightContentDate1 === undefined ? "" : rightContentDate1
      }${date1TimeStamp === undefined ? "" : date1TimeStamp}`;
      console.log("newDate1: ", newDate1);
    }

    if (date2 !== "" || date2 !== undefined) {
      let leftContentDate2 = date2.split("T")[0].split("-").reverse().join("-");
      let rightContentDate2 = date2.split("T")[1];
      newDate2 = `${leftContentDate2} ${
        rightContentDate2 === undefined ? "" : rightContentDate2
      }${date2TimeStamp === undefined ? "" : date2TimeStamp}`;
      console.log("newDate2: ", newDate2);
    }

    if (date3 !== "" || date3 !== undefined) {
      let leftContentDate3 = date3.split("T")[0].split("-").reverse().join("-");
      let rightContentDate3 = date3.split("T")[1];
      newDate3 = `${leftContentDate3} ${
        rightContentDate3 === undefined ? "" : rightContentDate3
      }${date3TimeStamp === undefined ? "" : date3TimeStamp}`;
      console.log("newDate3: ", newDate3);
    }

    if (date4 !== "" || date4 !== undefined) {
      let leftContentDate4 = date4.split("T")[0].split("-").reverse().join("-");
      let rightContentDate4 = date4.split("T")[1];
      newDate4 = `${leftContentDate4} ${
        rightContentDate4 === undefined ? "" : rightContentDate4
      }${date4TimeStamp === undefined ? "" : date4TimeStamp}`;
      console.log("newDate4: ", newDate4);
    }

    // final report
    let patientInfo = {
      patientname: patientDetails ? patientDetails.name : "-",
      age: patientDetails ? patientDetails.age : "-",
      gender: patientDetails ? patientDetails.gender : "-",
      refBy: patientDetails ? patientDetails.refBy : "-",
      registeredDate: newDate1,
      collectionDate: newDate2,
      ReportedDate: newDate4,
      centerName: patientDetails ? patientDetails.centerName : "-",
      refCustomer: patientDetails ? patientDetails.refCustomer : "-",
      sampleId: patientDetails ? patientDetails.sampleId : "-",
      blankLetterHead: true,
      client: "",
      employeeId: patientDetails ? patientDetails.employeeId : "",
      department: patientDetails ? patientDetails.department : "",
      results: myArray.reverse(), // to get tests in smart report according to the sequence they got filled
    };
    // modifying observations data in required format
    let loopingData = patientInfo.results;
    let collectTests = [];

    for (let x = 0; x < loopingData.length; x++) {
      let theProfileWithTestsArray = loopingData[x].investigation;
      for (let j = 0; j < theProfileWithTestsArray.length; j++) {
        let getTheTest = theProfileWithTestsArray[j];
        if (getTheTest.result === "") {
          //
        } else {
          collectTests.push(getTheTest);
        }
      }
    }

    // console.log("collectTests\n", collectTests)

    // saving modified data
    patientInfo.results = [
      {
        profilename: "undefined",
        investigation: collectTests,
      },
    ];

    // updating test details stored in the local storage after the user clicks on the save report button
    console.log("collectedTests", collectTests);
    localStorage.setItem("storedTestDetails", JSON.stringify(collectTests));

    setFinalRecord(patientInfo);
    console.log("whats getting saved", patientInfo);
    console.log("whats desired\n");

    setSendReportStatus(true);
  };

  console.log("Final Record", finalRecord);

  const calculateBmi = (pHeight, pWeight, theGender) => {
    let bmi_report = "";
    let patient_height = parseInt(pHeight);
    let patient_weight = parseInt(pWeight);
    let pGender =
      theGender === "" || theGender === "NA" || theGender === undefined
        ? "male"
        : theGender;

    let patient_height_from_cm_to_m = patient_height / 100;
    let meters_for_bmi = patient_height_from_cm_to_m ** 2;
    let bmi = patient_weight / meters_for_bmi;

    let final_result = bmi.toFixed(1);

    if (pGender === "female") {
      if (final_result <= 19.1) {
        bmi_report = final_result;
      } else if (final_result >= 19.1 && final_result <= 25.8) {
        bmi_report = final_result;
      } else if (final_result >= 25.8 && final_result <= 27.3) {
        bmi_report = final_result;
      } else if (final_result >= 27.3 && final_result <= 32.3) {
        bmi_report = final_result;
      } else if (final_result >= 32.3) {
        bmi_report = final_result;
      }
    } else if (pGender === "male") {
      if (final_result <= 20.7) {
        bmi_report = final_result;
      } else if (final_result >= 20.7 && final_result <= 26.4) {
        bmi_report = final_result;
      } else if (final_result >= 26.4 && final_result <= 27.8) {
        bmi_report = final_result;
      } else if (final_result >= 27.8 && final_result <= 31.1) {
        bmi_report = final_result;
      } else if (final_result >= 31.1) {
        bmi_report = final_result;
      }
    }

    return bmi_report;
  };

  const updatingJson = (json) => {
    const updatePastData = json.results[0].investigation.findIndex(
      (eachTest) => eachTest.pastObservation?.length > 0
    );
    const jsonStructureFormat = {
      Centre: "",
      WorkOrderID: "",
      LabNo: "",
      LabRefno: "",
      NonSmartTestID: "",
      PanelID: "",
      PanelName: "",
      PName: "",
      Gender: "M",
      CollectedDate: "",
      Age: "",
      ReferredBy: "",
      ReportDate: "",
      Received: "",
      FilePath: "",
      MaxID: "",
      Date: "",
      hasPastData: false,
      results: [
        {
          Package_name: "",
          Package_book_code: "",
          investigation: [
            {
              test_name: "Dummy",
              test_code: "1234",
              barcodeNo: "",
              SampleType: "",
              SampleCollDate: "",
              SampleRcvDate: "",
              ApprovalDate: "",
              SampleCollBy: "",
              SampleRcvBy: "",
              ApprovedByDoctorID: "",
              IsNabl: "",
              observations: [],
            },
          ],
        },
      ],
      ApprovedDoctorDetails: [
        {
          ApprovedDoctorID: "",
          ApprovedDoctorSign: "",
        },
      ],
      BackGroudImageData: "",
      reportType: "dynamic",
    };
    const requiredJsonStructure = JSON.parse(
      JSON.stringify(jsonStructureFormat)
    );
    const testStructure = {
      name: "",
      observation_time: "",
      id: "",
      value: "",
      MinValue: "",
      MaxValue: "",
      unit: "",
      method: "",
      impression: "",
      pastObservation: [],
    };
    json.results[0].investigation.forEach((eachTest) => {
      const copyTestStructure = JSON.parse(JSON.stringify(testStructure));
      copyTestStructure.name = eachTest.test;
      copyTestStructure.MinValue = eachTest.low;
      copyTestStructure.MaxValue = eachTest.high;
      copyTestStructure.unit = eachTest.unit;
      copyTestStructure.value = eachTest.result;
      copyTestStructure.observation_time = eachTest.observation_time
        ? eachTest.observation_time
        : today;
      copyTestStructure.pastObservation = eachTest.pastObservation?.filter(
        (eachValue) => eachValue.observation_time && eachValue.value
      );
      requiredJsonStructure.results[0].investigation[0].observations.push(
        copyTestStructure
      );
    });
    requiredJsonStructure.Centre = JSON.parse(
      localStorage.getItem("credentials")
    ).center;
    requiredJsonStructure.PName = json.patientname;
    requiredJsonStructure.Gender = json.gender;
    requiredJsonStructure.Age = json.age;
    requiredJsonStructure.LabRefno = json.sampleId;
    requiredJsonStructure.LabNo = json.sampleId;
    requiredJsonStructure.CollectedDate = json.collectionDate;
    requiredJsonStructure.reportType = JSON.parse(
      localStorage.getItem("credentials")
    ).reportSubscription.reportType;
    requiredJsonStructure.ReferredBy = json.refBy;
    requiredJsonStructure.hasPastData = updatePastData !== -1;
    console.log("jsonStructureSending", requiredJsonStructure);
    return requiredJsonStructure;
  };

  // url = "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/srportalbackend"
  const sendData = () => {
    submitReportHandler();

    var config = {
      method: "POST",
      url: "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/production/pdfparserclients",

      headers: {
        "Content-Type": "application/json",
      },
      data: updatingJson(finalRecord),
    };

    let collectPdfLinks = [];
    const jsonStoredInLocalStorage = JSON.parse(
      localStorage.getItem("storedPatientsData")
    )
      ? JSON.parse(localStorage.getItem("storedPatientsData"))
      : [];
    const JsonSentForReportGeneration = updatingJson(finalRecord);

    // checking for whether json with same name
    const otherJson = jsonStoredInLocalStorage.filter(
      (each) => each.PName !== JsonSentForReportGeneration.PName
    );
    otherJson.push(JsonSentForReportGeneration);
    // localStorage.setItem('storedPatientsData', JSON.stringify(otherJson));
    // localStorage.removeItem('storedTestDetails');

    axios(config)
      .then(function (response) {
        downloadPDF(response);
        localStorage.setItem("storedPatientsData", JSON.stringify(otherJson));
        // localStorage.removeItem('storedTestDetails');
        // collectPdfLinks.push(response.data.EncodeData);
        // collectPdfLinks.push(response.data.NoLetterHead);
        // setPdfLinks(collectPdfLinks);
      })
      .then(() => {
        setShowLoading(false);
      })
      .catch(function (error) {
        alert.error(error.message);
        setShowLoading(false);
        console.log(error);
      });
  };

  const testHandler = (id, data, type, pastindex = 0) => {
    let myData = data;
    // let pastValues = { observation_time: pastDate, value: pastResult };
    let testValue = "";
    let methodValue = "";
    let lowValue = "";
    let highValue = "";
    let unitValue = "";
    let resultValue = "";

    const editableData = currentTestData.filter((each, idx) => idx === id);

    testValue = editableData[0].profileTest.test;
    methodValue = editableData[0].profileTest.method;
    lowValue = editableData[0].profileTest.low;
    highValue = editableData[0].profileTest.high;
    unitValue = editableData[0].profileTest.unit;
    resultValue = editableData[0].profileTest.result;

    for (let i = 0; i < currentTestData.length; i++) {
      if (i === id) {
        type === "emptyPastData"
          ? setHistoricActive(true)
          : setHistoricActive(false);
        currentTestData[i].profileTest.test =
          type === "test" ? myData : testValue;
        currentTestData[i].profileTest.method =
          type === "method" ? myData : methodValue;
        currentTestData[i].profileTest.low = type === "low" ? myData : lowValue;
        currentTestData[i].profileTest.high =
          type === "high" ? myData : highValue;
        currentTestData[i].profileTest.unit =
          type === "unit" ? myData : unitValue;
        currentTestData[i].profileTest.result =
          type === "result" ? myData : resultValue;
        if (type === "currentDate") {
          currentTestData[i].profileTest.observation_time = myData
            ? myData
            : today;
        }
        currentTestData[i].profileTest.pastObservation =
          type === "emptyPastData"
            ? currentTestData[i].profileTest.pastObservation
              ? [...currentTestData[i].profileTest.pastObservation, myData]
              : [myData]
            : currentTestData[i].profileTest.pastObservation;

        if (type === "updatePastDate") {
          currentTestData[i].profileTest.pastObservation[
            pastindex
          ].observation_time = myData;
        }
        if (type === "updatePastValue") {
          currentTestData[i].profileTest.pastObservation[pastindex].value =
            myData;
        }
        if (type === "deletePastData") {
          currentTestData[i].profileTest.pastObservation.splice(pastindex, 1);
        }
      }
    }

    // console.log("currentTestData", currentTestData);

    const updatedTestData = currentTestData.map((each, idx) => each);
    setCurrentTestData(updatedTestData);
  };

  const resetProfileTestsHandler = () => {
    localStorage.removeItem("page1Data");
    localStorage.removeItem("storedTestDetails");
    setCurrentTestData([]);
    handleClose();
    window.location.reload();
  };

  let theRequiredData = {
    packageName: page1Data ? page1Data.value : "",
    profile: activeItem,
    profileTest: "",
  };

  let myNewArray = [];

  const getData = () => {
    let investigationRecord = page1Data ? page1Data.investigation : [];

    for (let i = 0; i < investigationRecord.length; i++) {
      let currentProfileWithInvestigation = investigationRecord[i];
      for (let k = 0; k < currentProfileWithInvestigation.length; k++) {
        let profileNameRecieved = currentProfileWithInvestigation[k];
        let myProfile = currentProfileWithInvestigation[k].profilename;
        let investigationOfProfile = profileNameRecieved.investigation;
        for (let z = 0; z < investigationOfProfile.length; z++) {
          let testRecieved = investigationOfProfile[z];
          theRequiredData = {
            packageName: page1Data ? page1Data.value : "",
            profile: myProfile,
            profileTest: testRecieved,
          };
          myNewArray.push(theRequiredData);
        }
      }
    }
    // console.log('myNewArray', myNewArray);
    setCurrentTestData(myNewArray);
  };

  const handleDropDownValue = (event, data) => {
    setTest(data.value);
  };

  const manualDataLogic = () => {
    let recordProfileNames = [];
    let requiredProfiles = [];
    let manualProfiles = page1Data ? page1Data.selectedPackTests : "";

    //let allPackages = completePackageDataByGender[0].results;
    // console.log('manualProfiles', manualProfiles);

    let allPackages = [];

    for (let t = 0; t < completePackageDataByGender.length; t++) {
      let thisPackageResults = completePackageDataByGender[t].results;

      for (let w = 0; w < thisPackageResults.length; w++) {
        if (recordProfileNames.includes(thisPackageResults[w].profilename)) {
          // allPackages.push(thisPackageResults[w]);
          // console.log('coming here');
        } else {
          allPackages.push(thisPackageResults[w]);
          recordProfileNames.push(thisPackageResults[w].profilename);
        }
      }

      // console.log("thisPackageResults\n", thisPackageResults)
      // for (let s = 0; s < thisPackageResults.length; s++) {
      //   let thisCurrentProfile = thisPackageResults[s].profilename
      //   if (recordProfileNames.includes(thisCurrentProfile.profilename)) {
      //     //
      //   } else {
      //     allPackages.push(thisPackageResults)
      //     recordProfileNames.push(thisCurrentProfile.profilename)
      //   }
      // }
    }

    for (let i = 0; i < allPackages.length; i++) {
      if (manualProfiles.includes(allPackages[i].profilename)) {
        requiredProfiles.push(allPackages[i]);
      }
    }

    let dataForLs = []; // for local storage

    for (let j = 0; j < requiredProfiles.length; j++) {
      let firstProfile = requiredProfiles[j].profilename;
      let firstProfileTests = requiredProfiles[j].investigation;
      for (let k = 0; k < firstProfileTests.length; k++) {
        let newProfile = {
          profile: firstProfile,
          profileTest: firstProfileTests[k],
        };
        dataForLs.push(newProfile);
      }
    }

    setCurrentTestData(dataForLs);
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
  };

  let profileWithSimilarResult = [];

  const searchFilterHandler = (data) => {
    let requiredSearch = data.profileTest.test
      .toLowerCase()
      .includes(term.toLowerCase());

    if (requiredSearch && term !== "") {
      if (profileWithSimilarResult.includes(data.profile)) {
        // nothing
      } else {
        profileWithSimilarResult.push(data.profile);
      }
    }

    localStorage.setItem(
      "profileWithSimilarResult",
      JSON.stringify(profileWithSimilarResult)
    );
    return requiredSearch;
  };

  const downloadPDF = (response) => {
    console.log("the file getting downloaded");
    if (response.data.PdfData) {
      const linkSource = `data:application/pdf;base64,${response.data.PdfData}`;
      const downloadLink = document.createElement("a");
      const fileName = `${finalRecord.patientname}.pdf`;
      // const fileName = `compact-report.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      alert.error(
        "Smart Report Generation Error. Please Contact NirogGyan Team"
      );
    }

    //setPdfStatus(true)
  };

  const downloadZip = () => {
    // console.log("your array\n", pdfLinks)

    var zip = new JsZip();
    let allFiles = zip.folder("All_Reports");

    for (let i = 0; i < pdfLinks.length; i++) {
      allFiles.file(`${finalRecord.patientname}-${i + 1}.pdf`, pdfLinks[i], {
        base64: true,
      });
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      saveAs(content, "SmartReports.zip");
    });
  };

  const exportCsv = (dummyJson) => {
    const headerLine =
      "Patient name,Age,Sample ID,Referral Doctor,Gender,Date,Package name,Profile name,Test,Low,High,Unit,Result,observation_time\n";
    const firstLine =
      `${dummyJson.PName}` +
      "," +
      `${dummyJson.Age}` +
      "," +
      `${dummyJson.LabRefno}` +
      "," +
      `${dummyJson.ReferredBy}` +
      "," +
      `${dummyJson.Gender}` +
      "," +
      `${dummyJson.CollectedDate.split(" ")[0]}` +
      "," +
      "" +
      "," +
      "" +
      "," +
      `${
        dummyJson.results[0].investigation[0].observations[0]
          ? dummyJson.results[0].investigation[0].observations[0].name
          : ""
      }` +
      "," +
      `${
        dummyJson.results[0].investigation[0].observations[0]
          ? dummyJson.results[0].investigation[0].observations[0].MinValue
          : ""
      }` +
      "," +
      `${
        dummyJson.results[0].investigation[0].observations[0]
          ? dummyJson.results[0].investigation[0].observations[0].MaxValue
          : ""
      }` +
      "," +
      `${
        dummyJson.results[0].investigation[0].observations[0]
          ? dummyJson.results[0].investigation[0].observations[0].unit
          : ""
      }` +
      "," +
      `${
        dummyJson.results[0].investigation[0].observations[0]
          ? dummyJson.results[0].investigation[0].observations[0].value
          : ""
      }` +
      "," +
      `${
        dummyJson.results[0].investigation[0].observations[0]
          ? dummyJson.results[0].investigation[0].observations[0]
              .observation_time
          : ""
      }` +
      "\n";

    let testsArray = [];
    let eachLineOfParameter = "";

    const tests = dummyJson.results[0].investigation[0].observations.forEach(
      (eachParameter, index) => {
        if (index > 0) {
          eachLineOfParameter =
            ",".repeat(8) +
            `${eachParameter.name}` +
            "," +
            `${eachParameter.MinValue}` +
            "," +
            `${eachParameter.MaxValue}` +
            "," +
            `${eachParameter.unit}` +
            "," +
            `${eachParameter.value}` +
            "," +
            `${eachParameter.observation_time}`;

          testsArray.push(eachLineOfParameter);
        }

        if (eachParameter?.pastObservation?.length) {
          eachParameter.pastObservation.forEach((eachData) => {
            let pastDataLine =
              ",".repeat(8) +
              `${eachParameter.name}` +
              ",".repeat(4) +
              `${eachData.value}` +
              "," +
              `${eachData.observation_time}`;
            if (pastDataLine) testsArray.push(pastDataLine);
          });
        }
      }
    );
    var blob = new Blob([headerLine + firstLine + testsArray.join("\n")]);
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `${dummyJson.PName}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // console.log(
  //   completePackageDataByGender,
  //   updatingTestDetails(completePackageDataByGender)
  // );
  const getRestrictedDate = (testDate) => {
    const newCurrentDate = new Date(testDate);
    const maxDate = `${newCurrentDate.getFullYear()}-${
      newCurrentDate.getMonth() + 1
    }-${newCurrentDate.getDate()}`;
    // console.log("maxDate", testDate);
    const restrictedDate = new Date(maxDate).toISOString().slice(0, 10);
    return restrictedDate;
  };

  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />
      <div style={{ flexGrow: "1", padding: "0px 64px 40px" }}>
        <PageTitle title="Manual Generation" />
        <NavBar />
        {/* <SideNavbar /> */}
        <div>
          <div>
            {/* Modal Start*/}

            <div>
              <>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header>
                    <Modal.Title>
                      <i
                        style={{ color: "#e6e600" }}
                        className="fas fa-exclamation-triangle"
                      ></i>{" "}
                      Reset Confirmation
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    This will delete/reset all the currently selected profiles
                    and their tests. Are you sure you want to continue?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger btn-sm"
                      onClick={resetProfileTestsHandler}
                    >
                      Confirm Reset
                    </Button>
                    <Button variant="primary btn-sm" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>

            {/* Modal End */}

            {/* Search Bar Start */}
            <Row>
              <div>
                <Form.Group
                  className="mb-4 button-focus-css"
                  onSubmit={searchSubmitHandler}
                >
                  <InputGroup>
                    <InputGroup.Text>
                      {"Smart Search "}{" "}
                      {matchingProfile.length !== 0 && term !== "" ? (
                        <span style={{ marginLeft: "5px" }}>
                          {"(Results: "}
                          <span
                            style={{
                              backgroundColor: "yellow",
                              borderRadius: "10px",
                              padding: "2px",
                            }}
                          >
                            {matchingProfile.length}
                          </span>
                          {")"}
                        </span>
                      ) : (
                        ""
                      )}
                      <i className="fas fa-search mx-2"></i>
                    </InputGroup.Text>

                    <Form.Control
                      className="button-focus-css"
                      disabled={activeItem === ""}
                      type="text"
                      onClick={() => {
                        setCloneTest("");
                        setGoDownStatus(false);
                      }}
                      placeholder={
                        activeItem === ""
                          ? "choose any profile to enable smart search"
                          : "find your test"
                      }
                      value={term}
                      onChange={(e) => setTerm(e.target.value)}
                    />
                    <span
                      onClick={() => setTerm("")}
                      className="btn btn-info btn-sm"
                    >
                      <div style={{ margin: "2px 0px 0px 0px" }}>
                        Clear Search
                      </div>
                    </span>
                  </InputGroup>
                </Form.Group>
              </div>
            </Row>

            {/* Search Bar End */}

            <Row>
              <Col>
                {/* <span style={{ display: "flex", justifyContent: "center" }}>
                        <h3>Profile |</h3>
                        
                    </span> */}

                <Grid.Column>
                  <Segment raised>
                    <Label as="a" color="red" ribbon>
                      PROFILES
                    </Label>

                    <Menu
                      className="blue"
                      fluid
                      vertical
                      tabular
                      style={{
                        padding: "2px",
                        overflow: "scroll",
                        height: "365px",
                      }}
                    >
                      {page1Data && page1Data.selectionChoice === "package" ? (
                        <div
                          className="text-center mb-2"
                          style={{
                            border: "1.5px solid #5cd65c",
                            borderRadius: "2px",
                            padding: "4px",
                          }}
                        >
                          <b>PACKAGE:</b> {page1Data ? page1Data.value : ""}
                        </div>
                      ) : (
                        <div
                          className="text-center mb-2"
                          style={{
                            border: "1.5px solid #5cd65c",
                            borderRadius: "2px",
                            padding: "4px",
                          }}
                        >
                          Manual Tests
                        </div>
                      )}

                      {page1Data && page1Data.selectedPackTests.length > 0
                        ? page1Data.selectedPackTests.map((each, idx) => (
                            <div
                              key={idx}
                              className={
                                matchingProfile.includes(each)
                                  ? ""
                                  : "profiles-hover-css"
                              }
                            >
                              {matchingProfile.includes(each) && term !== "" ? (
                                <Label
                                  basic
                                  color="red"
                                  className="mt-1 tiny"
                                  pointing="below"
                                >
                                  match
                                </Label>
                              ) : (
                                ""
                              )}
                              <Menu.Item
                                style={{
                                  backgroundColor:
                                    matchingProfile.includes(each) &&
                                    term !== ""
                                      ? "#ffff4d"
                                      : "",
                                  borderRadius:
                                    matchingProfile.includes(each) &&
                                    term !== ""
                                      ? "15px"
                                      : "",
                                }}
                                name={each}
                                disabled={
                                  activeItem !== "" &&
                                  sendReportStatus === false
                                }
                                title={
                                  sendReportStatus === false &&
                                  activeItem !== each
                                    ? "Save Report to enable the profile"
                                    : ""
                                }
                                active={activeItem === each}
                                onClick={() => {
                                  activeItemHandler(each);
                                  console.log(`clicking${each}`);
                                }}
                              />
                            </div>
                          ))
                        : ""}
                    </Menu>
                  </Segment>
                </Grid.Column>
              </Col>

              <Col xs={9}>
                <Segment>
                  {activeItem === "" ? (
                    <div>
                      {page1Data && page1Data.selectedPackTests.length > 0 ? (
                        <h5>Select a profile to create/edit tests.</h5>
                      ) : (
                        <span>
                          No profile selected!{" "}
                          <Link to={"/manualReports/itemSelection/"}>
                            Go to selection page
                          </Link>
                        </span>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="text-center mb-2">
                        <b>Test Name: </b>
                        {activeItem}
                      </div>
                      <Form
                        style={{
                          overflow: "scroll",
                          width: "100%",
                          height: "380px",
                        }}
                        onChange={() => {
                          setSendReportStatus(false);
                          setPdfLinks([]);
                        }}
                        onSubmit={testsSubmitHandler}
                      >
                        <Table>
                          <thead>
                            <tr className="text-center">
                              <th style={{ fontWeight: "600", width: "25%" }}>
                                Test
                              </th>
                              {/* <th width="22%" style={{ fontWeight: "600" }}>
                          Method
                        </th> */}
                              <th style={{ fontWeight: "600" }}>Low</th>
                              <th style={{ fontWeight: "600" }}>High</th>
                              <th style={{ fontWeight: "600" }}>Unit</th>
                              <th style={{ fontWeight: "600" }}>Result</th>
                              <th style={{ fontWeight: "600", width: "16%" }}>
                                Date
                              </th>
                              <th style={{ fontWeight: "600" }}>Add/ Del</th>
                              <th style={{ fontWeight: "600" }}>History</th>
                            </tr>
                          </thead>

                          <tbody className="text-center">
                            {currentTestData
                              // .filter((each) => each.profileTest.test.toLowerCase().includes(term.toLowerCase()))
                              .filter((each) => searchFilterHandler(each))
                              .map((each, idx) =>
                                each.packageName === page1Data.value &&
                                each.profile === activeItem ? (
                                  <tr
                                    key={idx}
                                    onClick={() => {
                                      setCloneTest(each.profileTest.test);
                                      setTerm("");
                                    }}
                                  >
                                    <td style={{ width: "25%" }}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="test"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="test"
                                          title={each.profileTest.test}
                                          value={each.profileTest.test}
                                          autoFocus={
                                            each.profileTest.test === cloneTest
                                              ? true
                                              : false
                                          }
                                          // onChange={(e) =>
                                          //   testHandler(idx, e.target.value, "test")
                                          // }
                                        />
                                      </Form.Group>
                                    </td>

                                    {/* <td>
                                <Form.Group className="mb-3" controlId="method">
                                  <Form.Control
                                    type="text"
                                    placeholder="method"
                                    value={each.profileTest.method}
                                    onChange={(e) =>
                                      testHandler(idx, e.target.value, "method")
                                    }
                                  />
                                </Form.Group>
                              </td> */}

                                    <td>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="low"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="low"
                                          value={each.profileTest.low}
                                          onChange={(e) =>
                                            testHandler(
                                              idx,
                                              e.target.value,
                                              "low"
                                            )
                                          }
                                        />
                                      </Form.Group>
                                    </td>

                                    <td>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="high"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="high"
                                          value={each.profileTest.high}
                                          onChange={(e) =>
                                            testHandler(
                                              idx,
                                              e.target.value,
                                              "high"
                                            )
                                          }
                                        />
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group
                                        title={each.profileTest.unit}
                                        className="select mb-3"
                                        controlId="unit"
                                      >
                                        <select
                                          //
                                          style={{ maxHeight: "34px" }}
                                          className="ui dropdown text-center fluid"
                                          value={each.profileTest.unit}
                                          onChange={(e) =>
                                            testHandler(
                                              idx,
                                              e.target.value,
                                              "unit"
                                            )
                                          }
                                        >
                                          <option> -- --</option>
                                          {unitsData.map((each, idx) => (
                                            <option value={each} key={idx}>
                                              {each}
                                            </option>
                                          ))}
                                        </select>
                                      </Form.Group>
                                    </td>

                                    <td>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="result"
                                      >
                                        <Form.Control
                                          type="text"
                                          placeholder="result"
                                          value={each.profileTest.result}
                                          onChange={(e) =>
                                            testHandler(
                                              idx,
                                              e.target.value,
                                              "result"
                                            )
                                          }
                                        />
                                      </Form.Group>
                                      {each.profileTest.pastObservation?.length
                                        ? each.profileTest.pastObservation.map(
                                            (eachValue, index) => (
                                              <Form.Group
                                                className="mb-3"
                                                controlId="result"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="result"
                                                  value={eachValue.value}
                                                  onChange={(e) =>
                                                    testHandler(
                                                      idx,
                                                      e.target.value,
                                                      "updatePastValue",
                                                      index
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            )
                                          )
                                        : ""}
                                    </td>

                                    <td style={{ width: "16%" }}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="result"
                                      >
                                        <Form.Control
                                          type="date"
                                          placeholder="Select Date"
                                          value={
                                            each.profileTest.observation_time
                                              ? each.profileTest
                                                  .observation_time
                                              : today
                                          }
                                          onChange={(e) => {
                                            testHandler(
                                              idx,
                                              e.target.value,
                                              "currentDate"
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                      {each.profileTest.pastObservation?.length
                                        ? each.profileTest.pastObservation.map(
                                            (eachValue, index) => (
                                              <Form.Group
                                                className="mb-3"
                                                controlId="result"
                                              >
                                                <Form.Control
                                                  type="date"
                                                  placeholder="Select Date"
                                                  max={getRestrictedDate(
                                                    each.profileTest
                                                      .observation_time
                                                      ? each.profileTest
                                                          .observation_time
                                                      : today
                                                  )}
                                                  value={
                                                    eachValue.observation_time
                                                  }
                                                  onChange={(e) =>
                                                    testHandler(
                                                      idx,
                                                      e.target.value,
                                                      "updatePastDate",
                                                      index
                                                    )
                                                  }
                                                  isInvalid={
                                                    each.profileTest
                                                      .observation_time <
                                                    eachValue.observation_time
                                                  }
                                                />
                                                {/* <Form.Control.Feedback type="invalid">
                                                Choose below current date
                                              </Form.Control.Feedback> */}
                                              </Form.Group>
                                            )
                                          )
                                        : ""}
                                    </td>

                                    <td
                                    // className="delete-button-css"
                                    // style={{ width: "10%" }}
                                    >
                                      <div
                                        className="mb-3"
                                        style={{
                                          height: "33px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <button
                                          onClick={() =>
                                            testDeleteHandler(each)
                                          }
                                          className="delete-button-css"
                                          style={{
                                            border: "none",
                                            background: "none",
                                            margin: "0",
                                          }}
                                        >
                                          <i
                                            style={{ marginTop: "10px" }}
                                            title="delete test"
                                            className="fas fa-trash-alt fa-lg"
                                          ></i>
                                        </button>
                                      </div>
                                      {each.profileTest.pastObservation?.length
                                        ? each.profileTest.pastObservation.map(
                                            (eachValue, index) => (
                                              <div
                                                className="mb-3"
                                                style={{
                                                  height: "33px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                <button
                                                  onClick={() => {
                                                    setSendReportStatus(false);
                                                    testHandler(
                                                      idx,
                                                      "",
                                                      "deletePastData",
                                                      index
                                                    );
                                                  }}
                                                  className="delete-button-css"
                                                  style={{
                                                    border: "none",
                                                    background: "none",
                                                    margin: "0",
                                                  }}
                                                >
                                                  <i
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                    title="delete test"
                                                    className="fas fa-trash-alt fa-lg"
                                                  ></i>
                                                </button>
                                              </div>
                                            )
                                          )
                                        : ""}
                                    </td>
                                    <td>
                                      <button
                                        title="add test"
                                        className="btn-sm btn btn-primary mt-1"
                                        disabled={
                                          each.profileTest?.pastObservation
                                            ?.length >= 4
                                        }
                                        onClick={() =>
                                          testHandler(
                                            idx,
                                            { observation_time: "", value: "" },
                                            "emptyPastData"
                                          )
                                        }
                                      >
                                        <i className="fas fa-plus-circle"></i>
                                      </button>
                                    </td>
                                  </tr>
                                ) : null
                              )}

                            <tr
                              style={{ backgroundColor: "#f2f2f2" }}
                              onClick={() => {
                                setTerm("");
                                setGoDownStatus(true);
                              }}
                            >
                              <td style={{ width: "25%" }}>
                                {/* <Form.Group className="mb-3" controlId="test">
                            <Form.Control
                              className="autocomplete"
                              autoComplete={false}
                              type="text"
                              placeholder="test"
                              value={test}
                              onChange={(e) => setTest(e.target.value)}
                            />
                          </Form.Group> */}

                                <Form.Group>
                                  <Dropdown
                                    style={{ margin: "-2px 0px 0px 0px" }}
                                    placeholder="New Test"
                                    fluid
                                    search
                                    selection
                                    value={test}
                                    //onChange={(e) => setTest(e.target.value)}
                                    onChange={handleDropDownValue}
                                    options={stateOptions}
                                  />
                                </Form.Group>
                              </td>

                              {/* <td>
                          <Form.Group className="mb-3" controlId="method">
                            <Form.Control
                              type="text"
                              placeholder="method"
                              value={method}
                              onChange={(e) => setMethod(e.target.value)}
                            />
                          </Form.Group>
                        </td> */}

                              <td>
                                <Form.Group className="mb-3" controlId="low">
                                  <Form.Control
                                    type="text"
                                    placeholder="low"
                                    value={low}
                                    onChange={(e) => setLow(e.target.value)}
                                  />
                                </Form.Group>
                              </td>

                              <td>
                                <Form.Group className="mb-3" controlId="high">
                                  <Form.Control
                                    type="text"
                                    placeholder="high"
                                    value={high}
                                    onChange={(e) => setHigh(e.target.value)}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <Form.Group
                                  title={unit}
                                  className="mb-3"
                                  controlId="unit"
                                >
                                  <select
                                    //style={{ width: "100%", maxHeight: "34px" }}
                                    style={{ maxHeight: "34px" }}
                                    className="ui dropdown text-center fluid"
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                  >
                                    <option> -- --</option>
                                    {unitsData.map((each, idx) => (
                                      <option value={each} key={idx}>
                                        {each}
                                      </option>
                                    ))}
                                  </select>
                                </Form.Group>
                              </td>

                              <td>
                                <Form.Group className="mb-3" controlId="result">
                                  <Form.Control
                                    type="text"
                                    placeholder="result"
                                    value={result}
                                    onChange={(e) => setResult(e.target.value)}
                                  />
                                </Form.Group>
                              </td>
                              <td
                                style={{ width: "16%", visibility: "hidden" }}
                              >
                                <Form.Group className="mb-3" controlId="result">
                                  <Form.Control
                                    type="date"
                                    placeholder="Date"
                                    // value={newDate}
                                    // onChange={(e) => setNewDate(e.target.value)}
                                  />
                                </Form.Group>
                              </td>
                              <td>
                                <button
                                  type="submit"
                                  title="add test"
                                  className="btn-sm btn btn-primary mt-1"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                </button>
                              </td>
                              <td style={{ visibility: "hidden" }}>
                                <button
                                  title="add test"
                                  className="btn-sm btn btn-primary mt-1"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Form>
                    </div>
                  )}
                </Segment>
              </Col>
            </Row>

            <button
              onClick={handleShow}
              className="btn btn-outline-danger btn-sm my-3"
            >
              <i className="fas fa-undo-alt"></i> Reset Profile
            </button>

            <button
              //disabled
              disabled={sendReportStatus === true}
              onClick={submitReportHandler}
              className="btn btn-primary btn-sm button-float-css my-3"
            >
              Save Report
            </button>

            {/* <button onClick={myNewFunction}>
        get value
      </button> */}

            {showLoading ? (
              <button
                className="btn btn-success btn-sm my-3 button-float-css mx-2"
                type="button"
                disabled
              >
                <span
                  className="spinner-border text-light spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Getting Link...
              </button>
            ) : (
              <>
                <button
                  className="btn btn-success btn-sm my-3 button-float-css mx-3"
                  onClick={() => {
                    if (finalRecord) exportCsv(updatingJson(finalRecord));
                  }}
                  disabled={sendReportStatus === false}
                >
                  Export CSV
                </button>
                <button
                  disabled={sendReportStatus === false}
                  className="btn btn-success btn-sm my-3 button-float-css mx-3"
                  onClick={() => {
                    sendData();
                    setShowLoading(true);
                  }}
                >
                  {pdfLinks === undefined ? "Try Again" : "Get Smart Report"}{" "}
                  <i className="far fa-file-pdf"></i>
                </button>
              </>
            )}

            {pdfLinks.length === 0 || pdfLinks === undefined ? (
              ""
            ) : (
              <>
                <span
                  className="button-hover-css text-primary"
                  onClick={() => downloadZip()}
                  style={{ float: "right", marginTop: "18px" }}
                >
                  download pdf <i className="fas fa-download"></i>
                </span>
              </>
            )}

            {/* <button className="button-float-css mt-3" onClick={newLogic}>my data</button> */}
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default ProfileTests;
