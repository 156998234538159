import { colors } from "@mui/material";
import { createTheme } from "@mui/material/styles";
export const ui_colors = {
  primary: "#3567D6",
  secondary: "#3CD0FF",
  primaryBack: "#F5F2F0",
  secondaryBack: "#F7F6F6",
  semiPrimary: "#f5f9ff",
  textPrimary: "#7F7F7F",
  lightGray: "#D9D9D9",
  pending: "#F7B217",
  error: "#ff0000",
};

export const border = (width = 1) => `${width}px solid #${ui_colors.lightGray}`;

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: `'IBM Plex Sans', 'Poppins', Inter`,
    },
  },

  palette: {
    primary: {
      main: ui_colors.primary,
    },
    secondary: {
      main: ui_colors.secondary,
    },
    error: {
      main: ui_colors.error,
    },
  },

  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "pulse",
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          scale: "1.1",
          padding: "8px",
          textTransform: "capitalize",
        },
      },
    },

    // MuiToolbar: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow: "none",
    //     },
    //   },
    // },

    MuiTypography: {
      styleOverrides: {
        h1: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "32px",
          fontWeight: "500",
          lineHeight: "130%",
        },
        h2: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "20px",
          fontWeight: "500",
          lineHeight: "130%",
        },
        h3: {
          color: "#818181",
          fontFamily: "IBM Plex Sans",
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "130%",
        },
        h4: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "20px",
          fontWeight: "500",
          lineHeight: "130%",
        },
        h5: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "130%",
        },
        h6: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "130%",
        },
        body1: {
          color: "#818181",
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "18px",
        },
        body2: {
          color: "#3A383F",
          fontFamily: "IBM Plex Sans",
          fontSize: "17px",
          fontStyle: "normal",
          lineHeight: "18px",
        },
        body3: {
          color: "#fff",
          fontFamily: "IBM Plex Sans",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "18px",
        },
        body4: {
          color: "#818181",
          fontFamily: "IBM Plex Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "18px",
        },
        body5: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "18px",
        },
        body6: {
          color: "#818181",
          fontFamily: "IBM Plex Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "18px",
        },
        body7: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "18px",
        },
        body8: {
          color: "#27252E",
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
        },
        body9: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
        },
        body10: {
          color: "#000000",
          fontFamily: "IBM Plex Sans",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "500",
        },
      },
    },

    // MuiDialogTitle: {
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 600,
    //     },
    //   },
    // },

    // MuiSelect: {
    //   defaultProps: {
    //     MenuProps: {
    //       sx: {
    //         background: "#000",
    //         ".MuiMenu-paper": {
    //           borderRadius: 8,
    //           border: border(),
    //         },
    //       },
    //     },
    //   },
    //   styleOverrides: {
    //     outlined: {
    //       "&.MuiOutlinedInput-input": {
    //         padding: "8px 12px",
    //       },
    //       "&.MuiPaper-root": {
    //         borderRadius: 8,
    //         border: border(),
    //         // background: "#b94040",
    //       },
    //     },
    //   },
    // },

    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "IBM Plex Sans",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "130%",
          textDecoration: "none",
          borderRadius: "30px",
          padding: "8px 16px",
          background: "#3567d6",
          color: "#fff",
          textTransform: "none",
          "&.Mui-disabled": {
            background: "transparent",
            boxShadow: `inset 0px 0px 0px 1px ${ui_colors.primary}`,
          },
          ":hover": {
            boxShadow: "none",
            background: "transparent",
            border: "1px solid #3567d6",
            color: "#000000",
          },
        },
        custom: {
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "IBM Plex Sans",
          padding: "12px 40px",
          borderRadius: "30px",
          textTransform: "none",
          background: "linear-gradient(to left, #22D1EE, #3D5AF1)",
          color: "#fff",
        },
        custom2: {
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "IBM Plex Sans",
          borderRadius: "8px",
          background: "#F1EFFF",
          color: "#7263FF",
          width: "100%",
        },
        custom3: {
          fontSize: "14px",
          fontWeight: 700,
          fontFamily: "IBM Plex Sans",
          borderRadius: "4px",
          background: "transparent",
          color: "#000000",
          border: "1px solid #D7D7D7",
          boxShadow: "0 1px 2px 0 rgba(0,0,0,0.1)",
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          padding: "16px 20px",
          minHeight: 0,
          background: "#fff",
          borderRadius: "8px",
          border: "1px solid #D7D7D7",
          boxShadow: "0px 1px 3px 0px rgba(63,61,60,0.15)",

          "&.Mui-expanded": {
            minHeight: 0,
            margin: 0,
          },
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
  },
});
