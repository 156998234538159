import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Dropdown } from "semantic-ui-react";
import { Form } from "react-bootstrap";
import { stiJson, stiTestsList } from "./dummyTestData";
import { ImBin } from "react-icons/im";
import { useAlert } from "react-alert";
import axios from "axios";

const STIReport = (props) => {
  let navigate = useNavigate();
  const [saveOption, setSaveOption] = useState(true);
  const [fullJsonData, setFullJsonData] = useState({});
  const [testJsonData, SetTestJsonData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [generationOption, setGenerationOption] = useState(false);
  const [center, setCenter] = useState();

  // hooks for add Tests
  const [testName, setTestName] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [result, setResult] = useState("");
  const [unit, setUnit] = useState("");

  const resultValues = [
    {
      key: "1-POSITIVE",
      text: "Positive",
      value: "POSITIVE",
    },
    {
      key: "2-Negative",
      text: "Negative",
      value: "NEGATIVE",
    },
    {
      key: "3-Present",
      text: "Present",
      value: "PRESENT",
    },
    {
      key: "4-Absent",
      text: "Absent",
      value: "ABSENT",
    },
    {
      key: "5-REACTIVE",
      text: "Reactive",
      value: "REACTIVE",
    },
    {
      key: "6-NON REACTIVE",
      text: "Non Reactive",
      value: "NON REACTIVE",
    },
  ];
  const packages = [
    {
      key: "STI",
      text: "STI",
      value: "STI",
    },
  ];

  useEffect(() => {
    const loginData = localStorage.getItem("credentials");
    if (JSON.parse(loginData)) {
      setCenter(JSON.parse(loginData)["center"]);
    } else {
      navigate("/login");
    }
    setFullJsonData(stiJson);

    const defaultTestJsonData = stiTestsList.map((eachTest) => {
      const newTest = {
        name: eachTest,
        observation_time: "",
        id: "",
        value: "",
        MinValue: "",
        MaxValue: "",
        unit: "",
        method: "",
        impression: "",
        pastObservation: [],
      };
      return newTest;
    });
    SetTestJsonData(defaultTestJsonData);
  }, []);

  const gettingPdfBuffer = async () => {
    setLoader(true);
    const config = {
      method: "POST",
      url: "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/production/pdfparserclients",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(fullJsonData),
    };
    console.log("json Sending", fullJsonData);

    try {
      const axiosData = await axios(config);

      if (axiosData.data.PdfData) {
        downloadPDF(axiosData.data.PdfData, fullJsonData.PName);
        alert.show("Report Generated Successfully");
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error.message);
      alert.error("Report Generation Error");
    }
  };

  const downloadPDF = (myPdfDoc, emp_name) => {
    console.log("the file getting downloaded");
    const linkSource = `data:application/pdf;base64,${myPdfDoc}`;
    const downloadLink = document.createElement("a");
    const fileName = `${emp_name}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    window.location.reload();
  };

  const addingTest = () => {
    const newTest = {
      name: testName,
      observation_time: "",
      id: "",
      value: result,
      MinValue: minValue,
      MaxValue: maxValue,
      unit: unit,
      method: "",
      impression: "",
      pastObservation: [],
    };
    if (testName !== "" || result !== "") {
      SetTestJsonData((prevState) => [...prevState, newTest]);
      setSaveOption(false);
      setTestName("");
      setMinValue("");
      setMaxValue("");
      setResult("");
      setUnit("");
    } else {
      alert.error("Pls Enter all Details");
    }
  };

  const genderOptions = [
    {
      key: "male",
      text: "Male",
      value: "Male",
    },
    {
      key: "Female",
      text: "Female",
      value: "Female",
    },
  ];

  const handlingDataEditing = (id, value, type) => {
    // console.log(id, value, type);
    let data = testJsonData;
    const newdata = data.map((eachData, idx) => {
      if (id === idx) {
        const editedData = { ...eachData };
        editedData[type] = value;
        return { ...editedData };
      } else {
        return { ...eachData };
      }
    });
    SetTestJsonData(newdata);
  };

  const handleDeleteTest = (id) => {
    const newTests = testJsonData.filter((eachData, idx) => id !== idx);
    SetTestJsonData(newTests);
  };

  const alert = useAlert();

  const updatingFullJson = () => {
    console.log(testJsonData);
    const UpdatedJson = fullJsonData;
    UpdatedJson.results[0].investigation[0].observations = testJsonData;
    setFullJsonData(UpdatedJson);
    const checkForEmptyJson =
      UpdatedJson.PName !== "" &&
      UpdatedJson.LabRefno !== "" &&
      UpdatedJson.Age !== "" &&
      UpdatedJson.Gender !== "" &&
      UpdatedJson.results[0].investigation[0].observations &&
      UpdatedJson.results[0].investigation[0].observations.length !== 0;
    if (checkForEmptyJson) {
      setGenerationOption(true);
    }
  };

  const patientDetailsEditing = (key, value) => {
    const newUpdatedJson = {};
    for (let eachkey of Object.keys(fullJsonData)) {
      if (eachkey !== key) {
        newUpdatedJson[eachkey] = fullJsonData[eachkey];
      } else {
        if (key === "Package_name") {
          newUpdatedJson.results[0].Package_name = value;
        } else {
          newUpdatedJson[key] = value;
        }
      }
    }
    setFullJsonData(newUpdatedJson);
    // console.log(key, value, newUpdateddata[key]);
  };

  return (
    <>
      <Table striped>
        <>
          <thead>
            <tr className="text-center">
              <th style={{ fontWeight: "600" }}>Patient Name *</th>
              {/* <th width="22%" style={{ fontWeight: "600" }}>
            Method
          </th> */}
              <th style={{ fontWeight: "600" }}>Age *</th>
              <th style={{ fontWeight: "600" }}>Gender *</th>

              <th style={{ fontWeight: "600" }}>Lab Ref No *</th>
              <th style={{ fontWeight: "600" }}>Package Name</th>
            </tr>
          </thead>

          <tbody
            className="text-center patientDetailsTableBody"
            style={{ overflow: "visible" }}
          >
            <tr>
              <td>
                <Form.Group controlId="PName">
                  <Form.Control
                    onChange={(e) => {
                      setSaveOption(false);
                      patientDetailsEditing("PName", e.target.value);
                    }}
                    type="text"
                    placeholder="Patient Name"
                    //   value={fullJsonData.PName}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="Age">
                  <Form.Control
                    onChange={(e) => {
                      setSaveOption(false);
                      patientDetailsEditing("Age", e.target.value);
                    }}
                    type="text"
                    placeholder="Patient Age"
                    value={fullJsonData.Age}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="Gender">
                  {/* <Form.Control
              onChange={e => {
                setSaveOption(false);
                patientDetailsEditing('Gender', e.target.value);
              }}
              type='text'
              placeholder='Patient Gender'
              value={fullJsonData.Gender}
              style={{ height: '38px' }}
            /> */}
                  <Dropdown
                    // disabled={selectionChoice === ''}
                    placeholder={
                      fullJsonData.Gender
                        ? fullJsonData.Gender
                        : "Select Gender"
                    }
                    fluid
                    search
                    selection
                    value={fullJsonData.Gender}
                    onChange={(event, data) => {
                      console.log(data);
                      setSaveOption(false);
                      // setGender(data.value);
                      patientDetailsEditing("Gender", data.value);
                    }}
                    options={genderOptions}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="LabRefno">
                  <Form.Control
                    type="text"
                    placeholder="Lab Ref No"
                    value={fullJsonData.LabRefno}
                    onChange={(e) => {
                      setSaveOption(false);
                      patientDetailsEditing("LabRefno", e.target.value);
                    }}
                    style={{ height: "38px" }}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="Package">
                  <Dropdown
                    // disabled={selectionChoice === ''}
                    placeholder="Select Package"
                    fluid
                    search
                    selection
                    onChange={(event, data) => {
                      handlingDataEditing("Package_name", data.value);
                    }}
                    options={packages}
                  />
                  {/* <Form.Control
                    type='text'
                    placeholder='Select Package'
                    value={
                      fullJsonData.result
                    }
                    onChange={e => {
                      setSaveOption(false);
                      patientDetailsEditing('Package_name', e.target.value);
                    }}
                    style={{ height: '38px' }}
                  /> */}
                </Form.Group>
              </td>
            </tr>
          </tbody>
        </>
      </Table>
      <Table striped>
        <>
          <thead>
            <tr className="text-center">
              <th style={{ fontWeight: "600" }}>Test</th>
              {/* <th width="22%" style={{ fontWeight: "600" }}>
                Method
              </th> */}
              <th style={{ fontWeight: "600" }}>Low</th>
              <th style={{ fontWeight: "600" }}>High</th>
              <th style={{ fontWeight: "600" }}>Unit</th>
              <th style={{ fontWeight: "600" }}>Result</th>
              <th style={{ fontWeight: "600" }}>Add/Del</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {testJsonData.map((eachData, idx) => (
              <tr className="text-center" key={eachData.name}>
                <td>
                  <Form.Group controlId="testName">
                    <Form.Control type="text" value={eachData.name} />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group controlId="minValue">
                    <Form.Control
                      type="text"
                      placeholder={
                        eachData.MinValue ? eachData.MinValue : "Min Value"
                      }
                      value={eachData.MinValue}
                      onChange={(e) => {
                        setSaveOption(false);
                        handlingDataEditing(idx, e.target.value, "MinValue");
                      }}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group controlId="MaxValue">
                    <Form.Control
                      type="text"
                      placeholder={
                        eachData.MaxValue ? eachData.MaxValue : "Max Value"
                      }
                      value={eachData.MaxValue}
                      onChange={(e) => {
                        setSaveOption(false);
                        handlingDataEditing(idx, e.target.value, "MaxValue");
                      }}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group controlId="unit">
                    <Form.Control
                      type="text"
                      placeholder={eachData.unit ? eachData.unit : "Unit"}
                      value={eachData.unit}
                      onChange={(e) => {
                        setSaveOption(false);
                        handlingDataEditing(idx, e.target.value, "unit");
                      }}
                    />
                  </Form.Group>
                </td>
                <td>
                  <Form.Group controlId="Result">
                    {/* <Form.Control
                      type='text'
                      value={eachData.value}
                      onChange={e => {
                        setSaveOption(false);
                        handlingDataEditing(idx, e.target.value, 'value');
                      }}
                    /> */}
                    <Dropdown
                      style={{ margin: "-2px 0px 0px 0px" }}
                      placeholder={eachData.value ? eachData.value : "Result"}
                      fluid
                      search
                      selection
                      value={eachData.value}
                      clearable
                      onChange={(event, data) => {
                        setSaveOption(false);
                        handlingDataEditing(idx, data.value, "value");
                      }}
                      options={resultValues}
                    />
                  </Form.Group>
                </td>
                <td
                  onClick={() => {
                    setSaveOption(false);
                    handleDeleteTest(idx);
                  }}
                >
                  <div className="delete-button-css">
                    <ImBin />
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <Form.Group controlId="testName">
                  <Form.Control
                    type="text"
                    placeholder="Test Name"
                    onChange={(e) => {
                      setSaveOption(false);
                      setTestName(e.target.value);
                    }}
                    value={testName}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="minValue">
                  <Form.Control
                    type="text"
                    placeholder="minValue"
                    onChange={(e) => {
                      setMinValue(e.target.value);
                    }}
                    value={minValue}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="maxValue">
                  <Form.Control
                    type="text"
                    placeholder="maxValue"
                    onChange={(e) => setMaxValue(e.target.value)}
                    value={maxValue}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="unit">
                  <Form.Control
                    type="text"
                    placeholder="unit"
                    onChange={(e) => setUnit(e.target.value)}
                    value={unit}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="Result">
                  {/* <Form.Control
                    type='text'
                    placeholder='Result'
                    onChange={e => setResult(e.target.value)}
                    value={result}
                  /> */}
                  <Dropdown
                    style={{ margin: "-2px 0px 0px 0px" }}
                    placeholder="Result"
                    fluid
                    search
                    selection
                    value={result}
                    clearable
                    onChange={(event, data) => {
                      setResult(data.value);
                    }}
                    options={resultValues}
                  />
                </Form.Group>
              </td>
              <td
                onClick={() => {
                  addingTest();
                }}
              >
                <div title="Add Test" className="btn-sm btn btn-primary mt-1">
                  <i className="fas fa-plus-circle"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </>
      </Table>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <button
          disabled={saveOption}
          className="btn btn-success btn-sm button-focus-css "
          style={{ marginRight: "15px", marginTop: "15px" }}
          onClick={() => {
            // SetTestJsonData(testJsonData);
            updatingFullJson();
            setSaveOption(true);
          }}
        >
          Save Changes
        </button>

        <button
          title="Pls Enter all Patient Details and atleast One Parameter to Generate Smart Report"
          disabled={!generationOption}
          className="btn btn-success btn-sm button-focus-css "
          style={{ marginRight: "15px", marginTop: "15px" }}
          onClick={() => {
            gettingPdfBuffer();
          }}
        >
          {!loader ? (
            "Generate Report"
          ) : (
            <>
              {"Generating PDF   "}{" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default STIReport;
