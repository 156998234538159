import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

export const FetchPercentage = () => {
  const [numberOfReportsConsumed, setNumberOfReportsConsumed] = useState('0');
  const [reportsSubscribedInfo, setReportsSubscribedInfo] = useState('0');
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const dataFetchedRef = useRef(false);

  const [percentage, setPercentage] = useState('0');

  useEffect(() => {
    //console.log("calling plan details section")
    const loginInfo = JSON.parse(localStorage.getItem('loginDetails'));
    let homepageData = JSON.parse(localStorage.getItem('homepageData'));
    let clientInfo = JSON.parse(localStorage.getItem('clientPreference'));

    if (loginInfo) {
      setReportsSubscribedInfo(loginInfo.reportsSubscribed);
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      fetchReportCounts();
    }
  }, [reRenderComponent]);

  const fetchReportCounts = async () => {
    let requiredData = JSON.parse(localStorage.getItem('loginDetails'));

    //console.log("fetching report counts....")
    // fetching reports consumed by client
    const endpointToCall = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${requiredData.org}&center=${requiredData.center}&startDate=${requiredData.startDate}&endDate=${requiredData.endDate}&query_on=${requiredData.role}`;

    //console.log("this one: ", endpointToCall)

    let config = {
      url: endpointToCall,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      //data: dataToSend,
    };

    //console.log("report count json:\n")
    //console.log(dataToSend)

    try {
      const axiosData = await axios(config);
      //console.log("inside axiosdata Report count  ===> \n\n", axiosData.data.subscription_data)
      let reportCountData = axiosData.data.subscription_data;
      //localStorage.setItem("homepageData", JSON.stringify({ "data": axiosData.data }))
      // window.dispatchEvent(new Event("storage"));
      if (reportCountData) {
        setNumberOfReportsConsumed(
          parseInt(reportCountData.subscription_usage)
        );
      }
    } catch (error) {
      console.log('Report count Error:\n', error);
    }
  };

  // calculate percentage
  //    useEffect(() => {

  // if (numberOfReportsConsumed !== 0) {
  //     // set percentage
  //     const thePercentage = Math.round((numberOfReportsConsumed / reportsSubscribedInfo : 0) * 100)

  //     if (thePercentage > 100) {
  //         setPercentage(100)
  //     } else {
  //         setPercentage(thePercentage)
  //     }
  // }

  // }, [numberOfReportsConsumed, percentage])

  // calculate percentage
  useEffect(() => {
    const thePercentage = Math.round(
      (numberOfReportsConsumed / reportsSubscribedInfo) * 100
    );
    // set percentage

    if (thePercentage > 100) {
      setPercentage(100);
    } else {
      setPercentage(thePercentage);
    }
  }, [numberOfReportsConsumed, percentage]);

  // console.log("over here percentage: ", percentage)

  return percentage;
};
