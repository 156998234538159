import React, { useState, useEffect } from "react";
import { Table, Dropdown, Checkbox } from "semantic-ui-react";
import { Button, Form } from "react-bootstrap";
// import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import { useAlert } from "react-alert";
// import {
//   completePackageData,
//   completePackageDataForFemale,
// } from './dummyTestData';
// import { cloneDeepWithoutLoc } from '@babel/types';
// import { useAlert } from 'react-alert';
// import JsZip from 'jszip';
// import { saveAs } from 'file-saver';
// import AlertTemplate from 'react-alert-template-basic';
// import data from '../components/dummyjson';
import { ImBin } from "react-icons/im";
import { testsList, vitalsTestList } from "./dummyTestData";
import { TiTick } from "react-icons/ti";
const TestPreview = (props) => {
  const [jsonData, setJsonData] = useState([]);

  const [clientId, setClientId] = useState("");
  const [letterHead, setLetterHead] = useState(false);

  const alert = useAlert();

  const [fullJsonData, setFullJsonData] = useState([]);

  const [saveOption, setSaveOption] = useState(true);
  const [loader, setLoader] = useState(false);

  const [pageLoader, setPageLoader] = useState(false);

  // hooks for add Tests
  const [testName, setTestName] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [result, setResult] = useState("");
  const [unit, setUnit] = useState("");

  // hooks for dropdown values
  const [packages, setPackages] = useState([]);
  const [gender, setGender] = useState("");

  //  button to copy url link
  const [urlButton, setUrlButton] = useState(false);
  const [urlGeneration, setUrlGeneration] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [copyStatus, setCopyStatus] = useState(false);
  // Function for adding tests in to jsonData

  const [vizApp, setVizApp] = useState(false);
  const [responseData, setResposeData] = useState("");
  const [vizAppLink, setVizAppLink] = useState("");
  const [vizAppPassword, setVizAppPassword] = useState("");

  const addingTest = () => {
    const newTest = {
      name: testName,
      observation_time: "",
      id: "",
      value: result,
      MinValue: minValue,
      MaxValue: maxValue,
      unit: unit,
      method: "",
      impression: "",
      pastObservation: [],
    };
    if (testName !== "" || result !== "") {
      setJsonData((prevState) => [...prevState, newTest]);
      setTestName("");
      setMinValue("");
      setMaxValue("");
      setResult("");
      setUnit("");
    } else {
      alert.error("Pls Enter all Details");
    }
  };

  const addingVitalTest = (vitalTest) => {
    const newTest = {
      name: vitalTest.test,
      observation_time: "",
      id: "",
      value: "",
      MinValue: vitalTest.low,
      MaxValue: vitalTest.high,
      unit: vitalTest.unit,
      method: "",
      impression: "",
      pastObservation: [],
    };

    setJsonData((prevState) => [...prevState, newTest]);
  };

  const settingPackages = (packgesObject) => {
    let sampleArray = [];
    for (let eachObject in packgesObject) {
      sampleArray.push({
        key: packgesObject[eachObject],
        text: packgesObject[eachObject],
        value: eachObject,
      });
    }
    setPackages(sampleArray);
  };

  useEffect(() => {
    setPageLoader(true);
    // const clientId =
    //   JSON.parse(localStorage.getItem('credentials'))['center'] ===
    //   'mayormedical'
    //     ? 'mayor'
    //     : 'curis';
    // switch (key) {
    //   case value:

    //     break;

    //   default:
    //     break;
    // }
    let clientId = "";
    switch (JSON.parse(localStorage.getItem("credentials"))["center"]) {
      case "mayormedical":
        clientId = "mayor";
        break;
      case "curis":
        clientId = "curis";
        break;
      case "srh":
        clientId = "SRH";
        break;
      case "pfh":
        clientId = "thyrocare";
        break;
      case "zaincare":
        clientId = "thumbay";
        break;
      case "testinguser":
        clientId = "curis";
        break;
      default:
        break;
    }
    setClientId(clientId);
    settingPackages(
      JSON.parse(localStorage.getItem("credentials"))["reportPackages"]
    );
    const { base64 } = props;
    const body = { pdfBase64: base64, clientId };
    // console.log(JSON.stringify(body));
    const config = {
      method: "POST",
      // sampleUrl from Gist
      // url:
      //   JSON.parse(localStorage.getItem("credentials"))["center"] !== "zaincare"
      //     ? "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/production/pdfparserbackend"
      //     : "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/production/pdfparserzaincare",
      url: "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/production/pdfparserbackend",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(body),
    };

    (async () => {
      try {
        const axiosData = await axios(config);
        const jsonData = axiosData.data.results;
        console.log("jsondata from PDF Parser", axiosData);
        const packagesData = jsonData.map(
          (eachPackage) => eachPackage.investigation
        );

        let tests = [];
        packagesData.forEach((eachTest) => {
          eachTest.map((eachOb) => tests.push(eachOb.observations));
        });
        // console.log(tests);

        let uniqueTestsObj = {};
        let uniqueTests2 = [];

        for (let i = 0; i < tests[0].length; i++) {
          uniqueTestsObj[tests[0][i].name] = tests[0][i];
        }
        for (let eachKey in uniqueTestsObj) {
          uniqueTests2.push(uniqueTestsObj[eachKey]);
        }

        console.log(
          "Tests from PDF Parser",
          tests[0].length,
          "Unique Tests",
          uniqueTests2.length
        );

        if (
          JSON.parse(localStorage.getItem("credentials"))["center"] ===
          "mayormedical"
        ) {
          setJsonData(cleaningResultValues(uniqueTests2));
        } else {
          setJsonData(uniqueTests2);
        }

        setFullJsonData(axiosData.data);
        setGender(axiosData.data.Gender);
        // settingPackages();
        setPageLoader(false);
      } catch (e) {
        console.log(e.message);
        // alert.error('Preview Error');
        setPageLoader(false);
      }
    })();
  }, []);

  const patientDetailsEditing = (key, value) => {
    const newUpdatedJson = {};
    for (let eachkey of Object.keys(fullJsonData)) {
      if (eachkey !== key) {
        newUpdatedJson[eachkey] = fullJsonData[eachkey];
      } else {
        newUpdatedJson[key] = value;
      }
    }
    setFullJsonData(newUpdatedJson);
    // console.log(key, value, newUpdateddata[key]);
  };
  const handleDropDownValue = (event, data) => {
    const newUpdatedJson = fullJsonData;

    newUpdatedJson.results[0]["Package_name"] = data.value;
    setFullJsonData(newUpdatedJson);
  };

  const handlingDataEditing = (id, value, type) => {
    // console.log(id, value, type);
    let data = jsonData;
    const newdata = data.map((eachData, idx) => {
      if (id === idx) {
        const editedData = { ...eachData };
        editedData[type] = value;
        return { ...editedData };
      } else {
        return { ...eachData };
      }
    });
    setJsonData(newdata);
  };

  const cleaningResultValues = (tests) => {
    const cleanedTests = tests.map((eachTest) => {
      if (eachTest.value.split("%")[1]) {
        return {
          ...eachTest,
          name: eachTest.name.trim(),
          value: eachTest.value.split("%")[1].trim(),
        };
      }
      return eachTest;
    });
    return cleanedTests;
  };

  const handleDeleteTest = (id) => {
    const newTests = jsonData.filter((eachData, idx) => id !== idx);
    setJsonData(newTests);
  };

  const gettingPdfBuffer = async () => {
    /// updatingEditedTestData  in fetched json
    setLoader(true);
    const updatedData = fullJsonData;
    const reportConfiguration = {
      vizapp: vizApp,
    };
    updatedData.Centre = JSON.parse(localStorage.getItem("credentials"))[
      "center"
    ];
    updatedData.LabNo = fullJsonData.LabRefno
      ? fullJsonData.LabRefno
      : fullJsonData.LabNo;
    updatedData.reportType = JSON.parse(localStorage.getItem("credentials"))[
      "reportSubscription"
    ]["reportType"];
    if (JSON.parse(localStorage.getItem("credentials"))["center"] === "curis") {
      updatedData.letterHead = letterHead;
      updatedData.reportConfiguration = reportConfiguration;
    }
    updatedData.results[0].investigation[0].observations = jsonData;
    console.log("json Sending", updatedData);
    const apiEndPoint =
      "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/production/pdfparserclients";
    const configuration = {
      method: "POST",
      // sampleUrl from Gist
      url: apiEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(updatedData),
    };

    try {
      const axiosData = await axios(configuration);
      console.log(axiosData);
      if (axiosData.data.PdfData) {
        if (
          JSON.parse(localStorage.getItem("credentials"))["center"] === "curis"
        ) {
          setResposeData(axiosData.data.PdfData);
          setVizAppLink(axiosData.data?.vizAppUrl);
          setVizAppPassword(axiosData.data?.vizAppPassword);
        }
        // adding request for PixAnimate

        if (
          JSON.parse(localStorage.getItem("credentials"))["center"] ===
            "curis" ||
          JSON.parse(localStorage.getItem("credentials"))["center"] ===
            "testinguser"
        ) {
          try {
            if (axiosData.data.externalReportObjFileUrl) {
              setUrlButton(true);
              setUrlGeneration(true);
              const pixAnimateBody = {
                s3url: axiosData.data.externalReportObjFileUrl,
              };
              const pixAnimateEndPoint =
                "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/Testing/videoreport";
              const pixAnimateApiConfig = {
                method: "POST",
                url: pixAnimateEndPoint,
                data: JSON.stringify(pixAnimateBody),
                headers: {
                  "Content-Type": "application/json",
                },
              };
              const pixAnimateURL = await axios(pixAnimateApiConfig);
              // console.log(pixAnimateURL, 'pixAnimateURL');
              setUrlGeneration(false);
              if (pixAnimateURL.data.data.url) {
                setVideoUrl(pixAnimateURL.data.data.url);
              }
            }
          } catch (e) {
            console.log(e.message);
            alert.error("video link generation error");
          }
        }
        if (
          JSON.parse(localStorage.getItem("credentials"))["center"] !== "curis"
        ) {
          downloadPDF(axiosData.data.PdfData, updatedData.PName);
        }

        alert.show("Report Generated Successfully");
      } else {
        alert.error(
          <span style={{ textTransform: "capitalize" }}>
            Smart Report Generation Error{"         "}({axiosData.data.message})
          </span>
        );
      }
    } catch (e) {
      console.log(e.message);
    }
    setLoader(false);
  };

  const downloadPDF = (myPdfDoc, emp_name) => {
    console.log("the file getting downloaded");
    const linkSource = `data:application/pdf;base64,${myPdfDoc}`;
    const downloadLink = document.createElement("a");
    const fileName = `${emp_name}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    const center = JSON.parse(localStorage.getItem("credentials"))["center"];
    // if (
    //   center === 'curis' ||
    //   center === 'srh' ||
    //   center === 'mayormedical' ||
    //   center === 'zaincare'
    // ) {
    //   window.location.reload();
    // }
  };

  // console.log(fullJsonData);

  const TotalTests = testsList.map((each, index) => {
    return {
      key: index,
      text: each,
      value: each,
    };
  });

  const genderOptions = [
    {
      key: "male",
      text: "Male",
      value: "Male",
    },
    {
      key: "Female",
      text: "Female",
      value: "Female",
    },
  ];
  console.log("Present Json Data", jsonData);

  const addingVitalTests = () => {
    vitalsTestList.map((eachTest) => {
      addingVitalTest(eachTest);
    });
  };

  return (
    <>
      {pageLoader ? (
        <div>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        <>
          <Table striped>
            <>
              <thead>
                <tr className="text-center">
                  <th style={{ fontWeight: "600" }}>Patient Name</th>
                  {/* <th width="22%" style={{ fontWeight: "600" }}>
                      Method
                    </th> */}
                  <th style={{ fontWeight: "600" }}>Age</th>
                  <th style={{ fontWeight: "600" }}>Gender</th>

                  <th style={{ fontWeight: "600" }}>Lab Ref No</th>
                  {JSON.parse(localStorage.getItem("credentials"))["center"] !==
                  "zaincare" ? (
                    <th style={{ fontWeight: "600" }}>Package Name</th>
                  ) : (
                    <th style={{ fontWeight: "600" }}>Referral Doctor</th>
                  )}
                </tr>
              </thead>

              <tbody
                className="text-center patientDetailsTableBody"
                style={{ overflow: "visible" }}
              >
                <tr>
                  <td>
                    <Form.Group controlId="PName">
                      <Form.Control
                        onChange={(e) => {
                          setSaveOption(false);
                          patientDetailsEditing("PName", e.target.value);
                        }}
                        type="text"
                        placeholder="Patient Name"
                        value={fullJsonData.PName}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="Age">
                      <Form.Control
                        onChange={(e) => {
                          setSaveOption(false);
                          patientDetailsEditing("Age", e.target.value);
                        }}
                        type="text"
                        placeholder="Patient Age"
                        value={fullJsonData.Age}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="Gender">
                      {/* <Form.Control
                        onChange={e => {
                          setSaveOption(false);
                          patientDetailsEditing('Gender', e.target.value);
                        }}
                        type='text'
                        placeholder='Patient Gender'
                        value={fullJsonData.Gender}
                        style={{ height: '38px' }}
                      /> */}
                      <Dropdown
                        // disabled={selectionChoice === ''}
                        placeholder={gender ? gender : "Select Gender"}
                        fluid
                        search
                        selection
                        value={gender}
                        onChange={(event, data) => {
                          console.log(data);
                          setSaveOption(false);
                          setGender(data.value);
                          patientDetailsEditing("Gender", data.value);
                        }}
                        options={genderOptions}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="LabRefno">
                      <Form.Control
                        type="text"
                        placeholder="Lab Ref No"
                        value={
                          fullJsonData.LabRefno
                            ? fullJsonData.LabRefno
                            : fullJsonData.LabNo
                        }
                        onChange={(e) => {
                          setSaveOption(false);
                          patientDetailsEditing("LabRefno", e.target.value);
                        }}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    {JSON.parse(localStorage.getItem("credentials"))[
                      "center"
                    ] !== "zaincare" ? (
                      <Form.Group controlId="Package">
                        <Dropdown
                          // disabled={selectionChoice === ''}
                          placeholder="Select Package"
                          fluid
                          search
                          selection
                          onChange={handleDropDownValue}
                          options={packages}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="ReferredBy">
                        <Form.Control
                          type="text"
                          placeholder="Referral Doctor"
                          value={
                            fullJsonData.ReferredBy
                              ? fullJsonData.ReferredBy
                              : ""
                          }
                          onChange={(e) => {
                            setSaveOption(false);
                            patientDetailsEditing("ReferredBy", e.target.value);
                          }}
                          style={{ height: "38px" }}
                        />
                      </Form.Group>
                    )}
                  </td>
                </tr>
              </tbody>
            </>
          </Table>
          <Table striped>
            <>
              <thead>
                <tr className="text-center">
                  <th style={{ fontWeight: "600", width: "25%" }}>Test</th>
                  {/* <th width="22%" style={{ fontWeight: "600" }}>
                      Method
                    </th> */}
                  <th style={{ fontWeight: "600" }}>Result</th>
                  <th style={{ fontWeight: "600" }}>Unit</th>
                  <th style={{ fontWeight: "600" }}>Low</th>
                  <th style={{ fontWeight: "600" }}>High</th>
                  <th style={{ fontWeight: "600" }}>Add/Del</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {jsonData.map((eachData, idx) => (
                  <tr className="text-center" key={`eachData.name+${idx}`}>
                    <td style={{ width: "25%" }}>
                      <Form.Group controlId="testName">
                        <Form.Control
                          type="text"
                          value={eachData.name}
                          onChange={(e) => {
                            setSaveOption(false);
                            handlingDataEditing(idx, e.target.value, "name");
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group controlId="Result">
                        <Form.Control
                          type="text"
                          value={eachData.value}
                          onChange={(e) => {
                            setSaveOption(false);
                            handlingDataEditing(idx, e.target.value, "value");
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group controlId="unit">
                        <Form.Control
                          type="text"
                          value={eachData.unit}
                          onChange={(e) => {
                            setSaveOption(false);
                            handlingDataEditing(idx, e.target.value, "unit");
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group controlId="minValue">
                        <Form.Control
                          type="text"
                          value={eachData.MinValue}
                          onChange={(e) => {
                            setSaveOption(false);
                            handlingDataEditing(
                              idx,
                              e.target.value,
                              "MinValue"
                            );
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group controlId="MaxValue">
                        <Form.Control
                          type="text"
                          value={eachData.MaxValue}
                          onChange={(e) => {
                            setSaveOption(false);
                            handlingDataEditing(
                              idx,
                              e.target.value,
                              "MaxValue"
                            );
                          }}
                        />
                      </Form.Group>
                    </td>
                    <td
                      onClick={() => {
                        setSaveOption(false);
                        handleDeleteTest(idx);
                      }}
                    >
                      <div className="delete-button-css">
                        <ImBin />
                      </div>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td style={{ width: "25%" }}>
                    <Form.Group controlId="testName-dropdown">
                      {/* <Form.Control
                        type='text'
                        placeholder='Test Name'
                        onChange={e => {
                          setSaveOption(false);
                          setTestName(e.target.value);
                        }}
                        value={testName}
                      /> */}
                      <Dropdown
                        style={{ margin: "-2px 0px 0px 0px" }}
                        placeholder="New Test"
                        fluid
                        search
                        selection
                        value={testName}
                        clearable
                        onChange={(event, data) => {
                          setTestName(data.value);
                        }}
                        options={TotalTests}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="Result">
                      <Form.Control
                        type="text"
                        placeholder="Result"
                        onChange={(e) => setResult(e.target.value)}
                        value={result}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="unit">
                      <Form.Control
                        type="text"
                        placeholder="unit"
                        onChange={(e) => setUnit(e.target.value)}
                        value={unit}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="minValue">
                      <Form.Control
                        type="text"
                        placeholder="minValue"
                        onChange={(e) => {
                          setMinValue(e.target.value);
                        }}
                        value={minValue}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="maxValue">
                      <Form.Control
                        type="text"
                        placeholder="maxValue"
                        onChange={(e) => setMaxValue(e.target.value)}
                        value={maxValue}
                      />
                    </Form.Group>
                  </td>

                  <td onClick={() => addingTest()}>
                    <div
                      title="Add Test"
                      className="btn-sm btn btn-primary mt-1"
                    >
                      <i className="fas fa-plus-circle"></i>
                    </div>
                  </td>
                </tr>
                {JSON.parse(localStorage.getItem("credentials"))["center"] ===
                "zaincare" ? (
                  <>
                    <button
                      className="btn btn-success btn-sm button-focus-css"
                      style={{ margin: "15px" }}
                      onClick={() => {
                        addingVitalTests();
                        setSaveOption(false);
                      }}
                    >
                      Add Body Parameters
                    </button>
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </>
          </Table>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <button
              disabled={saveOption}
              className="btn btn-success btn-sm button-focus-css "
              style={{ marginRight: "15px", marginTop: "15px" }}
              onClick={() => {
                setJsonData(jsonData);
                setSaveOption(true);
              }}
            >
              Save Changes
            </button>
            {clientId === "curis" ? (
              <div>
                <select
                  className="selectTag "
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setLetterHead(true);
                      console.log(true);
                    } else if (e.target.value === "false") {
                      setLetterHead(false);
                      console.log(false);
                    }
                  }}
                  style={{ marginRight: "15px", marginTop: "15px" }}
                >
                  <option value="" disabled selected>
                    Select Letterhead Option (Default option is "No Letterhead")
                  </option>
                  <option value={true}>Report With LetterHead</option>
                  <option value={false}>Report Without LetterHead</option>
                </select>
                <Checkbox
                  style={{ marginRight: "12px" }}
                  label="Viz App"
                  checked={vizApp}
                  onChange={() => setVizApp(!vizApp)}
                />
              </div>
            ) : (
              ""
            )}

            <button
              disabled={!saveOption || loader}
              className="btn btn-success btn-sm button-focus-css "
              style={{ marginRight: "15px", marginTop: "15px" }}
              onClick={() => {
                gettingPdfBuffer();
              }}
            >
              {!loader ? (
                "Generate Report"
              ) : (
                <>
                  {"Generating PDF   "}{" "}
                  <span
                    className="spinner-border text-light spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              )}
            </button>
            {urlButton && (
              <button
                style={{ marginRight: "15px", marginTop: "15px" }}
                disabled={urlGeneration || videoUrl === ""}
                className="btn btn-success btn-sm button-focus-css "
                onClick={async () => {
                  setCopyStatus(false);
                  if ("clipboard" in navigator) {
                    setCopyStatus(true);
                    return await navigator.clipboard.writeText(videoUrl);
                  } else {
                    setCopyStatus(true);
                    return document.execCommand("copy", true, videoUrl);
                  }
                }}
              >
                {urlGeneration ? (
                  <>
                    {"Generating Video Link   "}{" "}
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : (
                  "Copy Video Link"
                )}
              </button>
            )}
            {copyStatus && (
              <p style={{ marginTop: "15px", fontWeight: "bold" }}>
                {" "}
                <TiTick size={"20"} color={"#38AF48"} />
                Link Copied
              </p>
            )}
          </div>
          <div style={{ margin: "20px 0px", paddingBottom: "30px" }}>
            {responseData ? (
              <Table striped style={{ width: "70%" }}>
                <>
                  <thead>
                    <tr className="text-center">
                      <th style={{ fontWeight: "600", width: "25%" }}>Name</th>

                      <th style={{ fontWeight: "600", width: "25%" }}>
                        Report Link
                      </th>
                      <th style={{ fontWeight: "600", width: "25%" }}>
                        Viz App Link
                      </th>
                      {/* <th style={{ fontWeight: "600", width: "25%" }}>
                        Viz App Password
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr>
                      <td>Smart Report</td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() =>
                            downloadPDF(responseData, fullJsonData.PName)
                          }
                        >
                          Download Report
                        </Button>
                      </td>
                      {vizAppLink ? (
                        <td>
                          <a href={vizAppLink} target="_blank">
                            Open Viz App
                          </a>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                      {/* {vizAppPassword ? (
                        <td>
                          <p>{vizAppPassword}</p>
                        </td>
                      ) : (
                        <td>-</td>
                      )} */}
                    </tr>
                  </tbody>
                </>
              </Table>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TestPreview;
