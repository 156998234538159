import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import NavbarComponent from "../NavbarComponent";
import { useState } from "react";
import { CustomGraphBox } from "../../common/BoxAbstraction";
import { useAlert } from "react-alert";
import referralImage from "../../assets/referral.gif";
import PageTitle from "../../common/PageTitle";

const Referral = () => {
  const alert = useAlert();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loader, setLoader] = useState(false);

  const clientData = JSON.parse(localStorage.getItem("loginDetails"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const referralDetails = {
      name: name ? name : "",
      email: email ? email : "",
      contact: phoneNo ? phoneNo : "",
      companyName: companyName ? companyName : "",
      clientId: clientData?.clientId ? clientData.clientId : "",
      org: clientData?.org ? clientData.org : "",
      center: clientData?.center ? clientData.center : "",
    };
    const apiUrl =
      "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/referral/dashboard";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(referralDetails),
    };

    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("referraldata", data);
      if (data?.insertStatus) {
        alert.success("Submitted Successfully");
      }
    } catch (e) {
      console.log("error", e);
      alert.error("Something went wrong!, Please try again");
    } finally {
      setLoader(false);
      setName("");
      setEmail("");
      setPhoneNo("");
      setCompanyName("");
    }
  };

  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />
      <Box
        flexGrow={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          padding: "0px 64px 40px",
          // justifyContent: "center",
          // alignItems: "center",
        }}
      >
        {/* <Typography variant="h1" pl={2}>
          Refer and Earn
        </Typography> */}
        <PageTitle title="Refer and Earn" />

        <Stack
          sx={{
            padding: "30px 48px",
            background: "#fff",
            border: "1px solid rgba(0,0,0,0.2)",
            borderRadius: "6px",
            boxShadow: "",
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img src={referralImage} alt="refer" width="400px" />
          <CustomGraphBox sx={{ background: "#fff" }}>
            <Typography variant="h6" mb={2}>
              Please fill the details of referral person below.
            </Typography>
            <Stack gap={3} component="form" onSubmit={handleSubmit}>
              <Box>
                <Typography mb={1} variant="h5">
                  Name*
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  label=""
                  required
                  fullWidth
                  placeholder="Enter Your Referral Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ background: "#F7FBFF", borderColor: "#D4D7E3" }}
                />
              </Box>
              <Stack direction="row" gap={1}>
                <Box flexGrow={1}>
                  <Typography mb={1} variant="h5">
                    Email
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="email"
                    label=""
                    fullWidth
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ background: "#F7FBFF", borderColor: "#D4D7E3" }}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography mb={1} variant="h5">
                    Phone No.
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    label=""
                    fullWidth
                    placeholder="Enter Your Referral Ph"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    sx={{ background: "#F7FBFF", borderColor: "#D4D7E3" }}
                  />
                </Box>
              </Stack>
              <Box>
                <Typography mb={1} variant="h5">
                  Company Name*
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  label=""
                  required
                  fullWidth
                  placeholder="Enter Referral Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  sx={{ background: "#F7FBFF", borderColor: "#D4D7E3" }}
                />
              </Box>
              <Button type="submit">
                {loader ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Refer"
                )}
              </Button>
            </Stack>
          </CustomGraphBox>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Referral;
