import React from 'react';

const LogoutComponent = () => {
  const logoutHandler = () => {
    localStorage.clear();
    localStorage.setItem('myLoginDetails', JSON.stringify(''));
    localStorage.setItem('clientPreference', JSON.stringify('none'));
    localStorage.setItem(
      'logoUrl',
      JSON.stringify(
        'https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/niroggyan-logo.svg'
      )
    );
    window.dispatchEvent(new Event('storage'));
    window.location.replace('/login');
    // window.location.reload()
  };

  return (
    <>
      <svg
        style={{ cursor: 'pointer' }}
        onClick={logoutHandler}
        width='36'
        height='36'
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='18' cy='18' r='18' fill='#ECEDEF' />
        <path
          d='M13.6363 18.8652C13.3849 18.8652 13.1816 18.6611 13.1816 18.4097C13.1816 18.1593 13.3849 17.9551 13.6363 17.9551H25.9104C26.1608 17.9551 26.364 18.1592 26.364 18.4097C26.364 18.6611 26.1607 18.8652 25.9104 18.8652H13.6363Z'
          fill='#6A6B6B'
        />
        <path
          d='M20.7554 13.8986C20.5776 13.7209 20.5776 13.4336 20.7554 13.2558C20.9331 13.0781 21.2204 13.0781 21.3981 13.2558L26.2314 18.0884C26.4091 18.2661 26.4083 18.5534 26.2304 18.7312C26.0527 18.9089 25.7654 18.9089 25.5885 18.7321L20.7554 13.8986Z'
          fill='#6A6B6B'
        />
        <path
          d='M21.3991 23.5635C21.2214 23.7412 20.9341 23.7412 20.7563 23.5635C20.5786 23.3858 20.5786 23.0984 20.7563 22.9207L25.5889 18.0874C25.7666 17.9097 26.0539 17.9106 26.2317 18.0884C26.4094 18.2661 26.4094 18.5535 26.2326 18.7303L21.3991 23.5635Z'
          fill='#6A6B6B'
        />
        <path
          d='M10 10.4555C10 10.3336 10.0473 10.2191 10.1328 10.134C10.2186 10.0477 10.3332 10 10.4541 10L19.091 10.001C19.3424 10.001 19.5457 10.2042 19.5457 10.4556C19.5457 10.706 19.3424 10.9102 19.091 10.9102L10.9097 10.9091V25.9104H19.091C19.3424 25.9104 19.5457 26.1137 19.5457 26.3645C19.5457 26.6159 19.3424 26.8191 19.091 26.8191H10.4541C10.3331 26.8191 10.2186 26.7718 10.1326 26.6859C10.0473 26.6001 10 26.4856 10 26.3646V10.4555Z'
          fill='#6A6B6B'
        />
      </svg>
    </>
  );
};

export default LogoutComponent;
