import { useState, useEffect } from "react";

import { InputGroup, Form } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
// import "../../App.css";
//import Loader from "../loader/loader";
import { Spinner } from "react-bootstrap";
import regenerateToken from "../regenerateTokens";
import LabelElement from "../../../common/LabelElement";

const ColorConfig = () => {
  const [patientHeaderHeading, setPatientHeaderHeading] = useState("#757575");
  const [patientHeaderValue, setPatientHeaderValue] = useState("#5D2482");
  const [profileHeading, setProfileHeading] = useState("#5D2482");
  const [profileBackground, setProfileBackground] = useState("#F7EAFF");
  const [saveOption, setSaveOption] = useState(false);
  const [colorConfig, setColorConfig] = useState({
    colorObj: {
      colored: {
        normal: "#80c8b3",
        finalCritial: "#dd9690",
        oneFromNormal: "#F4B400",
        twoFromNormal: "#C47D63",
        threeFromNormal: "#C26564",
      },
      greyscaled: {
        normal: "#D2D2D2",
        finalCritial: "#111111",
        oneFromNormal: "#969696",
        twoFromNormal: "#7B7B7B",
        threeFromNormal: "#444444",
      },
    },
  });
  const [parameterColor, setParameterColor] = useState({
    normal: "#E4FEF2",
    finalCritial: "#FFEAE8",
    oneFromNormal: "#FCF%E2",
    twoFromNormal: "#C47D63",
    threeFromNormal: "#C26564",
  });

  const [loading, setLoading] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const getColorConfiguration = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const existingColorConfigBody = {
      headers: {
        authorization: idToken,
      },
      method: "GET",
      url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/config/color",
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const existingColorConfigurationResponse = await axios(
      existingColorConfigBody
    );
    return existingColorConfigurationResponse;
  };

  const updatingExistingColorConfiguration = (response) => {
    const { colorObj } = response.data.data;
    // Updating the default values into the state objects
    setColorConfig({
      colorObj: {
        colored: colorObj.colored,
        greyscaled: colorObj.greyscaled,
      },
    });
    setPatientHeaderHeading(colorObj.reportColors.patientHeader.heading);
    setPatientHeaderValue(colorObj.reportColors.patientHeader.value);
    setProfileHeading(colorObj.reportColors.profile.heading);
    setProfileBackground(colorObj.reportColors.profile.background);
    setParameterColor(colorObj.reportColors.parameter);
    setLoading(false);
  };

  useEffect(() => {
    (async function RetrieveExistingColorConfiguration() {
      setLoading(true);
      const existingColorConfigurationResponse = await getColorConfiguration();
      const statusCode = existingColorConfigurationResponse.status;

      if (statusCode === 401) {
        await regenerateToken();
        const existingColorConfigurationResponse = getColorConfiguration();
        if (existingColorConfigurationResponse.data.isSuccess) {
          console.log("updated Color Configuration @ second trail");
          updatingExistingColorConfiguration(
            existingColorConfigurationResponse
          );
        }
      } else {
        if (existingColorConfigurationResponse.data.isSuccess) {
          console.log("updated Color Configuration @ first trail");
          updatingExistingColorConfiguration(
            existingColorConfigurationResponse
          );
        }
      }
    })();
  }, []);

  const handlingUpdate = (key1, key2, value) => {
    setColorConfig((prevConfig) => ({
      ...prevConfig,
      colorObj: {
        ...prevConfig.colorObj,
        [key1]: {
          ...prevConfig.colorObj[key1],
          [key2]: value,
        },
      },
    }));
  };

  const handlingConfirmation = async () => {
    const finalColorConfig = {
      colorObj: {
        ...colorConfig.colorObj,
        reportColors: {
          patientHeader: {
            heading: patientHeaderHeading,
            value: patientHeaderValue,
          },
          profile: {
            heading: profileHeading,
            background: profileBackground,
          },
          parameter: parameterColor,
        },
      },
    };
    console.log("finalColorConfig", finalColorConfig);

    // API call for Updating Color Configuration
    const idToken = localStorage.getItem("idTokenBilling");
    const ColorConfigurationBody = {
      headers: {
        authorization: idToken,
      },
      method: "POST",
      url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/config/color",
      data: JSON.stringify(finalColorConfig.colorObj),
    };
    try {
      setConfirmationLoading(true);
      const updatingColorConfigurationResponse = await axios(
        ColorConfigurationBody
      );
      console.log(
        "updatingColorConfigurationResponse\n",
        updatingColorConfigurationResponse
      );
      setConfirmationLoading(false);
      // window.location.reload();
      // Successful Modal may be displayed
    } catch (e) {
      console.log("Error in API", e.message);
    }
  };

  return (
    <>
      <div className="container-fluid overflow-container mt-3">
        <Form
          className="fixed-form"
          style={{ height: "calc(100vh - 330px)", overflowY: "auto" }}
        >
          {/* Coloured */}

          <LabelElement title="Coloured Report Color Configuration" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.colored.normal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.colored.normal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate("colored", "normal", event.target.value);
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Final Critical </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.colored.finalCritial}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.colored.finalCritial}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate("colored", "finalCritial", event.target.value);
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>One From Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.colored.oneFromNormal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.colored.oneFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate(
                    "colored",
                    "oneFromNormal",
                    event.target.value
                  );
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Two From Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.colored.twoFromNormal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.colored.twoFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate(
                    "colored",
                    "twoFromNormal",
                    event.target.value
                  );
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49.5%",
                textTransform: "capitalize",
                // flexGrow: '1',
              }}
            >
              <InputGroup.Text>
                <b>Three From Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.colored.threeFromNormal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.colored.threeFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate(
                    "colored",
                    "threeFromNormal",
                    event.target.value
                  );
                }}
                type="color"
                className="form-control form-control-color"
                // list='colors'
                placeholder="Select Color"
              />
            </InputGroup>
          </div>
          {/* GreyScaled */}

          <LabelElement title=" GreyScaled Report Color Configuration" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.greyscaled.normal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.greyscaled.normal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate("greyscaled", "normal", event.target.value);
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Final Critical </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.greyscaled.finalCritial}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.greyscaled.finalCritial}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate(
                    "greyscaled",
                    "finalCritial",
                    event.target.value
                  );
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>One from Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.greyscaled.oneFromNormal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.greyscaled.oneFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate(
                    "greyscaled",
                    "oneFromNormal",
                    event.target.value
                  );
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Two from Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.greyscaled.twoFromNormal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.greyscaled.twoFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate(
                    "greyscaled",
                    "twoFromNormal",
                    event.target.value
                  );
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49%",
                textTransform: "capitalize",
                //flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Three from Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {colorConfig.colorObj.greyscaled.threeFromNormal}
              </InputGroup.Text>
              <input
                value={colorConfig.colorObj.greyscaled.threeFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  handlingUpdate(
                    "greyscaled",
                    "threeFromNormal",
                    event.target.value
                  );
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
          </div>
          {/* Patient Header Colors*/}

          <LabelElement title="Patient Headers Color Configuration" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Patient Header Heading Color</b>
              </InputGroup.Text>
              <InputGroup.Text>{patientHeaderHeading}</InputGroup.Text>
              <input
                value={patientHeaderHeading}
                onChange={(event) => {
                  setSaveOption(true);
                  setPatientHeaderHeading(event.target.value);
                }}
                type="color"
                className="form-control "
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Patient Header Value Color</b>
              </InputGroup.Text>
              <InputGroup.Text>{patientHeaderValue}</InputGroup.Text>
              <input
                value={patientHeaderValue}
                onChange={(event) => {
                  setSaveOption(true);
                  setPatientHeaderValue(event.target.value);
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
          </div>
          {/* Report Colors Profile */}

          <LabelElement title="Report Color Configuration" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Profile Heading Color</b>
              </InputGroup.Text>
              <InputGroup.Text>{profileHeading}</InputGroup.Text>
              <input
                value={profileHeading}
                onChange={(event) => {
                  setProfileHeading(event.target.value);
                  setSaveOption(true);
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Profile Background Color</b>
              </InputGroup.Text>
              <InputGroup.Text>{profileBackground}</InputGroup.Text>
              <input
                value={profileBackground}
                onChange={(event) => {
                  setSaveOption(true);
                  setProfileBackground(event.target.value);
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
          </div>
          {/* Parameter colors */}

          <LabelElement title="Parameter Color Configuration" />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>{parameterColor.normal}</InputGroup.Text>
              <input
                value={parameterColor.normal}
                onChange={(event) => {
                  setSaveOption(true);
                  setParameterColor({
                    ...parameterColor,
                    normal: event.target.value,
                  });
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Final Critical </b>
              </InputGroup.Text>
              <InputGroup.Text>{parameterColor.finalCritial}</InputGroup.Text>
              <input
                value={parameterColor.finalCritial}
                onChange={(event) => {
                  setSaveOption(true);
                  setParameterColor({
                    ...parameterColor,
                    finalCritial: event.target.value,
                  });
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>One From Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>{parameterColor.oneFromNormal}</InputGroup.Text>
              <input
                value={parameterColor.oneFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  setParameterColor({
                    ...parameterColor,
                    oneFromNormal: event.target.value,
                  });
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "45%",
                textTransform: "capitalize",
                flexGrow: "1",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Two From Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>{parameterColor.twoFromNormal}</InputGroup.Text>
              <input
                value={parameterColor.twoFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  setParameterColor({
                    ...parameterColor,
                    twoFromNormal: event.target.value,
                  });
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49.5%",
                textTransform: "capitalize",
                // flexGrow: '1',
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Three From Normal </b>
              </InputGroup.Text>
              <InputGroup.Text>
                {parameterColor.threeFromNormal}
              </InputGroup.Text>
              <input
                value={parameterColor.threeFromNormal}
                onChange={(event) => {
                  setSaveOption(true);
                  setParameterColor({
                    ...parameterColor,
                    threeFromNormal: event.target.value,
                  });
                }}
                type="color"
                className="form-control"
                // list='colors'
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>
          </div>
        </Form>
        {/* Buttons */}
        <div className="mt-4">
          <button
            onClick={() => setSaveOption(false)}
            className="btn btn-primary"
            disabled={!saveOption}
            style={{ width: "140px", borderRadius: "12px" }}
          >
            Save Changes
          </button>
          <button
            onClick={() => handlingConfirmation()}
            disabled={saveOption}
            className="btn btn-primary mx-4"
            style={{ width: "140px", borderRadius: "12px" }}
          >
            {confirmationLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#fff" }}
              ></Spinner>
            ) : (
              "Confirm Changes"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ColorConfig;
