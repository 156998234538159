import Cookies from "js-cookie";
import axios from "axios";

const regenerateToken = async () => {
  // Regeneration of Token
  const refreshToken = Cookies.get("refreshToken");
  const requestBody = {
    refresh_token: refreshToken,
  };
  const regenerateConfig = {
    method: "POST",
    url: "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/Testing/oauth/token?grant_type=refresh_token",
    data: JSON.stringify(requestBody),
  };
  try {
    const regenerateTokenResponse = await axios(regenerateConfig);
    console.log("regenerateTokenResponse", regenerateTokenResponse);
    Cookies.set("idToken", regenerateTokenResponse.data.data.IdToken, {
      expires: 1,
    });
  } catch (e) {
    console.log("error in regenerating Token", e.message);
  }
};

export default regenerateToken;
