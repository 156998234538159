import { CircularProgress, Stack } from "@mui/material";

const LoadingView = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "200px" }}
    >
      <CircularProgress />
    </Stack>
  );
};

export default LoadingView;
