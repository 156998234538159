import { Box, FormControlLabel, RadioGroup, Stack } from "@mui/material";
import Radio from "@mui/material/Radio";
import { useState } from "react";
import ProfileMapping from "./mapping/ProfileMapping";
import ParameterMapping from "./mapping/ParameterMapping";
import SmartReportConfig from "./productConfig/SmartReportConfig";
import VizAppConfig from "./productConfig/VizAppConfig";
import ColorConfig from "./productConfig/ColorConfig";

const ProductConfig = () => {
  const [productType, setProductType] = useState("smartreport");

  const renderSpecificProductType = () => {
    switch (productType) {
      case "smartreport":
        return <SmartReportConfig />;
      case "vizapp":
        return <VizAppConfig />;
      case "color":
        return <ColorConfig />;
      default:
        return <SmartReportConfig />;
    }
  };
  return (
    <Stack>
      <Box pl={2}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
        >
          <FormControlLabel
            value="smartreport"
            control={<Radio />}
            label="Smart Report"
          />
          <FormControlLabel
            value="vizapp"
            control={<Radio />}
            label="Viz App"
          />
          <FormControlLabel value="color" control={<Radio />} label="Color" />
        </RadioGroup>
      </Box>
      {renderSpecificProductType()}
    </Stack>
  );
};

export default ProductConfig;
