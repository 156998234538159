import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import NavbarComponent from "../NavbarComponent";
import { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import BugReportIcon from "@mui/icons-material/BugReport";
import PageTitle from "../../common/PageTitle";

const SupportSection = () => {
  // const [labno, setLabno] = useState("");
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const defaultProductType = loginData?.productType
    ? loginData?.productType
    : "smartreport";
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [labData, setLabData] = useState("");
  const [saveJson, setSaveJson] = useState("");
  const [show, setShow] = useState(false);
  const [jsonCopied, setJsonCopied] = useState(false);
  const [reportLoader, setReportLoader] = useState({});
  const [centerName, setCenterName] = useState("");
  const [labId, setLabId] = useState("");
  const [organization, setOrganization] = useState("");
  const [allOrganizations, setAllOrganizations] = useState("");
  const [loadingOrg, setLoadingOrg] = useState(false);
  const [getReportError, setGetReportError] = useState("");
  const [productType, setProductType] = useState(defaultProductType);
  const [hashId, setHashId] = useState("");

  const reportTypeLabels = {
    compact: "Compact",
    dynamic: "Personal",
    advanced: "Total",
  };

  // video dialoge
  const [open, setOpen] = useState(false);

  console.log("loginDatawer", loginData);
  const productDetails = [
    { label: "Smart Report", value: "smartreport" },
    { label: "Viz App", value: "vizapp" },
    { label: "Smart Video Report", value: "videoreport" },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleJsonCopy = () => {
    setJsonCopied(true);
    setTimeout(() => {
      setJsonCopied(false);
    }, 2500);
  };

  const getAllOrganizations = async () => {
    // resetting organizations
    setLoadingOrg(true);
    setAllOrganizations("");
    const apiUrl =
      "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/Testing/jsonlocator";

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("inside axiosdata ===> \n\n", data);
      setAllOrganizations(data?.Items);
    } catch (error) {
      console.log("Error Detected: \n", error);
    } finally {
      setLoadingOrg(false);
    }

    //await setAllOrganizations(allRecord)
  };

  useEffect(() => {
    getAllOrganizations();
  }, []);

  const fetchJsonData = async (e) => {
    e.preventDefault();
    setLoader(true);
    setGetReportError("");
    setError("");

    const apiUrlLab =
      productType === "vizapp"
        ? `https://api.niroggyan.com/billing/${productType}/list?queryby=labNo&hashId=${hashId}`
        : `https://api.niroggyan.com/billing/${productType}/list?queryby=labNo&labNo=${labId}`;
    const apiUrlAdmin =
      productType === "vizapp"
        ? `https://api.niroggyan.com/billing/${productType}/list?queryby=labNo&hashId=${hashId}&org=${organization}`
        : `https://api.niroggyan.com/billing/${productType}/list?queryby=labNo&labNo=${labId}&org=${organization}`;
    const apiUrl =
      loginData && loginData.role === "lab" ? apiUrlLab : apiUrlAdmin;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };

    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("jsonData", data);
      if (data?.data?.length === 0) {
        setError("No data found");
      } else {
        setError("");
        setLabData(data.data);
      }
    } catch (e) {
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  const showJson = async (theJsonUrl) => {
    const apiUrl = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/inputjson/s3json?s3url=${theJsonUrl}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      setSaveJson("");
      handleShow();
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("jsonViewData", data);
      if (data.hasOwnProperty("jsonData")) {
        setSaveJson(data.jsonData);
      } else {
        setSaveJson("Nothing to show");
      }
    } catch (error) {
      console.log(`Error while fetching json:\n ${error}`);
      setSaveJson("Nothing to show");
    }
  };

  const downloadPDF = (myPdfDoc, emp_name = "Sample Report") => {
    console.log("the file getting downloaded");
    const linkSource = `data:application/pdf;base64,${myPdfDoc}`;
    const downloadLink = document.createElement("a");
    const fileName = `${emp_name}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const generatePDF = async (theJsonUrl, index) => {
    setGetReportError("");
    setReportLoader((prev) => ({ ...prev, [index]: true }));
    let jsonToRetrieve = "";
    const apiUrl = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/inputjson/s3json?s3url=${theJsonUrl}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("reportJson", data);
      jsonToRetrieve = data.jsonData;
      jsonToRetrieve["generationType"] = "Test";
      console.log("jsonToRetrieve", jsonToRetrieve);
      const clients = loginData.clientData;
      const requiredClient = clients.filter(
        (eachClient) => eachClient.org === labData[0].org
      );

      const smartReportEndpoint = requiredClient[0].endpoints
        ? requiredClient[0].endpoints.smartReport
        : "";
      console.log("requiredClient", smartReportEndpoint);
      const smartReportApi = smartReportEndpoint;
      const configForReport = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonToRetrieve),
      };
      if (!smartReportEndpoint) {
        window.alert("Update endpoint for selected client");
      } else {
        try {
          const pdfResponse = await fetch(smartReportApi, configForReport);
          const reportData = await pdfResponse.json();
          console.log("reportData", reportData);
          downloadPDF(reportData.PdfData, reportData.labId);
        } catch (e) {
          console.log(`Error while generating Report:\n ${e}`);
          setGetReportError("Failed. Try again");
        } finally {
          setReportLoader((prev) => ({ ...prev, [index]: false }));
        }
      }

      // Download Report
    } catch (error) {
      console.log(`Error while fetching json:\n ${error}`);
    } finally {
      setReportLoader((prev) => ({ ...prev, [index]: false }));
    }
  };

  const labIdHandler = (theValue) => {
    console.log("theValue:", theValue);

    for (let i = 0; i < allOrganizations.length; i++) {
      if (allOrganizations[i].title === theValue) {
        //setCenterName(allOrganizations[i].content)
        setLabId(allOrganizations[i].cat);
        break;
      } else {
        //
      }
    }
  };

  const centerNameHandler = (theOrg) => {
    for (let i = 0; i < allOrganizations.length; i++) {
      if (allOrganizations[i].title === theOrg) {
        setCenterName(allOrganizations[i].content);
        break;
      } else {
        //
      }
    }
  };

  const jsonDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              JSON Preview
            </Typography>
            <Stack direction="row" gap={0.5} alignItems="center">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.stringify(saveJson, null, 2)
                  );
                  handleJsonCopy();
                }}
              >
                <ContentCopyIcon sx={{ color: "green" }} />
              </IconButton>
              {jsonCopied ? (
                <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
                  copied!
                </Typography>
              ) : (
                ""
              )}
            </Stack>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack sx={{ height: "400px", overflow: "auto" }}>
            <pre>{JSON.stringify(saveJson, null, 2)}</pre>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };

  const querySection = () => {
    return <Stack></Stack>;
  };

  const videoReportDialog = (videoUrl) => {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <video style={{ marginTop: "32px" }} controls width={"100%"} autoPlay>
            <source src={videoUrl} />
          </video>
        </DialogContent>
      </Dialog>
    );
  };

  const videoReportTable = () => {
    return (
      <Box>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{ maxHeight: "350px", overflowY: "auto", borderRadius: "10px" }}
        >
          <Table
            sx={{ minWidth: "100%" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead sx={{ boxShadow: "0 1px 50px 0px rgba(0,0,0,0.08)" }}>
              <TableRow>
                <TableCell>
                  <Typography variant="body7">Date</Typography>
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  <Typography variant="body7">Package Name</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography variant="body7">Lab No</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography variant="body7">View JSON</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography variant="body7">View SVR</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {labData.map((each, idx) => (
                <TableRow
                  key={idx}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <Typography variant="body5">
                      {each?.date_created_utc}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body5">
                      {each?.package_name}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="body5">{each?.lab_no}</Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      sx={{ textTransform: "none", fontSize: "12px" }}
                      variant="contained"
                      size="small"
                      onClick={() => showJson(each.input_url)}
                    >
                      JSON
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Stack gap={1} alignItems="center">
                      <Button
                        sx={{
                          textTransform: "none",
                          width: "80px",
                          fontSize: "12px",
                        }}
                        variant="contained"
                        size="small"
                        onClick={() => setOpen(true)}
                      >
                        View
                      </Button>
                      {videoReportDialog(each.report_url)}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const vizAppTable = () => {
    return (
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ maxHeight: "350px", overflowY: "auto", borderRadius: "10px" }}
      >
        <Table sx={{ minWidth: "100%" }} aria-label="simple table" stickyHeader>
          <TableHead sx={{ boxShadow: "0 1px 50px 0px rgba(0,0,0,0.08)" }}>
            <TableRow>
              <TableCell>
                <Typography>Date</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body7">Package Name</Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "30%" }}>
                <Typography variant="body7"> Hash Id</Typography>
              </TableCell>

              <TableCell align="center">
                <Typography variant="body7"> View JSON</Typography>
              </TableCell>

              <TableCell align="center">
                <Typography variant="body7">View App</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labData.map((each, idx) => (
              <TableRow
                key={idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">
                  <Typography variant="body5">
                    {each?.date_created_utc}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body5">{each?.package_name}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body5" sx={{ wordBreak: "break-word" }}>
                    {each?.report_id}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Button
                    sx={{ textTransform: "none", fontSize: "12px" }}
                    variant="contained"
                    size="small"
                    onClick={() => showJson(each.input_url)}
                  >
                    JSON
                  </Button>
                </TableCell>

                <TableCell align="center">
                  <Stack gap={1} alignItems="center">
                    <a href={each.app_url} target="_blank" rel="noreferrer">
                      <Button
                        sx={{
                          textTransform: "none",
                          width: "80px",
                          fontSize: "12px",
                        }}
                        variant="contained"
                        size="small"
                      >
                        Link
                      </Button>
                    </a>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const smartReportTable = () => {
    return (
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          maxHeight: "350px",
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <Table sx={{ minWidth: "100%" }} aria-label="simple table" stickyHeader>
          <TableHead sx={{ boxShadow: "0 1px 50px 0px rgba(0,0,0,0.08)" }}>
            <TableRow>
              <TableCell>
                <Typography variant="body7">Date</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body7">Package Name</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body7">Lab No</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body7">Report Type</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body7">View JSON</Typography>
              </TableCell>
              {loginData && loginData.role === "admin" ? (
                <TableCell align="center" sx={{ fontWeight: "600" }}>
                  <Typography variant="body7">View Report</Typography>
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {labData.map((each, idx) => (
              <TableRow
                key={idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left">
                  <Typography variant="body5">
                    {each?.date_created_utc}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body5">{each?.package_name}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body5">{each?.lab_no}</Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography variant="body5">
                    {reportTypeLabels[each?.report_type]}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Button
                    sx={{ textTransform: "none", fontSize: "12px" }}
                    variant="contained"
                    size="small"
                    onClick={() => showJson(each.url)}
                  >
                    JSON
                  </Button>
                </TableCell>
                {loginData && loginData.role === "admin" ? (
                  <TableCell align="center">
                    <Stack gap={1} alignItems="center">
                      <Button
                        sx={{
                          textTransform: "none",
                          width: "80px",
                          fontSize: "12px",
                        }}
                        variant="contained"
                        size="small"
                        onClick={() => generatePDF(each.url, idx)}
                      >
                        {reportLoader[idx] ? (
                          <CircularProgress size={26} sx={{ color: "#fff" }} />
                        ) : (
                          "Report"
                        )}
                      </Button>
                      {getReportError ? (
                        <Typography color="error" sx={{ fontSize: "12px" }}>
                          {getReportError}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </TableCell>
                ) : (
                  ""
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const dynamicTable = () => {
    switch (productType) {
      case "vizapp":
        return vizAppTable();
      case "videoreport":
        return videoReportTable();
      default:
        return smartReportTable();
    }
  };

  const handleChange = (event, newValue) => {
    setProductType(newValue);
  };

  const newProductTab = () => {
    return (
      <Stack p={0.5} sx={{ background: "#fff", borderRadius: "8px" }}>
        <Tabs
          value={productType}
          onChange={handleChange}
          centered
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {productDetails.map((each, idx) => (
            <Tab
              label={each.label}
              value={each.value}
              sx={{
                background: productType === each.value ? "#E0ECFE" : "",
                borderRadius: "4px",
                border: "none",
                fontFamily: "IBM Plex Sans",
                fontSize: "14px",
                textTransform: "none",
                width: "200px",
                padding: "8px 16px",
              }}
            />
          ))}
        </Tabs>
      </Stack>
    );
  };

  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />
      <Box
        flexGrow={1}
        sx={{
          padding: "0px 64px 40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageTitle title="Technical Support" />
        <Stack gap={3} flexGrow={1}>
          {/* <Typography variant="h1">Technical Support</Typography> */}

          <Stack direction="row" gap={2} alignItems="center" my={1}>
            {/* <Typography variant="body4">Select Product</Typography>
          <FormControl size="small" sx={{ width: "200px" }}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={productType}
              label=""
              variant="outlined"
              onChange={(e) => {
                setProductType(e.target.value);
                setLabData("");
                setOrganization("");
                setLabId("");
                setHashId("");
              }}
            >
              <MenuItem value="smartreport">Smart Report</MenuItem>
              <MenuItem value="vizapp">Viz App</MenuItem>
              <MenuItem value="videoreport">Smart Video Report</MenuItem>
            </Select>
          </FormControl> */}
            {newProductTab()}
          </Stack>
          <form onSubmit={(e) => fetchJsonData(e)}>
            <Stack direction="row" gap={2} alignItems="center">
              {loginData && loginData.role === "admin" ? (
                <Stack direction="row" gap={2}>
                  <FormControl
                    size="small"
                    sx={{ minWidth: "200px" }}
                    variant="outlined"
                  >
                    <InputLabel id="demo-select-small-label">
                      Organization
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Package"
                      value={organization}
                      onChange={(e) => {
                        setOrganization(e.target.value);
                        labIdHandler(e.target.value);
                        centerNameHandler(e.target.value);
                      }}
                    >
                      {allOrganizations &&
                        allOrganizations.map((org, idx) => (
                          <MenuItem key={idx} value={org.title}>
                            {org.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {loadingOrg ? <CircularProgress size={30} /> : ""}
                  {/* <TextField
                  id="outlined-basic"
                  label="Center"
                  variant="outlined"
                  size="small"
                  value={centerName}
                  onChange={(e) => setCenterName(e.target.value)}
                /> */}
                </Stack>
              ) : (
                ""
              )}
              {productType === "vizapp" ? (
                <TextField
                  id="outlined-basic"
                  label="Hash Id"
                  variant="outlined"
                  size="small"
                  required
                  value={hashId}
                  onChange={(e) => setHashId(e.target.value)}
                />
              ) : (
                <TextField
                  id="outlined-basic"
                  label="Lab Id/No"
                  variant="outlined"
                  size="small"
                  required
                  value={labId}
                  onChange={(e) => setLabId(e.target.value)}
                />
              )}
              <Button type="submit" sx={{ width: "90px" }}>
                {loader ? (
                  <CircularProgress sx={{ color: "#fff" }} size={20} />
                ) : (
                  "Submit"
                )}
              </Button>
            </Stack>
          </form>
          <Stack>
            {error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              labData && (
                <Box
                  p={2}
                  sx={{
                    background: "rgba(53, 103, 214, 0.1)",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: "6px",
                  }}
                >
                  {dynamicTable()}
                </Box>
              )
            )}
          </Stack>
          {jsonDialog()}
          {loginData && loginData.role !== "admin" ? (
            <Stack gap={3} justifyContent="flex-end" flexGrow={1}>
              <Typography variant="h5">Need More Help?</Typography>
              <Stack direction="row" gap={2}>
                <a
                  href="https://forms.gle/w66kXxvoJ4F7nK6U7"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Stack
                    component={Button}
                    direction="row"
                    alignItems="center"
                    gap={1}
                    p={2}
                    sx={{ borderRadius: "8px" }}
                  >
                    <BugReportIcon />
                    Raise Ticket
                  </Stack>
                </a>
                <a
                  href="https://wa.me/917678277891?text=hi"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Stack
                    component={Button}
                    direction="row"
                    alignItems="center"
                    gap={1}
                    p={2}
                    sx={{ borderRadius: "8px" }}
                  >
                    <BusinessCenterIcon />
                    Business Query
                  </Stack>
                </a>
              </Stack>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default SupportSection;
