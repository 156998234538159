import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Rating, Stack, Typography } from "@mui/material";
import axios from "axios";

export default function Feedback({ open, setOpen }) {
  const [value, setValue] = React.useState(0);
  const [feedbackText, setFeedbackText] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  console.log("feedbackItems", value, feedbackText, submitted);

  console.log("navigator", navigator);
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));

  const handleSubmit = () => {
    const feedbackObj = {
      clientId: loginData?.clientId ? loginData.clientId : "",
      center: loginData?.center ? loginData.center : "",
      org: loginData?.org ? loginData.org : "",
      device: navigator.userAgentData
        ? navigator.userAgentData.mobile
          ? "Mobile"
          : "Desktop"
        : "Apple Mobile",

      devicePlatform: navigator.userAgentData
        ? navigator.userAgentData.platform
        : "IOS/Android",
      browser: navigator.userAgentData
        ? navigator.userAgentData.brands[
            navigator.userAgentData.brands.length - 1
          ].brand
          ? navigator.userAgentData.brands[
              navigator.userAgentData.brands.length - 1
            ].brand
          : ""
        : "Safari/Firefox/WebView Android",
      referralScore: value,
      comments: feedbackText,
    };
    console.log("feedbackObj", feedbackObj);
    axios
      .post(
        `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/feedback/clientdashboard`,
        feedbackObj,
        {}
      )
      .then((response) => {
        console.log("feedback given successfully:", response.data);

        response.status === 200 && setSubmitted(true);
      })
      .catch((error) => {
        console.error("Error submitting Feedback:", error);
      });
  };

  const handleRatingChange = (event, newValue) => {
    console.log(event);
    setValue(newValue);
  };

  const handleTextChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const labels = {
    1: "Highly Unsatisfied",
    2: "Unsatisfied",
    3: "Neutral",
    4: "Satisfied",
    5: "Highly Satisfied",
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <Stack sx={{ width: { sm: "346px", xs: "300px" }, height: "416px" }}>
          <DialogContent>
            <Stack alignItems={"flex-end"}>
              <Typography
                style={{ cursor: "pointer", width: "40px" }}
                onClick={() => {
                  setValue(0);
                  setFeedbackText("");
                  setSubmitted(false);
                  handleClose();
                }}
                color={"#000"}
                fontSize={"16px"}
                fontWeight={400}
              >
                Skip
              </Typography>
            </Stack>
            <Stack gap="16px" alignItems={"center"}>
              {!value && !submitted && (
                <>
                  <img
                    src="https://niroggyan.s3.ap-south-1.amazonaws.com/feedback/vizApp-feedback-image.png"
                    alt="feedback png"
                    width={190}
                    height={186}
                  />
                  <Typography color={"#000"} fontSize={"16px"} fontWeight={500}>
                    Rate your experience with this app
                  </Typography>
                  <Rating
                    sx={{
                      "& .MuiRating-iconFilled": {
                        color: "#F9C852",
                      },
                    }}
                    name="simple-controlled"
                    value={value}
                    onChange={handleRatingChange}
                  />
                </>
              )}

              {submitted && value !== 0 && (
                <Stack alignItems={"center"} gap={2}>
                  <img
                    src="https://niroggyan.s3.ap-south-1.amazonaws.com/feedback/vizApp-feedback-image.png"
                    alt="feedback png"
                    width={190}
                    height={186}
                  />
                  <Typography
                    color={"#2E2E2E"}
                    alignSelf={"center"}
                    fontSize={"20px"}
                    fontWeight={500}
                  >
                    {"Thanks for your Feedback"}
                  </Typography>
                  <Typography
                    color={"#4C4C4C"}
                    alignSelf={"center"}
                    fontSize={"13px"}
                    fontWeight={400}
                  >
                    We will definitely work on this
                  </Typography>
                </Stack>
              )}
            </Stack>

            {value !== 0 && !submitted && (
              <Stack alignItems={"center"} gap={3}>
                {
                  <Stack gap="12px" marginTop={2}>
                    <Typography
                      color={"#000"}
                      alignSelf={"center"}
                      fontSize={"20px"}
                      fontWeight={500}
                    >
                      {labels[value]}
                    </Typography>
                    <Rating
                      sx={{
                        "& .MuiRating-iconFilled": {
                          color: "#F9C852",
                        },
                      }}
                      name="read-only"
                      value={value}
                      readOnly
                    />
                  </Stack>
                }
                <Stack
                  style={{
                    background: "#EEEEEE",
                    height: "8px",
                    width: "116%",
                  }}
                ></Stack>
                <Typography
                  color={"#000"}
                  alignSelf={"center"}
                  fontSize={"16px"}
                  fontWeight={500}
                >
                  {"Add a review"}
                </Typography>
                <TextField
                  id="feedback_text"
                  label="Tell us some improvements..."
                  fullWidth
                  multiline
                  maxRows={4}
                  value={feedbackText}
                  onChange={handleTextChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "#4C4C4C",
                    },
                  }}
                />
                <Button
                  fullWidth
                  style={{ color: "#fff", background: "#00599A" }}
                  onClick={handleSubmit}
                >
                  Submit your feedback
                </Button>
              </Stack>
            )}
          </DialogContent>
          <Typography
            marginBottom={"8px"}
            textAlign={"center"}
            color={"#515151"}
            fontSize={"10px"}
            fontWeight={400}
          >
            Your rating makes our app even better to serve you
          </Typography>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
