import { useState, useEffect } from "react";

import { Spinner, InputGroup, Form } from "react-bootstrap";
import regenerateToken from "../regenerateTokens";

import axios from "axios";
import Cookies from "js-cookie";

const VizAppConfig = () => {
  // hooks for loaders
  const [loading, setLoading] = useState(false);
  const [updatingLoader, setUpdatingLoader] = useState(false);
  const [saveOption, setSaveOption] = useState(false);
  const [vizappConfiguration, setVizappConfiguration] = useState({
    primaryColor: "#3567d6",
    secondaryColor: "#E8F1FA",
    introductionType: "",
    introGradient: false,
    fontFamily: "",
    backgroundType: "",
  });

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const existingStateConfigResponse = await getVizappConfiguration();
        const statusCode = existingStateConfigResponse.status;
        if (statusCode === 401) {
          console.log("Regenerating ID Token");
          // API Call is to be made for regenerating the ID Token.
          await regenerateToken();
          const existingStateConfigResponse = await getVizappConfiguration();
          console.log(
            "ExistingVizappConfigFile @ Second Trail",
            existingStateConfigResponse
          );
          if (existingStateConfigResponse?.data?.isSucess) {
            setVizappConfiguration(existingStateConfigResponse.data.data);
            setLoading(false);
          }
        } else {
          if (existingStateConfigResponse?.data?.isSucess) {
            setVizappConfiguration(existingStateConfigResponse.data.data);
            setLoading(false);
          }
        }
      } catch (error) {
        console.log("error", error.message);
        // console.log('existingStateConfigBody', existingStateConfigBody);
      }
    })();
  }, []);

  const getVizappConfiguration = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const existingStateConfigBody = {
      headers: {
        authorization: idToken,
      },
      method: "GET",
      url: "https://api.niroggyan.com/onboarding/config/vizapp",
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const existingStateConfigResponse = await axios(existingStateConfigBody);

    return existingStateConfigResponse;
  };

  const updatingVizappStateFields = (updatedValue, type) => {
    const getBooleanValue = () => {
      return updatedValue === "true" ? true : false;
    };

    setVizappConfiguration((prevConfig) => ({
      ...prevConfig,
      [type]: ["true", "false"].includes(updatedValue)
        ? getBooleanValue()
        : updatedValue,
    }));
  };

  const updatingVizappConfiguration = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    // Deleting the Unwanted keys

    const body = JSON.parse(JSON.stringify(vizappConfiguration));
    delete body["clientId"];
    delete body["createdAt"];
    delete body["updatedAt"];

    const stateConfigurationResponseConfig = {
      headers: {
        authorization: idToken,
      },
      method: "POST",
      url: "https://api.niroggyan.com/onboarding/config/vizapp",
      data: JSON.stringify(body),
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const stateConfigurationResponse = await axios(
      stateConfigurationResponseConfig
    );
    return stateConfigurationResponse;
  };

  const handlingRequest = async () => {
    // API call for Updating Vizapp Configuration
    setUpdatingLoader(true);
    try {
      const VizappConfigurationResponse = await updatingVizappConfiguration();
      if (VizappConfigurationResponse.status === 401) {
        console.log("Regenerating ID Token");
        await regenerateToken();
        const VizappConfigurationResponse = await updatingVizappConfiguration();
        setUpdatingLoader(false);
        console.log(
          VizappConfigurationResponse.data.data.acknowledged
            ? "Successfully Updated the Vizapp configuration @ first trail"
            : "Not Updated Successfully"
        );
      } else {
        console.log("VizappConfigurationResponse", VizappConfigurationResponse);
        setUpdatingLoader(false);
        console.log(
          VizappConfigurationResponse.data.data.acknowledged
            ? `Successfully Updated the Vizapp configuration @ first trail`
            : "Not Updated Successfully"
        );
      }
    } catch (e) {
      setUpdatingLoader(false);
      console.log("Error in updating Vizapp Configuration", e.message);
    }
  };

  return (
    <>
      <div className="container-fluid mt-3 ps-0 pe-0">
        <Form>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {/* Primary Color */}
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49%",
                textTransform: "capitalize",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Primary Color</b>
              </InputGroup.Text>
              <InputGroup.Text>
                {vizappConfiguration?.primaryColor}
              </InputGroup.Text>
              <input
                value={vizappConfiguration.primaryColor}
                type="color"
                className="form-control"
                onChange={(e) => {
                  updatingVizappStateFields(e.target.value, "primaryColor");
                  setSaveOption(true);
                }}
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>

            {/* Secondary Color */}
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49%",
                textTransform: "capitalize",
                height: "35px",
              }}
            >
              <InputGroup.Text>
                <b>Secondary Color</b>
              </InputGroup.Text>
              <InputGroup.Text>
                {vizappConfiguration.secondaryColor}
              </InputGroup.Text>
              <input
                value={vizappConfiguration.secondaryColor}
                type="color"
                className="form-control"
                onChange={(e) => {
                  updatingVizappStateFields(e.target.value, "secondaryColor");
                  setSaveOption(true);
                }}
                placeholder="Select Color"
                style={{ height: "100%" }}
              />
            </InputGroup>

            {/* Introduction Type */}
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49%",
                textTransform: "capitalize",
                height: "35px",
              }}
            >
              <InputGroup.Text id="inputGroup-sizing-default">
                <b>Introduction Type</b>
              </InputGroup.Text>

              <select
                className="form-control form-select"
                value={vizappConfiguration.introductionType}
                onChange={(e) => {
                  updatingVizappStateFields(e.target.value, "introductionType");
                  setSaveOption(true);
                }}
              >
                <option hidden>Select Introduction Type</option>
                <option value={"Classic"}>Classic</option>
                <option value={"Modern"}>Modern</option>
              </select>
            </InputGroup>

            {/* Intro Gradient */}
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49%",
                textTransform: "capitalize",
                height: "35px",
              }}
            >
              <InputGroup.Text id="inputGroup-sizing-default">
                <b>Intro Gradient</b>
              </InputGroup.Text>

              <select
                className="form-control form-select"
                value={vizappConfiguration.introGradient}
                onChange={(e) => {
                  updatingVizappStateFields(e.target.value, "introGradient");
                  setSaveOption(true);
                }}
              >
                <option hidden>Select Intro Gradient</option>
                <option value={true}>Required</option>
                <option value={false}>Not Required</option>
              </select>
            </InputGroup>

            {/* Font Family */}
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49%",
                textTransform: "capitalize",
                height: "35px",
              }}
            >
              <InputGroup.Text id="inputGroup-sizing-default">
                <b style={{ width: "49%", textTransform: "capitalize" }}>
                  Font Family
                </b>
              </InputGroup.Text>

              <select
                className="form-control form-select"
                value={vizappConfiguration.fontFamily}
                onChange={(e) => {
                  updatingVizappStateFields(e.target.value, "fontFamily");
                  setSaveOption(true);
                }}
              >
                <option hidden>Select Font Family</option>
                <option value={"Roboto"}>Roboto</option>
                <option value={"Open Sans"}>Open Sans</option>
                <option value={"Comfortoo"}>Comfortoo</option>
              </select>
            </InputGroup>

            {/* Background Type*/}
            <InputGroup
              className="mb-3 mx-1"
              style={{
                width: "49%",
                textTransform: "capitalize",
                height: "35px",
              }}
            >
              <InputGroup.Text id="inputGroup-sizing-default">
                <b style={{ width: "49%", textTransform: "capitalize" }}>
                  Background Type
                </b>
              </InputGroup.Text>

              <select
                className="form-control form-select"
                value={vizappConfiguration.backgroundType}
                onChange={(e) => {
                  updatingVizappStateFields(e.target.value, "backgroundType");
                  setSaveOption(true);
                }}
              >
                <option hidden>Select Font Family</option>
                <option value={"Classic"}>Classic</option>
                <option value={"Modern"}>Modern</option>
                <option value={"Solid"}>Solid</option>
              </select>
            </InputGroup>
          </div>
        </Form>

        {/* Buttons */}
        <div className="mt-4">
          <button
            onClick={() => setSaveOption(false)}
            className="btn btn-primary"
            disabled={!saveOption}
            style={{ borderRadius: "12px" }}
          >
            Save Changes
          </button>
          <button
            onClick={() => handlingRequest()}
            disabled={saveOption}
            className="btn btn-primary mx-4 "
            style={{ width: "132px", borderRadius: "12px" }}
          >
            {updatingLoader ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#fff" }}
              ></Spinner>
            ) : (
              "Confirm Changes"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default VizAppConfig;
