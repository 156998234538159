import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import LoginLeftContentSvg from "../svgs/LoginLeftContentSvg";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import "../css/loginPageCss.css";
import loginImage from "../assets/dashboardLogin.gif";

const LoginPage = () => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [checkingCredentials, setCheckingCredentials] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // get current date
  let todaysDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");

  // checking credentials
  useEffect(() => {
    // window.location.reload()

    const credentialsInfo = JSON.parse(localStorage.getItem("myLoginDetails"));

    if (credentialsInfo === null || credentialsInfo === undefined) {
      localStorage.setItem("myLoginDetails", JSON.stringify(""));
      window.location.reload();
    } else {
      let checkLoginDetails = JSON.parse(localStorage.getItem("loginDetails"));
      if (checkLoginDetails === null || checkLoginDetails === undefined) {
        localStorage.setItem(
          "logoUrl",
          JSON.stringify(
            "https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/niroggyan-logo.svg"
          )
        );
        window.dispatchEvent(new Event("storage"));
        //
      } else {
        if (credentialsInfo.toUpperCase() === checkLoginDetails.org) {
          navigate("/");
        }
      }
    }
  }, []);

  const loginFormHandler = async (e) => {
    e.preventDefault();

    setCheckingCredentials(true);

    let dataToSend = {
      username: username,
      password: password,
      // type: 'login',
    };

    // login endpoint
    var config = {
      method: "POST",
      //url: '',
      url: "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/Testing/client/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(dataToSend),
    };

    try {
      // EXPIRING LOGIN SESSION
      // var datetime = new Date().toLocaleString().replace(',', '')

      const axiosData = await axios(config);
      // console.log(
      //   'inside axiosdata ===> \n\n',
      //   axiosData.data.data.userDetails.accessTo.dashBoard
      // );
      if (axiosData?.data?.data?.userDetails) {
        localStorage.setItem(
          "credentials",
          JSON.stringify(axiosData.data.data.userDetails)
        );
      }
      if (axiosData.data.data.userDetails.accessTo) {
        if (axiosData.data.data.userDetails.accessTo.dashBoard) {
          localStorage.setItem("clientPreference", JSON.stringify("none"));
          localStorage.setItem(
            "idTokenBilling",
            axiosData.data.data.authTokens.idToken
          );
          if (axiosData.data.data.clientData) {
            localStorage.setItem(
              "loginDetails",
              JSON.stringify({
                ...axiosData.data.data.userDetails,
                clientData: [...axiosData.data.data.clientData],
              })
            );
          } else {
            localStorage.setItem(
              "loginDetails",
              JSON.stringify({
                ...axiosData.data.data.userDetails,
              })
            );
          }

          localStorage.setItem(
            "logoUrl",
            JSON.stringify(axiosData.data.data.userDetails.logoSrc)
          );
          localStorage.setItem("myLoginDetails", JSON.stringify(username));
          localStorage.removeItem("orgPreference");

          let orgName = axiosData.data.org;
          let centerName = axiosData.data.center;
          let lisName = axiosData.data.role;

          // once login is successful fetch the actual data (followed by org and center name)
          let homePageApi = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${orgName}&center=${centerName}`;
          let homePageApiForLIS = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${orgName}&center=${centerName}&query_on=${lisName}`;
          let adminEndPoint = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/testquery?org=any&center=any`;

          let endpointToUse;

          if (lisName === "lab") {
            endpointToUse = homePageApi;
          } else if (lisName === "LIS") {
            endpointToUse = homePageApiForLIS;
          } else {
            endpointToUse = adminEndPoint;
          }

          console.log("using endpoint: ", endpointToUse);

          var config2 = {
            method: lisName === "lab" || lisName === "LIS" ? "GET" : "POST",
            // url: "",
            url: endpointToUse,
            headers: {
              "Content-Type": "application/json",
            },
          };
          // const sampleLoginDetails = {
          //   username: username,
          //   password: password,
          // };
          // const loginApiConfig = {
          //   url: 'https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/Testing/oauth/token?grant_type=credentials',
          //   method: 'POST',
          //   data: JSON.stringify(sampleLoginDetails),
          // };

          // const loggingIn = async () => {
          //   const response = await axios(loginApiConfig);
          //   console.log('response From OAuth', response);
          //   const idToken = response.data.data.IdToken;
          //   localStorage.setItem('idTokenBilling', idToken);
          // };

          try {
            // await loggingIn();
            setCheckingCredentials(false);
            //console.log("axiosData2: ", axiosData2)
            localStorage.setItem("loggedOn", JSON.stringify(todaysDate));
            localStorage.setItem(
              "sessionStartTime",
              JSON.stringify(new Date().getTime() + 3600 * 1000)
            );
            // localStorage.setItem('homepageData', JSON.stringify(axiosData2));
            navigate("/subscription");
            alert.success("Login Successful!");

            // dispatchEvent => so that the event listener can notice the changes in local storage
            // (will make the navbar component to re render)
            window.dispatchEvent(new Event("storage"));
          } catch (error) {
            console.log("Homaepage API Error:\n", error);
          }
        } else {
          localStorage.setItem("maintenance", "true");
          navigate("/maintenance");
          window.location.reload();
          localStorage.setItem("loginDetails", JSON.stringify(axiosData.data));
        }
      } else {
        navigate("/maintenance");
        localStorage.setItem("maintenance", "true");
        localStorage.setItem("loginDetails", JSON.stringify(axiosData.data));
      }
    } catch (error) {
      setCheckingCredentials(false);
      console.log(error.message);
      alert.error(
        <span style={{ fontSize: "15px" }}>{"Invalid Credentials!"}</span>
      );
    }
  };

  return (
    <div className="loginPageContainer">
      <div>
        <img
          src="https://niroggyan.s3.ap-south-1.amazonaws.com/websitelogos/Niroggyan_logo.svg"
          alt="niroggyan-logo"
        />
      </div>
      <div className="loginPageLeftAndRightContent">
        {/* Left Content */}
        <div className="loginLeftContent">
          {/* <LoginLeftCnpmontentSvg /> */}
          <div className="leftImageWrapper">
            <img src={loginImage} alt="login" width="400px" height="400px" />
          </div>
        </div>

        {/* Right Content */}

        <div className="loginRightContent">
          <div className="formWrapper">
            <div style={{ fontSize: "25px", fontWeight: "550" }}>
              Welcome Back <span>👋</span>
            </div>
            <div
              style={{
                color: "#6A6B6B",
                fontWeight: "12px",
                marginTop: "28px",
              }}
            >
              {
                "This dashboard offers robust analytics and insights across utilisation, patient demographics, biomarker & package analysis, and operational efficiency, empowering you to finetune your operations and marketing for better healthcare delivery and business outcomes."
              }
            </div>

            {/* Login Form */}
            {/* <div
          style={{
            fontSize: "25px",
            fontWeight: "550",
            margin: "40px 0px 20px 0px",
          }}
        >
          {"Login"}
        </div> */}
            <div style={{ marginTop: "28px" }}>
              <Form onSubmit={loginFormHandler}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label style={{ fontSize: "14px", fontWeight: "600" }}>
                    {"Username"}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    defaultValue={username}
                    placeholder="username"
                    style={{ borderRadius: "12px", paddingLeft: "16px" }}
                  />
                </Form.Group>

                <Form.Label
                  style={{ fontSize: "14px", fontWeight: "600" }}
                  htmlFor="inputPassword5"
                >
                  Password
                </Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  defaultValue={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={"**********"}
                  aria-label="password"
                  style={{ borderRadius: "12px", paddingLeft: "16px" }}
                  // aria-describedby="passwordHelpBlock"
                />
                <div className="d-flex mt-1 align-items-center">
                  <Form.Check
                    id="show-password"
                    onChange={(e) => setShowPassword(e.target.checked)}
                  />
                  <Form.Label
                    style={{ fontSize: "14px" }}
                    htmlFor="show-password"
                    className="mx-2 mb-0 mt-1"
                  >
                    Show Password
                  </Form.Label>
                </div>

                {/* <div style={{ display: "flex", justifyContent: "end", color: "#6A6B6B", fontSize: "14px" }}>
            {"Forgot Password?"}
          </div> */}

                <div className="d-grid gap-2" style={{ marginTop: "30px" }}>
                  <Button
                    disabled={checkingCredentials}
                    type="submit"
                    size="lg"
                    style={{ backgroundColor: "#3567D6", borderRadius: "12px" }}
                  >
                    {checkingCredentials ? (
                      <span>
                        {"Checking Credentials"}
                        <Spinner
                          animation="border"
                          size="sm"
                          style={{ marginLeft: "10px" }}
                        />
                      </span>
                    ) : (
                      "Login"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
