import { Stack, Typography } from "@mui/material";
import NavbarComponent from "../NavbarComponent";
import PageTitle from "../../common/PageTitle";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Mapping from "./Mapping";
import ProductConfig from "./ProductConfig";
import ContentManagement from "./ContentManagement";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { IoCubeOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { useEffect, useState } from "react";

const Configuration = () => {
  const [activeTab, setActiveTab] = useState("mapping");
  const sections = [
    { title: "Mapping", path: "mapping", icon: <IoCubeOutline size={20} /> },
    {
      title: "Product Configuration",
      path: "product-config",
      icon: <FiEdit size={20} />,
    },
    {
      title: "Content Management",
      path: "content-management",
      icon: <IoSettingsOutline size={20} />,
    },
  ];
  const location = useLocation();

  const activeStyles = {
    padding: "10px 16px",
    color: "#1F78FF",
    background: "rgba(31, 120, 255, 0.1)",
    borderRadius: "8px",
  };

  useEffect(() => {
    const activePath = location.pathname.split("/");
    if (activePath[2]) {
      setActiveTab(activePath[2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    location.href = "/mapping";
  }, []);

  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />
      <Stack gap={1} flexGrow={1} sx={{ padding: "0px 64px 40px" }}>
        <PageTitle title="Configurations" />
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          sx={{
            background: "#fff",
            padding: "8px 16px", //14px 24px
            borderRadius: "12px",
            border: "1px solid rgba(0, 0, 0, 0.2)",
          }}
        >
          {sections.map((each) => (
            <Link to={each.path}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1.2}
                sx={
                  activeTab === each.path
                    ? activeStyles
                    : { padding: "10px 16px", color: "#000000" }
                }
              >
                <Typography variant="body9" sx={{ color: "inherit" }}>
                  {each.icon}
                </Typography>
                <Typography variant="body9" sx={{ color: "inherit" }}>
                  {each.title}
                </Typography>
              </Stack>
            </Link>
          ))}
        </Stack>
        <Routes>
          <Route path="/" element={<Navigate to="mapping" />} />
          <Route path="mapping" element={<Mapping />} />
          <Route path="product-config" element={<ProductConfig />} />
          <Route path="content-management" element={<ContentManagement />} />
        </Routes>
      </Stack>
    </Stack>
  );
};

export default Configuration;
