import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";

const NavBar = () => {
  const [hideLoginButton, setHideLoginButton] = useState("");
  const [toggleState, setToggleState] = useState(true);
  const [customMargins, setCustomMargins] = useState("-100px");
  const location = useLocation();

  useEffect(() => {
    const loginData = localStorage.getItem("credentials");

    if (JSON.parse(loginData)) {
      setHideLoginButton(true);
    } else {
      setHideLoginButton(false);
    }
  }, []);

  let navigate = useNavigate();
  // using same portal for Prolabs
  // const sampleCsvUrl =
  //   'https://niroggyan.s3.ap-south-1.amazonaws.com/excel_for_portals/Namma+Wellness+sample+CSVs.zip';
  const sampleCsvUrl =
    "https://proforma1-bucket.s3.ap-south-1.amazonaws.com/Sample-CSV.zip";

  return (
    <header>
      <Navbar
        className="mb-3 ps-3 pe-3"
        bg="primary"
        variant="dark"
        expand="lg"
        collapseOnSelect
      >
        <Container fluid>
          <Link to="/manualReports">
            <Navbar.Brand>
              <i className="mb-2 fas fa-home"> Home</i>
            </Navbar.Brand>
          </Link>

          {hideLoginButton ? (
            <>
              {["elixcare", "testinguser", "medibuddy", "max"].includes(
                JSON.parse(localStorage.getItem("credentials")).center
              ) ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Link to="/manualReports/Manual-Entry-Home-Page">
                    <Navbar.Brand>
                      <button
                        className={
                          location.pathname ===
                            "/manualReports/Manual-Entry-Home-Page" ||
                          location.pathname ===
                            "/manualReports/itemSelection/" ||
                          location.pathname === "/manualReports/profileTests/"
                            ? "btn btn-light"
                            : "btn btn-outline-light"
                        }
                      >
                        {" "}
                        Manual Test Entry
                      </button>
                    </Navbar.Brand>
                  </Link>
                  <Link to="/manualReports/archives">
                    <Navbar.Brand>
                      <button
                        className={
                          location.pathname === "/manualReports/archives"
                            ? "btn btn-light"
                            : "btn btn-outline-light"
                        }
                      >
                        {" "}
                        Archives
                      </button>
                    </Navbar.Brand>
                  </Link>
                  {/* <Link to='/'>
                    <Navbar.Brand>
                      <button className='btn btn-outline-light'>
                        {' '}
                        CSV Upload
                      </button>
                    </Navbar.Brand>
                  </Link> */}
                </div>
              ) : (
                ""
              )}
              {/* {hideLoginButton ? (
                <Link
                  to="/login"
                  onClick={() => {
                    localStorage.removeItem("credentials");
                    localStorage.removeItem("base64");
                    window.location.reload();
                  }}
                >
                  <div className="text-white">
                    Logout <i className="fas fa-sign-out-alt text-white"></i>
                  </div>
                </Link>
              ) : (
                ""
              )} */}
            </>
          ) : (
            <>
              <Link to="/login">
                <div className="text-light">
                  <i className="fas fa-user mx-1"></i> Login
                </div>
              </Link>
            </>
          )}
        </Container>
      </Navbar>
    </header>
  );
};

export default NavBar;
