import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Dropdown } from "react-bootstrap";
import LogoutIcon from "@mui/icons-material/Logout";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import "../css/pageTitleSection.css";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import AlarmIcon from "@mui/icons-material/Alarm";

const PageTitle = ({ title }) => {
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const [alertLoader, setAlertLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertError, setAlertError] = useState("");
  const [mailSent, setMailSent] = useState(false);

  const logoutHandler = () => {
    localStorage.clear();
    localStorage.setItem("myLoginDetails", JSON.stringify(""));
    localStorage.setItem("clientPreference", JSON.stringify("none"));
    localStorage.setItem(
      "logoUrl",
      JSON.stringify(
        "https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/niroggyan-logo.svg"
      )
    );
    window.dispatchEvent(new Event("storage"));
    window.location.replace("/login");
  };

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const sendSOSMailAlert = async () => {
    setAlertLoader(true);
    setAlertError("");
    setMailSent(false);
    let date = new Date();
    let formatDate = date.toString().split("GMT")[0];
    let subjectDateFormat = `[${month[date.getMonth()]} ${date.getFullYear()}]`;
    let maildata = {
      fromEmail: ["reports@niroggyan.com"],
      // toEmail: ["kiran@niroggyan.com"],
      toEmail: ["rajeev@niroggyan.com", "tech@niroggyan.com"],
      // cc: ["nithish@niroggyan.com", "kiran@niroggyan.com"],
      attachments: [],
      withAttachments: "false",
      bodyType: "text",
      emailBody: `An SOS alert has been triggered by ${loginData?.displayName} on ${formatDate}.`,
      subject: `🚨 SOS: ${loginData?.displayName}${subjectDateFormat}`,
    };
    const mailApi =
      "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/Testing/notifications/email";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(maildata),
    };
    try {
      const response = await fetch(mailApi, options);
      const data = await response.json();
      if (response.status == 200) {
        setMailSent(true);
      }
    } catch (e) {
      console.log("error", e);
      setAlertError("Something went wrong. Please try again later.");
    } finally {
      setAlertLoader(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const alertConfirmationDialog = () => {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack justifyContent="center" alignItems="center" mt={3}>
            {alertLoader ? (
              <CircularProgress />
            ) : (
              <Stack justifyContent="center" alignItems="center" gap={1.5}>
                <span>
                  {alertError ? (
                    <ErrorIcon color="error" sx={{ fontSize: "30px" }} />
                  ) : mailSent ? (
                    <CheckCircleIcon
                      color="success"
                      sx={{ fontSize: "30px" }}
                    />
                  ) : (
                    ""
                  )}
                </span>

                <Typography sx={{ textAlign: "center" }}>
                  {alertError
                    ? alertError
                    : mailSent
                    ? "Your SOS alert has been sent successfully. Our team has been notified and will respond shortly."
                    : "Are you sure you want to send an SOS alert?"}
                </Typography>
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          {mailSent || alertError ? (
            <Button onClick={handleClose}>Close</Button>
          ) : (
            <Button onClick={sendSOSMailAlert} disabled={alertLoader}>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: "0",
        zIndex: 100,
        background: "#E9F5FE",
        padding: "20px 16px 18px",
        margin: "0px -16px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography variant="h1">{title}</Typography>
        {/* logo dropdown */}
        <Dropdown className="d-inline mx-2">
          <Dropdown.Toggle
            id="dropdown-autoclose-true"
            className="customDropdown"
            style={{
              background: "#fff",
              color: "#000000",
              border: "1px solid #D9D9D9",
              padding: "14px",
            }}
          >
            {loginData?.role === "lab" ? (
              loginData?.customerName === "Dummy User" ? (
                <span style={{ width: "135px", display: "inline-block" }}>
                  Your Logo
                </span>
              ) : (
                <img
                  src={loginData.logoSrc}
                  alt={loginData.center}
                  height="35px"
                  width="135px"
                  style={{ objectFit: "contain", marginRight: "8px" }}
                />
              )
            ) : (
              <span style={{ width: "135px", display: "inline-block" }}>
                Admin
              </span>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: "184px", padding: "0" }}>
            {loginData?.role === "lab" && (
              <Dropdown.Item
                href="/refer"
                style={{ borderBottom: "1px solid #D4D7E3" }}
                className="customDropdownItem"
              >
                <div className="d-flex align-items-center py-2">
                  <MonetizationOnOutlinedIcon
                    sx={{ color: "#3567D6", fontSize: "24px" }}
                  />
                  <p style={{ marginLeft: "16px" }}>Refer</p>
                </div>
              </Dropdown.Item>
            )}
            {/* SOS alert button */}
            <Dropdown.Item
              onClick={setOpen}
              style={{ borderBottom: "1px solid #D4D7E3" }}
              className="customDropdownItem"
            >
              <div className="d-flex align-items-center py-2">
                <AlarmIcon sx={{ color: "red" }} />
                <p style={{ marginLeft: "16px" }}>SOS Alert</p>
              </div>
            </Dropdown.Item>
            {/* SOS alert button */}
            <Dropdown.Item onClick={logoutHandler}>
              <div className="d-flex align-items-center py-2">
                <LogoutIcon sx={{ color: "#3567D6" }} />
                <p style={{ marginLeft: "16px" }}>Logout</p>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {alertConfirmationDialog()}
      </Stack>
    </Box>
  );
};

export default PageTitle;
