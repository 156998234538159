import { useEffect, useState } from "react";
import NavBar from "./NavBar";
import SideNavbar from "./SideNavbar";
import { useNavigate } from "react-router-dom";
import { Table } from "semantic-ui-react";
import NavbarComponent from "../NavbarComponent";
import { Stack } from "@mui/material";
import PageTitle from "../../common/PageTitle";

const Archives = () => {
  // At present we are gathering from the local storage we can replace it from API in further stages
  useEffect(() => {
    const loginData = localStorage.getItem("credentials");
    if (JSON.parse(loginData)) {
    } else {
      navigate("/login");
    }
    const storedPatientDetailsFromStorage =
      localStorage.getItem("storedPatientsData");
    setStoredPatientDetails(
      JSON.parse(storedPatientDetailsFromStorage)
        ? JSON.parse(storedPatientDetailsFromStorage)
        : []
    );
  }, []);
  let navigate = useNavigate();
  const [storedPatientDetails, setStoredPatientDetails] = useState([]);
  // console.log('detailsfromStorage', storedPatientDetails);

  const settingUPPatientDetails = (details) => {
    const patientDetails = JSON.parse(localStorage.getItem("patientDetails"))
      ? JSON.parse(localStorage.getItem("patientDetails"))
      : {};
    // Add all the details
    patientDetails.name = details.PName;
    patientDetails.age = details.Age;
    patientDetails.sampleId = details.LabNo;
    patientDetails.collectionDate = details.CollectedDate;
    patientDetails.gender = details.Gender;
    patientDetails.refBy = details.ReferredBy;

    localStorage.setItem("patientDetails", JSON.stringify(patientDetails));
    localStorage.setItem(
      "storedTestDetails",
      JSON.stringify(updatingStoredTestDetails(details))
    );
    navigate("/manualReports/Manual-Entry-Home-Page");
  };
  // console.log('storedPatientDetails', storedPatientDetails);

  const updatingStoredTestDetails = (patientJson) => {
    console.log(patientJson);
    let toBeStoredTestsArray = [];
    patientJson.results[0].investigation[0].observations.map((each) => {
      toBeStoredTestsArray.push({
        test: each.name,
        low: each.MinValue,
        high: each.MaxValue,
        unit: each.unit,
        result: each.value,
        method: "",
        pastObservation: each.pastObservation,
        observation_time: each.observation_time,
      });
    });
    console.log(toBeStoredTestsArray);
    return toBeStoredTestsArray;
  };
  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />
      <div style={{ flexGrow: "1", padding: "0px 64px 40px" }}>
        <PageTitle title="Manual Generation" />
        <NavBar />
        {/* <SideNavbar /> */}
        <div>
          {storedPatientDetails.length !== 0 ? (
            <Table className="">
              <thead>
                <tr className="text-center">
                  <td style={{ fontWeight: "600" }}>S.no</td>
                  <td style={{ fontWeight: "600" }}>Patient Name</td>
                  <td style={{ fontWeight: "600" }}>Sample ID</td>
                  <td style={{ fontWeight: "600" }}>Age</td>
                  <td style={{ fontWeight: "600" }}>Gender</td>
                  <td style={{ fontWeight: "600" }}>No of Parameters</td>
                  <td style={{ fontWeight: "600" }}>Collection Date</td>
                  <td style={{ fontWeight: "600" }}>Edit Data</td>
                </tr>
              </thead>
              <tbody className="text-center">
                {storedPatientDetails.map((each, index) => (
                  <tr style={{ verticalAlign: "middle" }}>
                    <td>{index + 1}</td>
                    <td>{each.PName}</td>
                    <td>{each.LabNo}</td>
                    <td>{each.Age}</td>
                    <td>{each.Gender}</td>
                    <td>
                      {each.results[0].investigation[0].observations.length}
                    </td>
                    <td>{each.CollectedDate}</td>
                    <td>
                      <button
                        onClick={() => {
                          settingUPPatientDetails(each);
                        }}
                        className="btn btn-primary"
                      >
                        Click Here
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No Patient Data </p>
          )}
        </div>
      </div>
    </Stack>
  );
};

export default Archives;
