import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
//import { biomarkerProdfileMap } from './testsDatabase'
import {
  completePackageData,
  completePackageDataForFemale,
} from "./dummyTestData";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import SideNavbar from "./SideNavbar";
import { Stack } from "@mui/material";
import NavbarComponent from "../NavbarComponent";
import PageTitle from "../../common/PageTitle";

const ItemSelectionScreen = () => {
  let findGender = JSON.parse(localStorage.getItem("patientDetails"));
  var dataSelection;

  if (findGender.gender === "Female") {
    var dataSelection = completePackageDataForFemale;
    console.log("choosing female");
  } else {
    var dataSelection = completePackageData;
    console.log("choosing male");
  }

  var completePackageDataByGender = dataSelection;

  console.log("completePackageDataByGender\n", completePackageDataByGender);

  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [findPackTests, setFindPackTests] = useState(false);
  const [selectedPackTests, setSelectedPackTests] = useState("");
  const [selectionChoice, setSelectionChoice] = useState("");
  const [page1Data, setPage1Data] = useState("");
  const [manualValues, setManualValues] = useState("");
  const [nextPage, setNextPage] = useState(true);

  useEffect(() => {
    const loginData = localStorage.getItem("credentials");
    if (JSON.parse(loginData)) {
      //
    } else {
      navigate("/login");
    }
  }, []);

  // const packages = [
  //     {
  //         "packageName": "Good Health Package",
  //         "testsInclude": ["Liver Function Test", "Kidney Panel", "Thyroid Profile Total", "RDW SD", "Lipid Profile"]
  //     },
  //     {
  //         "packageName": "Winter Special Package",
  //         "testsInclude": ["Vitamin B12", "Kidney Panel", "Diabetic Screen", "Hemogram", "Iron Deficiency Profile"]
  //     },
  //     {
  //         "packageName": "Fever Package",
  //         "testsInclude": ["Widal Test (Slide Agglutination)", "Urine Routine & Microscopy", "Malarial Parasite Identification", "Complete Blood Count"]
  //     }
  // ]

  let packProfiles = [];
  let newPackageData = [];

  for (let i = 0; i < completePackageDataByGender.length; i++) {
    let packName = completePackageDataByGender[i].packageName;
    let allResults = completePackageDataByGender[i].results;
    for (let j = 0; j < allResults.length; j++) {
      let currentProfile = allResults[j].profilename;
      packProfiles.push(currentProfile);
    }
    let myPackage = {
      packageName: packName,
      profiles: packProfiles,
    };
    packProfiles = [];
    newPackageData.push(myPackage);
  }

  // let theData = dummyTestData.results

  // let allProfiles = []

  // for(let i=0; i<theData.length; i++) {
  //     let pname = theData[i].profilename

  //     if(allProfiles.includes(pname)) {
  //         // do nothing
  //     }
  //     else {
  //         allProfiles.push(pname)
  //     }
  // }

  // const packageData = [
  //     {
  //         "packageName": "Consolidated Package",
  //         "testsInclude": allProfiles
  //     }
  // ]

  useEffect(() => {
    const data = localStorage.getItem("page1Data");
    if (data) {
      setPage1Data(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("page1Data", JSON.stringify(page1Data));
    localStorage.setItem("profileWithSimilarResult", JSON.stringify([]));
  });

  //console.log("biomarkerProdfileMap", biomarkerProdfileMap.length)

  //const manualData = ["Lipid Profile", "Kidney Function", "Urine Routine"]
  let manualData = [];

  // manualData = completePackageDataByGender[0].results.map((each) => each.profilename);
  for (let p = 0; p < completePackageDataByGender.length; p++) {
    let packageResults = completePackageDataByGender[p].results;

    for (let q = 0; q < packageResults.length; q++) {
      let getProfileName = packageResults[q].profilename;

      if (
        getProfileName === undefined ||
        getProfileName === "" ||
        manualData.includes(getProfileName)
      ) {
        //
      } else {
        manualData.push(getProfileName);
      }
    }
  }

  //   let manualDataObject = "";

  //   let resultsOnly = completePackageDataByGender[0].results;

  //   const theNewArray = [];

  //   for (let t = 0; t < resultsOnly.length; t++) {
  //     let profileData = resultsOnly[t].profilename;
  //     let profileInvestigationData = resultsOnly[t].investigation;
  //     console.log("profileData", profileData);
  //     let currentProfileInv = profileInvestigationData;
  //     for (let y = 0; y < currentProfileInv.length; y++) {
  //       let theTest = currentProfileInv[y];
  //       manualDataObject = {
  //         profile: profileData,
  //         profileTest: theTest,
  //       };
  //       theNewArray.push(manualDataObject);
  //     }
  //   }

  //   console.log("final manual data", theNewArray);

  // for(let i=0; i<completePackageDataByGender.length; i++) {
  //     let thePackage = completePackageDataByGender[i]
  //     let theResults = thePackage.results
  //     for(let j=0; j<theResults.length; j++) {
  //         let theProfile = theResults[j].profilename
  //         if(manualData.includes(theProfile)) {
  //             // do nothing
  //         } else {
  //             manualData.push(theProfile)
  //         }
  //     }
  // }

  //console.log("data in manual", manualData)

  // for(let i=0; i<theData.length; i++) {
  //     let pname = theData[i].profilename
  //     if(manualData.includes(pname)) {
  //         // do nothing
  //     }
  //     else {
  //         manualData.push(pname)
  //     }
  // }

  // for (const key in biomarkerProdfileMap) {
  //     let profileName = biomarkerProdfileMap[key]
  //     if(manualData.includes(profileName)) {
  //         //
  //     } else {
  //         manualData.push(profileName)
  //     }
  //     // to get key, value pairs ===> console.log(`${key}: ${biomarkerProdfileMap[key]}`);
  // }

  // avoiding "Package with all profiles" selection to appear in the page
  const stateOptions = newPackageData.slice(0, 4).map((each, index) => ({
    key: index,
    text: each.packageName,
    value: each.packageName,
  }));

  const stateOptions2 = manualData.map((each, index) => ({
    key: index,
    text: each,
    value: each,
  }));

  const handleDropDownValue = (event, data) => {
    setNextPage(true);
    if (selectionChoice === "package") {
      setValue(data.value);
      setFindPackTests(true);
    } else if (selectionChoice === "manual") {
      //console.log("manual", data.value)
      setManualValues(data.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem("currentTestData");

    if (selectionChoice === "package") {
      let getSelectedPackage = newPackageData.filter(
        (each) => each.packageName === value
      );
      let getRequiredPackage = completePackageDataByGender.filter(
        (each) => each.packageName === value
      );
      let getRequiredTests = getRequiredPackage.map((each) => each.results);
      let getInvestigation = getRequiredTests.map((each) => each);
      setPage1Data({
        selectionChoice: selectionChoice,
        value: value,
        selectedPackTests: getSelectedPackage[0].profiles,
        investigation: getInvestigation,
      });
    } else if (selectionChoice === "manual") {
      setPage1Data({
        selectionChoice: selectionChoice,
        selectedPackTests: manualValues,
        allManualTests: [],
      });
      // setPage1Data({
      //     "selectionChoice": selectionChoice,
      //     "value": "Manual Profile",
      //     "selectedPackTests": ["Liver Function", "Kidney Function", "Urine Routine"]
      // })
    } else if (selectionChoice === "allSelection") {
      let allProfileNames = [];
      let profilesToAvoid = [
        "Stool Analysis",
        "Semen Analysis",
        "Hemoglobin Electrophoresis",
      ];

      for (let p = 0; p < completePackageDataByGender.length; p++) {
        let thisPackageProfiles = completePackageDataByGender[p].results;
        for (let q = 0; q < thisPackageProfiles.length; q++) {
          let thisProfileName = thisPackageProfiles[q].profilename;
          if (
            allProfileNames.includes(thisProfileName) ||
            profilesToAvoid.includes(thisProfileName)
          ) {
            // skip
          } else {
            allProfileNames.push(thisProfileName);
          }
        }
      }
      setPage1Data({
        selectionChoice: "manual",
        selectedPackTests: allProfileNames, // over here
        allManualTests: [],
      });
      // setPage1Data({
      //     "selectionChoice": selectionChoice,
      //     "value": "Manual Profile",
      //     "selectedPackTests": ["Liver Function", "Kidney Function", "Urine Routine"]
      // })
    }
    setNextPage(false);
  };

  // console.log("value", value)

  // console.log("selectionChoice", selectionChoice)

  // console.log(selectionChoice, value)

  // console.log(packages[selectionChoice])

  const packTestHandler = (data) => {
    //console.log(data)
    setSelectedPackTests(data);
    setFindPackTests(false);
  };

  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />
      <div style={{ flexGrow: "1", padding: "0px 64px 40px" }}>
        <PageTitle title="Manual Generation" />
        <NavBar />
        {/* <SideNavbar /> */}
        <div className="ps-4 pe-4" style={{ margin: "30px 0px 0px 70px" }}>
          <div className="card p-4" style={{ backgroundColor: "#e6f2ff" }}>
            <Form onChange={() => setNextPage(true)} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <div className="text-center mb-3">
                  <h2 style={{ letterSpacing: "0.3px", marginBottom: "30px" }}>
                    Choose how you load the profiles
                    <h4>
                      (for multiple patient entry please Upload CSV in HomePage
                      )
                    </h4>
                  </h2>
                </div>

                {newPackageData.map((each, idx) => (
                  <div key={idx}>
                    {each.packageName === value && findPackTests
                      ? packTestHandler(each.testsInclude)
                      : ""}
                  </div>
                ))}

                <Form.Group
                  title="Disabled Currently"
                  as={Col}
                  controlId="packageSelection"
                  style={{ display: "none" }}
                >
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Check
                      type="radio"
                      name="selection"
                      value="package"
                      disabled
                      checked={selectionChoice === "package"}
                      onChange={() => setSelectionChoice("package")}
                    />
                    <Form.Label>Package Selection</Form.Label>
                  </span>
                </Form.Group>

                <Form.Group as={Col} controlId="manualSelection">
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Form.Check
                      type="radio"
                      name="selection"
                      value="manual"
                      checked={selectionChoice === "manual"}
                      onChange={() => setSelectionChoice("manual")}
                    />
                    <Form.Label style={{ marginLeft: "8px" }}>
                      Manual Profile Selection
                    </Form.Label>
                  </span>
                </Form.Group>

                <Form.Group as={Col} controlId="allSelection">
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Form.Check
                      type="radio"
                      name="allSelection"
                      value="allSelection"
                      checked={selectionChoice === "allSelection"}
                      onChange={() => setSelectionChoice("allSelection")}
                    />
                    <Form.Label style={{ marginLeft: "8px" }}>
                      All Profiles Selection
                    </Form.Label>
                  </span>
                </Form.Group>
              </Row>

              {selectionChoice === "package" ? (
                <Row>
                  <Form.Group>
                    <Form.Label>Select Package</Form.Label>
                    <Dropdown
                      disabled={selectionChoice === ""}
                      placeholder="Select Package"
                      fluid
                      search
                      selection
                      onChange={handleDropDownValue}
                      options={stateOptions}
                    />
                  </Form.Group>
                </Row>
              ) : selectionChoice === "manual" ? (
                <Row>
                  <Form.Group>
                    <Form.Label>Select Tests</Form.Label>
                    <Dropdown
                      disabled={selectionChoice === ""}
                      placeholder="Manual Selection"
                      fluid
                      search
                      selection
                      multiple
                      onChange={handleDropDownValue}
                      options={stateOptions2}
                    />
                  </Form.Group>
                </Row>
              ) : (
                ""
              )}

              <Link
                className="button-float-css"
                to={"/manualReports/profileTests"}
                style={{ pointerEvents: nextPage === true ? "none" : "" }}
              >
                <button
                  disabled={nextPage === true}
                  className="btn btn-info btn-sm mt-3"
                >
                  Next Page <i className="fas fa-arrow-alt-circle-right"></i>
                </button>
              </Link>

              <button
                type="submit"
                className="mx-2 btn btn-primary btn-sm mt-3 button-float-css"
              >
                Save
              </button>

              <Link
                className="button-float-css"
                to={"/manualReports/Manual-Entry-Home-Page"}
                style={{ pointerEvents: nextPage === true ? "none" : "" }}
              >
                <button
                  disabled={nextPage === true}
                  className="btn btn-info btn-sm mt-3"
                >
                  <i className="fas fa-arrow-alt-circle-left"></i> Previous
                </button>
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </Stack>
  );
};

export default ItemSelectionScreen;
