import React, { useEffect, useState } from 'react'
import { Table, Dropdown } from 'react-bootstrap'


const TableComponent = () => {

    const [trialData, setTrialData] = useState("")
    const [reRenderComponent, setReRenderComponent] = useState(false)
    const [currentYear, setCurrentYear] = useState("2022")
    const [recordOfTrialClientsData, setRecordOfTrialClientsData] = useState([])
    const [term, setTerm] = useState("")

    let recordOfYears = []
    let minYear = 2019
    let thisYear = new Date().getFullYear()
    let theDiffernce = parseInt(thisYear) - parseInt(minYear)

    for (let i = 0; i < theDiffernce + 1; i++) {
        recordOfYears.push(minYear + i)
    }

    window.addEventListener("storage", (e) => {
        // console.log("something got changed.")
        setReRenderComponent(!reRenderComponent)
    });


    useEffect(() => {

        let trialClientsDataInfo = JSON.parse(localStorage.getItem("trialClientsData"))

        if (trialClientsDataInfo) {
            setTrialData(trialClientsDataInfo.data)
        }

    }, [reRenderComponent])

    useEffect(() => {

        let allData = []

        for (const prop in trialData) {
            // console.log("prop: ", prop, trialData[prop])
            let secondProp = trialData[prop]
            let collectYearWiseData = []
            for (const subProp in secondProp) {
                let modifiedData = { "year": subProp, "data": secondProp[subProp] }
                let toUse = modifiedData.data
                let copyMonthAndCount = []
                for (const monthsDetail in toUse) {
                    let singleMonthCount = { "monthName": monthsDetail, "reportGen": toUse[monthsDetail] }
                    copyMonthAndCount.push(singleMonthCount)
                }
                // console.log("=>", {"year": subProp, "monthsData": copyMonthAndCount})
                let dataToUse = { "year": subProp, "monthsData": copyMonthAndCount }
                if (dataToUse.year === currentYear) {
                    collectYearWiseData.push(dataToUse)
                }
            }
            let newData = { "clientId": prop, "clientData": collectYearWiseData }
            allData.push(newData)
            // console.log("new Data\n", {"clientId": prop, "clientData": collectYearWiseData})
            // console.log("---------------------------------------")
            // allData.push({ "clientId": prop, "clientData": trialData[prop] })
        }

        setRecordOfTrialClientsData(allData)

    }, [trialData, currentYear])

    // console.log("recordOfTrialClientsData\n", recordOfTrialClientsData)

    //let dataHere = recordOfTrialClientsData.map(each => each.clientData[0] === undefined ? "none" : each.clientData[0].monthsData.map(every => every.monthName === "Jan" ? every.reportGen : ''))
    //console.log("dataHere\n", dataHere)

    // console.log("currentYear: ", currentYear)

    const resetClientValues = () => {
        localStorage.removeItem("trialClientsData")
        localStorage.removeItem("trialClientPreference")
        window.location.reload()
    }

    return (
        <div>
            {/* Year Selector */}
            <Dropdown className="mb-3">
                <Dropdown.Toggle variant="info" size="sm" id="dropdown-basic">
                    {`Selected Year (${currentYear})`}
                </Dropdown.Toggle>

                <button
                    onClick={resetClientValues}
                    type="button" class="mx-2 btn btn-sm btn-danger">{"Reset"}</button>

                <Dropdown.Menu>
                    {recordOfYears.map((each, idx) => (
                        <Dropdown.Item onClick={() => setCurrentYear(each.toString())} key={idx}>{each}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            {/* Search Feature */}
            <div class="">
                <label class="sr-only" for="inlineFormInputGroup">Username</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="fa fa-search p-1" aria-hidden="true"></i>
                        </div>
                    </div>
                    <input
                        className='form-control mb-2'
                        type="text"
                        placeholder="search client"
                        value={term}
                        style={{ outline: "none", boxShadow: "none"}}
                        onChange={(e) => setTerm(e.target.value)}
                    />

                </div>
            </div>

            {/* Table Logic */}

            <div style={{ overflow: "scroll", height: "340px" }}>
                <Table bordered hover>
                    <thead style={{}}>
                        <tr>
                            <th>Client ID</th>
                            <th>Jan</th>
                            <th>Feb</th>
                            <th>Mar</th>
                            <th>Apr</th>
                            <th>May</th>
                            <th>Jun</th>
                            <th>Jul</th>
                            <th>Aug</th>
                            <th>Sep</th>
                            <th>Oct</th>
                            <th>Nov</th>
                            <th>Dec</th>
                        </tr>
                    </thead>

                    {/* For Display */}
                    <tbody>
                        {recordOfTrialClientsData
                            .filter((each) => each.clientId.toLowerCase().includes(term.toLowerCase()))
                            .map((each, idx) => (
                                <tr>
                                    <td>{each.clientId}</td>
                                    <td title='Jan'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Jan" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Feb'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Feb" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Mar'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Mar" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Apr'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Apr" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='May'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "May" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Jun'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Jun" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Jul'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Jul" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Aug'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Aug" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Sep'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Sep" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Oct'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Oct" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Nov'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Nov" ? <b>{every.reportGen}</b> : '')}</td>
                                    <td title='Dec'>{each.clientData[0] === undefined ? "0" : each.clientData[0].monthsData.map(every => every.monthName === "Dec" ? <b>{every.reportGen}</b> : '')}</td>

                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default TableComponent