import { CircularProgress, Stack, Typography } from "@mui/material";

const ErrorView = ({ error }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "200px" }}
    >
      <Typography color="error" variant="body5">
        {error}
      </Typography>
    </Stack>
  );
};

export default ErrorView;
