import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";

import BoltIcon from "@mui/icons-material/Bolt";
import { CustomBox } from "./BoxAbstraction";
import molecule from "../assets/molecule.png";

const CollapsableHeader = ({ title, subheading, tooltipText }) => {
  return (
    <Box>
      <Tooltip title={tooltipText} placement="bottom">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
          mr={2}
        >
          <Stack gap={1.5} direction="row" alignItems="center">
            <Box
              sx={{
                width: "32px",
                height: "32px",
                background: "#F0F0F0", // "#575757"
                borderRadius: "4px",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <BoltIcon /> */}
              <img
                src={molecule}
                alt="molecule"
                height="80%"
                width="80%"
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography variant="h2">{title}</Typography>
          </Stack>
          {/* <Button
        variant="custom3"
        sx={{
          padding: "8px 12px",
        }}
      >
        Collapse
      </Button> */}
        </Stack>
      </Tooltip>
      <Typography
        mt={1}
        sx={{ fontSize: "14px", fontWeight: 400, paddingLeft: "45px" }}
      >
        {subheading}
      </Typography>
    </Box>
  );
};

export default CollapsableHeader;
