import { Form, InputGroup, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { useAlert } from "react-alert";
import { IoMdRemoveCircle } from "react-icons/io";
import { AiFillPlusCircle } from "react-icons/ai";
import Cookies from "js-cookie";
import regenerateToken from "../regenerateTokens";
import axios from "axios";
import CollapsableHeader from "../../../common/CollapsableHeader";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SearchIcon from "@mui/icons-material/Search";

// Don't Delete this since these are tests of our standard database
const dummyTests = [
  "Total Cholesterol",
  "HDL Cholesterol",
  "LDL Cholesterol",
  "HDL % of total",
  "Direct LDL Cholestrol",
  "HDL : LDL ratio",
  "LDL : HDL ratio",
  "APO B : APO A Ratio",
  "APO A : APO B Ratio",
  "HsCRP",
  "Lipoprotein (a) [Lp(a)]",
  "Apo A",
  "Triglycerides",
  "VLDL",
  "Apo B",
  "Creatine-Kinase",
  "Non - HDL Cholesterol",
  "Total Cholesterol : HDL ratio",
  "HDL :Total Cholesterol ratio",
  "Total Bilirubin",
  "Direct Bilirubin",
  "Indirect Bilirubin",
  "SGOT (AST)",
  "SGOT (ALT)",
  "SGPT (ALT)",
  "SGPT (AST)",
  "AST / ALT Ratio",
  "GGT",
  "ALP",
  "LDH",
  "Protein (Total)",
  "Albumin",
  "Globulin",
  "Albumin : Globulin ratio",
  "Albumin / Creatinine ratio",
  "Serum Creatinine",
  "Uric Acid",
  "Blood Urea",
  "Blood Urea Nitrogen (BUN)",
  "Urea : Creatinine ratio",
  "BUN : Creatinine ratio",
  "Microalbumin : Creatinine ratio",
  "TSH",
  "TSH 3RD GENERATION",
  "Ultra TSH",
  "T3 (Triiodothyronine)",
  "T4 (Thyroxine)",
  "Free T3 (Triiodothyronine)",
  "Free T4 (Thyroxine)",
  "Parathyroid Hormone (PTH)",
  "Anti Thyroglobulin Abs",
  "THYROGLOBULIN",
  "Blood Sugar (Fasting)",
  "Amylase",
  "Blood Sugar (Postprandial)",
  "Blood Sugar (Random)",
  "Insulin (Fasting)",
  "Insulin (Postprandial)",
  "Insulin (Random)",
  "CRP",
  "RA factor (quantitative)",
  "RA factor (descriptive)",
  "ASO (quantitative)",
  "Haemoglobin",
  "Iron",
  "TIBC",
  "UIBC",
  "G6PD (Quantitative)",
  "Ferritin",
  "Transferrin Serum",
  "% Saturation Transferrin",
  "RBC count",
  "Haematocrit",
  "MCV",
  "MENTZER INDEX",
  "MCH",
  "MCHC",
  "Rapid Plasma Reagin",
  "Sickle Cell Solubility Test-Sickling phenomenon",
  "Content Haemoglobin (CH)",
  "Total Leukocyte Count",
  "Platelet Count",
  "Mean Platelet Component (MPC)",
  "Mean Platelet Mass (MPM)",
  "MPV",
  "PDW",
  "PDW - SD",
  "Large Unstained Cell",
  "Absolute Large Unstained Cell",
  "Peripheral smear",
  "PCT",
  "Specific Gravity",
  "pH",
  "Urinary Calcium",
  "Sodium",
  "Body Mass Index(BMI)",
  "Pulse",
  "Height",
  "Weight",
  "Systolic",
  "Diastolic",
  "SERUM BICARBONATE(D)",
  "Potassium",
  "Chloride",
  "Calcium",
  "Ionized Calcium",
  "Non Ionized Calcium",
  "Corrected Calcium",
  "Zinc",
  "Phosphorus",
  "Magnesium",
  "HbA1c (Glycosylated Haemoglobin)",
  "HbA1c (mmol/mol)",
  "eAG (Estimated Average Glucose)",
  "Neutrophils",
  "Abs. Neutrophil Count",
  "Segmented Neutrophils",
  "Neutrophil Lymphocyte Ratio",
  "Eosinophils",
  "Abs. Eosinophil Count",
  "Basophils",
  "Abs. Basophil Count",
  "Abs. Lymphocyte Count",
  "Abs. Monocyte Count",
  "Lymphocytes",
  "Polymorphs",
  "RBC Morphology",
  "WBC Morphology",
  "Platelate Morphology",
  "Reticulocytes Count",
  "CORRECTED RETICULOCYTE COUNT",
  "ABSOLUTE RETICULOCYTE COUNT",
  "RETICULOCYTE HEAMOGLOBIN EQUIVELENT",
  "IMMATURE PLATELET FRACTION",
  "Monocytes",
  "Myelocytes",
  "Meta-Myelocytes",
  "Pro-Myelocytes",
  "Atypical Lymphocytes (LUC)",
  "Band Cells",
  "Blast Cells",
  "HDW (Haemoglobin Distribution Width)",
  "RDW",
  "RDW-CV",
  "RDW-SD",
  "ESR",
  "Microalbumin",
  "Glucose in Urine",
  "URINE SPOT PROTEIN",
  "URINE SPOT CREATININE",
  "Urine Spot Microalbumin",
  "SPOT PROTEIN & CREATININE RATIO",
  "DEPOSITS :",
  "Urine Colour",
  "Appearance",
  "VOLUME",
  "Pus Cells",
  "Epithelial Cells",
  "Urobilinogen",
  "Protein",
  "Ketone",
  "Acetone",
  "Phosphates/Urates",
  "Bile Salts",
  "Blood",
  "Nitrite",
  "Casts",
  "Crystals",
  "Bacteria",
  "Yeast cells",
  "Amorphous Deposit",
  "Bile Pigment",
  "Bilirubin",
  "Absorbic Acid",
  "RBC",
  "Leukocytes",
  "Leukocyte Esterase",
  "Sugar in Urine",
  "Other compounds in Urine",
  "Candida in Urine",
  "Vitamin D (25-Hydroxy)",
  "Serum Folate",
  "Vitamin B9",
  "Vitamin B12",
  "Vitamin B1",
  "Vitamin B-Complex",
  "Biotin",
  "Glutathione",
  "Fat Burner",
  "Vitamin B6",
  "Vitamin B2",
  "Vitamin A",
  "Vitamin E",
  "Vitamin C",
  "RBC(PBS)",
  "WBC(PBS)",
  "Platelet(PBS)",
  "Glomerular Filtration Rate",
  "Glomerular Filtration Rate CKD",
  "Albuminuria",
  "P-LCR",
  "P-LCC",
  "Immature Granulocytes",
  "Abs. Immature Granulocytes",
  "Nucleated red blood cells",
  "Absolute Nucleated red blood cells",
  "Urinary Creatinine",
  "Blood Group",
  "Rh Factor",
  "Rh Factor Quantitative",
  "Prostate-Specific Antigen Free",
  "Prostate-Specific Antigen Total",
  "PT TEST",
  "Thrombin Time",
  "PT (Control)",
  "Mean Normal Prothrombic Time",
  "Partial Thromboplastin Time",
  "Activated Partial Thromboplastin Time",
  "Partial Thromboplastin Time(Control)",
  "PT-INR",
  "PT ratio",
  "ISI",
  "Index",
  "Dengue IgG",
  "Dengue IgM",
  "Dengue NS1",
  "HIV",
  "Typhoid (Widal Test)",
  "Malaria (VIVAX)",
  "Malaria (FALCIPARUM)",
  "Prolactin",
  "Urine for Beta HCG",
  "Serum for Beta HCG",
  "Beta HCG",
  "Pregnancy Test Serum",
  "Leutinizing Harmone",
  "AMH2",
  "Estradiol (E2)",
  "FSH",
  "Progesterone",
  "AMH",
  "Estrogen",
  "Oestradiol",
  "SHBG",
  "17-HydroxyProgesterone",
  "Homocysteine",
  "DHEA-Sulphate",
  "Cortosol Random",
  "Testosterone",
  "Testosterone(Free)",
  "Androstenedione",
  "HBsAb",
  "HBsAg",
  "Hepatitis B",
  "HBcAb Total",
  "HBcAb IgM",
  "HCV",
  "HCV AB",
  "Anti HCV",
  "HPV",
  "RAPID TP(SYPHILIS), VDRL",
  "HAV AB IgM",
  "HAV AB",
  "HAV AB Total",
  "CMV AB IgG",
  "CMV AB IgM",
  "CA 19-9",
  "CA 125",
  "CA 15-3",
  "CEA",
  "HIV AB",
  "HIV Combo (Ag+Ab)",
  "VDRL",
  "Herpes Semplex Virus I IgM",
  "Herpes Semplex Virus II IgM",
  "Herpes Semplex Virus",
  "Chlamydia IgM",
  "TPHA (TREPONEMA PALLIDUM)",
  "High Vaginal Swab",
  "Urea Breath Test",
  "Typhidot",
  "WIDAL TEST (SLIDE METHOD)",
  "Gram Stain",
  "Helicobacter Antigen in Stool",
  "Helicobacter Pylori Abs.(IgM)",
  "Helicobacter Pylori Abs.(IgG)",
  "Egg White Allergy",
  "Egg Yolk Allergy",
  "Cow Milk Allergy",
  "Chocolate Allergy",
  "Wheat Flour Allergy",
  "Soy Bean Allergy",
  "Baker's Yeast Allergy",
  "Nut Mix Allergy",
  "Peanut Allergy",
  "Orange Allergy",
  "Strawberry Allergy",
  "Banana Allergy",
  "Mango Allergy",
  "Tomato Allergy",
  "Carrot Allergy",
  "Onion Allergy",
  "Chicken Allergy",
  "Lamb Allergy",
  "Codfish Allergy",
  "Shrimp/Prawn Allergy",
  "HbF",
  "HbA",
  "HbS",
  "HbC",
  "HbD",
  "Unidentified",
  "HbA2",
  "D-Dimer",
  "Lipase",
  "Occult Blood in Stool",
  "Stool Color",
  "Stool Consistency",
  "Stool Odour",
  "Stool Mucus",
  "Visible Blood",
  "Red Blood Cell",
  "Pus Cells in Stool",
  "Polymorphonuclear Leukocytes",
  "Trophozoites",
  "Cysts",
  "OVA",
  "Adult Parasite",
  "Undigested Food",
  "Stool Specimen Type",
  "Organism Isolated",
  "Semen Type",
  "Primary Smear of Semen",
  "Ampicillin",
  "Amoxycillin",
  "Amoxycillin + Clavulanic Acid",
  "Ampicillin + Salbactum",
  "Piperacillin",
  "Ticarcillin",
  "Amikacin",
  "Cotrimoxazole",
  "Tetracycline",
  "Ciprofloxacin",
  "Levofloxacine",
  "Oflaxacine",
  "Imipenem",
  "Meropenem",
  "Piperacillin + Tazobactum",
  "Norfloxacin",
  "Ticarcillin + Clavulanic Acid",
  "Cefazolin",
  "Cefuroxime",
  "Cefoxitin",
  "Cefitriaxone",
  "Cefotaxime",
  "Ceftazidime",
  "Cefoperazone + Sulbactum",
  "Cefepime",
  "Aztreonam",
  "Gentamicin",
  "Appearance of Semen",
  "WBCs",
  "RBCs",
  "Abstinence Period",
  "Collection Time",
  "Analysis Time",
  "Volume of Semen",
  "Liquefaction",
  "pH of Semen",
  "Sperm Count / ml",
  "Sperm Count / ejaculate",
  "Motile",
  "Progressive",
  "Non Progressive",
  "Immotile",
  "Normal Form",
  "Abnormal Form",
  "Abnormal Head",
  "Abnormal Neck 8 midpiece",
  "Abnormal Tail",
  "IgM Level",
  "Varicella Zoster Ab IgG",
  "IgE Total",
  "CK-MB",
  "CK-Total",
  "Troponin I",
  "Covid-19 IgG",
  "COVID 19 IgM",
  "COVID Antibody",
  "COVID RT-PCR",
  "Cycle Threshold N-gene",
  "Cycle Threshold E-gene",
  "Cycle Threshold RDRP",
  "Cycle Threshold Orf 1a-gene",
  "Toxoplasma Ab IgG",
  "Toxoplasma Ab IgM",
  "Rubella Ab IgG",
  "Rubella Ab IgM",
  "Alfa Feto Protein (AFP)",
  "Growth Hormone",
  "Cortisol Serum (p.m)",
  "IL6",
  "Timothy Grass",
  "Cultivated Rye",
  "Alder",
  "Birch",
  "Oak",
  "Olive Tree",
  "Commn Ragweed",
  "Mugwort",
  "Dermatophagoides Pter",
  "Dermatophagoides Farinae",
  "Cockroach, German",
  "Cat",
  "Dog",
  "Horse",
  "Camel",
  "Penicillium Notatum",
  "Caldosporium Herbarium",
  "Aspergillus Fumigatus",
  "Candida Albicans",
  "Alternaria Alternata",
  "Lupus Anticoagulant",
  "Anti Cardiolipin IgM",
  "Anti Cardiolipin IgG",
  "Anti CCP",
  "Anti DNA Abs.",
  "Coombs Test (Indirect)",
  "Anti-Phospholipid (IgG)",
  "Anti-Phospholipid (IgM)",
  "ANA-Titer",
  "Anti Nuclear Antibodies (ANA)",
  "Serum Copper",
  "Aluminium",
  "Antimony",
  "Arsenic",
  "Barium",
  "Beryllium",
  "Bismuth",
  "Cadmium",
  "Caesium",
  "Chromium",
  "Cobalt",
  "Lead",
  "Manganese",
  "Mercury",
  "Molybdenum",
  "Nickel",
  "Selenium",
  "Silver",
  "Strontium",
  "Thallium",
  "Tin",
  "Uranium",
  "Vanadium",
  "Anti-TPO",
  "Anti-Tissue Transglutaminase IgA",
  "TG : HDL Ratio",
  "MYELOBLAST",
  "ABNORMAL CELLS",
  "Lymphoblasts",
  "HEMOPARASITES",
  "LIC%",
  "LIC#",
  "Vitamin B3",
  "Granulocytes",
  "Absolute Granulocytes count",
  "HBeAg",
  "Gonorrhoea Ag",
  "TROPONI - I",
  "Myoglobin",
];

const ParameterMapping = () => {
  const countPerPage = 9; // For Pagination Purpose.
  const alert = useAlert();
  const [parameterList, setParameterList] = useState(dummyTests.sort());
  const [filteredTests, setFilteredTests] = useState(dummyTests);
  const [paginatedData, setPaginatedData] = useState(
    dummyTests.slice(0, countPerPage)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [mappedTests, setMappedTests] = useState({});
  const [mappingQue, setMappingQue] = useState({});
  const [saveOption, setSaveOption] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [search, setSearch] = useState("");

  // console.log('mappedTests', mappedTests);

  const getExistingMapping = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const existingStateConfiguration = {
      headers: {
        authorization: idToken,
      },
      method: "GET",
      url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/mapping/parameter",
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const existingStateConfigurationResponse = await axios(
      existingStateConfiguration
    );
    console.log(
      "existingStateConfigurationResponse",
      existingStateConfigurationResponse
    );
    return existingStateConfigurationResponse;
  };

  useEffect(() => {
    (async function RetrieveExistingColorConfiguration() {
      setLoading(true);
      const existingStateConfigurationResponse = await getExistingMapping();
      const statusCode = existingStateConfigurationResponse.status;
      if (statusCode === 401) {
        await regenerateToken();
        const existingStateConfigurationResponse = await getExistingMapping();
        if (existingStateConfigurationResponse.data.isSuccess) {
          showingExistingMappedTests(existingStateConfigurationResponse);
          console.log("updated state Configuration @ second trail");
        }
      } else {
        if (existingStateConfigurationResponse.data.isSuccess) {
          showingExistingMappedTests(existingStateConfigurationResponse);
          console.log("updated State Configuration @ first trail");
        }
      }
      setLoading(false);
    })();
  }, []);

  // logic for showing the existing mapped
  const showingExistingMappedTests = (response) => {
    const mappedTests = {};
    const existingMappingKeys = Object.keys(response.data.data.idMapping);

    dummyTests.forEach((eachTest) => {
      let i = 0;
      for (i; i < existingMappingKeys.length; i++) {
        if (response.data.data.idMapping[existingMappingKeys[i]] === eachTest) {
          // if(eachTest in mappedTests){
          //   mappedTests[eachTest] = mappedTests[eachTest]+"$"+
          // }

          // This logic is for combining the multiple ids for same test
          if (mappedTests[eachTest]) {
            mappedTests[eachTest] = [
              ...mappedTests[eachTest],
              existingMappingKeys[i],
            ];
          } else {
            mappedTests[eachTest] = [existingMappingKeys[i]];
          }
          // AVoided break for making provision for repeated ID's
          // break;
        }
      }
    });
    // console.log('mappedTestsLogic', mappedTests);
    setMappedTests(mappedTests);
  };

  const filteringTests = (search) => {
    setSearch(search);
    const tests = parameterList.filter((each) =>
      each.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredTests(tests);
    setPaginatedData(tests.slice(0, countPerPage));
    setCurrentPage(1);
    document.getElementById("custom-switch-1").checked = true;
  };

  const handlingMapping = (mappedTest, requiredID) => {
    removeFilter();
    document.getElementById("custom-switch-1").checked = true;
    if (requiredID.includes("$")) {
      setMappingQue((prevState) => ({
        ...prevState,
        [mappedTest]: requiredID.split("$"),
      }));
    } else {
      // setMappingQue(prevState => ({
      //   ...prevState,
      //   [mappedTest]: [requiredID],
      // }));
      setMappingQue((prevState) => {
        if (requiredID !== "") {
          return { ...prevState, [mappedTest]: [requiredID] };
        } else {
          return { ...prevState, [mappedTest]: [""] };
        }
      });
    }
  };

  const confirmMapping = (test) => {
    setMappedTests((prevMapped) => ({ ...prevMapped, ...mappingQue }));
    setMappingQue({});
    setSaveOption(false);
  };

  const retrieveValue = (test) => {
    if (mappedTests[test]) {
      return mappedTests[test].join("$");
    }
  };

  const handleConfirmation = async () => {
    const idEnteredTillTime = Object.values(mappedTests);
    const repeatitionOfId = {};
    idEnteredTillTime.forEach((eachIDArray) => {
      eachIDArray.forEach((eachId) => {
        if (eachId in repeatitionOfId) {
          repeatitionOfId[eachId] += 1;
        } else {
          repeatitionOfId[eachId] = 1;
        }
      });
    });

    // checking for deuplicate ID's
    const repeatedID = [];
    Object.keys(repeatitionOfId).forEach((eachId) => {
      if (repeatitionOfId[eachId] > 1) {
        repeatedID.push(eachId);
      }
    });

    if (repeatedID.length > 0) {
      return alert.error(
        <span style={{ textTransform: "none" }}>
          Test {repeatedID.length >= 2 ? "Id's " : "Id "}
          {repeatedID.join(", ")} {repeatedID.length >= 2 ? "have " : "has "}{" "}
          been repeated. Please Enter Unique ID.
        </span>
      );
    } else {
      const finalMappedTests = {};
      const testsKeys = Object.keys(mappedTests);
      testsKeys.forEach((eachTest) => {
        mappedTests[eachTest].forEach((eachID) => {
          finalMappedTests[eachID] = eachTest;
        });
      });
      // console.log('finalMappedTests', finalMappedTests);
      // API call to made later
      const idToken = localStorage.getItem("idTokenBilling");
      const mappingConfiguration = {
        url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/mapping/parameter",
        headers: {
          authorization: idToken,
        },
        method: "POST",
        validateStatus: function (status) {
          return status >= 200 && status <= 401;
        },
        data: finalMappedTests,
      };
      try {
        setUpdateLoader(true);
        const updatingMapping = await axios(mappingConfiguration);
        if (updatingMapping.status === 401) {
          console.log("Regenerating ID Token");
          await regenerateToken();
          const updatingMapping = await axios(mappingConfiguration);
          if (updatingMapping.data.isSuccess) {
            setUpdateLoader(false);
            window.location.reload();
          }
        }
        console.log("updating Mapping Parameters Response\n", updatingMapping);
        setUpdateLoader(false);
        if (updatingMapping.data.isSuccess) {
          window.location.reload();
        }
      } catch (e) {
        console.log(e.message);
        setUpdateLoader(false);
      }
    }
  };

  const updatingPages = (p) => {
    const finalCount = p * countPerPage;
    const initialCount = finalCount - countPerPage;
    setPaginatedData(filteredTests.slice(initialCount, finalCount));
    setCurrentPage(p);
  };

  const clearingMappedTest = (test) => {
    // const IdTobeStored = mappedTests[test];
    // setMappingQue(prevQue => ({
    //   ...prevQue,
    //   [test]: IdTobeStored,
    // }));

    let filteredMappedTests = mappedTests;
    delete filteredMappedTests[test];

    // if (filteredMappedTests === {}) {
    //   setMappedTests({});
    // } else {
    //   setMappedTests(filteredMappedTests);
    // }
    setMappedTests((prevMapped) => {
      delete prevMapped[test];
      return { ...prevMapped };
    });
    document.getElementById(test).value = "";
    setSaveOption(true);
  };

  // Below three functions showMapped, showUnmapped, removeFilter are used for filtering parameters
  const showMapped = () => {
    const tests = parameterList.filter((each) =>
      each.toLowerCase().includes(search.toLowerCase())
    );
    const MappedTests = tests.filter((each) => {
      if (Object.keys(mappedTests).includes(each)) {
        return true;
      } else {
        return false;
      }
    });
    setFilteredTests(MappedTests);
    setPaginatedData(MappedTests.slice(0, countPerPage));
    setCurrentPage(1);
  };

  const showUnmapped = () => {
    const tests = parameterList.filter((each) =>
      each.toLowerCase().includes(search.toLowerCase())
    );
    const UnmappedTests = tests.filter((each) => {
      if (Object.keys(mappedTests).includes(each)) {
        return false;
      } else {
        return true;
      }
    });
    setFilteredTests(UnmappedTests);
    setPaginatedData(UnmappedTests.slice(0, countPerPage));
    setCurrentPage(1);
  };

  const removeFilter = () => {
    const tests = parameterList.filter((each) =>
      each.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredTests(tests);
    setPaginatedData(tests.slice(0, countPerPage));
    setCurrentPage(1);
  };

  const loader = () => {
    return (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "45vh" }}
      >
        <CircularProgress />
      </Stack>
    );
  };

  return (
    <>
      <Box
        mt={1.4}
        sx={{
          background: "#fff",
          border: "1px solid #D7D7D7",
          borderRadius: "6px 6px 0px 0px",
        }}
      >
        <Stack
          sx={{ padding: "18px 16px", borderBottom: "1px solid #D7D7D7" }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h4">Parameter Mapping</Typography>
          <Stack
            sx={{ width: "30%", background: "#F5F6F7", borderRadius: "8px" }}
            direction="row"
            alignItems={"center"}
          >
            <Box sx={{ padding: "8px 0px 8px 12px" }}>
              <SearchIcon />
            </Box>
            <Form.Control
              style={{ border: "none", background: "inherit" }}
              placeholder="Search for the parameters"
              onChange={(event) => filteringTests(event.target.value)}
            />
          </Stack>
        </Stack>
        {loading ? (
          loader()
        ) : (
          <Box>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              py={1.4}
              px={2}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={0.5}
                sx={{
                  backgroundColor: "rgba(31, 120, 255, 0.1)",
                  padding: "10px 16px",
                  color: "#1F78FF",
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  Mapped Tests :
                </p>
                <p
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    justifyContent: "center",
                    color: "blue",
                  }}
                >
                  {Object.keys(mappedTests).length}
                </p>
              </Stack>
              {/* Filter Radio Buttons added for filtering Mapped and Unmapped Tests */}
              <Form className="d-flex mx-2">
                <Form.Check
                  className="mx-2"
                  type="radio"
                  id="custom-switch-1"
                  label="All Tests"
                  defaultChecked
                  name="mapping-filter"
                  onChange={() => removeFilter()}
                />
                <Form.Check
                  className="mx-2"
                  type="radio"
                  id="custom-switch-2"
                  label="Mapped Tests"
                  name="mapping-filter"
                  onChange={() => showMapped()}
                />
                <Form.Check
                  className="mx-2"
                  type="radio"
                  id="custom-switch-3"
                  label="Unmapped Tests"
                  name="mapping-filter"
                  onChange={() => showUnmapped()}
                />
              </Form>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{
                background: "rgba(244, 247, 252, 0.75)",
                padding: "12px 25px",
              }}
            >
              <Typography variant="body9" sx={{ width: "50%" }}>
                Master Test List
              </Typography>
              <Typography variant="body9">Test name / Test ID</Typography>
            </Stack>
            {/* Rendering of Parameters happens below */}
            <Stack
              px={1.4}
              py={2}
              gap={2}
              sx={{ overflowY: "auto", maxHeight: "calc(100vh - 510px)" }}
            >
              {paginatedData.length !== 0 ? (
                paginatedData.map((test, index) => (
                  <Box
                    key={test}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      variant="body9"
                      sx={{
                        width: "40%",
                        padding: "10px 16px",
                        fontWeight: 400,
                      }}
                    >
                      {test}
                    </Typography>
                    <Typography
                      variant="body9"
                      sx={{ width: "5%", padding: "10px 16px" }}
                    >
                      <ArrowRightAltIcon />
                    </Typography>
                    <Form.Control
                      id={test}
                      disabled={mappedTests[test]}
                      style={{
                        maxWidth: "45%",
                        marginLeft: "15px",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                      placeholder="Corresponding Test Id / Test Name"
                      defaultValue={retrieveValue(test)}
                      onChange={(event) => {
                        handlingMapping(test, event.target.value);
                        setSaveOption(true);
                      }}
                    />

                    {mappingQue[test] && mappingQue[test].join("") !== "" ? (
                      <button
                        title="Add Mapping"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          marginLeft: "15px",
                        }}
                        onClick={() => {
                          confirmMapping(test);
                        }}
                      >
                        <AiFillPlusCircle size={"30"} color="#32CD32		" />
                      </button>
                    ) : (
                      ""
                    )}
                    {mappedTests[test] && (
                      <button
                        title="Clear Mapping"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          marginLeft: "15px",
                        }}
                        onClick={() => {
                          clearingMappedTest(test);
                        }}
                      >
                        <IoMdRemoveCircle size={"30"} color="#fd6363" />
                      </button>
                    )}
                  </Box>
                ))
              ) : (
                <p>... No parameters found to display</p>
              )}
            </Stack>

            {/* pagination & confirm buttons */}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              sx={{
                background: "rgba(244, 247, 252, 0.75)",
                padding: "12px 24px",
              }}
            >
              <Pagination
                //   simple
                onChange={(p) => updatingPages(p)}
                pageSize={10}
                total={filteredTests.length}
                current={currentPage}
                showTitle={false}
              />
              {/* Buttons for Confirmation below */}
              <button
                disabled={!saveOption}
                onClick={() => {
                  confirmMapping();
                }}
                className="btn btn-primary mt-4 d-none"
              >
                Save Changes
              </button>

              <button
                onClick={() => handleConfirmation()}
                // disabled={saveOption}
                className="btn btn-primary"
                style={{ width: "140px", borderRadius: "8px" }}
              >
                {updateLoader ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    style={{ color: "#fff" }}
                  ></Spinner>
                ) : (
                  "Confirm Changes"
                )}
              </button>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ParameterMapping;
