import {
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useAlert } from "react-alert";
import { FaCheckCircle } from "react-icons/fa";
import { RiErrorWarningFill } from "react-icons/ri";
import regenerateToken from "../regenerateTokens";
import { useNavigate } from "react-router-dom";
import { Item } from "semantic-ui-react";
import {
  Box,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
//import "../../../css/versionmanagement.css";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const VersionManagement = ({ setEditVersion, setContentType }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [versionName, setVersionName] = useState("");
  const [validationError, setValidationError] = useState("");
  const [loader, setLoader] = useState(false);
  const [versionData, setVersionData] = useState("");
  const [versionLoader, setVersionLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [profileContent, setProfileContent] = useState("");
  const [parameterContent, setParameterContent] = useState("");
  const [openMakeLiveModal, setOpenMakeLiveModal] = useState(false);
  const [makeLiveLoader, setMakeLiveLoader] = useState(false);
  const [makeLiveSuccess, setMakeLiveSuccess] = useState(false);
  const [makeLiveError, setMakeLiveError] = useState(false);
  const [liveButtonLoader, setLiveButtonLoader] = useState(false);
  const [selectedDeleteVersion, setSelectedDeleteVersion] = useState("");
  const [chosenLiveVersion, setChosenLiveVersion] = useState("");

  const validateName = (name) => {
    const regex = /^[^A-Z\s]+$/;
    if (name && !regex.test(name)) {
      setValidationError("Name cannot contain uppercase letters & spaces");
    } else {
      setValidationError("");
      setVersionName(name);
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await handleSubmit();
      if (response.status === 401) {
        await regenerateToken();
        const response = await handleSubmit();
        const data = await response.json();
        if (data.isSuccess) {
          alert.success("Successfully created version");
          setEditVersion(versionName);
          fetchAllVersionsFinal();
        } else {
          alert.error(data?.error);
        }
      } else {
        const data = await response.json();
        if (data.isSuccess) {
          alert.success("Successfully created version");
          setEditVersion(versionName);
          fetchAllVersionsFinal();
        } else {
          alert.error(data?.error);
        }
      }
    } catch (e) {
      console.log("error in creating version", e);
      alert.error("Something went wrong. Please try again!");
    } finally {
      setLoader(false);
      setVersionName("");
    }
  };

  const handleSubmit = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const payload = { versionName: versionName };
    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/version/content`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(apiUrl, options);
    return response;
  };

  const fetchAllVersionsFinal = async () => {
    setVersionLoader(true);
    try {
      const response = await fetchAllVersions();
      if (response.status === 401) {
        await regenerateToken();
        const response = await fetchAllVersions();
        const data = await response.json();
        if (data.isSuccess) {
          setVersionData(data.data);
          localStorage.setItem("versionData", JSON.stringify(data.data));
        }
      } else {
        const data = await response.json();
        if (data.isSuccess) {
          setVersionData(data.data);
          localStorage.setItem("versionData", JSON.stringify(data.data));
        }
      }
    } catch (e) {
      console.log("Error fetching version records", e);
    } finally {
      setVersionLoader(false);
    }
  };

  const fetchAllVersions = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/version/content?versionName=${"all"}`;
    const options = {
      method: "GET",
      headers: {
        authorization: idToken,
      },
    };

    const response = await fetch(apiUrl, options);
    return response;
  };

  const handleDeleteVersion = async (name) => {
    setDeleteLoader(true);
    setDeleteSuccessMessage("");
    setDeleteErrorMessage("");
    try {
      const response = await deleteVersion(name);
      if (response.status === 401) {
        await regenerateToken();
        const response = await deleteVersion(name);
        const data = await response.json();
        if (data.isSuccess) {
          setDeleteSuccessMessage(data.data);
          fetchAllVersionsFinal();
          setEditVersion("");
        } else {
          setDeleteErrorMessage(data.error);
        }
      } else {
        const data = await response.json();
        if (data.isSuccess) {
          setDeleteSuccessMessage(data.data);
          fetchAllVersionsFinal();
          setEditVersion("");
        } else {
          setDeleteErrorMessage(data.error);
        }
      }
    } catch (e) {
      console.log("Error fetching version records", e);
      setDeleteErrorMessage("Something went wrong. Please try again later.");
    } finally {
      setDeleteLoader(false);
    }
  };

  const deleteVersion = async (name) => {
    const idToken = localStorage.getItem("idTokenBilling");
    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/version/content?versionName=${name}&fileType=${"all"}`;
    const options = {
      method: "DELETE",
      headers: {
        authorization: idToken,
      },
    };

    const response = await fetch(apiUrl, options);
    return response;
  };

  const deleteConfirmModal = () => {
    return (
      <Modal
        size="md"
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {deleteLoader ? (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          ) : deleteSuccessMessage ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ fontSize: "20px" }}
            >
              <span>
                <FaCheckCircle color="green" size={30} />
              </span>
              <p style={{ marginLeft: "16px" }}>{deleteSuccessMessage}</p>
            </div>
          ) : deleteErrorMessage ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ fontSize: "20px" }}
            >
              <span>
                <RiErrorWarningFill color="red" size={30} />
              </span>
              <p style={{ marginLeft: "16px" }}>{deleteErrorMessage}</p>
            </div>
          ) : (
            <p style={{ fontSize: "20px", textAlign: "center" }}>
              Are you sure, you want to delete {selectedDeleteVersion}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          {deleteSuccessMessage || deleteErrorMessage ? (
            <Button onClick={() => setOpen(false)}>Close</Button>
          ) : (
            <Button
              onClick={() => handleDeleteVersion(selectedDeleteVersion)}
              disabled={deleteLoader}
            >
              Confirm
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  const makeLiveConfimModal = () => {
    return (
      <Modal
        size="md"
        show={openMakeLiveModal}
        onHide={() => setOpenMakeLiveModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body>
          {makeLiveLoader ? (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          ) : makeLiveSuccess ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ fontSize: "20px" }}
            >
              <span>
                <FaCheckCircle color="green" size={30} />
              </span>
              <p style={{ marginLeft: "16px" }}>
                Success! your {chosenLiveVersion} is Live now.
              </p>
            </div>
          ) : makeLiveError ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ fontSize: "20px" }}
            >
              <span>
                <RiErrorWarningFill color="red" size={30} />
              </span>
              <p style={{ marginLeft: "16px" }}>
                Something went wrong! Please try again later.
              </p>
            </div>
          ) : (
            <p style={{ fontSize: "20px", textAlign: "center" }}>
              Are you sure, you want to make {chosenLiveVersion} Live?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          {makeLiveSuccess || makeLiveError ? (
            <Button onClick={() => setOpenMakeLiveModal(false)}>Close</Button>
          ) : (
            <Button
              onClick={() => handleLiveConfim()}
              disabled={makeLiveLoader}
            >
              Confirm
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  // get profileContent of specific version
  const getProfileContent = async (selectedVersion) => {
    const idToken = localStorage.getItem("idTokenBilling");
    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/profile?version=${selectedVersion}`;
    const options = {
      method: "GET",
      headers: {
        authorization: idToken,
      },
    };
    const response = await fetch(apiUrl, options);
    return response;
  };

  const getFinalProfileContent = async (selectedVersion) => {
    const response = await getProfileContent(selectedVersion);
    if (response.status === 401) {
      await regenerateToken();
      const response = await getProfileContent(selectedVersion);
      const data = await response.json();
      if (data.isSuccess) {
        setProfileContent(data.data);
      }
    } else {
      const data = await response.json();
      console.log("getModifiedData", data);
      if (data.isSuccess) {
        setProfileContent(data.data);
      }
    }
  };

  const handleProfileUpdate = async () => {
    try {
      const response = await updateProfileContent();
      // console.log(response);
      if (response.status === 401) {
        await regenerateToken();
        const response = await updateProfileContent();
        const data = await response.json();
        console.log("response generated after regeneration", data);
      } else {
        const data = await response.json();
        console.log("updateProfile", data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const updateProfileContent = async () => {
    console.log("updatedContent", profileContent);
    const idToken = localStorage.getItem("idTokenBilling");
    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/profile?version=${""}`;
    const options = {
      method: "POST",
      headers: {
        authorization: idToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(profileContent),
    };
    const response = await fetch(apiUrl, options);
    return response;
  };

  const handleParameterUpdate = async () => {
    try {
      const response = await updateParameterContent();
      // console.log(response);
      if (response.status === 401) {
        await regenerateToken();
        const response = await updateParameterContent();
        const data = await response.json();
        console.log("response generated after regeneration", data);
      } else {
        const data = await response.json();
        console.log("updateProfile", data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const updateParameterContent = async () => {
    console.log("updatedContent", parameterContent);
    const idToken = localStorage.getItem("idTokenBilling");
    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/parameter?version=${""}`;
    const options = {
      method: "POST",
      headers: {
        authorization: idToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parameterContent),
    };
    const response = await fetch(apiUrl, options);
    return response;
  };

  const getParameterContent = async (selectedVersion) => {
    const idToken = localStorage.getItem("idTokenBilling");
    const apiUrl = `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/parameter?version=${selectedVersion}`;
    const options = {
      method: "GET",
      headers: {
        authorization: idToken,
      },
    };
    const response = await fetch(apiUrl, options);
    return response;
  };

  const getFinalParameterContent = async (selectedVersion) => {
    const response = await getParameterContent(selectedVersion);
    if (response.status === 401) {
      await regenerateToken();
      const response = await getParameterContent(selectedVersion);
      const data = await response.json();
      if (data.isSuccess) {
        setParameterContent(data.data);
      }
    } else {
      const data = await response.json();
      console.log("getModifiedData", data);
      if (data.isSuccess) {
        setParameterContent(data.data);
      }
    }
  };

  const handleLiveConfim = async () => {
    console.log("getContents", profileContent, parameterContent);
    setMakeLiveLoader(true);
    setMakeLiveSuccess(false);
    setMakeLiveError(false);
    try {
      await handleProfileUpdate();
      await handleParameterUpdate();
      setMakeLiveSuccess(true);
    } catch (e) {
      console.log("update error", e);
      setMakeLiveError(true);
    } finally {
      setMakeLiveLoader(false);
      setEditVersion("");
    }
  };

  const makeSpecificVersionLive = async (selectedVersion) => {
    setMakeLiveError(false);
    setLiveButtonLoader(true);
    await getFinalProfileContent(selectedVersion);
    await getFinalParameterContent(selectedVersion);

    setOpenMakeLiveModal(true);
  };

  // edit
  const handleEdit = (name) => {
    setEditVersion(name);
    setContentType("profilecontent");
    // navigate("/configurations/content-management");
  };

  useEffect(() => {
    fetchAllVersionsFinal();
  }, []);

  return (
    <div>
      <div className="py-3">
        {/* create version form  */}
        <div>
          <Typography variant="h4" mb={1.5}>
            Create Version
          </Typography>
          <Form
            onSubmit={handleCreate}
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Version Name</Form.Label>
              <Form.Control
                style={{ width: "400px" }}
                type="text"
                placeholder="Enter Your Version Name"
                required
                value={versionName}
                onChange={(e) => validateName(e.target.value)}
              />
              <Form.Text muted>
                Your Version Name cannot contain uppercase letters & spaces.
              </Form.Text>
              {validationError && (
                <p
                  style={{ color: "red", margin: "6px 0px", fontSize: "12px" }}
                >
                  {validationError}
                </p>
              )}
            </Form.Group> */}
            <InputGroup
              style={{ height: "40px", borderRadius: "8px", width: "600px" }}
            >
              <InputGroup.Text id="inputGroup-sizing-default">
                <Typography variant="body5">Version Name</Typography>
              </InputGroup.Text>
              <Form.Control
                type="text"
                style={{ width: "425px" }}
                value={versionName}
                onChange={(e) => validateName(e.target.value)}
                placeholder="Enter Your Version Name"
              />
            </InputGroup>
            <Button
              type="submit"
              style={{
                width: "140px",
                marginLeft: "16px",
                borderRadius: "12px",
              }}
            >
              {loader ? (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#fff" }}
                />
              ) : (
                "Create"
              )}
            </Button>
          </Form>
          <Form.Text muted>
            Your Version Name cannot contain uppercase letters & spaces.
          </Form.Text>
          {validationError && (
            <p style={{ color: "red", margin: "6px 0px", fontSize: "12px" }}>
              {validationError}
            </p>
          )}
        </div>
        {/* versions table */}
        <div className="mt-4">
          <Typography variant="h4" mb={1.25}>
            Your Version Details
          </Typography>
          <div
            style={{
              overflow: "hidden",
              borderRadius: "10px",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              width: "80%",
              maxHeight: "200px",
              overflowY: "hidden",
            }}
          >
            <Table
              style={{
                // overflowY: "auto",
                margin: "0",
                background: "#fff",
                borderCollapse: "seperate",
                borderRadius: "10px",
              }}
            >
              <thead>
                <tr
                  style={{
                    background: "rgba(244, 247, 252, 0.75)",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <th style={{ width: "80px" }} className="text-center">
                    <Typography variant="body9">S No.</Typography>
                  </th>
                  <th>
                    <Typography variant="body9">Created Date</Typography>
                  </th>
                  {/* <th>Updated Date</th> */}
                  <th className="text-center">
                    <Typography variant="body9">Version Name</Typography>
                  </th>
                  <th style={{ width: "10%" }}></th>
                  <th style={{ width: "10%" }}></th>
                  <th style={{ width: "15%" }}></th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: "160px", overflowY: "auto" }}>
                {versionLoader ? (
                  <tr>
                    <td className="text-center">
                      {" "}
                      <Spinner animation="border" size="sm" />
                    </td>
                  </tr>
                ) : versionData && versionData.length ? (
                  versionData.map((each, idx) => (
                    <tr key={idx}>
                      <td
                        style={{ width: "80px" }}
                        className="align-middle"
                        align="center"
                      >
                        {idx + 1}
                      </td>
                      <td className="align-middle">
                        {new Date(each.createdAt).toString().split("GMT")[0]}
                      </td>
                      {/* <td className="align-middle">
                      {new Date(each.updatedAt).toString().split("GMT")[0]}
                    </td> */}
                      <td className="align-middle text-center">{each.name}</td>
                      <td
                        className="align-middle text-center"
                        style={{ width: "10%" }}
                      >
                        <IconButton
                          variant="secondary"
                          onClick={() => {
                            handleEdit(each.name);
                          }}
                        >
                          <EditOutlinedIcon sx={{ color: "#000000" }} />
                        </IconButton>
                      </td>

                      <td
                        className="align-middle text-center"
                        style={{ width: "10%" }}
                      >
                        <IconButton
                          variant="danger"
                          onClick={() => {
                            setSelectedDeleteVersion(each.name);
                            setOpen(true);
                            setDeleteSuccessMessage("");
                            setDeleteErrorMessage("");
                          }}
                        >
                          <DeleteOutlineOutlinedIcon
                            sx={{ color: "#DF0000" }}
                          />
                        </IconButton>
                      </td>
                      <td
                        className="align-middle text-center"
                        style={{ width: "15%" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() => {
                            setChosenLiveVersion(each.name);
                            makeSpecificVersionLive(each.name);
                          }}
                          style={{ borderRadius: "6px" }}
                        >
                          Make Live
                        </Button>
                      </td>
                      {deleteConfirmModal()}
                      {makeLiveConfimModal()}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center">No Data found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {/* <TableContainer component={Paper} variant="Bordered">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "5%" }}>SN</TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    Created Date
                  </TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}>
                    Version Name
                  </TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}></TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}></TableCell>
                  <TableCell align="center" sx={{ width: "15%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {versionLoader ? (
                  <TableRow>
                    <TableCell>
                      <Spinner animation="border" size="sm" />
                    </TableCell>
                  </TableRow>
                ) : versionData?.length ? (
                  versionData.map((each, idx) => (
                    <TableRow
                      key={each.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right"> {idx + 1}</TableCell>

                      <TableCell align="right">
                        {new Date(each.createdAt).toString().split("GMT")[0]}
                      </TableCell>
                      <TableCell align="right"> {each.name}</TableCell>
                      <TableCell align="right">
                        {" "}
                        <IconButton
                          onClick={() => {
                            handleEdit(each.name);
                          }}
                        >
                          Edit
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <IconButton
                          onClick={() => {
                            setSelectedDeleteVersion(each.name);
                            setOpen(true);
                            setDeleteSuccessMessage("");
                            setDeleteErrorMessage("");
                          }}
                        >
                          Delete
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <Button
                          onClick={() => {
                            setChosenLiveVersion(each.name);
                            makeSpecificVersionLive(each.name);
                          }}
                        >
                          Make Live
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Box>No Data found</Box>
                )}
              </TableBody>
            </Table>
          </TableContainer> */}
        </div>
      </div>
    </div>
  );
};

export default VersionManagement;
