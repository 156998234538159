import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../../css/planDetailsSectionCss.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import axios from 'axios';
// import { FetchPercentage } from '../FetchPercentage';
import { Spinner } from "react-bootstrap";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import TopFivePackageSection from "./TopFivePackageSection";
import Invoice from "./Invoice";
import { content } from "../utils";
import LoadingView from "../../common/LoadingView";

const PlanDetailsSection = ({
  reportsConsumed,
  dashboardInfo,
  productType,
  loader,
}) => {
  const [percentage, setPercentage] = useState("0");
  const [loginData, setLoginData] = useState("");
  const [fetchedHomeData, setFetchedHomeData] = useState("");
  const [clientData, setClientData] = useState("");

  // const [orgName, setOrgName] = useState('');
  // const [centerName, setCenterName] = useState('');
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [statesUpdated, setStatesUpdated] = useState(false);
  // const [numberOfReportsConsumed, setNumberOfReportsConsumed] =
  //   useState(reportsConsumed);

  const [reportsSubscribedInfo, setReportsSubscribedInfo] = useState("0");
  const [reRenderComponent, setReRenderComponent] = useState(false);

  const [dbLoader, setDbLoader] = useState(false);
  const dataFetchedRef = useRef(false);

  // console.log(loader, 'loder');

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  // just for little animation in circular progress bar
  // useEffect(() => {
  //     setTimeout(() => {
  //         setPercentage("60")
  //     }, 1200)
  // }, [])

  // fetching loginDetails
  useEffect(() => {
    //console.log("calling plan details section")
    const loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    let homepageData = JSON.parse(localStorage.getItem("homepageData"));
    let clientInfo = JSON.parse(localStorage.getItem("clientPreference"));

    if (loginInfo) {
      setLoginData(loginInfo);
      setClientData(clientInfo);
      setReportsSubscribedInfo(loginInfo.reportsSubscribed);
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      // fetchReportCounts();
    }

    if (homepageData) {
      setFetchedHomeData(homepageData);
    }
  }, [reRenderComponent]);

  // calculate percentage
  useEffect(() => {
    const thePercentage = Math.round(
      (reportsConsumed / reportsSubscribedInfo) * 100
    );
    // set percentage

    if (thePercentage > 100) {
      setPercentage(100);
    } else {
      setPercentage(thePercentage);
    }
  }, [percentage]);

  // console.log(FetchPercentage())
  //console.log("fetchedHomeData\n", fetchedHomeData)

  // return (
  //   <>
  //     <div
  //       className='planScreenResolutionCss my-4'
  //       style={{
  //         display:
  //           loginData && loginData.showBilling === true && clientData === 'none'
  //             ? ''
  //             : 'none',
  //       }}
  //     >
  //       <div className='planScreenLeftContentCss'>
  //         <Card>
  //           <Card.Body>
  //             <Card.Title>{'Plan Detail'}</Card.Title>
  //             <div>
  //               <Card.Subtitle className='planDetailsCardSubtitleCss'>
  //                 <div className='circularProgressBarDimensionsCss'>
  //                   <CircularProgressbar
  //                     value={percentage}
  //                     text={`${percentage}%`}
  //                     styles={buildStyles({
  //                       textSize: '16px',
  //                       pathTransitionDuration: 0.5, // How long animation takes to go from one percentage to another, in seconds
  //                       // pathColor: `rgb(53,103,214)`, ${percentage / 100})`,
  //                       pathColor:
  //                         percentage < 80
  //                           ? 'rgb(53,103,214)'
  //                           : 'rgb(255,77,77)',
  //                       textColor: '#170C6B',
  //                       trailColor: '#C7CEDD',
  //                       backgroundColor: '#3567D6',
  //                     })}
  //                   />
  //                 </div>
  //               </Card.Subtitle>
  //               <div
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   marginTop: '10px',
  //                 }}
  //               >
  //                 <span style={{ color: '#7787bc', marginRight: '3px' }}>
  //                   {'Total Reports: '}
  //                 </span>
  //                 <span style={{ fontWeight: '550' }}>
  //                   {loginData ? loginData.reportsSubscribed : ''}
  //                 </span>
  //               </div>
  //               <div
  //                 style={{
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   marginTop: '5px',
  //                 }}
  //               >
  //                 <span style={{ color: '#7787bc', marginRight: '3px' }}>
  //                   {'Number of Report Generated: '}
  //                 </span>
  //                 <span style={{ fontWeight: '550' }}>
  //                   {percentage}
  //                   {'%'}
  //                 </span>
  //               </div>
  //             </div>
  //           </Card.Body>
  //         </Card>
  //       </div>

  //       <div className='planScreenRightContentCss'>
  //         <Row xs={1} md={2} className='g-4'>
  //           <Col>
  //             <Card className='planDetailsRightCardCss'>
  //               <Card.Body className='planDetailsRightCardBodyCss'>
  //                 <Card.Title className='totalReportsTitleCss'>
  //                   {'Total Reports'}
  //                 </Card.Title>
  //                 <Card.Text className='totalReportsNumberCss'>
  //                   {loginData ? loginData.reportsSubscribed : ''}
  //                 </Card.Text>
  //               </Card.Body>
  //             </Card>
  //           </Col>

  //           <Col>
  //             <Card className='planDetailsRightCardCss'>
  //               <Card.Body className='planDetailsRightCardBodyCss'>
  //                 <Card.Title className='totalReportsTitleCss'>
  //                   {'Consumed'}
  //                 </Card.Title>

  //                 <Card.Text className='totalReportsNumberCss'>
  //                   {reportsConsumed}
  //                 </Card.Text>
  //               </Card.Body>
  //             </Card>
  //           </Col>

  //           <Col>
  //             <Card className='planDetailsRightCardCss'>
  //               <Card.Body className='planDetailsRightCardBodyCss'>
  //                 <Card.Title className='totalReportsTitleCss'>
  //                   {'Remaining'}
  //                 </Card.Title>
  //                 <Card.Text className='totalReportsNumberCss'>
  //                   {loginData && reportsConsumed !== '0'
  //                     ? loginData.reportsSubscribed - reportsConsumed
  //                     : '0'}
  //                 </Card.Text>
  //               </Card.Body>
  //             </Card>
  //           </Col>

  //           <Col>
  //             <Card className='planDetailsRightCardCss'>
  //               <Card.Body className='planDetailsRightCardBodyCss'>
  //                 <Card.Title className='totalReportsTitleCss'>
  //                   {'Percentage'}
  //                 </Card.Title>
  //                 <Card.Text className='totalReportsNumberCss'>
  //                   {loginData ? (
  //                     Math.round(
  //                       (reportsConsumed / loginData.reportsSubscribed) * 100
  //                     ) > 100 ? (
  //                       <span style={{ fontSize: '20px' }}>
  //                         {'Over Consumed'}
  //                       </span>
  //                     ) : (
  //                       Math.round(
  //                         (reportsConsumed / loginData.reportsSubscribed) * 100
  //                       )
  //                     )
  //                   ) : (
  //                     ''
  //                   )}
  //                 </Card.Text>
  //               </Card.Body>
  //             </Card>
  //           </Col>
  //         </Row>
  //       </div>
  //     </div>
  //   </>
  // );

  let colorCondition =
    loginData && loginData.subscriptionStatus === "on hold"
      ? "#ff4d4d"
      : "#27BC48";

  return (
    <Box py={0} sx={{ background: "#fff", borderRadius: "8px" }} p={3}>
      <Tooltip title={content.subscription.tooltipText} position="bottom">
        <Typography
          variant="h2"
          sx={{ display: "inline-block", cursor: "pointer" }}
        >
          {content.subscription.title}
        </Typography>
      </Tooltip>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={3}
        pt={3}
        // sx={{ background: "#fff", borderRadius: "8px" }}
      >
        {loginData?.role !== "admin" && (
          <Stack
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            py={3}
            sx={{
              border: "1px solid #E7E7E7",
              boxShadow: "0 15px 30px rgba(8, 7, 45, 0.15)",
              maxWidth: "30%",
              borderRadius: "16px",
            }}
          >
            {/* <Typography px={2} variant="h5">
              Details
            </Typography> */}
            {loader ? (
              <LoadingView />
            ) : (
              <>
                <Box sx={{ width: "50%", alignSelf: "center" }}>
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      textSize: "20px",
                      fontWeight: "700",
                      pathTransitionDuration: 0.5, // How long animation takes to go from one percentage to another, in seconds
                      // pathColor: `rgb(53,103,214)`, ${percentage / 100})`,
                      pathColor:
                        percentage < 80 ? "rgb(53,103,214)" : "rgb(255,77,77)",
                      textColor: "#000000",
                      trailColor: "#C7CEDD",
                      backgroundColor: "#3567D6",
                    })}
                  />
                </Box>
                <Divider flexItem variant="middle" />
                <Box p={2} sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    Total Usage Limit
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#818181" }}>
                    {loginData
                      ? parseInt(loginData.reportsSubscribed).toLocaleString(
                          "en-IN"
                        )
                      : ""}
                  </Typography>
                </Box>
                <Divider flexItem variant="middle" />
                <Box p={2} sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>
                    Usage Till Date
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#818181" }}>
                    {reportsConsumed.toLocaleString("en-IN")}
                  </Typography>
                </Box>
              </>
            )}
            {/* <Box sx={{ width: "60%", alignSelf: "center" }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                  textSize: "16px",
                  pathTransitionDuration: 0.5, // How long animation takes to go from one percentage to another, in seconds
                  // pathColor: `rgb(53,103,214)`, ${percentage / 100})`,
                  pathColor:
                    percentage < 80 ? "rgb(53,103,214)" : "rgb(255,77,77)",
                  textColor: "#170C6B",
                  trailColor: "#C7CEDD",
                  backgroundColor: "#3567D6",
                })}
              />
            </Box> */}
          </Stack>
        )}
        <Stack flexGrow={3} alignItems="center">
          {/* <Stack
            mb={2}
            px={1.5}
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography>
              {dashboardInfo.welcomeScreen.planInfo.planCardHeading}
            </Typography>
            <Typography>
              <svg
                style={{ float: "right", position: "relative", top: "5px" }}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8"
                  stroke={colorCondition}
                  stroke-width="1.38462"
                />
                <circle
                  cx="9.15385"
                  cy="9.15385"
                  r="6.15385"
                  fill={colorCondition}
                />
              </svg>
            </Typography>
          </Stack> */}
          {loginData && loginData.role === "lab" ? (
            <Stack
              p={1}
              mb={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              sx={{ background: "#ebf9ee", display: "none" }}
            >
              <Typography>
                {" "}
                {dashboardInfo.welcomeScreen.planInfo.planPlaceHolder}
              </Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#fff",
                    backgroundColor: colorCondition,
                    borderRadius: "20px",
                    padding: "6px 20px",
                    textAlign: "center",
                  }}
                >
                  {loginData && loginData.subscriptionStatus
                    ? loginData.subscriptionStatus.toUpperCase()
                    : "None"}
                </Typography>
                <Invoice />
              </Stack>
            </Stack>
          ) : (
            ""
          )}

          <TopFivePackageSection productType={productType} />

          {/* <Box sx={{ width: "40%" }}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              styles={buildStyles({
                textSize: "16px",
                pathTransitionDuration: 0.5, // How long animation takes to go from one percentage to another, in seconds
                // pathColor: `rgb(53,103,214)`, ${percentage / 100})`,
                pathColor:
                  percentage < 80 ? "rgb(53,103,214)" : "rgb(255,77,77)",
                textColor: "#170C6B",
                trailColor: "#C7CEDD",
                backgroundColor: "#3567D6",
              })}
            />
          </Box> */}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PlanDetailsSection;
