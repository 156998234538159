import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

const Invoice = () => {
  const [open, setOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [invoiceLoader, setInvoiceLoader] = useState(false);

  const handleClickOpen = () => {
    fetchInvoices();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchInvoices = async () => {
    setInvoiceLoader(true);
    const apiUrl = "https://api.niroggyan.com/billing/invoice";
    const idToken = localStorage.getItem("idTokenBilling");
    let config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, config);
      const fetchedInvoices = await response.json();
      console.log("data", fetchedInvoices);
      if (fetchedInvoices.isSuccess) {
        setInvoiceLoader(false);
        setInvoiceData(fetchedInvoices.data);
      }
    } catch (e) {
      setInvoiceLoader(false);
      console.log("error", e);
    }
  };

  const formatDate = (date) => {
    const parts = date.split("-");
    const modifiedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return modifiedDate;
  };

  // const pendingInvoices = invoiceData.length
  //   ? invoiceData.filter((each) => each.status === "sent")
  //   : "";

  const invoiceModal = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" mt={3}>
          Your Overdue Invoice
        </DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 6,
              top: 6,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {invoiceLoader ? (
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Spinner
                className="spinner-border"
                style={{ fontWeight: "normal" }}
                id="loadingSpinnerCss"
                animation="border"
                role="status"
              />
            </Stack>
          ) : invoiceData && invoiceData.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right" sx={{ fontWeight: "700" }}>
                      Invoice Number
                    </TableCell>

                    <TableCell align="right" sx={{ fontWeight: "700" }}>
                      Due Date
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "700" }}>
                      Amount
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "700" }}>
                      Link
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData.map((each, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        {each.invoice_number}
                      </TableCell>
                      <TableCell align="right">
                        {formatDate(each.due_date)}
                      </TableCell>
                      <TableCell align="right">
                        &#8377; {""}
                        {each.balance?.toLocaleString("en-IN")}
                      </TableCell>
                      <TableCell align="right">
                        <a href={each.invoice_url} target="_blank">
                          <Button variant="contained" size="small">
                            View
                          </Button>
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box sx={{ textAlign: "center" }}>No Data Found</Box>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box>
      <Button onClick={handleClickOpen}>View Invoice</Button>
      {invoiceModal()}
    </Box>
  );
};

export default Invoice;
