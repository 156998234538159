import { useEffect, useState } from "react";

// import Loader from "../loader/loader";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import regenerateToken from "../regenerateTokens";
import { Dropdown } from "semantic-ui-react";
// import "../../App.css";
import JSONInput from "react-json-editor-ajrm";
import LoadingView from "../../../common/LoadingView";
import { Box, Stack } from "@mui/material";
import LabelElement from "../../../common/LabelElement";
import { useAlert } from "react-alert";

const ParameterContent = ({ version, setEditVersion }) => {
  const alert = useAlert();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [parameterContent, setParameterContent] = useState("");
  const [selectedParameter, setSelectedParameter] = useState("");
  const [category, setCategory] = useState("");
  const [textLanguage, setTextLanguage] = useState("");
  const [dynamicTextLanguage, setDynamicTextLanguage] = useState("");
  const [newLangKey, setNewLangKey] = useState("");
  const [availableLang, setAvailableLang] = useState(["en", "hi", "ar"]);
  const [availableLangObj, setAvailableLangObj] = useState({
    en: "",
    hi: "",
    ar: "",
  });
  const [availableLangArray, setAvailableLangArray] = useState({
    en: [],
    hi: [],
    ar: [],
  });

  const languageOptions = [
    { name: "Choose Lang", value: "" },
    { name: "czech", value: "cz" },
  ];

  const availableLanguageNames = {
    en: "English",
    hi: "Hindi",
    ar: "Arabic",
    cz: "czech",
  };

  // hooks related to Tips

  const [tipCategory, setTipCategory] = useState("");
  const [tipHeaderLang, setTipHeaderLang] = useState("");
  const [tipContentLang, setTipContentLang] = useState("");

  // additional Sections
  const [additionalSectionIndex, setAdditionalSectionIndex] = useState("");
  const [additionalSectionCategory, setAdditionalSectionCategory] =
    useState("");
  const [additionalSectionLang, setAdditionalSectionLang] = useState("");
  const [additionalSectionTipsLang, setAdditionalSectionTipsLang] =
    useState("");

  // causes

  const [causesType, setCausesType] = useState("");
  const [causesSubtype, setCausesSubType] = useState("");
  const [causeLang, setCauseLang] = useState("");
  const [causesTipsLang, setCausesTipsLang] = useState("");

  // vizapp Content

  const [vizappSubCategory, setVizappSubcategory] = useState("");
  const [vizappTipContentLang, setVizappTipContentLang] = useState("");

  useEffect(() => {
    (async function () {
      setPageLoader(true);
      const response = await getExistingParameterContent();
      if (response.status === 401) {
        await regenerateToken();
        const response = await getExistingParameterContent();
        if (response.data.isSuccess) {
          //   setProfileContent(response.data.data);
          setParameterContent(response.data.data);
        }
      } else {
        if (response.data.isSuccess) {
          //   setProfileContent(response.data.data);
          setParameterContent(response.data.data);
          setPageLoader(false);
        }
      }
      setPageLoader(false);
    })();
  }, [version]);

  // get existing languages from json
  useEffect(() => {
    const existingLanguages = detectLanguages(parameterContent);
    if (existingLanguages?.length) {
      const existingLanguagesObj = existingLanguages.reduce((acc, cur) => {
        acc[cur] = "";
        return acc;
      }, {});
      const existingLanguagesArray = existingLanguages.reduce((acc, cur) => {
        acc[cur] = [];
        return acc;
      }, {});

      console.log(
        "existingLanguages",
        existingLanguages,
        existingLanguagesObj,
        existingLanguagesArray
      );

      // update state variables
      setAvailableLang(existingLanguages);
      setAvailableLangObj(existingLanguagesObj);
      setAvailableLangArray(existingLanguagesArray);
    }
  }, [parameterContent]);

  const detectLanguages = (jsonData) => {
    const languageSet = new Set(); // To store unique languages

    const findLanguages = (obj) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => findLanguages(item));
      } else {
        for (let key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            findLanguages(obj[key]);
          }
          // Assuming language keys are strings like 'en', 'hi', 'ar'
          if (
            typeof key === "string" &&
            key.length === 2 &&
            isNaN(key) &&
            key.toLowerCase() != "ph"
          ) {
            languageSet.add(key); // Add detected language key
          }
        }
      }
    };

    findLanguages(jsonData); // Start traversing
    return Array.from(languageSet); // Return unique languages as an array
  };

  const getExistingParameterContent = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "GET",
      url: `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/parameter?version=${version}`,
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const response = await axios(configBody);
    console.log(response);
    return response;
  };

  const getParameters = () => {
    const parameters = Object.keys(parameterContent);
    return parameters.map((parameters, idx) => ({
      key: idx,
      text: parameters,
      value: parameters,
    }));
  };

  // Add Sections here to reflect in the DropDown
  const categories = [
    "text",
    "dynamicText",
    "tips",
    "causes",
    "additionalSections",
    "vizapp",
  ];

  const availableLanguages = ["en", "hi", "ar"];

  const renderText = () => {
    return (
      <>
        <select
          onChange={(e) => setTextLanguage(e.target.value)}
          value={textLanguage}
          className="form-control form-select mt-2"
        >
          <option hidden>Select Language</option>
          {/* Object.keys(parameterContent[selectedParameter][category]) */}
          {availableLang.map((eachLang, idx) => (
            <option key={`Text-heading-${idx}`} value={eachLang}>
              {eachLang}
            </option>
          ))}
        </select>
        {textLanguage && (
          <InputGroup className="mt-3">
            <InputGroup.Text>Text Content</InputGroup.Text>

            <Form.Control
              as="textarea"
              placeholder="Enter the content here"
              onChange={(e) =>
                setParameterContent((prevContent) => ({
                  ...prevContent,
                  [selectedParameter]: {
                    ...prevContent[selectedParameter],
                    [category]: {
                      ...prevContent[selectedParameter][category],
                      [textLanguage]: e.target.value,
                    },
                  },
                }))
              }
              rows="3"
              value={
                parameterContent[selectedParameter][category][textLanguage]
                  ? parameterContent[selectedParameter][category][textLanguage]
                  : ""
              }
            />
          </InputGroup>
        )}
      </>
    );
  };

  const renderDynamicText = () => {
    return (
      <>
        {Object.keys(parameterContent[selectedParameter]).includes(category) ? (
          <>
            <select
              onChange={(e) => setDynamicTextLanguage(e.target.value)}
              value={dynamicTextLanguage}
              className="form-control form-select mt-2"
            >
              <option hidden>Select Language</option>
              {/* Object.keys(parameterContent[selectedParameter][category]) */}
              {availableLang.map((eachLang) => (
                <option
                  key={`dynamic-text-${eachLang}-${category}`}
                  value={eachLang}
                >
                  {eachLang}
                </option>
              ))}
            </select>
            {dynamicTextLanguage && (
              <InputGroup className="mt-3">
                <InputGroup.Text>Dynamic Text Content</InputGroup.Text>

                <Form.Control
                  as="textarea"
                  placeholder="Enter Dynamic Text"
                  onChange={(e) =>
                    setParameterContent((prevContent) => ({
                      ...prevContent,
                      [selectedParameter]: {
                        ...prevContent[selectedParameter],
                        [category]: {
                          ...prevContent[selectedParameter][category],
                          [dynamicTextLanguage]: e.target.value,
                        },
                      },
                    }))
                  }
                  rows="3"
                  value={
                    parameterContent[selectedParameter][category][
                      dynamicTextLanguage
                    ]
                      ? parameterContent[selectedParameter][category][
                          dynamicTextLanguage
                        ]
                      : ""
                  }
                />
              </InputGroup>
            )}
          </>
        ) : (
          <div className="d-flex mt-2 align-items-center">
            <p className="mt-0 mb-0">
              No Content to display. Add Section if Required
            </p>
            <button
              onClick={() => {
                setDynamicTextLanguage("en");
                setParameterContent((prevContent) => ({
                  ...prevContent,
                  [selectedParameter]: {
                    ...prevContent[selectedParameter],
                    [category]: {
                      ...availableLangObj,
                    },
                  },
                }));
              }}
              className="btn btn-success mx-2"
            >
              Add Section
            </button>
          </div>
        )}
      </>
    );
  };

  const renderTips = () => {
    return (
      <>
        {Object.keys(parameterContent[selectedParameter]).includes(category) ? (
          <select
            className="form-control form-select mt-2"
            onChange={(e) => {
              setTipCategory(e.target.value);
              setTipHeaderLang("");
              setTipContentLang("");
              setAdditionalSectionIndex("");
              setAdditionalSectionCategory("");
              setAdditionalSectionTipsLang("");
              setCausesType("");
              setCausesSubType("");
              setCauseLang("");
              setCausesTipsLang("");
              setVizappSubcategory("");
              setVizappTipContentLang("");
            }}
            value={tipCategory}
          >
            <option hidden>Select Tip Category</option>
            {Object.keys(parameterContent[selectedParameter][category]).map(
              (eachTipCategory, idx) => (
                <option
                  className="text-capitalize"
                  key={`${eachTipCategory}-${category}`}
                >
                  {eachTipCategory}
                </option>
              )
            )}
          </select>
        ) : (
          <div className="d-flex mt-2 align-items-center">
            <p className="mt-0 mb-0">
              No Content to display. Add Section if Required
            </p>
            <button
              onClick={() => {
                setDynamicTextLanguage("en");
                setParameterContent((prevContent) => ({
                  ...prevContent,
                  [selectedParameter]: {
                    ...prevContent[selectedParameter],
                    [category]: {
                      tipsHeader: {
                        ...availableLangObj,
                      },
                      tipsSvg: [],
                      tipsSvgText: {
                        ...availableLangArray,
                      },
                    },
                  },
                }));
              }}
              className="btn btn-success mx-2"
            >
              Add Section
            </button>
          </div>
        )}
        {/* Tips Category */}
        {getDetailedTips()}
      </>
    );
  };

  const renderCauses = () => {
    const addCause = (type) => {
      const template = {
        heading: {
          ...availableLangObj,
        },
        description: {
          ...availableLangObj,
        },
        listType: "", // svg
        list: [
          {
            text: {
              ...availableLangObj,
            },
            image: "",
          },
        ],
      };
      setParameterContent((prevContent) => ({
        ...prevContent,
        [selectedParameter]: {
          ...prevContent[selectedParameter],
          [category]: {
            ...prevContent[selectedParameter][category],
            [type]: template,
          },
        },
      }));
      setCausesType(type);
    };

    // checking whether causes field key present in the object and then checking for low, high etc.., keys
    const availableCauses = parameterContent[selectedParameter][category]
      ? Object.keys(parameterContent[selectedParameter][category])
      : [];

    const defaultCauses = [
      { defaultCause: "critical low", colorCode: "danger", displayText: "" },
      { defaultCause: "too low", colorCode: "warning", displayText: "" },
      { defaultCause: "low", colorCode: "warning", displayText: "" },
      { defaultCause: "borderline", colorCode: "warning", displayText: "" },
      {
        defaultCause: "nearOptimal high",
        colorCode: "warning",
        displayText: "",
      },
      { defaultCause: "high", colorCode: "danger", displayText: "" },
      { defaultCause: "very high", colorCode: "danger", displayText: "" },
    ];

    const checkingAvailability = (type) => {
      return Object.keys(parameterContent[selectedParameter]).includes("causes")
        ? Object.keys(parameterContent[selectedParameter][category]).includes(
            type
          )
        : false;
    };

    const updateCauseHeadingAndDescription = (updatedContent) => {
      setParameterContent((prevContent) => ({
        ...prevContent,
        [selectedParameter]: {
          ...prevContent[selectedParameter],
          [category]: {
            ...prevContent[selectedParameter][category],
            [causesType]: {
              ...prevContent[selectedParameter][category][causesType],
              [causesSubtype]: {
                ...prevContent[selectedParameter][category][causesType][
                  causesSubtype
                ],
                [causeLang]: updatedContent,
              },
            },
          },
        },
      }));
    };

    const renderCausesHeadingAndDescription = (type) => {
      return (
        <>
          <select
            className="form-control form-select mt-2"
            value={causeLang}
            onChange={(e) => {
              setCauseLang(e.target.value);
              setCausesTipsLang("");
              setVizappSubcategory("");
              setVizappTipContentLang("");
            }}
          >
            <option hidden>Select Cause Language</option>
            {availableLang.map((eachCauseLang, idx) => (
              <option key={`cause-language-${idx}`}>{eachCauseLang}</option>
            ))}
          </select>

          {causeLang && (
            <Form.Control
              className="mt-2"
              placeholder={`Enter ${type}`}
              value={
                parameterContent[selectedParameter][category][causesType][
                  causesSubtype
                ][causeLang]
                  ? parameterContent[selectedParameter][category][causesType][
                      causesSubtype
                    ][causeLang]
                  : ""
              }
              onChange={(e) => updateCauseHeadingAndDescription(e.target.value)}
            />
          )}
        </>
      );
    };

    const updateListType = (updatedValue) => {
      const reqParameterContent = JSON.parse(JSON.stringify(parameterContent));
      reqParameterContent[selectedParameter][category][causesType][
        causesSubtype
      ] = updatedValue;
      setParameterContent(reqParameterContent);
    };

    const renderCauseListType = () => {
      return (
        <Form.Control
          placeholder="Enter List Type "
          className="mt-2"
          value={
            parameterContent[selectedParameter][category][causesType][
              causesSubtype
            ]
          }
          onChange={(e) => updateListType(e.target.value)}
        />
      );
    };

    const addCauseTipSection = () => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      requiredParameterContent[selectedParameter][category][causesType][
        causesSubtype
      ].push({
        text: {
          ...availableLangObj,
        },
        image: "",
      });
      setParameterContent(requiredParameterContent);
    };

    const removeCauseTipSection = (index) => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      const requiredTips = requiredParameterContent[selectedParameter][
        category
      ][causesType][causesSubtype].filter(
        (eachTipSection, idx) => index !== idx
      );
      requiredParameterContent[selectedParameter][category][causesType][
        causesSubtype
      ] = requiredTips;

      setParameterContent(requiredParameterContent);
    };

    const updateCauseTipContentSVG = (updatedContent, type, index) => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      if (type === "tip") {
        requiredParameterContent[selectedParameter][category][causesType][
          causesSubtype
        ][index]["text"][causesTipsLang] = updatedContent;
      } else {
        requiredParameterContent[selectedParameter][category][causesType][
          causesSubtype
        ][index]["image"] = updatedContent;
      }
      setParameterContent(requiredParameterContent);
    };

    const renderCauseList = () => {
      return (
        <>
          <div className="d-flex align-items-center mt-2">
            <p className="mt-0 mb-0">Tips</p>
            <button
              onClick={() => addCauseTipSection()}
              className="btn btn-success mx-2"
            >
              Add Tip Section
            </button>
          </div>
          <select
            className="form-control form-select mt-2"
            value={causesTipsLang}
            onChange={(e) => setCausesTipsLang(e.target.value)}
          >
            <option hidden>Select Causes Tips Language</option>
            {availableLang.map((each) => (
              <option key={`Causes Section Tips Language-${each}`} value={each}>
                {each}
              </option>
            ))}
          </select>
          {causesTipsLang &&
            parameterContent[selectedParameter][category][causesType][
              causesSubtype
            ].map((eachCauseTip, idx) => (
              <div
                key={`causes-tip-${idx}`}
                className="d-flex mt-2 align-items-start"
              >
                <textarea
                  placeholder="Enter the tip content"
                  className=" w-100 form-control"
                  onChange={(e) =>
                    updateCauseTipContentSVG(e.target.value, "tip", idx)
                  }
                  value={eachCauseTip["text"][causesTipsLang]}
                />
                <Form.Control
                  placeholder="Enter the Corresponding Tip Icon"
                  className="mx-2"
                  onChange={(e) =>
                    updateCauseTipContentSVG(e.target.value, "svg", idx)
                  }
                  value={eachCauseTip["image"]}
                />
                <button
                  onClick={() => removeCauseTipSection(idx)}
                  className="btn btn-danger"
                >
                  Remove
                </button>
              </div>
            ))}
        </>
      );
    };

    const renderDetailedCauses = () => {
      switch (causesSubtype) {
        case "heading":
          return renderCausesHeadingAndDescription("heading");
        case "description":
          return renderCausesHeadingAndDescription("description");
        case "listType":
          return renderCauseListType();
        case "list":
          return renderCauseList();
        default:
          break;
      }
    };

    return (
      <>
        <div className="d-flex mt-2 align-items-center flex-wrap">
          <p className="mt-0 mb-0">Add Causes for</p>

          {defaultCauses.map((eachCause, idx) => (
            <button
              className={`btn btn-${eachCause.colorCode} m-2 text-capitalize`}
              onClick={() => addCause(eachCause.defaultCause)}
              key={`defaultCauses-${idx}-${eachCause}`}
              disabled={checkingAvailability(eachCause.defaultCause)}
            >
              {`${eachCause.defaultCause}`}
            </button>
          ))}
        </div>
        {availableCauses.length !== 0 && (
          <select
            className="w-100 form-control form-select mt-2"
            onChange={(e) => {
              setCausesType(e.target.value);
              setCausesSubType("");
              setCauseLang("");
              setCausesTipsLang("");
              setVizappSubcategory("");
              setVizappTipContentLang("");
            }}
            value={causesType}
          >
            <option hidden>Select Causes</option>
            {availableCauses.map((eachCause) => (
              <option key={`eachCause-${eachCause}`} value={eachCause}>
                {eachCause}
              </option>
            ))}
          </select>
        )}

        {causesType && (
          <select
            className="form-control form-select mt-2"
            value={causesSubtype}
            onChange={(e) => {
              setCausesSubType(e.target.value);
              setCauseLang("");
              setCausesTipsLang("");
              setVizappSubcategory("");
              setVizappTipContentLang("");
            }}
          >
            <option hidden> select sub category</option>
            {Object.keys(
              parameterContent[selectedParameter][category][causesType]
            ).map((eachSubCategory, idx) => (
              <option key={`cause-sub-category-${idx}`} value={eachSubCategory}>
                {eachSubCategory}
              </option>
            ))}
          </select>
        )}
        {renderDetailedCauses()}
      </>
    );
  };

  const renderAdditionalSections = () => {
    return (
      <>
        <div className="d-flex mt-2 align-items-center">
          <p className="mt-0 mb-0">Additional Sections</p>
          <button
            onClick={() => {
              setParameterContent((prevContent) => {
                const updatedCategory = prevContent[selectedParameter][category]
                  ? [...prevContent[selectedParameter][category]]
                  : [];
                return {
                  ...prevContent,
                  [selectedParameter]: {
                    ...prevContent[selectedParameter],
                    [category]: [
                      ...updatedCategory,
                      {
                        type: "full",
                        valueDependent: "",
                        valueType: "",
                        heading: {
                          ...availableLangObj,
                        },
                        description: {
                          ...availableLangObj,
                        },
                        descriptionImage: "",
                        showHeadingIcon: false,
                        showListIcons: true,
                        listType: "",
                        list: [
                          {
                            text: {
                              ...availableLangObj,
                            },
                            image: "",
                          },
                        ],
                      },
                    ],
                  },
                };
              });
              setAdditionalSectionIndex(
                parameterContent[selectedParameter][category]
                  ? parameterContent[selectedParameter][category].length
                  : 0
              );
              setAdditionalSectionCategory("");
              setAdditionalSectionTipsLang("");
            }}
            className="btn btn-success mx-2"
          >
            Add Section
          </button>
        </div>
        <select
          className="form-control form-select mt-2"
          value={additionalSectionIndex}
          onChange={(e) => {
            setAdditionalSectionIndex(e.target.value);
            setAdditionalSectionCategory("");
            setAdditionalSectionTipsLang("");
          }}
        >
          <option hidden>Select Additional Section</option>
          {parameterContent[selectedParameter][category] &&
            parameterContent[selectedParameter][category].map(
              (eachSection, idx) => (
                <option
                  key={`additional-section-${idx}`}
                  value={idx}
                >{`Additional Section-${idx + 1}`}</option>
              )
            )}
        </select>
        {additionalSectionIndex !== "" && (
          <select
            className="form-control form-select mt-2"
            value={additionalSectionCategory}
            onChange={(e) => {
              setAdditionalSectionCategory(e.target.value);
              setAdditionalSectionTipsLang("");
              setAdditionalSectionLang("");
            }}
          >
            <option hidden>Select Additional Section Category</option>
            {Object.keys(
              parameterContent[selectedParameter][category][
                additionalSectionIndex
              ]
            ).map((eachCategory, idx) => (
              <option
                key={`Additional-section-category-${eachCategory}-${idx}`}
                value={eachCategory}
              >
                {eachCategory}
              </option>
            ))}
          </select>
        )}
        {getAdditionalCategories()}
      </>
    );
  };

  const renderVizappTips = () => {
    const removeVizappTip = (deletedTip, index) => {
      setParameterContent((prevContent) => ({
        ...prevContent,
        [selectedParameter]: {
          ...prevContent[selectedParameter],
          [category]: {
            ...prevContent[selectedParameter][category],
            [vizappSubCategory]: [
              ...prevContent[selectedParameter][category][
                vizappSubCategory
              ].filter(
                (eachTip, idx) =>
                  idx !== index ||
                  eachTip["text"][vizappTipContentLang] !==
                    deletedTip["text"][vizappTipContentLang]
              ),
            ],
          },
        },
      }));
    };

    const updateVizappTip = (updatedContent, type, index) => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      if (type === "text") {
        requiredParameterContent[selectedParameter][category][
          vizappSubCategory
        ][index][type][vizappTipContentLang] = updatedContent;
      } else {
        requiredParameterContent[selectedParameter][category][
          vizappSubCategory
        ][index][type] = updatedContent;
      }
      setParameterContent(requiredParameterContent);
    };

    return (
      <>
        <select
          className="form-control form-select mt-2"
          onChange={(e) => {
            setVizappTipContentLang(e.target.value);
          }}
          value={vizappTipContentLang}
        >
          <option hidden>Select Language</option>
          {availableLang.map((eachLang) => (
            <option
              key={`Vizapp-tip-content-language-${eachLang}`}
              value={eachLang}
            >
              {eachLang}
            </option>
          ))}
        </select>

        {vizappTipContentLang && (
          <div className="d-flex mt-2 align-items-center">
            <p className="mt-0 mb-0">Vizapp Tips Content</p>
            {/* Adding the default Tips Object */}
            <button
              className="btn btn-success mx-2"
              onClick={() => {
                setParameterContent((prevContent) => ({
                  ...prevContent,
                  [selectedParameter]: {
                    ...prevContent[selectedParameter],
                    [category]: {
                      ...prevContent[selectedParameter][category],
                      [vizappSubCategory]: [
                        ...prevContent[selectedParameter][category][
                          vizappSubCategory
                        ],
                        {
                          text: {
                            ...availableLangObj,
                          },
                          svg: "",
                          mediaUrl: "",
                        },
                      ],
                    },
                  },
                }));
              }}
            >
              Add Tips to Vizapp
            </button>
          </div>
        )}

        {vizappTipContentLang &&
          parameterContent[selectedParameter][category][vizappSubCategory].map(
            (eachTip, idx) => (
              <div
                key={`Vizapp-tip-content-${idx}`}
                className="d-flex align-items-center mt-3"
              >
                <div className="d-flex flex-column w-100">
                  {idx === 0 && <label>Content</label>}
                  <Form.Control
                    onChange={(e) => {
                      updateVizappTip(e.target.value, "text", idx);
                    }}
                    placeholder="Add Content"
                    value={
                      eachTip["text"][vizappTipContentLang]
                        ? eachTip["text"][vizappTipContentLang]
                        : ""
                    }
                  />
                </div>
                <div className="d-flex flex-column mx-2 w-100">
                  {idx === 0 && <label>SVG</label>}
                  <Form.Control
                    onChange={(e) => {
                      updateVizappTip(e.target.value, "svg", idx);
                    }}
                    placeholder="Add Svg"
                    value={eachTip["svg"]}
                  />
                </div>
                <div className="d-flex flex-column w-100">
                  {idx === 0 && <label>Media URl</label>}
                  <Form.Control
                    onChange={(e) => {
                      updateVizappTip(e.target.value, "mediaUrl", idx);
                    }}
                    placeholder="Add Media URL"
                    value={eachTip["mediaUrl"]}
                  />
                </div>
                <button
                  onClick={() => removeVizappTip(eachTip, idx)}
                  className={`btn btn-danger mx-2 ${idx === 0 ? "mt-4" : ""}`}
                >
                  Remove
                </button>
              </div>
            )
          )}
      </>
    );
  };

  const renderVizappSubCategory = () => {
    switch (vizappSubCategory) {
      case "tips":
        return renderVizappTips();
      default:
        break;
    }
  };

  const renderVizApp = () => {
    return (
      <>
        <select
          className="form-control form-select mt-2"
          value={vizappSubCategory}
          onChange={(e) => {
            setVizappSubcategory(e.target.value);
            setVizappTipContentLang("");
          }}
        >
          <option hidden>Select Vizapp Category</option>
          {parameterContent[selectedParameter][category] &&
            Object.keys(parameterContent[selectedParameter][category]).map(
              (eachSection, idx) => (
                <option key={`vizapp-parameter-${idx}`} value={eachSection}>
                  {eachSection}
                </option>
              )
            )}
        </select>
        {renderVizappSubCategory()}
      </>
    );
  };

  const updateAdditionalSectionType = (updatedValue) => {
    // logic for boolean values
    const returnBoolean = (booleanValue) => {
      switch (booleanValue) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          break;
      }
    };

    const requiredParameterContent = JSON.parse(
      JSON.stringify(parameterContent)
    );
    requiredParameterContent[selectedParameter][category][
      additionalSectionIndex
    ][additionalSectionCategory] =
      updatedValue !== "true" && updatedValue !== "false"
        ? updatedValue
        : returnBoolean(updatedValue);
    setParameterContent(requiredParameterContent);
  };

  const updateHeadingDescription = (updatedValue) => {
    const requiredParameterContent = JSON.parse(
      JSON.stringify(parameterContent)
    );
    requiredParameterContent[selectedParameter][category][
      additionalSectionIndex
    ][additionalSectionCategory][additionalSectionLang] = updatedValue;
    setParameterContent(requiredParameterContent);
  };

  const additionalSectionType = (defaultValues, type) => {
    return (
      <select
        className="form-control form-select mt-2"
        onChange={(e) => {
          updateAdditionalSectionType(e.target.value);
        }}
        // .tostring() is used to mitigate the  boolean effect on value
        value={
          parameterContent[selectedParameter][category][additionalSectionIndex][
            additionalSectionCategory
          ].toString()
            ? parameterContent[selectedParameter][category][
                additionalSectionIndex
              ][additionalSectionCategory].toString()
            : ""
        }
      >
        <option hidden>Select {type}</option>
        {defaultValues.map((eachValue) => (
          <option
            key={`additional-section-type-${eachValue}`}
            value={eachValue}
          >
            {eachValue}
          </option>
        ))}
      </select>
    );
  };

  const additionalSectionHeadingDescription = (type) => {
    return (
      <>
        <select
          className="form-control form-select mt-2"
          onChange={(e) => {
            setAdditionalSectionLang(e.target.value);
          }}
          value={additionalSectionLang}
        >
          <option hidden>Select Additional Section {type} Language</option>
          {availableLang.map((eachValue) => (
            <option
              key={`additional-section-languages${eachValue}`}
              value={eachValue}
            >
              {eachValue}
            </option>
          ))}
        </select>

        {additionalSectionLang && (
          <InputGroup className="mt-3">
            <InputGroup.Text>{type}</InputGroup.Text>
            {/* <p className="mt-2 mb-2">{type}</p> */}
            <Form.Control
              value={
                parameterContent[selectedParameter][category][
                  additionalSectionIndex
                ][additionalSectionCategory][additionalSectionLang]
              }
              onChange={(e) => updateHeadingDescription(e.target.value)}
            />
          </InputGroup>
        )}
      </>
    );
  };

  const additionalSectionListTypeAndDescImage = (type) => {
    const updateListTypeandDescImage = (updatedValue) => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      requiredParameterContent[selectedParameter][category][
        additionalSectionIndex
      ][additionalSectionCategory] = updatedValue;
      setParameterContent(requiredParameterContent);
    };

    return (
      <>
        <p className="mt-2 mb-2">{type}</p>
        <Form.Control
          value={
            parameterContent[selectedParameter][category][
              additionalSectionIndex
            ][additionalSectionCategory]
              ? parameterContent[selectedParameter][category][
                  additionalSectionIndex
                ][additionalSectionCategory]
              : ""
          }
          onChange={(e) => updateListTypeandDescImage(e.target.value)}
        />
      </>
    );
  };

  const additionalSectionTips = () => {
    const addTipSection = () => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      requiredParameterContent[selectedParameter][category][
        additionalSectionIndex
      ][additionalSectionCategory].push({
        text: {
          ...availableLangObj,
        },
        image: "",
      });
      setParameterContent(requiredParameterContent);
    };

    const updateAdditionalSectionTip = (updatedTip, type, index) => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      if (type === "tip") {
        requiredParameterContent[selectedParameter][category][
          additionalSectionIndex
        ][additionalSectionCategory][index]["text"][additionalSectionTipsLang] =
          updatedTip;
      } else {
        requiredParameterContent[selectedParameter][category][
          additionalSectionIndex
        ][additionalSectionCategory][index]["image"] = updatedTip;
      }
      setParameterContent(requiredParameterContent);
    };

    const removeAdditionalSectionTip = (tip, index) => {
      const requiredParameterContent = JSON.parse(
        JSON.stringify(parameterContent)
      );
      const requiredTips = requiredParameterContent[selectedParameter][
        category
      ][additionalSectionIndex][additionalSectionCategory].filter(
        (eachTipSection, idx) => index !== idx
      );
      requiredParameterContent[selectedParameter][category][
        additionalSectionIndex
      ][additionalSectionCategory] = requiredTips;
      setParameterContent(requiredParameterContent);
    };

    return (
      <>
        <div className="d-flex mt-2 align-items-center">
          <p className="mt-0 mb-0">Additional Sections Tips</p>
          <button
            onClick={() => {
              addTipSection();
            }}
            className="btn btn-success mx-2"
          >
            Add Tip Section
          </button>
        </div>

        <select
          className="form-control form-select mt-2"
          onChange={(e) => setAdditionalSectionTipsLang(e.target.value)}
          value={additionalSectionTipsLang}
        >
          <option hidden>Select Additional Section Tips Language</option>
          {availableLang.map((each) => (
            <option
              key={`Additional Section Tips Language-${each}`}
              value={each}
            >
              {each}
            </option>
          ))}
        </select>

        {additionalSectionTipsLang &&
          parameterContent[selectedParameter][category][additionalSectionIndex][
            additionalSectionCategory
          ].map((eachAdditionalSectionTip, idx) => (
            <div
              key={`additional-section-tip-${idx}`}
              className="d-flex mt-2 align-items-start"
            >
              <textarea
                placeholder="Enter the tip content"
                className=" w-100 form-control"
                value={
                  parameterContent[selectedParameter][category][
                    additionalSectionIndex
                  ][additionalSectionCategory][idx]["text"][
                    additionalSectionTipsLang
                  ]
                    ? parameterContent[selectedParameter][category][
                        additionalSectionIndex
                      ][additionalSectionCategory][idx]["text"][
                        additionalSectionTipsLang
                      ]
                    : ""
                }
                onChange={(e) =>
                  updateAdditionalSectionTip(e.target.value, "tip", idx)
                }
              />
              <Form.Control
                placeholder="Enter the Corresponding Tip Icon"
                onChange={(e) =>
                  updateAdditionalSectionTip(e.target.value, "svg", idx)
                }
                value={
                  parameterContent[selectedParameter][category][
                    additionalSectionIndex
                  ][additionalSectionCategory][idx]["image"]
                    ? parameterContent[selectedParameter][category][
                        additionalSectionIndex
                      ][additionalSectionCategory][idx]["image"]
                    : ""
                }
                className="mx-2"
              />
              <button
                onClick={() =>
                  removeAdditionalSectionTip(eachAdditionalSectionTip, idx)
                }
                className="btn btn-danger"
              >
                Remove
              </button>
            </div>
          ))}
      </>
    );
  };

  const getAdditionalCategories = () => {
    switch (additionalSectionCategory) {
      case "type":
        return additionalSectionType(["full", "half", "third"], "Type");
      case "valueDependent":
        return additionalSectionType(["Yes", "No"], "Select Value Dependency");
      case "valueType":
        return additionalSectionType(
          ["low", "normal", "borderline", "high"],
          "Value Dependency Type"
        );
      case "heading":
        return additionalSectionHeadingDescription(additionalSectionCategory);
      case "description":
        return additionalSectionHeadingDescription(additionalSectionCategory);
      case "descriptionImage":
        return additionalSectionListTypeAndDescImage("Description Image");
      case "showHeadingIcon":
        return additionalSectionType(["true", "false"], "To show Heading Icon");
      case "showListIcons":
        return additionalSectionType(["true", "false"], "To show List Icons");
      case "listType":
        return additionalSectionListTypeAndDescImage("List Type");
      case "list":
        return additionalSectionTips();
      default:
        return "";
    }
  };

  const renderTipsHeader = () => {
    return Object.keys(parameterContent[selectedParameter][category]).includes(
      tipCategory
    ) ? (
      <>
        <select
          className="form-control form-select mt-2"
          onChange={(e) => {
            setTipHeaderLang(e.target.value);
            setTipContentLang("");
            setAdditionalSectionIndex("");
            setAdditionalSectionCategory("");
            setAdditionalSectionTipsLang("");
          }}
          value={tipHeaderLang}
        >
          <option hidden>Select Tips Header Language</option>
          {/* Object.keys(
          parameterContent[selectedParameter][category][tipCategory]
        ) */}
          {availableLang.map((tipsHeaderLang) => (
            <option
              value={tipsHeaderLang}
              key={`Tips Header Language-${tipCategory}-${tipsHeaderLang}`}
            >
              {tipsHeaderLang}
            </option>
          ))}
        </select>
        {tipHeaderLang && (
          <InputGroup className="mt-3">
            <InputGroup.Text>Tip Header</InputGroup.Text>

            <Form.Control
              as="textarea"
              placeholder="Enter Tip Header"
              onChange={(e) =>
                setParameterContent((prevContent) => ({
                  ...prevContent,
                  [selectedParameter]: {
                    ...prevContent[selectedParameter],
                    [category]: {
                      ...prevContent[selectedParameter][category],
                      [tipCategory]: {
                        ...prevContent[selectedParameter][category][
                          tipCategory
                        ],
                        [tipHeaderLang]: e.target.value,
                      },
                    },
                  },
                }))
              }
              rows="3"
              value={
                parameterContent[selectedParameter][category][tipCategory][
                  tipHeaderLang
                ]
                  ? parameterContent[selectedParameter][category][tipCategory][
                      tipHeaderLang
                    ]
                  : ""
              }
            />
          </InputGroup>
        )}
      </>
    ) : (
      <div className="d-flex mt-2 align-items-center">
        <p className="mt-0 mb-0">
          No Content to display. Add Section if Required
        </p>
        <button
          onClick={() => {
            setTipHeaderLang("en");
            setParameterContent((prevContent) => ({
              ...prevContent,
              [selectedParameter]: {
                ...prevContent[selectedParameter],
                [category]: {
                  ...prevContent[selectedParameter][category],
                  [tipCategory]: {
                    ...availableLangObj,
                  },
                },
              },
            }));
          }}
          className="btn btn-success mx-2"
        >
          Add Section
        </button>
      </div>
    );
  };

  const updateSVG = (updatedContent, index) => {
    const requiredParameterContent = JSON.parse(
      JSON.stringify(parameterContent)
    );
    requiredParameterContent[selectedParameter][category][tipCategory][index] =
      updatedContent;
    setParameterContent(requiredParameterContent);
  };

  const updateTip = (updatedTip, index) => {
    const requiredParameterContent = JSON.parse(
      JSON.stringify(parameterContent)
    );
    requiredParameterContent[selectedParameter][category][tipCategory][
      tipContentLang
    ][index] = updatedTip;
    setParameterContent(requiredParameterContent);
  };

  const removeSVG = (deletedSvg, index) => {
    setParameterContent((prevContent) => ({
      ...prevContent,
      [selectedParameter]: {
        ...prevContent[selectedParameter],
        [category]: {
          ...prevContent[selectedParameter][category],
          [tipCategory]: [
            ...prevContent[selectedParameter][category][tipCategory].filter(
              (each, idx) => idx !== index || each !== deletedSvg
            ),
          ],
        },
      },
    }));
  };

  const removeTip = (deletedTip, index) => {
    setParameterContent((prevContent) => ({
      ...prevContent,
      [selectedParameter]: {
        ...prevContent[selectedParameter],
        [category]: {
          ...prevContent[selectedParameter][category],
          [tipCategory]: {
            ...prevContent[selectedParameter][category][tipCategory],
            [tipContentLang]: [
              ...prevContent[selectedParameter][category][tipCategory][
                tipContentLang
              ].filter((each, idx) => idx !== index || each !== deletedTip),
            ],
          },
        },
      },
    }));
  };

  const renderTipsSvg = () => {
    return (
      <>
        <div className="d-flex mt-2 align-items-center">
          <p className="mt-0 mb-0">Tips SVG</p>
          <button
            onClick={() => {
              setParameterContent((prevContent) => ({
                ...prevContent,
                [selectedParameter]: {
                  ...prevContent[selectedParameter],
                  [category]: {
                    ...prevContent[selectedParameter][category],
                    [tipCategory]: [
                      ...prevContent[selectedParameter][category][tipCategory],
                      "",
                    ],
                  },
                },
              }));
            }}
            className="btn btn-success mx-2"
          >
            Add SVG
          </button>
        </div>
        {parameterContent[selectedParameter][category][tipCategory].map(
          (eachSvg, index) => (
            <div
              key={`tip-svg-${index}`}
              className="d-flex align-items-center mt-2"
            >
              <Form.Control
                placeholder="Enter Svg file name along with the extension like example.svg"
                value={
                  parameterContent[selectedParameter][category][tipCategory][
                    index
                  ]
                }
                onChange={(e) => {
                  updateSVG(e.target.value, index);
                }}
              />
              <button
                onClick={() => {
                  removeSVG(eachSvg, index);
                }}
                className="btn btn-danger"
              >
                Remove
              </button>
            </div>
          )
        )}
      </>
    );
  };

  const renderTipsContent = () => {
    return (
      <>
        <select
          className="form-control form-select mt-2"
          onChange={(e) => {
            setTipContentLang(e.target.value);
            setAdditionalSectionIndex("");
            setAdditionalSectionCategory("");
            setAdditionalSectionTipsLang("");
          }}
          value={tipContentLang}
        >
          <option hidden>Select Language</option>
          {availableLang.map((eachLang) => (
            <option key={`tip-content-language-${eachLang}`} value={eachLang}>
              {eachLang}
            </option>
          ))}
        </select>
        {tipContentLang && (
          <div className="d-flex mt-2 align-items-center">
            <p className="mt-0 mb-0">Tips Content</p>
            <button
              onClick={() => {
                setParameterContent((prevContent) => ({
                  ...prevContent,
                  [selectedParameter]: {
                    ...prevContent[selectedParameter],
                    [category]: {
                      ...prevContent[selectedParameter][category],
                      [tipCategory]: {
                        ...prevContent[selectedParameter][category][
                          tipCategory
                        ],
                        [tipContentLang]: prevContent[selectedParameter][
                          category
                        ][tipCategory][tipContentLang]
                          ? [
                              ...prevContent[selectedParameter][category][
                                tipCategory
                              ][tipContentLang],
                              "",
                            ]
                          : [],
                      },
                    },
                  },
                }));
              }}
              className="btn btn-success mx-2"
            >
              Add Tip Content
            </button>
          </div>
        )}
        {Object.keys(
          parameterContent[selectedParameter][category][tipCategory]
        ).includes(tipContentLang) && (
          <>
            {parameterContent[selectedParameter][category][tipCategory][
              tipContentLang
            ].map((eachTip, idx) => (
              <div
                key={`tip-content-${idx}`}
                className="d-flex align-items-center mt-2"
              >
                <Form.Control
                  placeholder="Enter the Tip "
                  value={eachTip}
                  onChange={(e) => {
                    updateTip(e.target.value, idx);
                  }}
                />
                <button
                  onClick={() => {
                    removeTip(eachTip, idx);
                  }}
                  className="btn btn-danger"
                >
                  Remove
                </button>
              </div>
            ))}
          </>
        )}
      </>
    );
  };

  const getDetailedTips = () => {
    switch (tipCategory) {
      case "tipsHeader":
        return renderTipsHeader();
      case "tipsSvg":
        return renderTipsSvg();
      case "tipsSvgText":
        return renderTipsContent();
      default:
        break;
    }
  };

  const getDetailedData = () => {
    switch (category) {
      case "text":
        return renderText();
      case "dynamicText":
        return renderDynamicText();
      case "tips":
        return renderTips();
      case "causes":
        return renderCauses();
      case "additionalSections":
        return renderAdditionalSections();
      case "vizapp":
        return renderVizApp();
      default:
        break;
    }
  };

  const updateParameterContent = async () => {
    console.log("updatedContent", parameterContent);
    const idToken = localStorage.getItem("idTokenBilling");
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "POST",
      url: `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/parameter?version=${version}`,
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
      data: parameterContent,
    };
    const response = await axios(configBody);
    console.log(response);
    if (response?.data?.isSuccess) {
      alert.success("Updated Successfully");
    }
    return response;
  };

  const handlingRequest = async () => {
    setUpdateLoader(true);
    try {
      const response = await updateParameterContent();
      if (response.status === 401) {
        await regenerateToken();
        const response = await updateParameterContent();
        console.log("response generated after regeneration", response);
      }
    } catch (e) {
      setUpdateLoader(false);
      console.log(e.message);
      alert.error("Something went wrong. Please try again");
    }
    setUpdateLoader(false);
  };

  const addnewLang = () => {
    const newData = JSON.parse(JSON.stringify(parameterContent));
    const addLanguage = (obj, newLangKey, newLangValue) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => addLanguage(item, newLangKey, newLangValue));
      } else {
        for (let key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            addLanguage(obj[key], newLangKey, newLangValue);
          }
          if (
            key === "en"
            // obj.hasOwnProperty("hi") &&
            // obj.hasOwnProperty("ar")
          ) {
            if (!obj.hasOwnProperty(newLangKey)) {
              if (Array.isArray(obj[key])) {
                obj[newLangKey] = [];
              } else {
                obj[newLangKey] = newLangValue;
              }
            }
          }
        }
      }
    };
    addLanguage(newData, newLangKey, "");
    setAvailableLang([...availableLang, newLangKey]);
    setAvailableLangObj({ ...availableLangObj, [newLangKey]: "" });
    setAvailableLangArray({ ...availableLangArray, [newLangKey]: [] });
    setParameterContent(newData);

    window.alert("Succesfully added new language");
    setNewLangKey("");
  };

  const newLangList = languageOptions.filter(
    (each) => !availableLang.includes(each.value)
  );

  const versionName = version ? `(${version})` : "(live)";
  const storedVersions = JSON.parse(localStorage.getItem("versionData"));
  const allVersions = storedVersions?.length
    ? [...storedVersions.map((each) => each.name), "live"]
    : ["live"];

  return (
    <>
      {pageLoader ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <LoadingView />
        </Box>
      ) : (
        <>
          <Stack mt={2} direction={"row"} gap={2}>
            <Box
              flexGrow={1}
              sx={{
                height: "calc(100vh - 315px)",
                overflowY: "auto",
                padding: "8px",
                width: "50%",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <LabelElement
                  title={`Update Parameter Content ${versionName}`}
                />
                <div className="ms-2">
                  <Form.Select
                    style={{ textTransform: "none" }}
                    onChange={(e) => setEditVersion(e.target.value)}
                    value={version}
                  >
                    {allVersions.map((each, index) => (
                      <option
                        style={{ textTransform: "none" }}
                        key={index}
                        value={each === "live" ? "" : each}
                      >
                        {each.toLowerCase()}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              {/* Add language button */}
              <Stack direction={"row"} flexWrap="wrap" gap={2} my={2}>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                  <h4 style={{ margin: "0" }}>Add New Language</h4>
                  <div className="ms-2">
                    <Form.Select
                      style={{ textTransform: "none" }}
                      value={newLangKey}
                      onChange={(e) => setNewLangKey(e.target.value)}
                    >
                      {newLangList.map((each, index) => (
                        <option
                          style={{ textTransform: "none" }}
                          key={index}
                          value={each.value}
                        >
                          {each.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <button
                    className="btn btn-info ms-2"
                    onClick={addnewLang}
                    disabled={
                      !(
                        newLangKey &&
                        parameterContent &&
                        Object.keys(parameterContent)?.length
                      )
                    }
                  >
                    Add
                  </button>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <h4 style={{ margin: "0px" }}>Available Languages:</h4>
                  {availableLang.map((each, idx) => (
                    <p key={idx} style={{ margin: "0px" }} className="ms-3">
                      {availableLanguageNames[each]}
                    </p>
                  ))}
                </Stack>
              </Stack>

              {/* Select Parameter */}
              <Dropdown
                placeholder="Search for Parameters"
                fluid
                search
                selection
                options={getParameters()}
                onChange={(e, data) => {
                  setSelectedParameter(data.value);
                  setCategory("");
                  setTextLanguage("");
                  setTipCategory("");
                  setTipHeaderLang("");
                  setTipContentLang("");
                  setAdditionalSectionIndex("");
                  setAdditionalSectionCategory("");
                  setAdditionalSectionTipsLang("");
                  setCausesType("");
                  setCausesSubType("");
                  setCauseLang("");
                  setCausesTipsLang("");
                  setVizappSubcategory("");
                  setVizappTipContentLang("");
                }}
              />
              {/* Select categories */}

              {selectedParameter && (
                <select
                  className="form-control form-select mt-2"
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setTextLanguage("");
                    setTipCategory("");
                    setTipHeaderLang("");
                    setTipContentLang("");
                    setAdditionalSectionIndex("");
                    setAdditionalSectionCategory("");
                    setAdditionalSectionTipsLang("");
                    setCausesType("");
                    setCausesSubType("");
                    setCauseLang("");
                    setCausesTipsLang("");
                    setVizappSubcategory("");
                    setVizappTipContentLang("");
                  }}
                  value={category}
                >
                  <option hidden>Select Category</option>
                  {categories.map((eachCategory, idx) => (
                    <option
                      key={`${selectedParameter}-${eachCategory}`}
                      value={eachCategory}
                    >
                      {eachCategory}
                    </option>
                  ))}
                </select>
              )}

              {category && getDetailedData()}
            </Box>

            <JSONInput
              className="w-100"
              height="calc(100vh - 315px)"
              viewOnly
              // value={JSON.stringify(parameterContent[selectedParameter])}
              placeholder={parameterContent[selectedParameter]}
              locale={"en"}
            />
          </Stack>

          {/* Buttons */}
          <button
            onClick={() => {
              handlingRequest();
            }}
            disabled={updateLoader}
            className="btn btn-primary  mt-2"
            style={{ width: "132px" }}
          >
            {updateLoader ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#fff" }}
              ></Spinner>
            ) : (
              "Confirm Changes"
            )}
          </button>
        </>
      )}
    </>
  );
};

export default ParameterContent;
