import { CircularProgress, Stack, Typography } from "@mui/material";

const EmptyView = ({ message }) => {
  return (
    <Stack justifyContent="center" alignItems="center" gap={2} pt={3}>
      <Typography variant="body2" sx={{ color: "#6A6A6A" }}>
        {message}
      </Typography>
      <img
        src="https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/B2B-analysis.png"
        alt="analysis-logo"
        height="200px"
      />
    </Stack>
  );
};

export default EmptyView;
