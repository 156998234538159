import { Typography, Box, Stack } from "@mui/material";

const Legend = ({ color, name }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Box
        sx={{
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          display: "inline-block",
          background: color,
        }}
      ></Box>
      <Typography variant="body9">{name}</Typography>
    </Stack>
  );
};

export default Legend;
