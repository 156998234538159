import { Box, Stack, Typography } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";

const LabelElement = ({ title }) => {
  return (
    <Box sx={{ display: "inline-block" }}>
      <Stack
        alignItems={"center"}
        direction={"row"}
        gap={1}
        sx={{
          borderRadius: "6px",
          padding: "9px 16px",
          background: "#fff",
          border: "1px solid #D7D7D7",
          padding: "9px 16px",
        }}
      >
        <Box
          sx={{
            width: "30px",
            height: "30px",
            background: "#575757",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BoltIcon sx={{ color: "#fff" }} />
        </Box>
        <Typography variant="body10">{title}</Typography>
      </Stack>
    </Box>
  );
};

export default LabelElement;
