import { useEffect } from "react";

// import Loader from "../loader/loader";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import regenerateToken from "../regenerateTokens";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import JSONInput from "react-json-editor-ajrm";
import { Box, Stack } from "@mui/material";
import LabelElement from "../../../common/LabelElement";
import LoadingView from "../../../common/LoadingView";
import { useAlert } from "react-alert";

const ProfileContent = ({ version, setEditVersion }) => {
  const alert = useAlert();
  const [profileContent, setProfileContent] = useState("");
  const [pageLoader, setPageLoader] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [reportType, setReportType] = useState("");
  const [contentType, setContentType] = useState("");
  const [language, setLanguage] = useState("");
  const [tipSection, setTipSection] = useState("");
  const [tipsLanguage, setTipsLanguage] = useState("");
  const [newLangKey, setNewLangKey] = useState("");
  const [availableLang, setAvailableLang] = useState(["en", "hi", "ar"]);
  const [availableLangObj, setAvailableLangObj] = useState({
    en: "",
    hi: "",
    ar: "",
  });
  const [availableLangArray, setAvailableLangArray] = useState({
    en: [],
    hi: [],
    ar: [],
  });

  const languageOptions = [
    { name: "Choose Lang", value: "" },
    { name: "czech", value: "cz" },
  ];

  const availableLanguageNames = {
    en: "English",
    hi: "Hindi",
    ar: "Arabic",
    cz: "czech",
  };

  // loader when updating the content

  const [updateLoader, setUpdateLoader] = useState(false);
  useEffect(() => {
    (async function () {
      setPageLoader(true);
      const response = await getExistingProfileContent();
      if (response.status === 401) {
        await regenerateToken();
        const response = await getExistingProfileContent();
        if (response.data.isSuccess) {
          setProfileContent(response.data.data);
        }
      } else {
        if (response.data.isSuccess) {
          setProfileContent(response.data.data);
          setPageLoader(false);
        }
      }
      setPageLoader(false);
    })();
  }, [version]);

  // get existing languages from json
  useEffect(() => {
    const existingLanguages = detectLanguages(profileContent);
    if (existingLanguages?.length) {
      const existingLanguagesObj = existingLanguages.reduce((acc, cur) => {
        acc[cur] = "";
        return acc;
      }, {});
      const existingLanguagesArray = existingLanguages.reduce((acc, cur) => {
        acc[cur] = [];
        return acc;
      }, {});

      // update state variables
      setAvailableLang(existingLanguages);
      setAvailableLangObj(existingLanguagesObj);
      setAvailableLangArray(existingLanguagesArray);
    }
  }, [profileContent]);

  const detectLanguages = (jsonData) => {
    const languageSet = new Set(); // To store unique languages

    const findLanguages = (obj) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => findLanguages(item));
      } else {
        for (let key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            findLanguages(obj[key]);
          }
          // Assuming language keys are strings like 'en', 'hi', 'ar'
          if (typeof key === "string" && key.length === 2) {
            languageSet.add(key); // Add detected language key
          }
        }
      }
    };

    findLanguages(jsonData); // Start traversing
    return Array.from(languageSet); // Return unique languages as an array
  };

  const getExistingProfileContent = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "GET",
      url: `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/profile?version=${version}`,
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const response = await axios(configBody);
    return response;
  };

  const removeTips = (selectedTip) => {
    const requiredTips = profileContent[selectedProfile][reportType][
      contentType
    ][tipSection].tipsArray.filter(
      (eachTip) => eachTip.text.en !== selectedTip.text.en
    );
    const profileContentToBeUpdated = JSON.parse(
      JSON.stringify(profileContent)
    );
    profileContentToBeUpdated[selectedProfile][reportType][contentType][
      tipSection
    ].tipsArray = requiredTips;

    setProfileContent(profileContentToBeUpdated);
  };

  const addTips = () => {
    const defaultText = {
      en: "",
      ar: "",
      hi: "",
    };
    const emptyTip = {
      text: {
        ...availableLangObj,
      },
      svg: "",
    };
    const profileContentToBeUpdated = JSON.parse(
      JSON.stringify(profileContent)
    );
    profileContentToBeUpdated[selectedProfile][reportType][contentType][
      tipSection
    ].tipsArray.push(emptyTip);
    setProfileContent(profileContentToBeUpdated);
  };

  const updateTip = (updatedTip, type, index) => {
    const profileContentToBeUpdated = JSON.parse(
      JSON.stringify(profileContent)
    );
    if (type === "tip") {
      profileContentToBeUpdated[selectedProfile][reportType][contentType][
        tipSection
      ].tipsArray[index].text[tipsLanguage] = updatedTip;
    } else if (type === "svg") {
      profileContentToBeUpdated[selectedProfile][reportType][contentType][
        tipSection
      ].tipsArray[index].svg = updatedTip;
    } else {
      profileContentToBeUpdated[selectedProfile][reportType][contentType][
        tipSection
      ].tipsArray[index].mediaUrl = updatedTip;
    }
    setProfileContent(profileContentToBeUpdated);
  };

  const updateTipHeader = (value, type) => {
    const profileContentToBeUpdated = JSON.parse(
      JSON.stringify(profileContent)
    );
    profileContentToBeUpdated[selectedProfile][reportType][contentType][
      tipSection
    ][type][tipsLanguage] = value;

    setProfileContent(profileContentToBeUpdated);
  };

  const addTipSection = () => {
    const sampleTipSection = {
      header: {
        ...availableLangObj,
      },
      subHeader: {
        ...availableLangObj,
      },
      tipsArray: [
        {
          text: {
            ...availableLangObj,
          },
          svg: "",
        },
      ],
    };
    const profileContentToBeUpdated = JSON.parse(
      JSON.stringify(profileContent)
    );
    profileContentToBeUpdated[selectedProfile][reportType][contentType].push(
      sampleTipSection
    );
    setProfileContent(profileContentToBeUpdated);
  };

  const updateProfileContent = async () => {
    console.log("updatedContent", profileContent);
    const idToken = localStorage.getItem("idTokenBilling");
    const requestConfig = {
      headers: {
        authorization: idToken,
      },
      method: "POST",
      url: `https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/content/profile?version=${version}`,
      validateStatus: function (status) {
        return status >= 200 && status <= 502;
      },
      data: JSON.stringify(profileContent),
    };
    const response = await axios(requestConfig);
    if (response?.data?.isSuccess) {
      alert.success("Updated Successfully");
    }
    return response;
  };

  const handlingRequest = async () => {
    setUpdateLoader(true);
    try {
      const response = await updateProfileContent();
      console.log("response", response);

      if (response.status === 401) {
        await regenerateToken();
        const response = await updateProfileContent();
        console.log("response generated after regeneration", response);
      }
    } catch (e) {
      setUpdateLoader(false);
      console.log(e.message);
      alert.error("Something went wrong. Please try again");
    }
    setUpdateLoader(false);
  };

  const updateOthers = (content, type) => {
    const requiredProfileContent = JSON.parse(JSON.stringify(profileContent));
    requiredProfileContent[selectedProfile][reportType][type] = content;
    setProfileContent(requiredProfileContent);
  };

  const addnewLang = () => {
    const newData = JSON.parse(JSON.stringify(profileContent));
    const addLanguage = (obj, newLangKey, newLangValue) => {
      if (Array.isArray(obj)) {
        obj.forEach((item) => addLanguage(item, newLangKey, newLangValue));
      } else {
        for (let key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            addLanguage(obj[key], newLangKey, newLangValue);
          }

          if (
            key === "en"
            // obj.hasOwnProperty("hi") &&
            // obj.hasOwnProperty("ar")
          ) {
            if (!obj.hasOwnProperty(newLangKey)) obj[newLangKey] = newLangValue;
          }
        }
      }
    };
    addLanguage(newData, newLangKey, "");
    setAvailableLang([...availableLang, newLangKey]);
    setAvailableLangObj({ ...availableLangObj, [newLangKey]: "" });
    setAvailableLangArray({ ...availableLangArray, [newLangKey]: [] });
    setProfileContent(newData);
    window.alert("Succesfully added new language");
    setNewLangKey("");
  };

  const newLangList = languageOptions.filter(
    (each) => !availableLang.includes(each.value)
  );

  const versionName = version ? `(${version})` : "(live)";
  const storedVersions = JSON.parse(localStorage.getItem("versionData"));
  const allVersions = storedVersions?.length
    ? [...storedVersions.map((each) => each.name), "live"]
    : ["live"];

  return (
    <>
      {pageLoader ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <LoadingView />
        </Box>
      ) : (
        <>
          <Stack mt={2} direction={"row"} gap={2}>
            <Box
              flexGrow={1}
              sx={{
                height: "calc(100vh - 315px)",
                overflowY: "auto",
                padding: "8px",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <LabelElement title={`Update Profile Content ${versionName}`} />
                <div className="ms-2">
                  <Form.Select
                    style={{ textTransform: "none" }}
                    onChange={(e) => setEditVersion(e.target.value)}
                    value={version}
                  >
                    {allVersions.map((each, index) => (
                      <option
                        style={{ textTransform: "none" }}
                        key={index}
                        value={each === "live" ? "" : each}
                      >
                        {each.toLowerCase()}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>

              {/* Add language button */}
              <Stack direction="row" flexWrap="wrap" gap={2} my={2}>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                  <h4 style={{ margin: "0" }}>Add New Language</h4>
                  <div className="ms-2">
                    <Form.Select
                      style={{ textTransform: "none" }}
                      value={newLangKey}
                      onChange={(e) => setNewLangKey(e.target.value)}
                    >
                      {newLangList.map((each, index) => (
                        <option
                          style={{ textTransform: "none" }}
                          key={index}
                          value={each.value}
                        >
                          {each.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <button
                    className="btn btn-info ms-2"
                    onClick={addnewLang}
                    disabled={
                      !(
                        newLangKey &&
                        profileContent &&
                        Object.keys(profileContent)?.length
                      )
                    }
                  >
                    Add
                  </button>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <h4 style={{ margin: "0px" }}>Available Languages:</h4>
                  {availableLang.map((each, idx) => (
                    <p key={idx} style={{ margin: "0px" }} className="ms-3">
                      {availableLanguageNames[each]}
                    </p>
                  ))}
                </Stack>
              </Stack>

              {/* Select Profile */}
              <InputGroup>
                <InputGroup.Text>Select Profile</InputGroup.Text>
                {/* <p className="mb-2">Select Profile</p> */}
                <Form.Select
                  className="form-control form-select"
                  id="profile Drop Down"
                  onChange={(e) => {
                    setSelectedProfile(e.target.value);
                    // resetting other states
                    setReportType("");
                    setContentType("");
                    setLanguage("");
                    setLanguage("");
                    setTipSection("");
                    setTipsLanguage("");
                  }}
                >
                  <option hidden>Select Profile</option>
                  {Object.keys(profileContent).map((eachProfile, index) => (
                    <option key={eachProfile} value={eachProfile}>
                      {eachProfile}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>

              {/* Select Report type for content Update */}

              {selectedProfile !== "" ? (
                <InputGroup className="mt-3">
                  <InputGroup.Text>Select Report Type</InputGroup.Text>
                  {/* <p className="mb-2 mt-2">Select Report Type</p> */}
                  <Form.Select
                    className="form-control form-select"
                    id="profile Drop Down"
                    onChange={(e) => {
                      setReportType(e.target.value);
                      // resetting the bottom state hooks
                      setContentType("");
                      setLanguage("");
                      setTipSection("");
                      setTipsLanguage("");
                    }}
                    value={reportType}
                  >
                    <option hidden>Select Report Type</option>
                    {selectedProfile &&
                      Object.keys(profileContent[selectedProfile]).map(
                        (reportType) => (
                          // reportType !== 'others' && (
                          <option key={reportType} value={reportType}>
                            {reportType}
                          </option>
                        )
                      )}
                  </Form.Select>
                </InputGroup>
              ) : (
                ""
              )}
              {/* select type of content to edit */}
              {reportType !== "" && reportType !== "others" && (
                <InputGroup className="mt-3">
                  <InputGroup.Text>Profile Header</InputGroup.Text>
                  {/* <p className="mb-2 mt-2">Profile Header</p> */}
                  <Form.Select
                    className="form-control form-select"
                    id="profile Drop Down"
                    onChange={(e) => {
                      setContentType(e.target.value);
                      // Resetting Bottom down statehooks
                      setLanguage("");
                      setTipSection("");
                      setTipsLanguage("");
                    }}
                    value={contentType}
                  >
                    <option hidden>Select Type</option>
                    {Object.keys(
                      profileContent[selectedProfile][reportType]
                    ).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              )}

              {/* Select language  */}
              {contentType !== "" && contentType === "about" && (
                <InputGroup className="mt-3">
                  <InputGroup.Text>Profile Header</InputGroup.Text>
                  {/* <p className="mb-2 mt-2">Profile Header</p> */}
                  <Form.Select
                    className="form-control form-select"
                    id="profile Drop Down"
                    onChange={(e) => {
                      setLanguage(e.target.value);
                    }}
                  >
                    <option hidden>Select Language</option>
                    {Object.keys(
                      profileContent[selectedProfile][reportType][contentType][
                        "header"
                      ]
                    ).map((lang) => (
                      <option key={lang} value={lang}>
                        {lang}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              )}
              {language !== "" && contentType === "about" && (
                <>
                  <InputGroup className="mt-3">
                    <InputGroup.Text>Profile Heading</InputGroup.Text>
                    {/* <p className="w-100 mb-2 mt-2">Profile Heading </p> */}
                    <Form.Control
                      value={
                        profileContent[selectedProfile][reportType][
                          contentType
                        ]["header"][language]
                      }
                      onChange={(e) =>
                        setProfileContent((prevState) => {
                          return {
                            ...prevState,
                            [selectedProfile]: {
                              ...prevState[selectedProfile],
                              [reportType]: {
                                ...prevState[selectedProfile][reportType],
                                [contentType]: {
                                  ...prevState[selectedProfile][reportType][
                                    contentType
                                  ],
                                  header: {
                                    ...prevState[selectedProfile][reportType][
                                      contentType
                                    ].header,
                                    [language]: e.target.value,
                                  },
                                },
                              },
                            },
                          };
                        })
                      }
                    />
                  </InputGroup>
                  {/* Profile Content */}
                  <InputGroup className="mt-3">
                    <InputGroup.Text>Profile Content</InputGroup.Text>
                    {/* <p className="mb-2 mt-2">Profile Content</p> */}
                    <Form.Control
                      as="textarea"
                      // className="w-100 form-control"
                      rows="7"
                      value={
                        profileContent[selectedProfile][reportType][
                          contentType
                        ]["text"][language]
                      }
                      onChange={(e) => {
                        setProfileContent((prevState) => {
                          return {
                            ...prevState,
                            [selectedProfile]: {
                              ...prevState[selectedProfile],
                              [reportType]: {
                                ...prevState[selectedProfile][reportType],
                                [contentType]: {
                                  ...prevState[selectedProfile][reportType][
                                    contentType
                                  ],
                                  text: {
                                    ...prevState[selectedProfile][reportType][
                                      contentType
                                    ].text,
                                    [language]: e.target.value,
                                  },
                                },
                              },
                            },
                          };
                        });
                      }}
                    ></Form.Control>
                  </InputGroup>

                  {/* Media Url in about section for Vizapp only.*/}
                  {reportType === "vizapp" && (
                    <InputGroup className="mt-3">
                      <InputGroup.Text>Media URL</InputGroup.Text>
                      {/* <p className="mb-2 mt-2">Media URL</p> */}
                      <Form.Control
                        onChange={(e) => {
                          setProfileContent((prevState) => {
                            return {
                              ...prevState,
                              [selectedProfile]: {
                                ...prevState[selectedProfile],
                                [reportType]: {
                                  ...prevState[selectedProfile][reportType],
                                  [contentType]: {
                                    ...prevState[selectedProfile][reportType][
                                      contentType
                                    ],
                                    mediaUrl: e.target.value,
                                  },
                                },
                              },
                            };
                          });
                        }}
                        value={
                          profileContent[selectedProfile][reportType][
                            contentType
                          ]["mediaUrl"]
                        }
                        placeholder="Enter Media Url"
                      />
                    </InputGroup>
                  )}
                </>
              )}
              {contentType !== "" && contentType === "tips" && (
                <>
                  <div className="d-flex flex-row justify-content-start align-items-center mb-2 mt-2">
                    <p className="mb-0 mt-0">Profile Tips </p>
                    <button
                      className="btn btn-success mx-2"
                      onClick={() => {
                        addTipSection();
                        setTipSection(
                          profileContent[selectedProfile][reportType][
                            contentType
                          ].length
                        );
                      }}
                    >
                      Add Tip Section
                    </button>
                  </div>
                  <select
                    className="form-control form-select"
                    id="profile tips main level Drop Down"
                    onChange={(e) => {
                      setTipSection(e.target.value);
                      // resetting bottom down state hooks
                      setTipsLanguage("");
                    }}
                    value={tipSection}
                  >
                    <option hidden>Select Tip Section</option>
                    {profileContent[selectedProfile][reportType][
                      contentType
                    ].map((eachTipSection, idx) => (
                      <option
                        key={`tipsection-${idx}`}
                        value={idx}
                      >{`Tip Section-${idx + 1}`}</option>
                    ))}
                    {/* <option value={'add tip section'}>Add Tip Section</option> */}
                  </select>
                </>
              )}

              {tipSection !== "" && (
                <InputGroup className="mt-3">
                  <InputGroup.Text>Tips Language</InputGroup.Text>
                  {/* <p className="w-100 mb-2 mt-2"> Tips Language</p> */}
                  <Form.Select
                    className="form-control form-select"
                    id="profile-tips-language-Drop-Down"
                    onChange={(e) => setTipsLanguage(e.target.value)}
                    value={tipsLanguage}
                  >
                    <option hidden>Select language</option>
                    {availableLang.map((eachLang, idx) => (
                      <option key={`eachlang${idx}`} value={eachLang}>
                        {eachLang}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              )}

              {tipSection !== "" && tipsLanguage !== "" && (
                <>
                  {/* <p className='w-100 mb-2 mt-2'>Tips and SVG </p> */}
                  <InputGroup className="mt-3">
                    <InputGroup.Text>Tip Header</InputGroup.Text>
                    {/* <p className="w-100 mb-2 mt-2">Tip Header </p> */}
                    <Form.Control
                      value={
                        profileContent[selectedProfile][reportType][
                          contentType
                        ][tipSection]["header"][tipsLanguage]
                      }
                      onChange={(e) =>
                        updateTipHeader(e.target.value, "header")
                      }
                    />
                  </InputGroup>
                  {Object.keys(
                    profileContent[selectedProfile][reportType][contentType][
                      tipSection
                    ]
                  ).includes("subHeader") && (
                    <>
                      <p className="w-100 mb-2 mt-2">Tip Sub Header </p>
                      <Form.Control
                        value={
                          profileContent[selectedProfile][reportType][
                            contentType
                          ][tipSection]["subHeader"][tipsLanguage]
                        }
                        onChange={(e) =>
                          updateTipHeader(e.target.value, "subHeader")
                        }
                      />
                    </>
                  )}

                  {profileContent[selectedProfile][reportType][contentType][
                    tipSection
                  ]["tipsArray"].map((eachTip, index) => (
                    <div key={`tip-index${index}`} className="mb-2">
                      {index === 0 && (
                        <div className="d-flex mt-2 mb-2">
                          <div className="w-100 d-flex flex-grow">
                            <p className="mb-2 mt-0">Tip</p>
                            <button
                              className="btn btn-success"
                              style={{
                                height: "30px",
                                width: "85px",
                                marginLeft: "15px",
                              }}
                              onClick={() => {
                                addTips();
                              }}
                            >
                              ADD TIP
                            </button>
                          </div>
                          {/* <div className='w-100' style={{ marginRight: '85px' }}>
                          {index === 0 && <p className='mb-2 mt-0'>Tip Svg</p>}
                        </div> */}
                        </div>
                      )}
                      <div
                        className="w-100 d-flex"
                        style={{ marginRight: "15px" }}
                      >
                        <div className="d-flex flex-column w-100">
                          {index === 0 && (
                            <label className="mb-2">Content</label>
                          )}
                          <textarea
                            className="w-100 form-control"
                            value={
                              profileContent[selectedProfile][reportType][
                                contentType
                              ][tipSection]["tipsArray"][index]["text"][
                                tipsLanguage
                              ]
                            }
                            placeholder="Update the Tip Content"
                            onChange={(e) => {
                              updateTip(e.target.value, "tip", index);
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column w-100 mx-3">
                          {index === 0 && <label className="mb-2">SVG</label>}
                          <Form.Control
                            className="w-100 form-control "
                            placeholder="Enter SVG name"
                            value={
                              profileContent[selectedProfile][reportType][
                                contentType
                              ][tipSection]["tipsArray"][index].svg
                            }
                            onChange={(e) => {
                              updateTip(e.target.value, "svg", index);
                            }}
                          />
                        </div>

                        {reportType === "vizapp" && (
                          <div className="d-flex flex-column w-100">
                            {index === 0 && (
                              <label className="mb-2">Media Url</label>
                            )}
                            <Form.Control
                              className="w-100 form-control"
                              placeholder="Enter Media URL"
                              value={
                                profileContent[selectedProfile][reportType][
                                  contentType
                                ][tipSection]["tipsArray"][index].mediaUrl
                              }
                              onChange={(e) => {
                                updateTip(e.target.value, "mediaUrl", index);
                              }}
                            />
                          </div>
                        )}

                        <button
                          className={`btn btn-danger ${
                            index === 0 ? "mt-4" : ""
                          }`}
                          style={{
                            height: "30px",
                            width: "75px",
                            marginLeft: "15px",
                          }}
                          onClick={() => {
                            removeTips(eachTip);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                      <div className=" d-flex flex-column"></div>
                    </div>
                  ))}
                </>
              )}

              {/* Adding Others field */}
              {reportType === "others" && (
                <>
                  <label className="mt-2">Href</label>
                  <Form.Control
                    value={
                      profileContent[selectedProfile][reportType]["href"]
                        ? profileContent[selectedProfile][reportType]["href"]
                        : ""
                    }
                    placeholder="href"
                    onChange={(e) => {
                      updateOthers(e.target.value, "href");
                    }}
                  />
                  <label className="mt-2">Recommendations</label>
                  <Form.Control
                    onChange={(e) => {
                      updateOthers(e.target.value, "recommendation");
                    }}
                    value={
                      profileContent[selectedProfile][reportType][
                        "recommendation"
                      ]
                        ? profileContent[selectedProfile][reportType][
                            "recommendation"
                          ]
                        : ""
                    }
                    placeholder="Recommendations"
                  />
                  <label className="mt-2">Concern Areas</label>
                  <Form.Control
                    onChange={(e) => {
                      updateOthers(e.target.value, "concernArea");
                    }}
                    value={
                      profileContent[selectedProfile][reportType]["concernArea"]
                        ? profileContent[selectedProfile][reportType][
                            "concernArea"
                          ]
                        : ""
                    }
                    placeholder="Concern areas"
                  />
                </>
              )}
            </Box>

            <JSONInput
              className="w-100"
              height="calc(100vh - 315px)"
              viewOnly
              // value={JSON.stringify(parameterContent[selectedParameter])}
              placeholder={profileContent[selectedProfile]}
              locale={"en"}
            />
          </Stack>

          {/* Buttons */}
          <button
            onClick={() => handlingRequest()}
            disabled={updateLoader}
            className="btn btn-primary mt-2"
            style={{ width: "140px", borderRadius: "12px" }}
          >
            {updateLoader ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#fff" }}
              ></Spinner>
            ) : (
              "Confirm Changes"
            )}
          </button>
        </>
      )}
    </>
  );
};
export default ProfileContent;
