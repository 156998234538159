import Pagination from "rc-pagination";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import "rc-pagination/assets/index.css";
import regenerateToken from "../regenerateTokens";
import axios from "axios";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SearchIcon from "@mui/icons-material/Search";

// import { IoMdRemoveCircle } from 'react-icons/io';
// import { AiFillPlusCircle } from 'react-icons/ai';
import Cookies from "js-cookie";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";

// const dummyTests = [
//   'Total Cholesterol',
//   'HDL Cholesterol',
//   'LDL Cholesterol',
//   'HDL % of total',
//   'Direct LDL Cholestrol',
//   'HDL : LDL ratio',
//   'LDL : HDL ratio',
//   'APO B : APO A Ratio',
//   'APO A : APO B Ratio',
//   'HsCRP',
//   'Lipoprotein (a) [Lp(a)]',
//   'Apo A',
//   'Triglycerides',
//   'VLDL',
//   'Apo B',
//   'Creatine-Kinase',
//   'Non - HDL Cholesterol',
//   'Total Cholesterol : HDL ratio',
//   'HDL :Total Cholesterol ratio',
//   'Total Bilirubin',
//   'Direct Bilirubin',
//   'Indirect Bilirubin',
//   'SGOT (AST)',
//   'SGOT (ALT)',
//   'SGPT (ALT)',
//   'SGPT (AST)',
//   'AST / ALT Ratio',
//   'GGT',
//   'ALP',
//   'LDH',
//   'Protein (Total)',
//   'Albumin',
//   'Globulin',
//   'Albumin : Globulin ratio',
//   'Albumin / Creatinine ratio',
//   'Serum Creatinine',
//   'Uric Acid',
//   'Blood Urea',
//   'Blood Urea Nitrogen (BUN)',
//   'Urea : Creatinine ratio',
//   'BUN : Creatinine ratio',
//   'Microalbumin : Creatinine ratio',
//   'TSH',
//   'TSH 3RD GENERATION',
//   'Ultra TSH',
//   'T3 (Triiodothyronine)',
//   'T4 (Thyroxine)',
//   'Free T3 (Triiodothyronine)',
//   'Free T4 (Thyroxine)',
//   'Parathyroid Hormone (PTH)',
//   'Anti Thyroglobulin Abs',
//   'THYROGLOBULIN',
//   'Blood Sugar (Fasting)',
//   'Amylase',
//   'Blood Sugar (Postprandial)',
//   'Blood Sugar (Random)',
//   'Insulin (Fasting)',
//   'Insulin (Postprandial)',
//   'Insulin (Random)',
//   'CRP',
//   'RA factor (quantitative)',
//   'RA factor (descriptive)',
//   'ASO (quantitative)',
//   'Haemoglobin',
//   'Iron',
//   'TIBC',
//   'UIBC',
//   'G6PD (Quantitative)',
//   'Ferritin',
//   'Transferrin Serum',
//   '% Saturation Transferrin',
//   'RBC count',
//   'Haematocrit',
//   'MCV',
//   'MENTZER INDEX',
//   'MCH',
//   'MCHC',
//   'Rapid Plasma Reagin',
//   'Sickle Cell Solubility Test-Sickling phenomenon',
//   'Content Haemoglobin (CH)',
//   'Total Leukocyte Count',
//   'Platelet Count',
//   'Mean Platelet Component (MPC)',
//   'Mean Platelet Mass (MPM)',
//   'MPV',
//   'PDW',
//   'PDW - SD',
//   'Large Unstained Cell',
//   'Absolute Large Unstained Cell',
//   'Peripheral smear',
//   'PCT',
//   'Specific Gravity',
//   'pH',
//   'Urinary Calcium',
//   'Sodium',
//   'Body Mass Index(BMI)',
//   'Pulse',
//   'Height',
//   'Weight',
//   'Systolic',
//   'Diastolic',
//   'SERUM BICARBONATE(D)',
//   'Potassium',
//   'Chloride',
//   'Calcium',
//   'Ionized Calcium',
//   'Non Ionized Calcium',
//   'Corrected Calcium',
//   'Zinc',
//   'Phosphorus',
//   'Magnesium',
//   'HbA1c (Glycosylated Haemoglobin)',
//   'HbA1c (mmol/mol)',
//   'eAG (Estimated Average Glucose)',
//   'Neutrophils',
//   'Abs. Neutrophil Count',
//   'Segmented Neutrophils',
//   'Neutrophil Lymphocyte Ratio',
//   'Eosinophils',
//   'Abs. Eosinophil Count',
//   'Basophils',
//   'Abs. Basophil Count',
//   'Abs. Lymphocyte Count',
//   'Abs. Monocyte Count',
//   'Lymphocytes',
//   'Polymorphs',
//   'RBC Morphology',
//   'WBC Morphology',
//   'Platelate Morphology',
//   'Reticulocytes Count',
//   'CORRECTED RETICULOCYTE COUNT',
//   'ABSOLUTE RETICULOCYTE COUNT',
//   'RETICULOCYTE HEAMOGLOBIN EQUIVELENT',
//   'IMMATURE PLATELET FRACTION',
//   'Monocytes',
//   'Myelocytes',
//   'Meta-Myelocytes',
//   'Pro-Myelocytes',
//   'Atypical Lymphocytes (LUC)',
//   'Band Cells',
//   'Blast Cells',
//   'HDW (Haemoglobin Distribution Width)',
//   'RDW',
//   'RDW-CV',
//   'RDW-SD',
//   'ESR',
//   'Microalbumin',
//   'Glucose in Urine',
//   'URINE SPOT PROTEIN',
//   'URINE SPOT CREATININE',
//   'Urine Spot Microalbumin',
//   'SPOT PROTEIN & CREATININE RATIO',
//   'DEPOSITS :',
//   'Urine Colour',
//   'Appearance',
//   'VOLUME',
//   'Pus Cells',
//   'Epithelial Cells',
//   'Urobilinogen',
//   'Protein',
//   'Ketone',
//   'Acetone',
//   'Phosphates/Urates',
//   'Bile Salts',
//   'Blood',
//   'Nitrite',
//   'Casts',
//   'Crystals',
//   'Bacteria',
//   'Yeast cells',
//   'Amorphous Deposit',
//   'Bile Pigment',
//   'Bilirubin',
//   'Absorbic Acid',
//   'RBC',
//   'Leukocytes',
//   'Leukocyte Esterase',
//   'Sugar in Urine',
//   'Other compounds in Urine',
//   'Candida in Urine',
//   'Vitamin D (25-Hydroxy)',
//   'Serum Folate',
//   'Vitamin B9',
//   'Vitamin B12',
//   'Vitamin B1',
//   'Vitamin B-Complex',
//   'Biotin',
//   'Glutathione',
//   'Fat Burner',
//   'Vitamin B6',
//   'Vitamin B2',
//   'Vitamin A',
//   'Vitamin E',
//   'Vitamin C',
//   'RBC(PBS)',
//   'WBC(PBS)',
//   'Platelet(PBS)',
//   'Glomerular Filtration Rate',
//   'Glomerular Filtration Rate CKD',
//   'Albuminuria',
//   'P-LCR',
//   'P-LCC',
//   'Immature Granulocytes',
//   'Abs. Immature Granulocytes',
//   'Nucleated red blood cells',
//   'Absolute Nucleated red blood cells',
//   'Urinary Creatinine',
//   'Blood Group',
//   'Rh Factor',
//   'Rh Factor Quantitative',
//   'Prostate-Specific Antigen Free',
//   'Prostate-Specific Antigen Total',
//   'PT TEST',
//   'Thrombin Time',
//   'PT (Control)',
//   'Mean Normal Prothrombic Time',
//   'Partial Thromboplastin Time',
//   'Activated Partial Thromboplastin Time',
//   'Partial Thromboplastin Time(Control)',
//   'PT-INR',
//   'PT ratio',
//   'ISI',
//   'Index',
//   'Dengue IgG',
//   'Dengue IgM',
//   'Dengue NS1',
//   'HIV',
//   'Typhoid (Widal Test)',
//   'Malaria (VIVAX)',
//   'Malaria (FALCIPARUM)',
//   'Prolactin',
//   'Urine for Beta HCG',
//   'Serum for Beta HCG',
//   'Beta HCG',
//   'Pregnancy Test Serum',
//   'Leutinizing Harmone',
//   'AMH2',
//   'Estradiol (E2)',
//   'FSH',
//   'Progesterone',
//   'AMH',
//   'Estrogen',
//   'Oestradiol',
//   'SHBG',
//   '17-HydroxyProgesterone',
//   'Homocysteine',
//   'DHEA-Sulphate',
//   'Cortosol Random',
//   'Testosterone',
//   'Testosterone(Free)',
//   'Androstenedione',
//   'HBsAb',
//   'HBsAg',
//   'Hepatitis B',
//   'HBcAb Total',
//   'HBcAb IgM',
//   'HCV',
//   'HCV AB',
//   'Anti HCV',
//   'HPV',
//   'RAPID TP(SYPHILIS), VDRL',
//   'HAV AB IgM',
//   'HAV AB',
//   'HAV AB Total',
//   'CMV AB IgG',
//   'CMV AB IgM',
//   'CA 19-9',
//   'CA 125',
//   'CA 15-3',
//   'CEA',
//   'HIV AB',
//   'HIV Combo (Ag+Ab)',
//   'VDRL',
//   'Herpes Semplex Virus I IgM',
//   'Herpes Semplex Virus II IgM',
//   'Herpes Semplex Virus',
//   'Chlamydia IgM',
//   'TPHA (TREPONEMA PALLIDUM)',
//   'High Vaginal Swab',
//   'Urea Breath Test',
//   'Typhidot',
//   'WIDAL TEST (SLIDE METHOD)',
//   'Gram Stain',
//   'Helicobacter Antigen in Stool',
//   'Helicobacter Pylori Abs.(IgM)',
//   'Helicobacter Pylori Abs.(IgG)',
//   'Egg White Allergy',
//   'Egg Yolk Allergy',
//   'Cow Milk Allergy',
//   'Chocolate Allergy',
//   'Wheat Flour Allergy',
//   'Soy Bean Allergy',
//   "Baker's Yeast Allergy",
//   'Nut Mix Allergy',
//   'Peanut Allergy',
//   'Orange Allergy',
//   'Strawberry Allergy',
//   'Banana Allergy',
//   'Mango Allergy',
//   'Tomato Allergy',
//   'Carrot Allergy',
//   'Onion Allergy',
//   'Chicken Allergy',
//   'Lamb Allergy',
//   'Codfish Allergy',
//   'Shrimp/Prawn Allergy',
//   'HbF',
//   'HbA',
//   'HbS',
//   'HbC',
//   'HbD',
//   'Unidentified',
//   'HbA2',
//   'D-Dimer',
//   'Lipase',
//   'Occult Blood in Stool',
//   'Stool Color',
//   'Stool Consistency',
//   'Stool Odour',
//   'Stool Mucus',
//   'Visible Blood',
//   'Red Blood Cell',
//   'Pus Cells in Stool',
//   'Polymorphonuclear Leukocytes',
//   'Trophozoites',
//   'Cysts',
//   'OVA',
//   'Adult Parasite',
//   'Undigested Food',
//   'Stool Specimen Type',
//   'Organism Isolated',
//   'Semen Type',
//   'Primary Smear of Semen',
//   'Ampicillin',
//   'Amoxycillin',
//   'Amoxycillin + Clavulanic Acid',
//   'Ampicillin + Salbactum',
//   'Piperacillin',
//   'Ticarcillin',
//   'Amikacin',
//   'Cotrimoxazole',
//   'Tetracycline',
//   'Ciprofloxacin',
//   'Levofloxacine',
//   'Oflaxacine',
//   'Imipenem',
//   'Meropenem',
//   'Piperacillin + Tazobactum',
//   'Norfloxacin',
//   'Ticarcillin + Clavulanic Acid',
//   'Cefazolin',
//   'Cefuroxime',
//   'Cefoxitin',
//   'Cefitriaxone',
//   'Cefotaxime',
//   'Ceftazidime',
//   'Cefoperazone + Sulbactum',
//   'Cefepime',
//   'Aztreonam',
//   'Gentamicin',
//   'Appearance of Semen',
//   'WBCs',
//   'RBCs',
//   'Abstinence Period',
//   'Collection Time',
//   'Analysis Time',
//   'Volume of Semen',
//   'Liquefaction',
//   'pH of Semen',
//   'Sperm Count / ml',
//   'Sperm Count / ejaculate',
//   'Motile',
//   'Progressive',
//   'Non Progressive',
//   'Immotile',
//   'Normal Form',
//   'Abnormal Form',
//   'Abnormal Head',
//   'Abnormal Neck 8 midpiece',
//   'Abnormal Tail',
//   'IgM Level',
//   'Varicella Zoster Ab IgG',
//   'IgE Total',
//   'CK-MB',
//   'CK-Total',
//   'Troponin I',
//   'Covid-19 IgG',
//   'COVID 19 IgM',
//   'COVID Antibody',
//   'COVID RT-PCR',
//   'Cycle Threshold N-gene',
//   'Cycle Threshold E-gene',
//   'Cycle Threshold RDRP',
//   'Cycle Threshold Orf 1a-gene',
//   'Toxoplasma Ab IgG',
//   'Toxoplasma Ab IgM',
//   'Rubella Ab IgG',
//   'Rubella Ab IgM',
//   'Alfa Feto Protein (AFP)',
//   'Growth Hormone',
//   'Cortisol Serum (p.m)',
//   'IL6',
//   'Timothy Grass',
//   'Cultivated Rye',
//   'Alder',
//   'Birch',
//   'Oak',
//   'Olive Tree',
//   'Commn Ragweed',
//   'Mugwort',
//   'Dermatophagoides Pter',
//   'Dermatophagoides Farinae',
//   'Cockroach, German',
//   'Cat',
//   'Dog',
//   'Horse',
//   'Camel',
//   'Penicillium Notatum',
//   'Caldosporium Herbarium',
//   'Aspergillus Fumigatus',
//   'Candida Albicans',
//   'Alternaria Alternata',
//   'Lupus Anticoagulant',
//   'Anti Cardiolipin IgM',
//   'Anti Cardiolipin IgG',
//   'Anti CCP',
//   'Anti DNA Abs.',
//   'Coombs Test (Indirect)',
//   'Anti-Phospholipid (IgG)',
//   'Anti-Phospholipid (IgM)',
//   'ANA-Titer',
//   'Anti Nuclear Antibodies (ANA)',
//   'Serum Copper',
//   'Aluminium',
//   'Antimony',
//   'Arsenic',
//   'Barium',
//   'Beryllium',
//   'Bismuth',
//   'Cadmium',
//   'Caesium',
//   'Chromium',
//   'Cobalt',
//   'Lead',
//   'Manganese',
//   'Mercury',
//   'Molybdenum',
//   'Nickel',
//   'Selenium',
//   'Silver',
//   'Strontium',
//   'Thallium',
//   'Tin',
//   'Uranium',
//   'Vanadium',
//   'Anti-TPO',
//   'Anti-Tissue Transglutaminase IgA',
//   'TG : HDL Ratio',
//   'MYELOBLAST',
//   'ABNORMAL CELLS',
//   'Lymphoblasts',
//   'HEMOPARASITES',
//   'LIC%',
//   'LIC#',
//   'Vitamin B3',
//   'Granulocytes',
//   'Absolute Granulocytes count',
//   'HBeAg',
//   'Gonorrhoea Ag',
//   'TROPONI - I',
//   'Myoglobin',
// ];

// const initialProfileData = {
//   'Total Cholesterol': 'Lipid Profile',
//   'HDL Cholesterol': 'Lipid Profile',
//   'LDL Cholesterol': 'Lipid Profile',
//   'HDL % of total': 'Lipid Profile',
//   'Direct LDL Cholestrol': 'Lipid Profile',
//   'HDL : LDL ratio': 'Lipid Profile',
//   'LDL : HDL ratio': 'Lipid Profile',
//   'APO B : APO A Ratio': 'Lipid Profile',
//   'APO A : APO B Ratio': 'Lipid Profile',
//   HsCRP: 'Lipid Profile',
//   'Lipoprotein (a) [Lp(a)]': 'Lipid Profile',
//   'Apo A': 'Lipid Profile',
//   Triglycerides: 'Lipid Profile',
//   VLDL: 'Lipid Profile',
//   'Apo B': 'Lipid Profile',
//   'Non - HDL Cholesterol': 'Lipid Profile',
//   'Total Cholesterol : HDL ratio': 'Lipid Profile',
//   'HDL :Total Cholesterol ratio': 'Lipid Profile',
//   'TG : HDL Ratio': 'Lipid Profile',
//   'Creatine-Kinase': 'Liver Profile',
//   'Total Bilirubin': 'Liver Profile',
//   'Direct Bilirubin': 'Liver Profile',
//   'Indirect Bilirubin': 'Liver Profile',
//   'SGOT (AST)': 'Liver Profile',
//   'SGOT (ALT)': 'Liver Profile',
//   'SGPT (ALT)': 'Liver Profile',
//   'SGPT (AST)': 'Liver Profile',
//   'AST / ALT Ratio': 'Liver Profile',
//   GGT: 'Liver Profile',
//   ALP: 'Liver Profile',
//   LDH: 'Liver Profile',
//   'Protein (Total)': 'Liver Profile',
//   Albumin: 'Liver Profile',
//   Globulin: 'Liver Profile',
//   'Albumin : Globulin ratio': 'Liver Profile',
//   'Albumin / Creatinine ratio': 'Liver Profile',
//   'Serum Creatinine': 'Kidney Profile',
//   'Blood Urea': 'Kidney Profile',
//   'Blood Urea Nitrogen (BUN)': 'Kidney Profile',
//   'Urea : Creatinine ratio': 'Kidney Profile',
//   'BUN : Creatinine ratio': 'Kidney Profile',
//   'Microalbumin : Creatinine ratio': 'Kidney Profile',
//   'SERUM BICARBONATE(D)': 'Kidney Profile',
//   'Glomerular Filtration Rate': 'Kidney Profile',
//   'Glomerular Filtration Rate CKD': 'Kidney Profile',
//   Albuminuria: 'Kidney Profile',
//   'Uric Acid': 'Electrolyte Profile',
//   Sodium: 'Electrolyte Profile',
//   Potassium: 'Electrolyte Profile',
//   Chloride: 'Electrolyte Profile',
//   Calcium: 'Electrolyte Profile',
//   'Corrected Calcium': 'Electrolyte Profile',
//   Zinc: 'Electrolyte Profile',
//   Phosphorus: 'Electrolyte Profile',
//   Magnesium: 'Electrolyte Profile',
//   'Phosphates/Urates': 'Electrolyte Profile',
//   'Serum Copper': 'Electrolyte Profile',
//   TSH: 'Thyroid Profile',
//   'TSH 3RD GENERATION': 'Thyroid Profile',
//   'Ultra TSH': 'Thyroid Profile',
//   'T3 (Triiodothyronine)': 'Thyroid Profile',
//   'T4 (Thyroxine)': 'Thyroid Profile',
//   'Free T3 (Triiodothyronine)': 'Thyroid Profile',
//   'Free T4 (Thyroxine)': 'Thyroid Profile',
//   'Parathyroid Hormone (PTH)': 'Thyroid Profile',
//   'Anti Thyroglobulin Abs': 'Thyroid Profile',
//   THYROGLOBULIN: 'Thyroid Profile',
//   'Blood Sugar (Fasting)': 'Diabetes Monitoring',
//   Amylase: 'Diabetes Monitoring',
//   'Blood Sugar (Postprandial)': 'Diabetes Monitoring',
//   'Blood Sugar (Random)': 'Diabetes Monitoring',
//   'Insulin (Fasting)': 'Diabetes Monitoring',
//   'Insulin (Postprandial)': 'Diabetes Monitoring',
//   'Insulin (Random)': 'Diabetes Monitoring',
//   'HbA1c (Glycosylated Haemoglobin)': 'Diabetes Monitoring',
//   'HbA1c (mmol/mol)': 'Diabetes Monitoring',
//   'eAG (Estimated Average Glucose)': 'Diabetes Monitoring',
//   Microalbumin: 'Diabetes Monitoring',
//   CRP: 'Inflammation',
//   IL6: 'Inflammation',
//   'RA factor (quantitative)': 'Arthritis Screening',
//   'RA factor (descriptive)': 'Arthritis Screening',
//   'ASO (quantitative)': 'Arthritis Screening',
//   'Ionized Calcium': 'Arthritis Screening',
//   'Non Ionized Calcium': 'Arthritis Screening',
//   Haemoglobin: 'Blood Counts',
//   'RBC count': 'Blood Counts',
//   Haematocrit: 'Blood Counts',
//   MCV: 'Blood Counts',
//   'MENTZER INDEX': 'Blood Counts',
//   MCH: 'Blood Counts',
//   MCHC: 'Blood Counts',
//   'Total Leukocyte Count': 'Blood Counts',
//   'Platelet Count': 'Blood Counts',
//   'Mean Platelet Component (MPC)': 'Blood Counts',
//   'Mean Platelet Mass (MPM)': 'Blood Counts',
//   MPV: 'Blood Counts',
//   PDW: 'Blood Counts',
//   'PDW - SD': 'Blood Counts',
//   'Large Unstained Cell': 'Blood Counts',
//   'Absolute Large Unstained Cell': 'Blood Counts',
//   'Peripheral smear': 'Blood Counts',
//   PCT: 'Blood Counts',
//   Neutrophils: 'Blood Counts',
//   'Abs. Neutrophil Count': 'Blood Counts',
//   'Segmented Neutrophils': 'Blood Counts',
//   'Neutrophil Lymphocyte Ratio': 'Blood Counts',
//   Eosinophils: 'Blood Counts',
//   'Abs. Eosinophil Count': 'Blood Counts',
//   Basophils: 'Blood Counts',
//   'Abs. Basophil Count': 'Blood Counts',
//   'Abs. Lymphocyte Count': 'Blood Counts',
//   'Abs. Monocyte Count': 'Blood Counts',
//   Lymphocytes: 'Blood Counts',
//   Polymorphs: 'Blood Counts',
//   'RBC Morphology': 'Blood Counts',
//   'WBC Morphology': 'Blood Counts',
//   'Platelate Morphology': 'Blood Counts',
//   'Reticulocytes Count': 'Blood Counts',
//   'CORRECTED RETICULOCYTE COUNT': 'Blood Counts',
//   'ABSOLUTE RETICULOCYTE COUNT': 'Blood Counts',
//   'RETICULOCYTE HEAMOGLOBIN EQUIVELENT': 'Blood Counts',
//   'IMMATURE PLATELET FRACTION': 'Blood Counts',
//   Monocytes: 'Blood Counts',
//   Myelocytes: 'Blood Counts',
//   'Meta-Myelocytes': 'Blood Counts',
//   'Pro-Myelocytes': 'Blood Counts',
//   'Atypical Lymphocytes (LUC)': 'Blood Counts',
//   'Band Cells': 'Blood Counts',
//   'Blast Cells': 'Blood Counts',
//   'HDW (Haemoglobin Distribution Width)': 'Blood Counts',
//   RDW: 'Blood Counts',
//   'RDW-CV': 'Blood Counts',
//   'RDW-SD': 'Blood Counts',
//   ESR: 'Blood Counts',
//   'RBC(PBS)': 'Blood Counts',
//   'WBC(PBS)': 'Blood Counts',
//   'Platelet(PBS)': 'Blood Counts',
//   'P-LCR': 'Blood Counts',
//   'P-LCC': 'Blood Counts',
//   'Immature Granulocytes': 'Blood Counts',
//   'Abs. Immature Granulocytes': 'Blood Counts',
//   'Nucleated red blood cells': 'Blood Counts',
//   'Absolute Nucleated red blood cells': 'Blood Counts',
//   'Polymorphonuclear Leukocytes': 'Blood Counts',
//   MYELOBLAST: 'Blood Counts',
//   'ABNORMAL CELLS': 'Blood Counts',
//   Lymphoblasts: 'Blood Counts',
//   HEMOPARASITES: 'Blood Counts',
//   'LIC%': 'Blood Counts',
//   'LIC#': 'Blood Counts',
//   Granulocytes: 'Blood Counts',
//   'Absolute Granulocytes count': 'Blood Counts',
//   Iron: 'Anemia Studies',
//   TIBC: 'Anemia Studies',
//   UIBC: 'Anemia Studies',
//   'G6PD (Quantitative)': 'Anemia Studies',
//   Ferritin: 'Anemia Studies',
//   'Transferrin Serum': 'Anemia Studies',
//   '% Saturation Transferrin': 'Anemia Studies',
//   'Sickle Cell Solubility Test-Sickling phenomenon': 'Anemia Studies',
//   'Content Haemoglobin (CH)': 'Anemia Studies',
//   'Serum Folate': 'Anemia Studies',
//   'Rapid Plasma Reagin': 'STD Profile',
//   HIV: 'STD Profile',
//   HBsAg: 'STD Profile',
//   'Hepatitis B': 'STD Profile',
//   HCV: 'STD Profile',
//   HPV: 'STD Profile',
//   'RAPID TP(SYPHILIS), VDRL': 'STD Profile',
//   'HIV AB': 'STD Profile',
//   'HIV Combo (Ag+Ab)': 'STD Profile',
//   VDRL: 'STD Profile',
//   'Herpes Semplex Virus I IgM': 'STD Profile',
//   'Herpes Semplex Virus II IgM': 'STD Profile',
//   'Herpes Semplex Virus': 'STD Profile',
//   'Chlamydia IgM': 'STD Profile',
//   'TPHA (TREPONEMA PALLIDUM)': 'STD Profile',
//   'High Vaginal Swab': 'STD Profile',
//   'Gonorrhoea Ag': 'STD Profile',
//   'Specific Gravity': 'Urinalysis',
//   pH: 'Urinalysis',
//   'Urinary Calcium': 'Urinalysis',
//   'Glucose in Urine': 'Urinalysis',
//   'URINE SPOT PROTEIN': 'Urinalysis',
//   'URINE SPOT CREATININE': 'Urinalysis',
//   'Urine Spot Microalbumin': 'Urinalysis',
//   'SPOT PROTEIN & CREATININE RATIO': 'Urinalysis',
//   'DEPOSITS :': 'Urinalysis',
//   'Urine Colour': 'Urinalysis',
//   Appearance: 'Urinalysis',
//   VOLUME: 'Urinalysis',
//   'Pus Cells': 'Urinalysis',
//   'Epithelial Cells': 'Urinalysis',
//   Urobilinogen: 'Urinalysis',
//   Protein: 'Urinalysis',
//   Ketone: 'Urinalysis',
//   Acetone: 'Urinalysis',
//   'Bile Salts': 'Urinalysis',
//   Blood: 'Urinalysis',
//   Nitrite: 'Urinalysis',
//   Casts: 'Urinalysis',
//   Crystals: 'Urinalysis',
//   Bacteria: 'Urinalysis',
//   'Yeast cells': 'Urinalysis',
//   'Amorphous Deposit': 'Urinalysis',
//   'Bile Pigment': 'Urinalysis',
//   Bilirubin: 'Urinalysis',
//   'Absorbic Acid': 'Urinalysis',
//   RBC: 'Urinalysis',
//   Leukocytes: 'Urinalysis',
//   'Leukocyte Esterase': 'Urinalysis',
//   'Sugar in Urine': 'Urinalysis',
//   'Other compounds in Urine': 'Urinalysis',
//   'Candida in Urine': 'Urinalysis',
//   'Urinary Creatinine': 'Urinalysis',
//   'Body Mass Index(BMI)': 'BMI & BP',
//   Pulse: 'BMI & BP',
//   Height: 'BMI & BP',
//   Weight: 'BMI & BP',
//   Systolic: 'BMI & BP',
//   Diastolic: 'BMI & BP',
//   'Vitamin D (25-Hydroxy)': 'Vitamin Profile',
//   'Vitamin B9': 'Vitamin Profile',
//   'Vitamin B12': 'Vitamin Profile',
//   'Vitamin B1': 'Vitamin Profile',
//   'Vitamin B-Complex': 'Vitamin Profile',
//   Biotin: 'Vitamin Profile',
//   Glutathione: 'Vitamin Profile',
//   'Fat Burner': 'Vitamin Profile',
//   'Vitamin B6': 'Vitamin Profile',
//   'Vitamin B2': 'Vitamin Profile',
//   'Vitamin A': 'Vitamin Profile',
//   'Vitamin E': 'Vitamin Profile',
//   'Vitamin C': 'Vitamin Profile',
//   'Vitamin B3': 'Vitamin Profile',
//   'Blood Group': 'Blood Group',
//   'Rh Factor': 'Blood Group',
//   'Rh Factor Quantitative': 'Blood Group',
//   'Prostate-Specific Antigen Free': 'Cancer Profile',
//   'Prostate-Specific Antigen Total': 'Cancer Profile',
//   'CA 19-9': 'Cancer Profile',
//   'CA 125': 'Cancer Profile',
//   'CA 15-3': 'Cancer Profile',
//   CEA: 'Cancer Profile',
//   'PT TEST': 'Blood Clotting',
//   'Thrombin Time': 'Blood Clotting',
//   'Activated Partial Thromboplastin Time': 'Blood Clotting',
//   'D-Dimer': 'Blood Clotting',
//   'PT (Control)': 'Prothrombin Time',
//   'Mean Normal Prothrombic Time': 'Prothrombin Time',
//   'Partial Thromboplastin Time': 'Prothrombin Time',
//   'Partial Thromboplastin Time(Control)': 'Prothrombin Time',
//   'PT-INR': 'Prothrombin Time',
//   'PT ratio': 'Prothrombin Time',
//   ISI: 'Prothrombin Time',
//   Index: 'Prothrombin Time',
//   'Dengue IgG': 'Viral Infections',
//   'Dengue IgM': 'Viral Infections',
//   'Dengue NS1': 'Viral Infections',
//   'Typhoid (Widal Test)': 'Viral Infections',
//   'Malaria (VIVAX)': 'Viral Infections',
//   'Malaria (FALCIPARUM)': 'Viral Infections',
//   'Varicella Zoster Ab IgG': 'Viral Infections',
//   Prolactin: 'Pregnancy',
//   'Urine for Beta HCG': 'Pregnancy',
//   'Serum for Beta HCG': 'Pregnancy',
//   'Beta HCG': 'Pregnancy',
//   'Pregnancy Test Serum': 'Pregnancy',
//   'Leutinizing Harmone': 'Fertility Profile',
//   'Estradiol (E2)': 'Fertility Profile',
//   FSH: 'Fertility Profile',
//   Progesterone: 'Fertility Profile',
//   AMH: 'Fertility Profile',
//   Estrogen: 'Fertility Profile',
//   Oestradiol: 'Fertility Profile',
//   SHBG: 'Fertility Profile',
//   'DHEA-Sulphate': 'Fertility Profile',
//   Testosterone: 'Fertility Profile',
//   'Testosterone(Free)': 'Fertility Profile',
//   Androstenedione: 'Fertility Profile',
//   AMH2: 'undefined',
//   RBCs: 'undefined',
//   'Anti Nuclear Antibodies (ANA)': 'undefined',
//   '17-HydroxyProgesterone': 'Hormones',
//   'Cortosol Random': 'Hormones',
//   'Growth Hormone': 'Hormones',
//   'Cortisol Serum (p.m)': 'Hormones',
//   Homocysteine: 'Cardiac Profile',
//   'CK-MB': 'Cardiac Profile',
//   'CK-Total': 'Cardiac Profile',
//   'Troponin I': 'Cardiac Profile',
//   'TROPONI - I': 'Cardiac Profile',
//   Myoglobin: 'Cardiac Profile',
//   HBsAb: 'Hepatitis',
//   'HBcAb Total': 'Hepatitis',
//   'HBcAb IgM': 'Hepatitis',
//   'HCV AB': 'Hepatitis',
//   'Anti HCV': 'Hepatitis',
//   'HAV AB IgM': 'Hepatitis',
//   'HAV AB': 'Hepatitis',
//   'HAV AB Total': 'Hepatitis',
//   'CMV AB IgG': 'Hepatitis',
//   'CMV AB IgM': 'Hepatitis',
//   HBeAg: 'Hepatitis',
//   'Urea Breath Test': 'Bacterial Infections',
//   Typhidot: 'Bacterial Infections',
//   'WIDAL TEST (SLIDE METHOD)': 'Bacterial Infections',
//   'Gram Stain': 'Bacterial Infections',
//   'Helicobacter Antigen in Stool': 'Bacterial Infections',
//   'Helicobacter Pylori Abs.(IgM)': 'Bacterial Infections',
//   'Helicobacter Pylori Abs.(IgG)': 'Bacterial Infections',
//   'Egg White Allergy': 'Allergy Panel',
//   'Egg Yolk Allergy': 'Allergy Panel',
//   'Cow Milk Allergy': 'Allergy Panel',
//   'Chocolate Allergy': 'Allergy Panel',
//   'Wheat Flour Allergy': 'Allergy Panel',
//   'Soy Bean Allergy': 'Allergy Panel',
//   "Baker's Yeast Allergy": 'Allergy Panel',
//   'Nut Mix Allergy': 'Allergy Panel',
//   'Peanut Allergy': 'Allergy Panel',
//   'Orange Allergy': 'Allergy Panel',
//   'Strawberry Allergy': 'Allergy Panel',
//   'Banana Allergy': 'Allergy Panel',
//   'Mango Allergy': 'Allergy Panel',
//   'Tomato Allergy': 'Allergy Panel',
//   'Carrot Allergy': 'Allergy Panel',
//   'Onion Allergy': 'Allergy Panel',
//   'Chicken Allergy': 'Allergy Panel',
//   'Lamb Allergy': 'Allergy Panel',
//   'Codfish Allergy': 'Allergy Panel',
//   'Shrimp/Prawn Allergy': 'Allergy Panel',
//   HbF: 'Hemoglobin Electrophoresis',
//   HbA: 'Hemoglobin Electrophoresis',
//   HbS: 'Hemoglobin Electrophoresis',
//   HbC: 'Hemoglobin Electrophoresis',
//   HbD: 'Hemoglobin Electrophoresis',
//   Unidentified: 'Hemoglobin Electrophoresis',
//   HbA2: 'Hemoglobin Electrophoresis',
//   Lipase: 'Pancreas',
//   'Occult Blood in Stool': 'Stool Analysis',
//   'Stool Color': 'Stool Analysis',
//   'Stool Consistency': 'Stool Analysis',
//   'Stool Odour': 'Stool Analysis',
//   'Stool Mucus': 'Stool Analysis',
//   'Visible Blood': 'Stool Analysis',
//   'Red Blood Cell': 'Stool Analysis',
//   'Pus Cells in Stool': 'Stool Analysis',
//   Trophozoites: 'Stool Analysis',
//   Cysts: 'Stool Analysis',
//   OVA: 'Stool Analysis',
//   'Adult Parasite': 'Stool Analysis',
//   'Undigested Food': 'Stool Analysis',
//   'Stool Specimen Type': 'Stool Analysis',
//   'Organism Isolated': 'Stool Analysis',
//   'Semen Type': 'Semen Culture and Sensitivity',
//   'Primary Smear of Semen': 'Semen Culture and Sensitivity',
//   Ampicillin: 'Semen Culture and Sensitivity',
//   Amoxycillin: 'Semen Culture and Sensitivity',
//   'Amoxycillin + Clavulanic Acid': 'Semen Culture and Sensitivity',
//   'Ampicillin + Salbactum': 'Semen Culture and Sensitivity',
//   Piperacillin: 'Semen Culture and Sensitivity',
//   Ticarcillin: 'Semen Culture and Sensitivity',
//   Amikacin: 'Semen Culture and Sensitivity',
//   Cotrimoxazole: 'Semen Culture and Sensitivity',
//   Tetracycline: 'Semen Culture and Sensitivity',
//   Ciprofloxacin: 'Semen Culture and Sensitivity',
//   Levofloxacine: 'Semen Culture and Sensitivity',
//   Oflaxacine: 'Semen Culture and Sensitivity',
//   Imipenem: 'Semen Culture and Sensitivity',
//   Meropenem: 'Semen Culture and Sensitivity',
//   'Piperacillin + Tazobactum': 'Semen Culture and Sensitivity',
//   Norfloxacin: 'Semen Culture and Sensitivity',
//   'Ticarcillin + Clavulanic Acid': 'Semen Culture and Sensitivity',
//   Cefazolin: 'Semen Culture and Sensitivity',
//   Cefuroxime: 'Semen Culture and Sensitivity',
//   Cefoxitin: 'Semen Culture and Sensitivity',
//   Cefitriaxone: 'Semen Culture and Sensitivity',
//   Cefotaxime: 'Semen Culture and Sensitivity',
//   Ceftazidime: 'Semen Culture and Sensitivity',
//   'Cefoperazone + Sulbactum': 'Semen Culture and Sensitivity',
//   Cefepime: 'Semen Culture and Sensitivity',
//   Aztreonam: 'Semen Culture and Sensitivity',
//   Gentamicin: 'Semen Culture and Sensitivity',
//   'Appearance of Semen': 'Semen Analysis',
//   WBCs: 'Semen Analysis',
//   'Abstinence Period': 'Semen Analysis',
//   'Collection Time': 'Semen Analysis',
//   'Analysis Time': 'Semen Analysis',
//   'Volume of Semen': 'Semen Analysis',
//   Liquefaction: 'Semen Analysis',
//   'pH of Semen': 'Semen Analysis',
//   'Sperm Count / ml': 'Semen Analysis',
//   'Sperm Count / ejaculate': 'Semen Analysis',
//   Motile: 'Semen Analysis',
//   Progressive: 'Semen Analysis',
//   'Non Progressive': 'Semen Analysis',
//   Immotile: 'Semen Analysis',
//   'Normal Form': 'Semen Analysis',
//   'Abnormal Form': 'Semen Analysis',
//   'Abnormal Head': 'Semen Analysis',
//   'Abnormal Neck 8 midpiece': 'Semen Analysis',
//   'Abnormal Tail': 'Semen Analysis',
//   'IgM Level': 'Immunity',
//   'IgE Total': 'Immunity',
//   'Covid-19 IgG': 'COVID',
//   'COVID 19 IgM': 'COVID',
//   'COVID Antibody': 'COVID',
//   'COVID RT-PCR': 'COVID',
//   'Cycle Threshold N-gene': 'COVID',
//   'Cycle Threshold E-gene': 'COVID',
//   'Cycle Threshold RDRP': 'COVID',
//   'Cycle Threshold Orf 1a-gene': 'COVID',
//   'Toxoplasma Ab IgG': 'Infectious Diseases',
//   'Toxoplasma Ab IgM': 'Infectious Diseases',
//   'Rubella Ab IgG': 'Infectious Diseases',
//   'Rubella Ab IgM': 'Infectious Diseases',
//   'Alfa Feto Protein (AFP)': 'Tumour Marker Test',
//   'Timothy Grass': 'Timothy Grass',
//   'Cultivated Rye': 'Cultivated Rye',
//   Alder: 'Alder',
//   Birch: 'Birch',
//   Oak: 'Oak',
//   'Olive Tree': 'Olive Tree',
//   'Commn Ragweed': 'Commn Ragweed',
//   Mugwort: 'Mugwort',
//   'Dermatophagoides Pter': 'Dermatophagoides Pter',
//   'Dermatophagoides Farinae': 'Dermatophagoides Farinae',
//   'Cockroach, German': 'Cockroach, German',
//   Cat: 'Cat',
//   Dog: 'Dog',
//   Horse: 'Horse',
//   Camel: 'Camel',
//   'Penicillium Notatum': 'Penicillium Notatum',
//   'Caldosporium Herbarium': 'Caldosporium Herbarium',
//   'Aspergillus Fumigatus': 'Aspergillus Fumigatus',
//   'Candida Albicans': 'Candida Albicans',
//   'Alternaria Alternata': 'Alternaria Alternata',
//   'Lupus Anticoagulant': 'Autoimmune Disorders',
//   'Anti Cardiolipin IgM': 'Autoimmune Disorders',
//   'Anti Cardiolipin IgG': 'Autoimmune Disorders',
//   'Anti CCP': 'Autoimmune Disorders',
//   'Anti DNA Abs.': 'Autoimmune Disorders',
//   'Coombs Test (Indirect)': 'Autoimmune Disorders',
//   'Anti-Phospholipid (IgG)': 'Autoimmune Disorders',
//   'Anti-Phospholipid (IgM)': 'Autoimmune Disorders',
//   'ANA-Titer': 'Autoimmune Disorders',
//   'Anti-TPO': 'Autoimmune Disorders',
//   'Anti-Tissue Transglutaminase IgA': 'Autoimmune Disorders',
//   Aluminium: 'Toxic elements',
//   Antimony: 'Toxic elements',
//   Arsenic: 'Toxic elements',
//   Barium: 'Toxic elements',
//   Beryllium: 'Toxic elements',
//   Bismuth: 'Toxic elements',
//   Cadmium: 'Toxic elements',
//   Caesium: 'Toxic elements',
//   Chromium: 'Toxic elements',
//   Cobalt: 'Toxic elements',
//   Lead: 'Toxic elements',
//   Manganese: 'Toxic elements',
//   Mercury: 'Toxic elements',
//   Molybdenum: 'Toxic elements',
//   Nickel: 'Toxic elements',
//   Selenium: 'Toxic elements',
//   Silver: 'Toxic elements',
//   Strontium: 'Toxic elements',
//   Thallium: 'Toxic elements',
//   Tin: 'Toxic elements',
//   Uranium: 'Toxic elements',
//   Vanadium: 'Toxic elements',
// };

const existingProfiles = [
  "Lipid Profile",
  "Liver Profile",
  "Kidney Profile",
  "Electrolyte Profile",
  "Thyroid Profile",
  "Diabetes Monitoring",
  "Inflammation",
  "Arthritis Screening",
  "Blood Counts",
  "Anemia Studies",
  "STD Profile",
  "Urinalysis",
  "BMI & BP",
  "Vitamin Profile",
  "Blood Group",
  "Cancer Profile",
  "Blood Clotting",
  "Prothrombin Time",
  "Viral Infections",
  "Pregnancy",
  "Fertility Profile",
  "Hormones",
  "Cardiac Profile",
  "Hepatitis",
  "Cockroach, German",
  "Cat",
  "Dog",
  "Horse",
  "Camel",
  "Penicillium Notatum",
  "Caldosporium Herbarium",
  "Aspergillus Fumigatus",
  "Candida Albicans",
  "Alternaria Alternata",
  "Autoimmune Disorders",
  "Toxic elements",
  "Bacterial Infections",
  "Stool Analysis",
  "COVID",
];

const ProfileMapping = () => {
  const countPerPage = 9; // For Pagination Purpose.

  const [currentPage, setCurrentPage] = useState(1);
  const [parameterList, setParameterList] = useState({});
  const [paginatedData, setPaginatedData] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);

  const getExistingProfileMapping = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const existingMappingConfig = {
      headers: {
        authorization: idToken,
      },
      url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/mapping/profile",
      method: "GET",
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const existingProfileMappingResponse = await axios(existingMappingConfig);
    return existingProfileMappingResponse;
  };

  useEffect(() => {
    (async function () {
      setPageLoading(true);
      const response = await getExistingProfileMapping();
      const statusCode = response.status;
      if (statusCode === 401) {
        await regenerateToken();
        const response = await getExistingProfileMapping();
        if (response.data.isSuccess) {
          // update State hook
          setParameterList(response.data.data.profileMapping);
          setPaginatedData(
            Object.keys(response.data.data.profileMapping).slice(
              0,
              countPerPage
            )
          );
          setFilteredTests(Object.keys(response.data.data.profileMapping));
        }
      } else {
        setParameterList(response.data.data.profileMapping);
        setPaginatedData(
          Object.keys(response.data.data.profileMapping).slice(0, countPerPage)
        );
        setFilteredTests(Object.keys(response.data.data.profileMapping));
      }
      setPageLoading(false);
      console.log("response", response);
    })();
  }, []);

  const updatingPages = (p) => {
    const finalCount = p * countPerPage;
    const initialCount = finalCount - countPerPage;
    setPaginatedData(filteredTests.slice(initialCount, finalCount));
    setCurrentPage(p);
  };

  const filteringTests = (search) => {
    const tests = Object.keys(parameterList).filter((each) =>
      each.toLowerCase().includes(search.toLowerCase())
    );

    setFilteredTests(tests);
    setPaginatedData(tests.slice(0, countPerPage));
  };

  const handlingUpdate = (parameter, profile) => {
    const profileMappingObject = parameterList;
    profileMappingObject[parameter] = profile;
    setParameterList(profileMappingObject);
  };

  const handleConfirmation = async () => {
    console.log("finalList", parameterList);
    setUpdateLoader(true);
    const response = await apiCallForUpdatingProfileMapping();
    if (response.status === 401) {
      await regenerateToken();
      const response = await apiCallForUpdatingProfileMapping();
      if (response.data.isSuccess) {
        setUpdateLoader(false);
        window.location.reload();
      }
    } else {
      if (response.data.isSuccess) {
        window.location.reload();
      }
    }
    setUpdateLoader(false);
  };

  const apiCallForUpdatingProfileMapping = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const config = {
      headers: {
        authorization: idToken,
      },
      url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/mapping/profile",
      method: "POST",
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
      data: JSON.stringify(parameterList),
    };
    const response = await axios(config);
    return response;
  };

  const loader = () => {
    return (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "45vh" }}
      >
        <CircularProgress />
      </Stack>
    );
  };
  return (
    <>
      <Box
        mt={1.4}
        sx={{
          background: "#fff",
          border: "1px solid #D7D7D7",
          borderRadius: "6px 6px 0px 0px",
        }}
      >
        <Stack
          sx={{ padding: "18px 16px", borderBottom: "1px solid #D7D7D7" }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h4">Profile Mapping</Typography>
          <Stack
            sx={{ width: "30%", background: "#F5F6F7", borderRadius: "8px" }}
            direction="row"
            alignItems={"center"}
          >
            <Box sx={{ padding: "8px 0px 8px 12px" }}>
              <SearchIcon />
            </Box>
            <Form.Control
              style={{ border: "none", background: "inherit" }}
              placeholder="Search for the parameters"
              onChange={(event) => filteringTests(event.target.value)}
            />
          </Stack>
        </Stack>
        {pageLoading ? (
          loader()
        ) : (
          <Box>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{
                background: "rgba(244, 247, 252, 0.75)",
                padding: "12px 25px",
              }}
            >
              <Typography variant="body9" sx={{ width: "50%" }}>
                Master Test List
              </Typography>
              <Typography variant="body9">Profile Name</Typography>
            </Stack>
            {/* Rendering of Parameters happens below */}
            <Stack
              px={1.4}
              py={2}
              gap={2}
              sx={{
                maxHeight: "30vh",
                overflowY: "auto",
                maxHeight: "calc(100vh - 450px)",
              }}
            >
              {paginatedData.map((test, index) => (
                <Box key={test} sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="body9"
                    sx={{
                      width: "40%",
                      padding: "10px 16px",
                      fontWeight: 400,
                    }}
                  >
                    {test}
                  </Typography>
                  <Typography
                    variant="body9"
                    sx={{ width: "5%", padding: "10px 16px" }}
                  >
                    <ArrowRightAltIcon />
                  </Typography>
                  <select
                    id="profile Drop Down"
                    key={test}
                    className="form-control form-select"
                    style={{
                      maxWidth: "45%",
                      marginLeft: "15px",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                    defaultValue={
                      parameterList[test] ? parameterList[test] : ""
                    }
                    onChange={(event) => {
                      handlingUpdate(test, event.target.value);
                    }}
                  >
                    <option hidden>Select Profile</option>
                    {existingProfiles.map((eachProfile) => (
                      <option key={`${eachProfile}`} value={eachProfile}>
                        {eachProfile}
                      </option>
                    ))}
                  </select>
                </Box>
              ))}
            </Stack>

            {/* pagination & confirm buttons */}
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              sx={{
                background: "rgba(244, 247, 252, 0.75)",
                padding: "12px 24px",
              }}
            >
              <Pagination
                //   simple
                onChange={(p) => updatingPages(p)}
                pageSize={10}
                total={filteredTests.length}
                current={currentPage}
                showTitle={false}
              />
              {/* Buttons for Confirmation below */}

              <button
                onClick={() => handleConfirmation()}
                // disabled={saveOption}
                className="btn btn-primary"
                style={{ width: "140px", borderRadius: "8px" }}
              >
                {updateLoader ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    style={{ color: "#fff" }}
                  ></Spinner>
                ) : (
                  "Confirm Changes"
                )}
              </button>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfileMapping;
