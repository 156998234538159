import { useState, useEffect } from "react";

import { Spinner, InputGroup, Form } from "react-bootstrap";
import regenerateToken from "../regenerateTokens";

import axios from "axios";
import Cookies from "js-cookie";
import { Box, Stack, Typography } from "@mui/material";
import { TitleBox } from "../../../common/BoxAbstraction";
import LabelElement from "../../../common/LabelElement";

// Don't Delete this
const sampleStateObjectKeys = [
  // 'reportType',
  "show Summary",
  "historical",
  "show QrEnd",
  "show bodySummary",
  "generate PrintPdf",
  "show Additional RecommendationsPage",
  "show Only Abnormal",
  "show Accredation",
  "show JsonNames",
  "show cardBackgroundColor",
  "generate CovePage",
  "dynamic CoverPage",
  // 'showPatientIntro',
  "show Doc Sign",
  // 'clientSpecificTips',
  // 'useClientColors',
  "show Additional Bookings Page",
  // 'pullClientData',
  "feedback Flag",
  // 'summaryType',
];

const stateOptions = {
  general: ["historical"],
  addons: [
    "show Summary",
    "show bodySummary",
    "show Additional RecommendationsPage",
    "show Additional Bookings Page",
    "dynamic CoverPage",
    "generate CovePage",
  ],
  content: [
    "show JsonNames",
    "show Doc Sign",
    "feedback Flag",
    "show QrEnd",
    "show Accredation",
  ],
  styling: ["show cardBackgroundColor"],
};

const SmartReportConfig = () => {
  // const [stateObject, setStateObject] = useState({});
  const [saveOption, setSaveOption] = useState(false);

  // hooks for colors

  const [stateConfiguration, setStateConfiguration] = useState({
    showbodySummary: true,
    reportType: "dynamic",
    showAdditionalRecommendationsPage: true,
    showcoloredValues: false,
    showBoldAbnormal: true,
    showSummary: true,
    showJsonNames: true,
    base64assets: "default",
    cardBackgroundColorColored: "#F5F9FC",
    cardBackgroundColorGrayscaled: "#FAFAFA",
    headingColor: "#43536E",
    footerTemplateType: "default",
    useClientColors: false,
    generateCovePage: true,
    dynamicCoverPage: false,
    feedbackFlag: false,
    baseAssetPath:
      "https://reportsvg.s3.ap-south-1.amazonaws.com/reportSvg/pfh/svg/",
    baseAssetNormalPath:
      "https://reportsvg.s3.ap-south-1.amazonaws.com/reportSvg/pfh/svg/body/",
    baseAssetAbnormalPath:
      "https://reportsvg.s3.ap-south-1.amazonaws.com/reportSvg/pfh/svg/bodyAbnormal/",
  });

  // hooks for loaders
  const [loading, setLoading] = useState(false);
  const [updatingStateLoading, setUpdatingStateLoading] = useState(false);

  const getStateConfiguration = async () => {
    const idToken = localStorage.getItem("idTokenBilling");

    const existingStateConfigBody = {
      headers: {
        authorization: idToken,
      },
      method: "GET",
      url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/config/state",
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const existingStateConfigResponse = await axios(existingStateConfigBody);
    return existingStateConfigResponse;
  };

  const updateStateConfiguration = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    const stateConfigurationResponseConfig = {
      headers: {
        authorization: idToken,
      },
      method: "POST",
      url: "https://ap94204xve.execute-api.ap-south-1.amazonaws.com/prod/config/state",
      data: JSON.stringify(stateConfiguration),
      validateStatus: function (status) {
        return status >= 200 && status <= 401;
      },
    };
    const stateConfigurationResponse = await axios(
      stateConfigurationResponseConfig
    );
    return stateConfigurationResponse;
  };

  useEffect(() => {
    (async function RetrieveExistingColorConfiguration() {
      setLoading(true);
      try {
        const existingStateConfigResponse = await getStateConfiguration();
        console.log(
          "ExistingStateConfigFile @ First Trail",
          existingStateConfigResponse
        );
        const statusCode = existingStateConfigResponse.status;
        if (statusCode === 401) {
          console.log("Regenerating ID Token");
          // API Call is to be made for regenerating the ID Token.
          await regenerateToken();
          const existingStateConfigResponse = await getStateConfiguration();
          console.log(
            "ExistingStateConfigFile @ Second Trail",
            existingStateConfigResponse
          );
          setStateConfiguration(
            existingStateConfigResponse.data.data.stateData
          );
          setLoading(false);
        } else {
          setStateConfiguration(
            existingStateConfigResponse.data.data.stateData
          );
          setLoading(false);
        }
      } catch (error) {
        console.log("error", error.message);
        // console.log('existingStateConfigBody', existingStateConfigBody);
      }
    })();
  }, []);

  // Request structure
  const handlingUpdate = (stateObj, value) => {
    setSaveOption(true);
    setStateConfiguration((prevObject) => {
      return {
        ...prevObject,
        [stateObj.replace(/ /g, "")]: ["true", "false"].includes(value)
          ? value === "true"
            ? true
            : value !== "false"
            ? value
            : false
          : value,
      };
    });
  };

  const handlingRequest = async () => {
    // Needs to create base condition for checking mandatory fields

    const requestBody = {
      stateData: stateConfiguration,
    };
    console.log("requestBody", requestBody);

    // API call for Updating State Configuration
    setUpdatingStateLoading(true);
    try {
      const stateConfigurationResponse = await updateStateConfiguration();
      if (stateConfigurationResponse.status === 401) {
        console.log("Regenerating ID Token");
        await regenerateToken();
        const stateConfigurationResponse = await updateStateConfiguration();
        setUpdatingStateLoading(false);
        console.log(
          stateConfigurationResponse.data.data.acknowledged
            ? "Successfully Updated the State configuration @ first trail"
            : "Not Updated Successfully"
        );
      } else {
        console.log("stateConfigurationResponse", stateConfigurationResponse);
        setUpdatingStateLoading(false);
        console.log(
          stateConfigurationResponse.data.data.acknowledged
            ? `Successfully Updated the State configuration @ first trail`
            : "Not Updated Successfully"
        );
      }
    } catch (e) {
      console.log("Error in updating report Configuration", e.message);
    }
  };

  const newUi = () => {
    return (
      <Box mt={3}>
        <Box sx={{ height: "calc(100vh - 340px)", overflowY: "auto" }}>
          {/* general */}
          <Box>
            <LabelElement title="General" />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              mt={1.4}
            >
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  height: "35px",
                }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b style={{ width: "49%", textTransform: "capitalize" }}>
                    report Type
                  </b>
                </InputGroup.Text>
                <select
                  id="reportType"
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate("reportType", event.target.value)
                  }
                  defaultValue={stateConfiguration.reportType}
                >
                  <option hidden>Select an Option</option>
                  <option value="dynamic">Dynamic</option>
                  <option value="advanced">Advanced</option>
                  <option value="compact">Compact</option>
                </select>
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  height: "35px",
                }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b style={{ width: "49%", textTransform: "capitalize" }}>
                    historical
                  </b>
                </InputGroup.Text>
                <select
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate("historical", event.target.value)
                  }
                  value={stateConfiguration["historical".replace(/ /g, "")]}
                >
                  <option hidden>Select an Option</option>
                  <option value={true}>Required</option>
                  <option value={false}>Not Required</option>
                </select>
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  height: "35px",
                }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b style={{ width: "49%", textTransform: "capitalize" }}>
                    Report Language
                  </b>
                </InputGroup.Text>
                <select
                  id="curLang"
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate("curLang", event.target.value)
                  }
                  defaultValue={
                    stateConfiguration.curLang ? stateConfiguration.curLang : ""
                  }
                >
                  <option hidden>Select an Option</option>
                  <option value="en">English</option>
                  <option
                    hidden={
                      stateConfiguration.reportType === "compact" ? false : true
                    }
                    value="hi"
                  >
                    Hindi
                  </option>
                  <option
                    hidden={
                      stateConfiguration.reportType === "compact" ? false : true
                    }
                    value="ar"
                  >
                    Arabic
                  </option>
                </select>
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  height: "35px",
                }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b
                    style={{
                      width: "49%",
                      textTransform: "capitalize",
                    }}
                  >
                    Fall back language
                  </b>
                </InputGroup.Text>
                <select
                  id="fallbackLang"
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate("fallbackLang", event.target.value)
                  }
                  defaultValue={
                    stateConfiguration.fallbackLang
                      ? stateConfiguration.fallbackLang
                      : ""
                  }
                >
                  <option hidden>Select an Option</option>
                  <option value="en">English</option>
                  <option value="hi">Hindi</option>
                  <option value="ar">Arabic</option>
                </select>
              </InputGroup>
            </Stack>
          </Box>

          {/* Add-ons */}
          <Box mt={3}>
            <LabelElement title="Add-ons" />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              mt={1.4}
            >
              {stateOptions.addons.map((stateObject, index) => (
                <InputGroup
                  key={stateObject}
                  className="mb-3 mx-1"
                  style={{
                    width: "49%",
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    height: "35px",
                  }}
                >
                  <InputGroup.Text id="inputGroup-sizing-default">
                    <b style={{ width: "49%", textTransform: "capitalize" }}>
                      {stateObject}
                    </b>
                  </InputGroup.Text>
                  <select
                    className="form-control form-select"
                    onChange={(event) =>
                      handlingUpdate(stateObject, event.target.value)
                    }
                    value={stateConfiguration[stateObject.replace(/ /g, "")]}
                  >
                    <option hidden>Select an Option</option>
                    <option value={true}>Required</option>
                    <option value={false}>Not Required</option>
                  </select>
                </InputGroup>
              ))}
            </Stack>
          </Box>

          {/* report content  */}
          <Box mt={3}>
            <LabelElement title="Add-ons" />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              mt={1.4}
            >
              {stateOptions.content.map((stateObject, index) => (
                <InputGroup
                  key={stateObject}
                  className="mb-3 mx-1"
                  style={{
                    width: "49%",
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    height: "35px",
                  }}
                >
                  <InputGroup.Text id="inputGroup-sizing-default">
                    <b style={{ width: "49%", textTransform: "capitalize" }}>
                      {stateObject}
                    </b>
                  </InputGroup.Text>
                  <select
                    className="form-control form-select"
                    onChange={(event) =>
                      handlingUpdate(stateObject, event.target.value)
                    }
                    value={stateConfiguration[stateObject.replace(/ /g, "")]}
                  >
                    <option hidden>Select an Option</option>
                    <option value={true}>Required</option>
                    <option value={false}>Not Required</option>
                  </select>
                </InputGroup>
              ))}
            </Stack>
          </Box>

          {/* styling options */}
          <Box mt={3}>
            <LabelElement title="Styling Options" />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              mt={1.4}
            >
              {stateOptions.styling.map((stateObject, index) => (
                <InputGroup
                  key={stateObject}
                  className="mb-3 mx-1"
                  style={{
                    width: "49%",
                    textTransform: "capitalize",
                    borderRadius: "8px",
                    height: "35px",
                  }}
                >
                  <InputGroup.Text id="inputGroup-sizing-default">
                    <b style={{ width: "49%", textTransform: "capitalize" }}>
                      {stateObject}
                    </b>
                  </InputGroup.Text>
                  <select
                    className="form-control form-select"
                    onChange={(event) =>
                      handlingUpdate(stateObject, event.target.value)
                    }
                    value={stateConfiguration[stateObject.replace(/ /g, "")]}
                  >
                    <option hidden>Select an Option</option>
                    <option value={true}>Required</option>
                    <option value={false}>Not Required</option>
                  </select>
                </InputGroup>
              ))}
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  height: "35px",
                }}
              >
                <InputGroup.Text>
                  <b>Card Background Color (Colored)</b>
                </InputGroup.Text>
                <InputGroup.Text>
                  {stateConfiguration.cardBackgroundColorColored}
                </InputGroup.Text>
                <input
                  value={stateConfiguration.cardBackgroundColorColored}
                  onChange={(event) => {
                    setSaveOption(true);

                    handlingUpdate(
                      "cardBackgroundColorColored",
                      event.target.value
                    );
                  }}
                  type="color"
                  className="form-control"
                  // list='colors'
                  placeholder="Select Color"
                  style={{ height: "100%" }}
                />
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                  height: "35px",
                }}
              >
                <InputGroup.Text>
                  <b>Card Background Color (GreyScaled)</b>
                </InputGroup.Text>
                <InputGroup.Text>
                  {stateConfiguration.cardBackgroundColorGrayscaled}
                </InputGroup.Text>
                <input
                  value={stateConfiguration.cardBackgroundColorGrayscaled}
                  onChange={(event) => {
                    setSaveOption(true);
                    handlingUpdate(
                      "cardBackgroundColorGrayscaled",
                      event.target.value
                    );
                  }}
                  type="color"
                  className="form-control "
                  // list='colors' // for displaying standard colors
                  // id='GFG_Color'
                  placeholder="Select Color"
                  style={{ height: "100%" }}
                />
              </InputGroup>
            </Stack>
          </Box>

          {/* resource & asset options */}
          <Box mt={3}>
            <LabelElement title="Resource and Asset Options" />
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              mt={1.4}
            >
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  height: "35px",
                }}
              >
                <InputGroup.Text>
                  <b>Base64 Assets</b>
                </InputGroup.Text>
                <Form.Control
                  value={stateConfiguration.base64assets}
                  onChange={(e) => {
                    setSaveOption(true);
                    handlingUpdate("base64assets", e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  height: "35px",
                }}
              >
                <InputGroup.Text>
                  <b>Base asset path</b>
                </InputGroup.Text>
                <Form.Control
                  value={stateConfiguration.baseAssetPath}
                  onChange={(e) => {
                    setSaveOption(true);
                    handlingUpdate("baseAssetPath", e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  height: "35px",
                }}
              >
                <InputGroup.Text>
                  <b>Base asset normal path</b>
                </InputGroup.Text>
                <Form.Control
                  value={stateConfiguration.baseAssetNormalPath}
                  onChange={(e) => {
                    setSaveOption(true);
                    handlingUpdate("baseAssetNormalPath", e.target.value);
                  }}
                />
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{
                  width: "49%",
                  textTransform: "capitalize",
                  height: "35px",
                }}
              >
                <InputGroup.Text>
                  <b>Base asset abnormal path</b>
                </InputGroup.Text>
                <Form.Control
                  value={stateConfiguration.baseAssetAbnormalPath}
                  onChange={(e) => {
                    setSaveOption(true);
                    handlingUpdate("baseAssetAbnormalPath", e.target.value);
                  }}
                />
              </InputGroup>
            </Stack>
          </Box>

          {/* buttons */}
        </Box>
        <Box mt={3}>
          <button
            onClick={() => setSaveOption(false)}
            className="btn btn-primary"
            disabled={!saveOption}
            style={{ borderRadius: "12px" }}
          >
            Save Changes
          </button>
          <button
            onClick={() => handlingRequest()}
            disabled={saveOption}
            className="btn btn-primary mx-4"
            style={{ width: "132px", borderRadius: "12px" }}
          >
            {updatingStateLoading ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ color: "#fff" }}
              ></Spinner>
            ) : (
              "Confirm Changes"
            )}
          </button>
        </Box>
      </Box>
    );
  };

  return <div>{newUi()}</div>;

  return (
    <>
      <div className="container-fluid  mt-3">
        <Form>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {/* Input for report type, language */}
            <>
              <InputGroup
                className="mb-3 mx-1"
                style={{ width: "49%", textTransform: "capitalize" }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b style={{ width: "49%", textTransform: "capitalize" }}>
                    report Type
                  </b>
                </InputGroup.Text>
                <select
                  id="reportType"
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate("reportType", event.target.value)
                  }
                  defaultValue={stateConfiguration.reportType}
                >
                  <option hidden>Select an Option</option>
                  <option value="dynamic">Dynamic</option>
                  <option value="advanced">Advanced</option>
                  <option value="compact">Compact</option>
                </select>
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{ width: "49%", textTransform: "capitalize" }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b style={{ width: "49%", textTransform: "capitalize" }}>
                    Report Language
                  </b>
                </InputGroup.Text>
                <select
                  id="curLang"
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate("curLang", event.target.value)
                  }
                  defaultValue={
                    stateConfiguration.curLang ? stateConfiguration.curLang : ""
                  }
                >
                  <option hidden>Select an Option</option>
                  <option value="en">English</option>
                  <option
                    hidden={
                      stateConfiguration.reportType === "compact" ? false : true
                    }
                    value="hi"
                  >
                    Hindi
                  </option>
                  <option
                    hidden={
                      stateConfiguration.reportType === "compact" ? false : true
                    }
                    value="ar"
                  >
                    Arabic
                  </option>
                </select>
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{ width: "49%", textTransform: "capitalize" }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b style={{ width: "49%", textTransform: "capitalize" }}>
                    Fall back language
                  </b>
                </InputGroup.Text>
                <select
                  id="fallbackLang"
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate("fallbackLang", event.target.value)
                  }
                  defaultValue={
                    stateConfiguration.fallbackLang
                      ? stateConfiguration.fallbackLang
                      : ""
                  }
                >
                  <option hidden>Select an Option</option>
                  <option value="en">English</option>
                  <option value="hi">Hindi</option>
                  <option value="ar">Arabic</option>
                </select>
              </InputGroup>
            </>

            {/* Inputs with true or false values */}

            {sampleStateObjectKeys.map((stateObject, index) => (
              <InputGroup
                key={stateObject}
                className="mb-3 mx-1"
                style={{ width: "49%", textTransform: "capitalize" }}
              >
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b style={{ width: "49%", textTransform: "capitalize" }}>
                    {stateObject}
                  </b>
                </InputGroup.Text>
                <select
                  className="form-control form-select"
                  onChange={(event) =>
                    handlingUpdate(stateObject, event.target.value)
                  }
                  value={stateConfiguration[stateObject.replace(/ /g, "")]}
                >
                  <option hidden>Select an Option</option>
                  <option value={true}>Required</option>
                  <option value={false}>Not Required</option>
                </select>
              </InputGroup>
            ))}

            {/* inputs for colors */}
            <>
              <InputGroup
                className="mb-3 mx-1"
                style={{ width: "49%", textTransform: "capitalize" }}
              >
                <InputGroup.Text>
                  <b>Card Background Color (Colored)</b>
                </InputGroup.Text>
                <InputGroup.Text>
                  {stateConfiguration.cardBackgroundColorColored}
                </InputGroup.Text>
                <input
                  value={stateConfiguration.cardBackgroundColorColored}
                  onChange={(event) => {
                    setSaveOption(true);

                    handlingUpdate(
                      "cardBackgroundColorColored",
                      event.target.value
                    );
                  }}
                  type="color"
                  className="form-control form-control-color"
                  // list='colors'
                  placeholder="Select Color"
                />
              </InputGroup>
              <InputGroup
                className="mb-3 mx-1"
                style={{ width: "49%", textTransform: "capitalize" }}
              >
                <InputGroup.Text>
                  <b>Card Background Color (GreyScaled)</b>
                </InputGroup.Text>
                <InputGroup.Text>
                  {stateConfiguration.cardBackgroundColorGrayscaled}
                </InputGroup.Text>
                <input
                  value={stateConfiguration.cardBackgroundColorGrayscaled}
                  onChange={(event) => {
                    setSaveOption(true);
                    handlingUpdate(
                      "cardBackgroundColorGrayscaled",
                      event.target.value
                    );
                  }}
                  type="color"
                  className="form-control form-control-color"
                  // list='colors' // for displaying standard colors
                  // id='GFG_Color'
                  placeholder="Select Color"
                />
              </InputGroup>
            </>

            {/* inputs for AssetPaths */}

            <InputGroup
              className="mb-3 mx-1"
              style={{ width: "49%", textTransform: "capitalize" }}
            >
              <InputGroup.Text>
                <b>Base64 Assets</b>
              </InputGroup.Text>
              <Form.Control
                value={stateConfiguration.base64assets}
                onChange={(e) => {
                  setSaveOption(true);
                  handlingUpdate("base64assets", e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{ width: "49%", textTransform: "capitalize" }}
            >
              <InputGroup.Text>
                <b>Base asset path</b>
              </InputGroup.Text>
              <Form.Control
                value={stateConfiguration.baseAssetPath}
                onChange={(e) => {
                  setSaveOption(true);
                  handlingUpdate("baseAssetPath", e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{ width: "49%", textTransform: "capitalize" }}
            >
              <InputGroup.Text>
                <b>Base asset normal path</b>
              </InputGroup.Text>
              <Form.Control
                value={stateConfiguration.baseAssetNormalPath}
                onChange={(e) => {
                  setSaveOption(true);
                  handlingUpdate("baseAssetNormalPath", e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 mx-1"
              style={{ width: "49%", textTransform: "capitalize" }}
            >
              <InputGroup.Text>
                <b>Base asset abnormal path</b>
              </InputGroup.Text>
              <Form.Control
                value={stateConfiguration.baseAssetAbnormalPath}
                onChange={(e) => {
                  setSaveOption(true);
                  handlingUpdate("baseAssetAbnormalPath", e.target.value);
                }}
              />
            </InputGroup>
          </div>
        </Form>

        {/* Buttons */}
        <>
          <button
            onClick={() => setSaveOption(false)}
            className="btn btn-primary mt-2"
            disabled={!saveOption}
          >
            Save Changes
          </button>
          <button
            onClick={() => handlingRequest()}
            disabled={saveOption}
            className="btn btn-primary mx-4 mt-2"
            style={{ width: "132px" }}
          >
            {updatingStateLoading ? (
              <Spinner animation="border" role="status" size="sm"></Spinner>
            ) : (
              "Confirm Changes"
            )}
          </button>
        </>
      </div>
    </>
  );
};

export default SmartReportConfig;
