import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider } from "react-alert";
import { useAlert } from "react-alert";


const SignUpLIS = () => {

  const alert = useAlert();

  const [typeOfNewClient, setTypeOfNewClient] = useState("")
  const [noticeChangeInValue, setNoticeChangeInValue] = useState(true)
  const [expectedJson, setExpectedJson] = useState("")

  const [userId, setUserId] = useState("")
  const [clientId, setClientId] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [org, setOrg] = useState("")
  const [center, setCenter] = useState("")
  const [reportsSubscribed, setReportsSubscribed] = useState(0)
  const [subscriptionType, setSubscriptionType] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [subscriptionStatus, setSubscriptionStatus] = useState("on hold")
  const [showBilling, setShowBilling] = useState(true)
  const [logoUrl, setLogoUrl] = useState("https://res.cloudinary.com/nithish0504/image/upload/v1652856436/niroggyan-logo_pc2fef.svg")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [newClientName, setNewClientName] = useState("")
  const [allLiveClients, setAllLiveClients] = useState(["trial"])

  // modal states
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    let currentLocation = window.location.href
    let clientType = currentLocation.substring(currentLocation.lastIndexOf('/') + 1)
    setTypeOfNewClient(clientType)
  }, [])

  const handleRequiredJson = async () => {

    let theJson = {
      "userId": userId,
      "center": center,
      "clientId": clientId,
      "customerName": displayName,
      "displayName": displayName,
      "endDate": endDate + " 23:59:59.000",
      "logoSrc": logoUrl,
      "liveClients": allLiveClients,
      "org": org,
      "password": password,
      "reportsSubscribed": parseInt(reportsSubscribed),
      "role": "LIS",
      "showBilling": showBilling === "true" ? true : false,
      "startDate": startDate + " 00:00:00.000",
      "subscription": subscriptionType,
      "subscriptionStatus": subscriptionStatus
    }

    await setExpectedJson(theJson)
    await setNoticeChangeInValue(false)
  }

  const handleFormSubmission = (e) => {
    e.preventDefault()
    if (password === confirmPassword && password !== "" && confirmPassword !== "") {
      sendDataToBackend()
    }
    else {
      alert.error("Passwords do not match!")
    }
  }

  const sendDataToBackend = async () => {
    var config = {
      method: 'POST',
      // url: "",
      url: "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/subscription/signup",
      headers: {
        'Content-Type': 'application/json'
      },
      data: expectedJson
      //data: dummyData
    };

    console.log("what im sending:", expectedJson)

    try {
      const axiosData = await axios(config)
      console.log("inside axiosdata ===> \n\n", axiosData)
      alert.success("Signup Successfull")

    } catch (error) {
      console.log("Error for Labs:", error)
      alert.error("Signup Failed!");
    }
  }

  console.log(allLiveClients)

  const handleLiveClients = () => {
    let oldClients = allLiveClients
    let dummyArray = []
    oldClients.map(each => dummyArray.push(each))
    dummyArray.pop()
    dummyArray.push(newClientName)
    dummyArray.push("trial")
    setAllLiveClients(dummyArray)
    console.log("i have:", allLiveClients)
  }

  // react alert template options
  const options = {
    timeout: 5000,
    position: 'bottom left'
  }

  return (
    <>
      <Provider template={AlertTemplate} {...options}>
        {/* Add Live Clients Modal (START) */}

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Live Clients</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className='form-control' onSubmit={(e) => e.preventDefault()}>
              <div style={{ display: "flex", gap: "15px"}}>
                <input
                  type="text"
                  className='form-control'
                  onChange={(e) => setNewClientName(e.target.value)}
                  placeholder="enter live client"
                />
                <button className='btn btn-info' type="button" onClick={handleLiveClients}>Add</button>
              </div>
              <div className='mt-3'>
                <p><b>Live Clients:</b> {allLiveClients.map(each => each + ", ")}</p>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleClose} className='btn btn-success'>{"done"}</button>
          </Modal.Footer>
        </Modal>

        {/* Add Live Clients Modal (END) */}

        <div style={{ color: "#3c72eb", textAlign: "center", fontWeight: "bold" }}>
          {"Signing Up"} {typeOfNewClient.toUpperCase()}
        </div>
        <hr />

        <Form onSubmit={handleFormSubmission}>
          <div style={{ display: "flex", gap: "20px" }}>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon1">{"User ID"}</InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setUserId(e.target.value)
                }}
                placeholder="user id"
                aria-label="userid"
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon2">{"Client Id"}</InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setClientId(e.target.value)
                }}
                placeholder="client id"
                aria-label="clientId"
                aria-describedby="basic-addon2"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon3">{"Display Name"}</InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setDisplayName(e.target.value)
                }}
                placeholder="display name"
                aria-label="displayName"
                aria-describedby="basic-addon3"
              />
            </InputGroup>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon4">{"Org"}</InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setOrg(e.target.value)
                }}
                placeholder="org"
                aria-label="org"
                aria-describedby="basic-addon4"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon5">{"Center"}</InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setCenter(e.target.value)
                }}
                placeholder="center"
                aria-label="center"
                aria-describedby="basic-addon5"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon6">{"Reports Subscribed"}</InputGroup.Text>
              <Form.Control
                // required
                type="number"
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setReportsSubscribed(e.target.value)
                }}
                placeholder="reports"
                aria-label="reports"
                aria-describedby="basic-addon6"
              />
            </InputGroup>

          </div>

          <div className='mt-4' style={{ display: "flex", gap: "20px" }}>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon7">{"Subscription (Report Type)"}</InputGroup.Text>
              <Form.Control
                required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setSubscriptionType(e.target.value)
                }}
                placeholder="compact/dynamic/advanced"
                aria-label="subscription"
                aria-describedby="basic-addon7"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon12">
                {"Logo Url"}
              </InputGroup.Text>
              <Form.Control
                type="text"
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setLogoUrl(e.target.value)
                }}
                placeholder="https://......"
                aria-label="logoUrl"
                defaultValue={logoUrl}
                aria-describedby="basic-addon12"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text
                onClick={handleShow}
                title={"click to add more"} style={{ width: "100%", fontWeight: "bold", cursor: "pointer" }}>
                {"Live Clients:"} {allLiveClients.map(each => each + ", ")}
              </InputGroup.Text>
            </InputGroup>

          </div>

          <div style={{ display: "flex", gap: "20px" }}>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon8">
                {"Starting Date"}
              </InputGroup.Text>
              <Form.Control
                // required
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setStartDate(e.target.value)
                }}
                placeholder="startDate"
                aria-describedby="basic-addon8"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon9">
                {"Ending Date"}
              </InputGroup.Text>
              <Form.Control
                // required
                type="date"
                defaultValue={endDate}
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setEndDate(e.target.value)
                }}
                placeholder="endDate"
                aria-describedby="basic-addon9"
              />
            </InputGroup>

          </div>


          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon10">
                {"Subscription Status"}
              </InputGroup.Text>

              <Form.Select aria-label="Default select example" onChange={(e) => {
                setNoticeChangeInValue(true)
                setSubscriptionStatus(e.target.value)
              }}>
                <option value="live" selected={subscriptionStatus === "live"}>{"LIVE"}</option>
                <option value="on hold" selected={subscriptionStatus === "on hold"}>{"HOLD"}</option>
              </Form.Select>

            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon11">
                {"Show Billing"}
              </InputGroup.Text>

              <Form.Select aria-label="Default select example" onChange={(e) => {
                setNoticeChangeInValue(true)
                setShowBilling(e.target.value)
              }}>
                <option value="true" selected={showBilling}>{"YES"}</option>
                <option value="false" selected={!showBilling}>{"NO"}</option>
              </Form.Select>

            </InputGroup>

          </div>


          <div style={{ display: "flex", gap: "20px" }}>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon13">
                {"Password"}
              </InputGroup.Text>
              <Form.Control
                type="password"
                defaultValue={password}
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setPassword(e.target.value)
                }}
                aria-label="password"
                placeholder="password"
                aria-describedby="basic-addon13"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon14">
                {"Confirm Password"}
              </InputGroup.Text>
              <Form.Control
                type="password"
                defaultValue={confirmPassword}
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setConfirmPassword(e.target.value)
                }}
                aria-label="confirmpassword"
                placeholder="retype password"
                aria-describedby="basic-addon14"
              />
            </InputGroup>


          </div>


          <div className='my-3' style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
            <button className='btn btn-info btn-sm' type='button' onClick={handleRequiredJson}>Confirm Changes</button>
            <button disabled={noticeChangeInValue} className='btn btn-sm' style={{ backgroundColor: "#3c72eb", color: "#fff" }} type="submit">Submit</button>
          </div>

        </Form>
      </Provider>
    </>
  )
}

export default SignUpLIS