import React, { useState, useEffect, useRef } from "react";
import "../../App.css";
import { FileDrop } from "react-file-drop";
import axios from "axios";
import Message from "./Message";
import { Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import JsZip from "jszip";
import { saveAs } from "file-saver";
import SideNavbar from "./SideNavbar.js";

import data from "./dummyjson.js";
import requiredData from "./dummyjson.js";
import TestPreview from "./testPreview";
import { useAlert, positions } from "react-alert";
import { Radio, Table } from "semantic-ui-react";
import NavBar from "./NavBar";
import { TiTick } from "react-icons/ti";
import { AiOutlineClose } from "react-icons/ai";
import NavbarComponent from "../NavbarComponent.js";
import { Stack, Typography } from "@mui/material";
import { CustomBox } from "../../common/BoxAbstraction.js";
import PageTitle from "../../common/PageTitle.js";

const DragDropCSV = () => {
  let navigate = useNavigate();
  const alert = useAlert();
  const inputRef = useRef("");
  const [myFile, setMyFile] = useState([]);
  const [myRefRangesFile, setMyRefRangesFile] = useState([]);
  const [awsResponse, setAwsResponse] = useState("");
  const [pdfLinks, setPdfLinks] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");
  const [chooseFile, setChooseFile] = useState(false);
  const [userAllInfo, setAllUserInfo] = useState("");
  const [sendReportStatus, setSendReportStatus] = useState(false);
  const [refRangeRecord, setRefRangeRecord] = useState(null);
  const [refRangesStatus, setRefRangesStatus] = useState(false);
  const [refRangesUploadConfirm, setRefRangesUploadConfirm] = useState(false);
  const [showZipButton, setShowZipButton] = useState(false);
  const [count, setCount] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [historic, setHistoric] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");
  const [pdfBase64Array, setPdfBase64Array] = useState([]);
  const [center, setCenter] = useState("");
  const [generationLoading, setGenerationLoading] = useState(false);

  const [finalJsonArray, setFinalJsonArray] = useState([]);

  const [base64Array, setBase64Array] = useState([]);
  const [csvInputflag, setCsvInputFlag] = useState(false);
  const [onDragStyles, setDragStyles] = useState(false);

  const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));

  //  button to copy url link
  // const [urlButton, setUrlButton] = useState(false);
  // const [urlGeneration, setUrlGeneration] = useState(false);
  // const [videoUrl, setVideoUrl] = useState('');
  // const [copyStatus, setCopyStatus] = useState(false);
  const [announcement, setAnnouncement] = useState(true);

  useEffect(() => {
    const loginData = localStorage.getItem("credentials");
    if (JSON.parse(loginData)) {
      setCenter(JSON.parse(loginData)["center"]);
    } else {
      navigate("/login");
    }
  }, []);

  const downloadPDF = (myPdfDoc, emp_name) => {
    console.log("the file getting downloaded");
    const linkSource = `data:application/pdf;base64,${myPdfDoc}`;
    const downloadLink = document.createElement("a");
    const fileName = `${emp_name}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    if (center !== "pfh" && center !== "zaincare") {
      // window.location.reload();
    }
  };

  const downloadZip = () => {
    var zip = new JsZip();
    let allFiles = zip.folder("Smart Reports");

    for (let i = 0; i < base64Array.length; i++) {
      if (base64Array[i].base64 !== "Smart Report Generation Error") {
        allFiles.file(
          `${base64Array[i].sampleId + "-" + base64Array[i].patientName}.pdf`,
          base64Array[i]["base64"],
          { base64: true }
        );
      }
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      saveAs(content, "SmartReports.zip");
    });
  };

  // const styles = {
  //   border: "3px dashed grey",
  //   width: "100%",
  //   color: "black",
  //   padding: 20,
  //   backgroundColor: "#e6f7ff",
  // };

  const styles = onDragStyles
    ? {
        border: "4px solid #3567D6",
        borderRadius: "5px",
        width: "100%",
        color: "black",
        padding: 20,
        backgroundColor: "#EDF2FF",
        height: "360px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }
    : {
        border: "2px dashed #A0A0A0",
        borderRadius: "5px",
        width: "100%",
        color: "black",
        padding: 20,
        backgroundColor: "#ffffff",
        height: "360px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      };

  const valueHandler = (theValue) => {
    if (theValue !== undefined || theValue === "") {
      let modifyValue = "";

      let findJan = "";
      let findFeb = "";
      let findMar = "";
      let findApr = "";
      let findMay = "";
      let findJun = "";
      let findJul = "";
      let findAug = "";
      let findSep = "";
      let findOct = "";
      let findNov = "";
      let findDec = "";

      findJan = theValue.indexOf("Jan");
      findFeb = theValue.indexOf("Feb");
      findMar = theValue.indexOf("Mar");
      findApr = theValue.indexOf("Apr");
      findMay = theValue.indexOf("May");
      findJun = theValue.indexOf("Jun");
      findJul = theValue.indexOf("Jul");
      findAug = theValue.indexOf("Aug");
      findSep = theValue.indexOf("Sep");
      findOct = theValue.indexOf("Oct");
      findNov = theValue.indexOf("Nov");
      findDec = theValue.indexOf("Dec");

      if (findJan !== -1) {
        modifyValue = theValue.split("-")[0] + "-01";
        return modifyValue;
      } else if (findFeb !== -1) {
        modifyValue = theValue.split("-")[0] + "-02";
        return modifyValue;
      } else if (findMar !== -1) {
        modifyValue = theValue.split("-")[0] + "-03";
        return modifyValue;
      } else if (findApr !== -1) {
        modifyValue = theValue.split("-")[0] + "-04";
        return modifyValue;
      } else if (findMay !== -1) {
        modifyValue = theValue.split("-")[0] + "-05";
        return modifyValue;
      } else if (findJun !== -1) {
        modifyValue = theValue.split("-")[0] + "-06";
        return modifyValue;
      } else if (findJul !== -1) {
        modifyValue = theValue.split("-")[0] + "-07";
        return modifyValue;
      } else if (findAug !== -1) {
        modifyValue = theValue.split("-")[0] + "-08";
        return modifyValue;
      } else if (findSep !== -1) {
        modifyValue = theValue.split("-")[0] + "-09";
        return modifyValue;
      } else if (findOct !== -1) {
        modifyValue = theValue.split("-")[0] + "-10";
        return modifyValue;
      } else if (findNov !== -1) {
        modifyValue = theValue.split("-")[0] + "-11";
        return modifyValue;
      } else if (findDec !== -1) {
        modifyValue = theValue.split("-")[0] + "-12";
        return modifyValue;
      } else {
        return theValue;
      }
    } else {
      return theValue;
    }
  };

  const csvJSON = (csv) => {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",").map((each) => each.trim());

    // var headers = lines[0].replace(/\s/g, "").split(",");

    // console.log('headers\n', headers);

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].replace(/\r/g, "").split(",");

      // console.log('currrent lin\n', currentline);

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j].trim()] = valueHandler(currentline[j]);
      }

      result.push(obj);
    }
    let json = JSON.stringify(result);
    // console.log(result);
    return result; //JSON
  };

  let myArray = [];
  let collectionOfJson = [];

  const dataToRequiredJson = (data) => {
    console.log("data recieved\n", data);
    let requriedPatientDetails = {};

    for (let i = 0; i < data.length; i++) {
      let currentData = data[i];

      requriedPatientDetails = {
        fileName: currentData["Sl.No"] + "-" + currentData["Patient Name"],
        slNo: currentData["Sl.No"],
        patientname: currentData["Patient Name"],
        gender: currentData["Gender"],
        age: currentData["Current Age"],
        refBy: currentData["Ref By"],
        registeredDate: currentData["Registered Date"],
        collectionDate: currentData["Collection Date"],
        ReportedDate: currentData["Reported Date"],
        sampleId: currentData["Sample Id"],
        centerName: currentData["Center Name"],
        refCustomer: currentData["Ref Customer"],
        department: currentData["Department"],
        employeeId: currentData["Employee ID"],
        client: "StandardHealthCare",
        blankLetterHead: true,
        results: [
          {
            profilename: "undefined",
            investigation: [],
          },
        ],
      };

      delete data[i]["Sl.No"];
      delete data[i]["Patient name"];
      delete data[i]["Gender"];
      delete data[i]["Current Age"];
      delete data[i]["Ref By"];
      delete currentData["Registered Date"];
      delete currentData["Collection Date"];
      delete currentData["Reported Date"];
      delete data[i]["Sample ID"];
      delete data[i]["Center Name"];
      delete data[i]["Ref Customer"];
      delete data[i]["Department"];
      delete data[i]["Employee ID"];

      let restOfTheData = data[i];
      // console.log("restOfTheData\n", restOfTheData)

      // var p = {
      //     "p1": "value1",
      //     "p2": "value2",
      //     "p3": "value3"
      // };

      let collectionOfTests = [];
      // console.log(restOfTheData);

      for (var key in restOfTheData) {
        if (restOfTheData.hasOwnProperty(key)) {
          let theKey = key;
          let theLow = "";
          let theHigh = "";
          let theUnit = "";

          if (refRangesStatus) {
            // populating ref ranges and unit in test
            for (let p = 0; p < refRangeRecord.length; p++) {
              let theTest = refRangeRecord[p]["Test Name"];
              if (theTest === theKey) {
                //console.log("match found", theTest, "===", theKey)
                let gender = requriedPatientDetails.gender;
                theLow =
                  gender === "Male" || gender === "male" || gender === "MALE"
                    ? refRangeRecord[p]["male low"]
                    : refRangeRecord[p]["female low"];
                theHigh =
                  gender === "Male" || gender === "male" || gender === "MALE"
                    ? refRangeRecord[p]["male high"]
                    : refRangeRecord[p]["female high"];
                theUnit = refRangeRecord[p]["Unit"];
              } else {
                //console.log("mismatch", theTest, "!==", theKey)
              }
            }

            // modifying test json structure
            let theResult = restOfTheData[key];
            let testStructure = {
              test: theKey.trim(),
              low: theLow,
              high: theHigh,
              unit: theUnit,
              result: theResult,
              pastObservation: [],
            };

            // avoid inserting empty tests
            if (testStructure.result === "") {
              //
            } else {
              collectionOfTests.push(testStructure);
            }
          } else {
            let theResult = restOfTheData[key];
            // console.log('theResult: ', theResult);

            let testStructure = {
              // test: theKey.replace(/(\r\n|\n|\r)/gm, ''),
              test: theKey.trim(),

              low: "",
              high: "",
              unit: "",
              result: theResult,
              pastObservation: [],
            };

            // avoid inserting empty tests
            if (testStructure.result === "") {
              //
            } else {
              collectionOfTests.push(testStructure);
            }
          }
        }
      }

      // filling of tests
      console.log("collectionOfTests", collectionOfTests);
      requriedPatientDetails.results[0].investigation = collectionOfTests;

      // console.log("here\n", requriedPatientDetails)

      if (
        requriedPatientDetails.slNo === "" ||
        requriedPatientDetails.slNo === undefined
      ) {
        //
      } else {
        collectionOfJson.push(requriedPatientDetails);
      }
    }

    setAllUserInfo(collectionOfJson);
    console.log("collection of json\n", collectionOfJson);
  };
  // console.log(requriedPatientDetails)
  // console.log('userAllInfo\n', userAllInfo);

  let myCurrentValue = 0;
  let linksCollection = [];

  const jsonHandler = async () => {
    var config = {
      method: "POST",
      //url: "",
      url: "",
      headers: {
        "Content-Type": "application/json",
      },
      data: collectionOfJson[myCurrentValue],
    };
    console.log("what im sending\n", collectionOfJson[myCurrentValue]);

    try {
      const axiosData = await axios(config);
      console.log("inside axiosdata ===> \n\n", axiosData);
      //linksCollection.push(axiosData.data.base64Pdf)
      //setPdfLinks(linksCollection)
      downloadPDF(
        axiosData.data.EncodeData,
        collectionOfJson[myCurrentValue].slNo,
        collectionOfJson[myCurrentValue].patientname
      );

      if (myCurrentValue === collectionOfJson.length) {
        // nothing
        //downloadZip()
        setCount(collectionOfJson.length);
        setShowZipButton(true);
        setShowLoading(false);
        setError("");
      } else {
        myCurrentValue = myCurrentValue + 1;
        setCount(myCurrentValue);
        setShowZipButton(true);
        setShowLoading(false);
        setShowLoading(true);
        jsonHandler();
      }
    } catch (error) {
      // downloadZip()
      setShowZipButton(true);
      console.log("error", error);
      //setError(error.message)
      setShowLoading(false);

      // setError("generating remaining ones")
      // console.log("error here: ", error)
      // if (myCurrentValue < collectionOfJson.length) {
      //     downloadZip()
      //     setError("generating remaining ones")
      //     setError("")
      //     var newData = []
      //     console.log("at this point", collectionOfJson.splice(0, 25))
      //     collectionOfJson.splice(0, 25)
      //     console.log("number of items removed:", myCurrentValue)
      //     console.log("new collection\n", collectionOfJson)
      //     console.log("newData\n", newData)
      //     myCurrentValue = 0
      //     //jsonHandler()
      // } else {
      //     //setShowZipButton(true)
      //     console.log(error)
      // }
    }
  };

  // generate smart report
  const sendReport = () => {
    if (refRangesStatus && myRefRangesFile.length === 0) {
      setShowLoading(false);
      setError("Ref Ranges not provided!");
    } else {
      try {
        let files = myFile;
        const formData = new FormData();
        formData.append("files[]", files[0]);
        const reader = new FileReader();
        reader.onload = function (e) {
          var contents = e.target.result;
          const finalJson = csvJSON(contents);
          dataToRequiredJson(finalJson);
        };
        reader.readAsText(files[0]);
      } catch (error) {
        setError("File Error!");
        setShowLoading(false);
        setPdfLinks([]);
      }
    }
  };

  // upload ref ranges
  const refRangesHandler = () => {
    try {
      let files = myRefRangesFile;
      const formData = new FormData();
      formData.append("files[]", files[0]);
      const reader = new FileReader();
      reader.onload = function (e) {
        var contents = e.target.result;
        const finalJson = csvJSON(contents);
        requiredRefRangesJson(finalJson);
      };
      reader.readAsText(files[0]);
      setRefRangesUploadConfirm(true);
    } catch (error) {
      setError("File Error!");
      setShowLoading(false);
      setPdfLinks([]);
    }
  };

  const requiredRefRangesJson = (refRangesData) => {
    console.log("ref ranges data", refRangesData);
    setRefRangeRecord(refRangesData);
  };

  const thedata = awsResponse !== "" ? awsResponse : "not found";

  // console.log('refRangesStatus: ', refRangesStatus);

  const gettingTestPreview = async () => {
    const apiForJson = "exampleUrl";
  };
  // useEffect(() => fetchingDataFromParser(), []);

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(
            fileReader.result.replace("data:application/pdf;base64,", "")
          );
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    } else {
      return "";
    }
  };

  const csvToSr = async (arrayOfJson) => {
    // console.log('coming to csv to sr');
    // console.log(finalJsonArray);
    setGenerationLoading(true);
    const gettingPdfBuffer = async (json, serialNo) => {
      /// updatingEditedTestData  in fetched json
      // setGenerationLoading(true);
      console.log("json Sending", json);
      const apiEndPoint =
        "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/production/pdfparserclients";
      const configuration = {
        method: "POST",
        // sampleUrl from Gist
        url: apiEndPoint,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(json),
        validateStatus: function (status) {
          return status >= 200 && status <= 400;
        },
      };
      // let pixanimateVIdeoLink = '';
      try {
        const axiosData = await axios(configuration);
        console.log(axiosData);
        if (axiosData.data.PdfData) {
          // try {
          //   if (axiosData.data.externalReportObjFileUrl) {
          //     setUrlButton(true);
          //     setUrlGeneration(true);
          //     const pixAnimateBody = {
          //       s3url: axiosData.data.externalReportObjFileUrl,
          //     };
          //     const pixAnimateEndPoint =
          //       'https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/Testing/videoreport';
          //     const pixAnimateApiConfig = {
          //       method: 'POST',
          //       url: pixAnimateEndPoint,
          //       data: JSON.stringify(pixAnimateBody),
          //       headers: {
          //         'Content-Type': 'application/json',
          //       },
          //     };
          //     const pixAnimateURL = await axios(pixAnimateApiConfig);
          //     // console.log(pixAnimateURL, 'pixAnimateURL');
          //     pixanimateVIdeoLink = pixAnimateURL.data.isSuccess
          //       ? pixAnimateURL.data.data.url
          //       : '';
          //     setUrlGeneration(false);
          //     if (pixAnimateURL.data.data.url) {
          //       setVideoUrl(pixAnimateURL.data.data.url);
          //     }
          //   }
          // } catch (e) {
          //   console.log(e.message);
          // }
          setBase64Array((prev) => [
            ...prev,
            {
              slNo: serialNo,
              patientName: json.PName,
              sampleId: json.LabNo,
              base64: axiosData.data.PdfData,
              // videoLink: pixanimateVIdeoLink,
            },
          ]);
        } else {
          setBase64Array((prev) => [
            ...prev,
            {
              slNo: serialNo,
              patientName: json.PName,
              base64: "Smart Report Generation Error",
              sampleId: json.LabNo,
              // videoLink: '',
            },
          ]);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    let serialNo = 0;
    for (let eachJson of arrayOfJson) {
      serialNo++;
      await gettingPdfBuffer(eachJson, serialNo);
    }
    setGenerationLoading(false);
  };

  const fileHandler = async () => {
    if (myFile[0]) {
      // Checking File size for lambda feasibility of max 5MB payload

      if (myFile[0].size < 5 * 1024 * 1024) {
        if (myFile[0].type === "application/pdf") {
          fetchingDataFromParser();
        } else if (myFile[0].type === "text/csv") {
          console.log("coming here");
          // handlingCSVFile();
          await handlingCSVFile();
        } else {
          alert.error(
            <span className="alertError">Upload either PDF or CSV</span>,
            { position: positions.TOP_CENTER }
          );
        }
      } else {
        alert.error(
          <span className="alertError">Upload file less than 5mb</span>
        );
      }
    } else {
      alert.error(
        <span className="alertError">Upload either PDF or CSV</span>,
        { position: positions.TOP_CENTER }
      );
    }
  };

  const fetchingDataFromParser = async () => {
    if (myFile[0]) {
      const base64 = await convertBase64(myFile[0]);
      setPdfBase64(base64);
      // localStorage.setItem('base64', base64);
      setShowPreview(true);
    } else {
      alert.error("Please UploaD PDF");
    }

    // const { dummyJson } = requiredData;
    // const jsonData = dummyJson.results;
    // console.log(jsonData);
    // const packagesData = jsonData.map(eachPackage => eachPackage.investigation);
    // console.log(packagesData);
    // let tests = [];
    // packagesData.forEach(eachTest => {
    //   eachTest.map(eachOb => tests.push(eachOb.observations));
    // });
    // // console.log('fetchingDataFromParser', tests);
    // setTimeout(() => setShowLoading(false), 1500);
  };

  const generatingArrayBase64 = async () => {
    console.log("calling function");
    if (historic) {
      let base64ary = [];
      for (let i = 0; i < Object.keys(myFile).length; i++) {
        base64ary.push(await convertBase64(myFile[i]));
      }
      return base64ary;
    }
  };

  const generatingSmartReport = async () => {
    // creating Body for pdfparserbackend
    setGenerationLoading(true);
    const pdfBase64 = await generatingArrayBase64();
    let clientId = "";
    switch (JSON.parse(localStorage.getItem("credentials"))["center"]) {
      case "mayormedical":
        clientId = "mayor";
        break;
      case "curis":
        clientId = "curis";
        break;
      case "srh":
        clientId = "SRH";
        break;
      default:
        break;
    }
    const bodyforPdfParser = {
      pdfBase64,
      isHistorical: historic,
      clientId,
    };
    const config = {
      method: "POST",
      // sampleUrl from Gist
      url: "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/Testing/pdfParserhistoricalTesting",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(bodyforPdfParser),
    };
    try {
      let axiosData = await axios(config);
      if (axiosData.data) {
        const apiEndPointForSmartReport =
          "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/Testing/newhistoricaltesting14";

        // updating Centers, reportType,historic
        axiosData.data.Centre = center;
        axiosData.data.LabNo = axiosData.data.LabRefno;
        axiosData.data.hasPastData = historic;
        axiosData.data.reportType = JSON.parse(
          localStorage.getItem("credentials")
        )["reportSubscription"]["reportType"];

        const configuration = {
          method: "POST",
          url: apiEndPointForSmartReport,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(axiosData.data),
        };
        try {
          console.log("json Sending", axiosData.data);
          const smartReportData = await axios(configuration);
          console.log(smartReportData.data);
          downloadPDF(smartReportData.data.PdfData, axiosData.data.PName);
          setGenerationLoading(false);
          alert.show("Report Generated Successfully");
        } catch (error) {
          alert.error("Smart Report Generation Error");
        }
      }
    } catch (error) {
      alert.error("PDF parsing Error");
      setGenerationLoading(false);
    }
  };

  const handlingCSVFile = async () => {
    // console.log('parsing', file[0]);
    let files = myFile;
    const formData = new FormData();
    formData.append("files", files[0]);
    const reader = new FileReader();
    reader.onload = async function (e) {
      var contents = e.target.result;
      const finalJson = csvJSON(contents);
      // dataToRequiredJson(finalJson);
      const finalJsonTobeUsed = finalJsonPrep(finalJson);
      await csvToSr(finalJsonTobeUsed);
    };
    reader.readAsText(files[0]);
  };
  const finalJsonPrep = (listOfArray) => {
    let testsArray = [];
    let jsonsArray = [];

    const sampleJsonStructure = {
      Centre: "",
      WorkOrderID: "",
      LabRefno: "",
      LabNo: "",
      NonSmartTestID: "",
      PanelID: "",
      PanelName: "",
      PName: "",
      CollectedDate: "",
      Gender: "",
      Age: "",
      ReferredBy: "",
      ReportDate: "",
      Received: "",
      FilePath: "",
      MaxID: "",
      Date: "",
      hasPastData: false,
      results: [
        {
          Package_name: "",
          Package_book_code: "",
          investigation: [
            {
              test_name: "",
              test_code: "",
              barcodeNo: "",
              SampleType: "",
              SampleCollDate: "",
              SampleRcvDate: "",
              ApprovalDate: "",
              SampleCollBy: "",
              SampleRcvBy: "",
              ApprovedByDoctorID: "",
              IsNabl: "",
              observations: [],
            },
          ],
        },
      ],
      ApprovedDoctorDetails: [
        {
          ApprovedDoctorID: "",
          ApprovedDoctorSign: "",
        },
      ],
      BackGroudImageData: "",
      reportType: "",
    };

    // https://stackoverflow.com/questions/12690107/clone-object-without-reference-javascript
    let patientJson = JSON.parse(JSON.stringify(sampleJsonStructure));

    // logic for creating both historical and non -historical json from a pfh csv
    // console.log(listOfArray);
    for (let i = 0; i < listOfArray.length; i++) {
      if (listOfArray[i]["Test"] && listOfArray[i]["Test"] !== "") {
        if (listOfArray[i]["Patient name"] !== "" && i === 0) {
          patientJson["PName"] = listOfArray[i]["Patient name"];
          patientJson["Centre"] = center;
          patientJson["LabNo"] = listOfArray[i]["Sample ID"];
          patientJson["Gender"] = listOfArray[i]["Gender"];
          patientJson["Age"] = listOfArray[i]["Age"];
          patientJson["Date"] = listOfArray[i]["Date"];
          patientJson["CollectedDate"] = listOfArray[i]["Date"];
          patientJson["ReferredBy"] = listOfArray[i]["Referral Doctor"];
          patientJson["reportType"] = JSON.parse(
            localStorage.getItem("credentials")
          )["reportSubscription"]["reportType"];

          let sampletestObject = {
            name: listOfArray[i]["Test"],
            observation_time: listOfArray[i]["Date"],
            id: "",
            value: listOfArray[i]["Result"],
            MinValue: listOfArray[i]["Low"],
            MaxValue: listOfArray[i]["High"],
            unit: listOfArray[i]["Unit"],
            method: "",
            impression: "",
            // ranges: [],
            pastObservation: [],
          };
          let testRepetitionFlag = false;
          // console.log(testsArray.length);
          // console.log(testsArray);
          for (let j = 0; j < testsArray.length; j++) {
            if (
              testsArray[j]["name"] === sampletestObject["name"] &&
              !testsArray.includes(sampletestObject)
            ) {
              let Pastobs = {
                value: sampletestObject["value"],
                observation_time: sampletestObject["observation_time"],
              };
              testsArray[j]["pastObservation"].push(Pastobs);
              testRepetitionFlag = true;
              patientJson["hasPastData"] = true;
              break;
            }
          }
          if (!testRepetitionFlag && sampletestObject["name"] !== "") {
            testsArray.push(sampletestObject);
          }
        } else {
          if (listOfArray[i]["Patient name"] === "") {
            let sampletestObject = {
              name: listOfArray[i]["Test"],
              observation_time: patientJson["Date"],
              id: "",
              value: listOfArray[i]["Result"],
              MinValue: listOfArray[i]["Low"],
              MaxValue: listOfArray[i]["High"],
              unit: listOfArray[i]["Unit"],
              method: "",
              impression: "",
              // ranges: [],
              pastObservation: [],
            };
            let testRepetitionFlag = false;
            // console.log(testsArray.length);
            // console.log(testsArray);
            for (let j = 0; j < testsArray.length; j++) {
              if (
                testsArray[j]["name"] === sampletestObject["name"] &&
                !testsArray.includes(sampletestObject)
              ) {
                let Pastobs = {
                  value: sampletestObject["value"],
                  observation_time: sampletestObject["observation_time"],
                };
                testsArray[j]["pastObservation"].push(Pastobs);
                testRepetitionFlag = true;
                patientJson["hasPastData"] = true;
                break;
              }
            }
            if (!testRepetitionFlag) {
              testsArray.push(sampletestObject);
            }
          } else if (listOfArray[i]["Patient name"] !== "" && i > 0) {
            patientJson["results"][0]["investigation"][0]["observations"].push(
              ...testsArray
            );
            jsonsArray.push(patientJson);
            testsArray = [];
            patientJson = JSON.parse(JSON.stringify(sampleJsonStructure));
            patientJson["PName"] = listOfArray[i]["Patient name"];
            patientJson["Centre"] = center;
            patientJson["LabNo"] = listOfArray[i]["Sample ID"];
            patientJson["Gender"] = listOfArray[i]["Gender"];
            patientJson["Age"] = listOfArray[i]["Age"];
            patientJson["Date"] = listOfArray[i]["Date"];
            patientJson["CollectedDate"] = listOfArray[i]["Date"];
            patientJson["ReferredBy"] = listOfArray[i]["Referral Doctor"];
            patientJson["reportType"] = JSON.parse(
              localStorage.getItem("credentials")
            )["reportSubscription"]["reportType"];

            let sampletestObject = {
              name: listOfArray[i]["Test"],
              observation_time: patientJson["Date"],
              id: "",
              value: listOfArray[i]["Result"],
              MinValue: listOfArray[i]["Low"],
              MaxValue: listOfArray[i]["High"],
              unit: listOfArray[i]["Unit"],
              method: "",
              impression: "",
              ranges: [],
              pastObservation: [],
            };
            let testRepetitionFlag = false;
            // console.log(testsArray.length);
            // console.log(testsArray);
            for (let j = 0; j < testsArray.length; j++) {
              if (
                testsArray[j]["name"] === sampletestObject["name"] &&
                !testsArray.includes(sampletestObject)
              ) {
                let Pastobs = {
                  value: sampletestObject["value"],
                  observation_time: sampletestObject["observation_time"],
                };
                testsArray[j]["pastObservation"].push(Pastobs);
                testRepetitionFlag = true;
                break;
              }
            }
            if (!testRepetitionFlag && sampletestObject["name"] !== "") {
              testsArray.push(sampletestObject);
            }
          }
        }
      }
      if (i === listOfArray.length - 1) {
        // console.log(patientJson, testsArray);
        patientJson["results"][0]["investigation"][0]["observations"].push(
          ...testsArray
        );
        // console.log(patientJson);
        jsonsArray.push(patientJson);
        // console.log(jsonsArray);
      }
    }
    setFinalJsonArray(jsonsArray);
    return jsonsArray;
  };

  const checkingPdf = (pdfFiles) => {
    let totalMemory = 0; // in MegaBytes
    let pdfOnlyUploaded = true;
    let filegreaterThan5mb = false;
    for (let eachKey of pdfFiles) {
      totalMemory += eachKey.size / 1024 ** 2;
      if (eachKey.type !== "application/pdf") {
        pdfOnlyUploaded = false;
      }
      if (eachKey.size > 1024 ** 2 * 5) {
        filegreaterThan5mb = true;
      }
    }
    if (!pdfOnlyUploaded) {
      alert.error("Upload Only PDF files for Historical");
    }
    console.log(totalMemory, "MB");
    // if (totalMemory > 5) {
    //   alert.error('Files Size can not be more than 5 mb');
    // }
    if (filegreaterThan5mb) {
      alert.error("File Size can not be more than 5 mb.");
    }
    if (pdfOnlyUploaded) {
      setHistoric(true);
    }
  };

  const newBackendParsing = async () => {
    setGenerationLoading(true);
    let jsonFromParser = [];
    for (let eachPdf of myFile) {
      const pdfBase64 = await convertBase64(eachPdf);
      let clientId = "";
      switch (JSON.parse(localStorage.getItem("credentials"))["center"]) {
        case "mayormedical":
          clientId = "mayor";
          break;
        case "curis":
          clientId = "curis";
          break;
        case "srh":
          clientId = "SRH";
          break;
        default:
          break;
      }
      const bodyforPdfParser = {
        pdfBase64,
        clientId,
      };
      const config = {
        method: "POST",
        // sampleUrl from Gist
        url: "https://jyxmm1ky78.execute-api.ap-south-1.amazonaws.com/Testing/pdfparserbackend",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(bodyforPdfParser),
      };
      try {
        let axiosData = await axios(config);
        axiosData.data.Centre = center;
        axiosData.data.LabNo = axiosData.data.LabRefno;
        axiosData.data.hasPastData = historic;
        axiosData.data.reportType = JSON.parse(
          localStorage.getItem("credentials")
        )["reportSubscription"]["reportType"];
        jsonFromParser.push(axiosData.data);
      } catch (error) {
        alert.error(`PDF parsing Error of ${myFile.name}`);
      }
    }

    console.log("jsons", jsonFromParser);
    const sortedJsonArrays = jsonFromParser.sort((a, b) => {
      if (center === "srh") {
        return (
          new Date(
            b.ReportDate.split(" ")[0]
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-")
          ) -
          new Date(
            a.ReportDate.split(" ")[0]
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-")
          )
        );
      } else if (center === "mayormedical") {
        return (
          new Date(
            b.Received.split(" ")[0]
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-")
          ) -
          new Date(
            a.Received.split(" ")[0]
              .replace(/\//g, "-")
              .split("-")
              .reverse()
              .join("-")
          )
        );
      }
    });
    console.log(sortedJsonArrays);
    // FOR CLUBBING JSONS TO GET JSON WITH HISTORICAL DATA
    const jsonSending = preparingHistoricalJson(sortedJsonArrays);
    // sending Json to get historical
    const apiEndPoint =
      "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/production/pdfparserclients";
    const configuration = {
      method: "POST",
      // sampleUrl from Gist
      url: apiEndPoint,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(jsonSending),
    };

    try {
      const axiosData = await axios(configuration);
      console.log(axiosData);
      if (axiosData.data.PdfData) {
        downloadPDF(axiosData.data.PdfData, jsonSending.PName);
        alert.show("Report Generated Successfully");
      } else if (axiosData.data.fileAvailableInS3) {
        const path = `https://niroggyansmartreports.s3.ap-south-1.amazonaws.com/${axiosData.data.pdfFileName}`;
        const downloadLink = document.createElement("a");
        const fileName = `${jsonSending.PName}`;
        downloadLink.href = path;
        downloadLink.download = fileName;
        downloadLink.target = "_blank";
        downloadLink.click();
        window.location.reload();
      } else {
        alert.error(
          <span style={{ textTransform: "capitalize" }}>
            Smart Report Generation Error{"         "}({axiosData.data.message})
          </span>
        );
      }
    } catch (e) {
      console.log(e.message);
    }

    setGenerationLoading(false);
  };

  const preparingHistoricalJson = (array) => {
    let latestJson = array[0];
    // console.log('latestJson', latestJson);
    let tests = latestJson.results[0].investigation[0].observations;

    for (let i = 0; i < tests.length; i++) {
      for (let j = 1; j < array.length; j++) {
        for (
          let k = 0;
          k < array[j].results[0].investigation[0].observations.length;
          k++
        ) {
          if (
            tests[i].name ===
            array[j].results[0].investigation[0].observations[k].name
          ) {
            let observation = {
              value:
                array[j].results[0].investigation[0].observations[k]["value"],
              observation_time: array[j].results[0].investigation[0]
                .observations[k]["observation_time"]
                ? array[j].results[0].investigation[0].observations[k][
                    "observation_time"
                  ]
                : array[j].ReportDate,
            };
            latestJson.results[0].investigation[0].observations[
              i
            ].pastObservation.push(observation);
            break;
          }
        }
      }
    }
    console.log(latestJson);
    latestJson.results[0].investigation[0].observations = cleaningResultValues(
      latestJson.results[0].investigation[0].observations
    );
    return latestJson;
  };

  const cleaningResultValues = (tests) => {
    const cleanedTests = tests.map((eachTest) => {
      if (eachTest.value.split("%")[1]) {
        return {
          ...eachTest,
          name: eachTest.name.trim(),
          value: eachTest.value.split("%")[1].trim(),
          pastObservation: eachTest.pastObservation.map((eachPastObs) => {
            return {
              ...eachPastObs,
              value: eachTest.value.split("%")[1].trim(),
            };
          }),
        };
      }
      return eachTest;
    });
    return cleanedTests;
  };

  // const handleCopyStatus = response => {
  //   const newbase64Array = base64Array.map(each => {
  //     if (each.slNo === response.slNo) {
  //       if ('clipboard' in navigator) {
  //         navigator.clipboard.writeText(videoUrl);
  //       } else {
  //         document.execCommand('copy', true, videoUrl);
  //       }
  //       return { ...each, copyStatus: true };
  //     } else {
  //       return { ...each };
  //     }
  //   });
  //   console.log(newbase64Array, 'base64Array');
  //   setBase64Array(newbase64Array);
  // };

  const handleAnnouncement = () => {
    setAnnouncement(false);
  };

  const onClose = () => {
    setMyFile([]);
    // setDroppedStatus(false);
    setHistoric(false);
  };

  const showLockedView = () => {
    return (
      <Stack gap={0} flexGrow={1} sx={{ padding: "0px 64px 40px" }}>
        <PageTitle title="Manual Generation" />
        <CustomBox
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Currently this feature is locked for you</Typography>
        </CustomBox>
      </Stack>
    );
  };

  // console.log(myFile, csvInputflag);
  return (
    <Stack direction="row" gap={0}>
      <NavbarComponent />
      {loginDetails && loginDetails?.accessTo?.portal ? (
        <div style={{ flexGrow: "1", padding: "0px 64px 40px" }}>
          <PageTitle title="Manual Generation" />
          <NavBar />
          {/* <SideNavbar /> */}
          <div className=" ps-4 pe-4">
            {/* {error ? <Message variant='danger'>{error}</Message> : ''} */}
            <div style={{ display: "flex" }}>
              <div style={styles}>
                <FileDrop
                  onTargetClick={() => {
                    inputRef.current.click();
                    setShowPreview(false);
                  }}
                  accept="application/pdf,text/csv"
                  // onFrameDragEnter={event => console.log('onFrameDragEnter', event)}
                  // onFrameDragLeave={event => console.log('onFrameDragLeave', event)}
                  // onFrameDrop={event => console.log('onFrameDrop', event)}
                  // onDragOver={event => console.log('onDragOver', event)}
                  // onDragLeave={event => console.log('onDragLeave', event)}
                  onDrop={(files, event) => {
                    console.log("onDrop!", files, event);
                    setHistoric(false);
                    setBase64Array([]);
                    setMyFile(files);
                    setDragStyles(false);
                    if (files[0]) {
                      if (files[0].type === "text/csv") {
                        setCsvInputFlag(true);
                      } else {
                        setCsvInputFlag(false);
                      }
                      if (files.length > 1) {
                        // checking for whether all file types are of pdf only and their memory size <5mb.
                        checkingPdf(files);
                      }
                    }

                    // setPdfBase64(convertBase64(files));

                    // setPdfLinks([]);
                    // setError('');
                    // console.log('my file data\n', myFile);
                  }}
                  onDragOver={(event) => {
                    setDragStyles(true);
                  }}
                  onDragLeave={(event) => {
                    setDragStyles(false);
                  }}
                >
                  {myFile[0] ? null : (
                    <div>
                      {/* <span>
                    <i class="fas fa-cloud-upload-alt fa-3x text-primary"></i>
                  </span> */}
                      <span>
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="svg-height"
                        >
                          <path
                            d="M3.08186 24C2.23435 24 1.50883 23.6982 0.905297 23.0947C0.301766 22.4912 0 21.7656 0 20.9181V17.8363C0 17.3997 0.147673 17.0337 0.443018 16.7384C0.738363 16.443 1.10433 16.2953 1.54093 16.2953C1.97753 16.2953 2.3435 16.443 2.63884 16.7384C2.93419 17.0337 3.08186 17.3997 3.08186 17.8363V20.9181H21.573V17.8363C21.573 17.3997 21.7207 17.0337 22.0161 16.7384C22.3114 16.443 22.6774 16.2953 23.114 16.2953C23.5506 16.2953 23.9165 16.443 24.2119 16.7384C24.5072 17.0337 24.6549 17.3997 24.6549 17.8363V20.9181C24.6549 21.7656 24.3531 22.4912 23.7496 23.0947C23.1461 23.6982 22.4205 24 21.573 24H3.08186ZM10.7865 5.27769L7.89727 8.16693C7.58909 8.47512 7.22311 8.62279 6.79936 8.60995C6.3756 8.59711 6.00963 8.4366 5.70144 8.12841C5.41894 7.82022 5.27127 7.46067 5.25843 7.04976C5.24559 6.63884 5.39326 6.27929 5.70144 5.97111L11.2488 0.423756C11.4029 0.269663 11.5698 0.160514 11.7496 0.0963082C11.9294 0.0321027 12.122 0 12.3274 0C12.5329 0 12.7255 0.0321027 12.9053 0.0963082C13.0851 0.160514 13.252 0.269663 13.4061 0.423756L18.9535 5.97111C19.2616 6.27929 19.4093 6.63884 19.3965 7.04976C19.3836 7.46067 19.236 7.82022 18.9535 8.12841C18.6453 8.4366 18.2793 8.59711 17.8555 8.60995C17.4318 8.62279 17.0658 8.47512 16.7576 8.16693L13.8684 5.27769V16.2953C13.8684 16.7319 13.7207 17.0979 13.4254 17.3933C13.13 17.6886 12.764 17.8363 12.3274 17.8363C11.8909 17.8363 11.5249 17.6886 11.2295 17.3933C10.9342 17.0979 10.7865 16.7319 10.7865 16.2953V5.27769Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          marginTop: "10px",
                          color: "#121212",
                        }}
                      >
                        {["pfh", "zaincare"].includes(center)
                          ? "Drag & Drop your PDF / CSV file here or"
                          : ["elixcare", "ultimate", "wellcorp"].includes(
                              center
                            )
                          ? "Drag & Drop your CSV file here or"
                          : "Drag & Drop your PDF file here or"}
                        <span
                          style={{
                            cursor: "pointer",
                            color: "rgba(53, 103, 214, 1)",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          Choose file{" "}
                        </span>
                        <span> to upload</span>
                      </div>
                      <input
                        style={{
                          visibility: "hidden",
                          opacity: 0,
                          height: "0px",
                        }}
                        className="form-control"
                        id="fileupload"
                        type="file"
                        ref={inputRef}
                        multiple
                        accept="application/pdf,text/csv"
                        onChange={(e) => {
                          setHistoric(false);
                          setBase64Array([]);
                          setMyFile(e.target.files);

                          if (e.target.files[0]) {
                            if (e.target.files[0].type === "text/csv") {
                              setCsvInputFlag(true);
                            } else {
                              setCsvInputFlag(false);
                            }
                            if (e.target.files.length > 1) {
                              // checking for whether all file types are of pdf only and their memory size <5mb.
                              checkingPdf(e.target.files);
                            }
                            // console.log(e.target.files.length, 'coming');
                          }
                        }}
                      />
                    </div>
                  )}

                  <span>
                    <div style={{ fontSize: "1.1rem" }}>
                      {!historic ? (
                        // <>
                        //   {myFile[0] ? <b>File Received : {myFile[0].name}</b> : ""}
                        // </>
                        <>
                          {myFile[0] ? (
                            <div className="file-name-container-1">
                              <div className="file-name-container-2">
                                <div className="file-name-container-3">
                                  <b>File Received : {myFile[0].name}</b>
                                  <button
                                    className="close-button"
                                    onClick={onClose}
                                  >
                                    <AiOutlineClose className="close-icon" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        // <>
                        //   {myFile[0] ? (
                        //     <b>Files Received : {Object.keys(myFile).length}</b>
                        //   ) : (
                        //     ""
                        //   )}
                        // </>
                        <>
                          {Object.keys(myFile).length === 2 ? (
                            <div className="file-name-container-1">
                              <div className="file-name-container-2">
                                <div className="file-name-container-3">
                                  <b>
                                    {} {"  "}Files Received {"   "} :{" "}
                                    {myFile[0].name} {"   "}
                                    and
                                    {"   "}
                                    {myFile[1].name}
                                  </b>
                                  <button
                                    className="close-button"
                                    onClick={onClose}
                                  >
                                    <AiOutlineClose className="close-icon" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {Object.keys(myFile).length > 2 ? (
                            <div className="file-name-container-1">
                              <div className="file-name-container-2">
                                <div className="file-name-container-3">
                                  <b>
                                    {} {"  "}Files Received {"   "} :{" "}
                                    {myFile[0].name} {"   "},{"   "}
                                    {myFile[1].name} {"   "}
                                    and {"  "} {Object.keys(myFile).length - 2}{" "}
                                    more
                                  </b>
                                  <button
                                    className="close-button"
                                    onClick={onClose}
                                  >
                                    <AiOutlineClose className="close-icon" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </span>
                </FileDrop>
              </div>
              {announcement &&
                (center === "curis" || center === "testinguser") && (
                  <div
                    style={{
                      backgroundColor: "white",
                      // position: 'absolute',
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // border: '1px solid grey',
                      marginLeft: "10px",
                      padding: "10px",
                      transition: "linear 1s",
                    }}
                  >
                    <button
                      style={{
                        position: "absolute",
                        right: "30px",
                        // alignSelf: 'flex-end',
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => {
                        handleAnnouncement();
                      }}
                    >
                      X
                    </button>
                    <img
                      alt="anouncement"
                      style={{ height: "48px" }}
                      src="https://res.cloudinary.com/tejeshreddy17/image/upload/v1677148775/download_baonld.png"
                    />
                    <h1
                      style={{
                        fontSize: "14px",
                        marginBottom: "8px",
                        marginTop: "15px",
                        // fontFamily: 'sans-serif',
                      }}
                    >
                      VIDEO SMART REPORT
                    </h1>
                    <p style={{ fontSize: "11Spx", textAlign: "center" }}>
                      Video link is now generated along with smart report
                    </p>
                  </div>
                )}
            </div>
            <div className="row">
              <div className="col">
                {/* {chooseFile ? */}

                {/* :
                    null
                } */}
              </div>
            </div>

            {/* <Form.Group
        style={{ display: refRangesStatus ? '' : 'none' }}
        className='mb-4 button-focus-css'
        onSubmit={e => e.preventDefault()}
      >
        <div style={{ margin: '10px 0px -10px 0px' }}>
          <b>Upload Ref Ranges</b>
        </div>

        <input
          className='form-control mt-3'
          id='refRangesUpload'
          type='file'
          onChange={e => {
            setMyRefRangesFile(e.target.files);
            setError('');
          }}
        />

        <button onClick={refRangesHandler} className='btn btn-sm btn-info mt-3'>
          Save Ranges
        </button>
      </Form.Group> */}

            {/* 
            <button className="btn btn-info btn-sm button-focus-css" onClick={() => setChooseFile(!chooseFile)}>
                {chooseFile ? "Cancel Upload" : "Choose File"}
            </button> */}
            {center === "srh" || center === "mayormedical" ? (
              <div className="historicButtons">
                <Radio
                  type="radio"
                  label="Historic"
                  name="historic"
                  checked={historic === true}
                  onChange={() => setHistoric(true)}
                >
                  Historic
                </Radio>
                <Radio
                  type="radio"
                  label="Non Historic"
                  name="historic"
                  checked={historic !== true}
                  onChange={() => setHistoric(false)}
                >
                  Non Historic
                </Radio>
              </div>
            ) : (
              ""
            )}
            {showLoading ? (
              <button
                className="btn btn-success btn-sm"
                style={{ marginRight: "15px", marginTop: "15px" }}
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Getting Tests Preview...
              </button>
            ) : (
              <button
                onClick={async () => {
                  // setShowLoading(true);

                  if (historic) {
                    // generatingSmartReport();
                    newBackendParsing();
                  } else {
                    fileHandler();
                    setBase64Array([]);
                    // fetchingDataFromParser();
                  }
                }}
                className="btn btn-success btn-sm button-focus-css"
                style={{ marginRight: "15px", marginTop: "15px" }}
              >
                {historic || csvInputflag ? (
                  generationLoading ? (
                    <span>
                      Generating Report{"          "}
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </span>
                  ) : (
                    "Generate Report"
                  )
                ) : (
                  "Preview Tests"
                )}
              </button>
            )}
            {center === "pfh" ? (
              <Link to="/STI_Report">
                <button
                  className="btn btn-success btn-sm"
                  style={{ marginRight: "15px", marginTop: "15px" }}
                  type="button"
                >
                  Enter Manual STI Report
                </button>
              </Link>
            ) : (
              ""
            )}

            {/* <button onClick={() => generatingArrayBase64()}>Generate Report</button> */}
            {showPreview ? <TestPreview base64={pdfBase64} /> : ""}
            {base64Array.length !== 0 ? (
              <Table striped bordered className="text-center">
                <>
                  <thead>
                    <tr>
                      <th style={{ fontWeight: "600" }}>Sl.No</th>
                      {/* <th width="22%" style={{ fontWeight: "600" }}>
                      Method
                    </th> */}
                      <th style={{ fontWeight: "600" }}>Patient Name</th>
                      <th style={{ fontWeight: "600" }}>Sample ID</th>

                      <th style={{ fontWeight: "600" }}>
                        Download Link{" "}
                        <button
                          onClick={() => {
                            downloadZip();
                          }}
                          className="btn btn-outline-success btn-sm button-focus-css"
                          // className='zipAlReports'
                        >
                          zip All Reports
                        </button>
                      </th>
                      {/* <th style={{ fontWeight: '600' }}>Video Link</th> */}
                    </tr>
                  </thead>
                  <tbody
                    className="text-center patientDetailsTableBody"
                    // style={{ overflow: 'visible' }}
                  >
                    {base64Array.map((eachResponse) => (
                      <tr>
                        <td>{eachResponse.slNo}</td>
                        <td>{eachResponse.patientName}</td>
                        <td>{eachResponse.sampleId}</td>
                        <td>
                          {eachResponse.base64 !==
                          "Smart Report Generation Error" ? (
                            <span
                              onClick={() => {
                                downloadPDF(
                                  eachResponse.base64,
                                  eachResponse.patientName
                                );
                              }}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              download pdf <i className="fas fa-download"></i>
                            </span>
                          ) : (
                            "Generation Error"
                          )}
                        </td>
                        {/* <td>
                      <button
                        className='btn btn-success btn-sm button-focus-css'
                        onClick={() => {
                          handleCopyStatus(eachResponse);
                        }}
                      >
                        Copy Video Link
                      </button>
                      {eachResponse.copyStatus && (
                        <TiTick size={'20'} color={'#38AF48'} />
                      )}
                    </td> */}
                      </tr>
                    ))}
                  </tbody>
                </>
              </Table>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        showLockedView()
      )}
    </Stack>
  );
};

export default DragDropCSV;
