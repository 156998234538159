import { Label } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DownloadIcon from "@mui/icons-material/Download";

import { useEffect, useState } from "react";
import { getCurrentWeekDates } from "../utils";

const AbnormalMetrics = ({ isProfile }) => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [abnormalData, setAbnormalData] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);
  const loginData = JSON.parse(localStorage.getItem("credentials"));

  const fetchProfileAbnormalData = async () => {
    setLoader(true);
    const apiUrl = isProfile
      ? `https://api.niroggyan.com/analytics/smartreport/profile/data?start=${startDate}&end=${endDate}&filter_by=abnormal`
      : `https://api.niroggyan.com/analytics/smartreport/parameter/data?start=${startDate}&end=${endDate}&filter_by=abnormal
    `;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("Parameter", data);
      if (data.isSuccess) {
        setAbnormalData(data.data);
        const link = document.createElement("a");
        link.href = data.data;
        document.body.appendChild(link);
        link.click();
        setLoader(false);
        document.body.removeChild(link);
      }
    } catch (e) {
      setLoader(false);
      console.log("Error", e);
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
  }, []);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (option) => {
    setSelectedMetric(option);
    switch (option) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  return (
    <Box flexGrow={1}>
      <Typography variant="h2" mb={2}>
        {isProfile
          ? "Report Wise Abnormal Profiles"
          : "Report Wise Abnormal Biomarkers"}
      </Typography>
      <Stack my={2} direction="row" gap={3}>
        <FormControl
          size="small"
          variant="filled"
          disabled={
            loginData?.disableParameterData && !isProfile ? true : false
          }
        >
          <InputLabel id="demo-select-small-label">Time Period</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedMetric}
            label="Date"
            onChange={(e) => handleSelection(e.target.value)}
            onClick={(e) => {
              if (e.target.innerText === "Custom Month") {
                handleSelection("customMonth");
              }
            }}
          >
            <MenuItem value="currentWeek">Current Week</MenuItem>
            <MenuItem value="currentMonth">Current Month</MenuItem>
            <MenuItem value="previousMonth">Previous Month</MenuItem>
            <MenuItem value="customMonth">Custom Month</MenuItem>
          </Select>
        </FormControl>
        {customDateModal()}
        <Stack direction="row" alignItems="center" gap={2}>
          {/* <Button variant="contained" onClick={fetchProfileAbnormalData}>
            Download
          </Button> */}
          {loader ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              onClick={fetchProfileAbnormalData}
              disabled={
                loginData?.disableParameterData && !isProfile ? true : false
              }
            >
              Download{" "}
              <span>
                <DownloadIcon />
              </span>
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default AbnormalMetrics;
