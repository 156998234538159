import { Divider, Stack, Typography } from "@mui/material";

const CountContainer = ({ total, normal, abnormal }) => {
  return (
    <Stack
      py={3}
      px={2}
      sx={{ borderRadius: "8px", border: "1px solid #E7E7E7", height: "100%" }}
      alignItems="center"
      justifyContent="space-between"
      gap={2}
    >
      <Stack gap={2} alignItems="center">
        <Typography
          variant="body5"
          sx={{
            padding: "4px 8px",
            background: "#F8DEBD",
            borderRadius: "4px",
          }}
        >
          Total
        </Typography>
        <Typography variant="body6">
          {total ? total.toLocaleString("en-IN") : 0}
        </Typography>
      </Stack>
      <Divider flexItem variant="middle" />
      <Stack gap={2} alignItems="center">
        <Typography
          variant="body5"
          sx={{
            padding: "4px 8px",
            background: "#F8DEBD",
            borderRadius: "4px",
          }}
        >
          Normal
        </Typography>
        <Typography variant="body6">
          {normal ? normal.toLocaleString("en-IN") : 0} {"("}
          {total ? Math.round((normal / total) * 100) : 0}
          {"%)"}
        </Typography>
      </Stack>
      <Divider flexItem variant="middle" />
      <Stack gap={2} alignItems="center">
        <Typography
          variant="body5"
          sx={{
            padding: "4px 8px",
            background: "#F8DEBD",
            borderRadius: "4px",
          }}
        >
          Abnormal
        </Typography>
        <Typography variant="body6">
          {abnormal ? abnormal.toLocaleString("en-IN") : 0}
          {"("}
          {total ? Math.round((abnormal / total) * 100) : 0}
          {"%)"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CountContainer;
