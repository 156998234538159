import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { Line } from "rc-progress";
import "../../css/topFivePackageCss.css";
import axios from "axios";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getCurrentWeekDates } from "../utils";

// let dataLoaded = false;

const TopFivePackageSection = ({ productType }) => {
  const [renderJsxStatus, setRenderJsxStatus] = useState(false);
  const [pieDataCurrentMonth, setPieDataCurrentMonth] = useState(true);
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [loginData, setLoginData] = useState("");
  const [pieDataPastMonth, setPieDataPastMonth] = useState(false);
  const [clientPreference, setClientPreference] = useState("");
  const [fetchedData, setFetchedData] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  // fetch data from local storage
  useEffect(() => {
    setPieDataCurrentMonth(false);
    setPieDataPastMonth(false);

    // let homepageData = JSON.parse(localStorage.getItem('homepageData'));
    let loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    let clientPreferenceInfo = JSON.parse(
      localStorage.getItem("clientPreference")
    );

    if (true) {
      //   setFetchedData(homepageData);
      setRenderJsxStatus(true);
    }
    if (loginInfo) {
      setLoginData(loginInfo);
    }
    if (clientPreferenceInfo) {
      setClientPreference(clientPreferenceInfo);
    }
  }, []);

  const getIndividualPackageCounts = async (startDate, endDate) => {
    setLoader(true);
    const clientPreference = JSON.parse(
      localStorage.getItem("clientPreference")
    );
    const orgPreference = JSON.parse(localStorage.getItem("orgPreference"));

    const clientIdPreference = JSON.parse(
      localStorage.getItem("clientIdPreference")
    );
    const idToken = localStorage.getItem("idTokenBilling");
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "get",
      url: `https://api.niroggyan.com/billing/${productType}/counts?query_on=packages&query_by=range&start=${startDate}&end=${endDate}${
        orgPreference && clientPreference
          ? `&org=${orgPreference}&center=${clientPreference}`
          : ""
      }${clientIdPreference ? `&clientId=${clientIdPreference}` : ``}`,
      validateStatus: function (status) {
        return (status >= 200) & (status < 510);
      },
    };
    const response = await axios(configBody);
    setLoader(false);
    return response;
    // setFetchedData(response.data);
  };

  const formattedStartDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    return requiredTime;
  };

  const formattedEndDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formattedStartDate(startDate),
      endDate: formattedEndDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formattedStartDate(startDate),
      endDate: formattedEndDate(endDate),
    };
  };

  const currentMonthPackageWiseCounts = async () => {
    const startDate = getCurrentMonthStartEndDates().startDate;
    const endDate = getCurrentMonthStartEndDates().endDate;
    (async function () {
      const response = await getIndividualPackageCounts(startDate, endDate);
      setFetchedData(response.data.data);
    })();
  };

  const currentWeekPackageWiseCounts = async () => {
    const startDate = getCurrentWeekDates().startDate;
    const endDate = getCurrentWeekDates().endDate;
    (async function () {
      const response = await getIndividualPackageCounts(startDate, endDate);
      setFetchedData(response.data.data);
    })();
  };

  const PreviousMonthPackageWiseCounts = async () => {
    const startDate = getPreviousMonthStartEndDates().startDate;
    const endDate = getPreviousMonthStartEndDates().endDate;
    (async function () {
      const response = await getIndividualPackageCounts(startDate, endDate);
      console.log("previous Response", response);
      setFetchedData(response.data.data);
    })();
  };

  useEffect(() => {
    const storedPackagesData = JSON.parse(localStorage.getItem("packagesData"));

    if (storedPackagesData && Object.keys(storedPackagesData)?.length) {
      setFetchedData(storedPackagesData);
    } else {
      // dataLoaded &&
      (async function () {
        const today = new Date().getDate();
        // const startDate =
        //   today < 5
        //     ? getPreviousMonthStartEndDates().startDate
        //     : getCurrentMonthStartEndDates().startDate;
        // const endDate =
        //   today < 5
        //     ? getPreviousMonthStartEndDates().endDate
        //     : getCurrentMonthStartEndDates().endDate;
        const startDate = getCurrentWeekDates().startDate;
        const endDate = getCurrentWeekDates().endDate;
        // Receiving Data from API
        const response = await getIndividualPackageCounts(startDate, endDate);
        setFetchedData(response.data.data);
        localStorage.setItem(
          "packagesData",
          JSON.stringify(response.data.data)
        );
      })();
      // dataLoaded = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [productType]);

  let top5packagesPercentages = [];
  let totalOfAll5Package = [];
  let top5packages = [];

  // sum of all top 5 package counts
  totalOfAll5Package = fetchedData
    ? fetchedData.values.reduce(
        (partialSum, a) => parseInt(partialSum) + parseInt(a),
        0
      )
    : "";

  top5packages = fetchedData ? fetchedData.keys : "";

  for (let i = 0; i < top5packages.length; i++) {
    let reportCountOfPackage = fetchedData.values[i];
    let percentageOfReportCount = Math.round(
      (reportCountOfPackage / totalOfAll5Package) * 100
    );
    top5packagesPercentages.push(percentageOfReportCount);
  }

  // sorting in packages in descending order
  top5packagesPercentages.sort(function (a, b) {
    return b - a;
  });

  let colorsForTop5Packages = [
    "#ff6384",
    "#36a2eb",
    "#ffcd56",
    "#ffe0e6",
    "#d7ecfb",
  ];

  // pie chart
  let allTheData = {
    series: top5packagesPercentages,
    options: {
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex] + "%";
        },
      },
      colors: colorsForTop5Packages,
      legend: {
        show: false,
      },
      chart: {
        width: "380px",
        type: "pie",
      },
      labels: top5packages,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  const handleSelection = async (e) => {
    setSelectedMetric(e.target.value);
    switch (e.target.value) {
      case "currentMonth":
        setPieDataCurrentMonth(true);
        setPieDataPastMonth(false);
        !pieDataCurrentMonth && (await currentMonthPackageWiseCounts());
        break;
      case "previousMonth":
        setPieDataCurrentMonth(false);
        setPieDataPastMonth(false);
        await PreviousMonthPackageWiseCounts();
        break;
      case "currentWeek":
        setPieDataCurrentMonth(false);
        setPieDataPastMonth(false);
        await currentWeekPackageWiseCounts();
        break;
      default:
    }
  };

  let regrex = /WellWise|Wellwise|wellwise/gi;

  const renderJSX = () => {
    return (
      <Box p={0} flexGrow={1} alignSelf="stretch">
        <Stack
          direction="row"
          justifyContent="space-between"
          mb={2}
          alignItems="center"
        >
          <Typography variant="h2">
            {" "}
            {loginData &&
            loginData.role === "LIS" &&
            clientPreference === "none"
              ? "Report Type Distribution"
              : "Top Health Packages"}
          </Typography>
          <FormControl
            sx={{ m: 1, minWidth: 120 }}
            size="small"
            variant="filled"
          >
            <InputLabel id="demo-select-small-label">Time Period</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedMetric}
              label="Count"
              onChange={handleSelection}
            >
              <MenuItem value="currentWeek">Current Week</MenuItem>
              <MenuItem value="currentMonth">Current Month</MenuItem>
              <MenuItem value="previousMonth">Previous Month</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {top5packagesPercentages.length === 0 && !loader ? (
          <Typography sx={{ color: "#7787bc" }}>No Data to display</Typography>
        ) : loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70%",
            }}
          >
            <Spinner animation="border" size="md" />
          </div>
        ) : (
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{
              borderRadius: "16px",
              boxShadow: "0px 15px 30px 0px rgba(8, 7, 45, 0.15)",
            }}
          >
            <Table sx={{ minWidth: "90%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body4">Name of Package</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body4">Count</Typography>
                  </TableCell>
                  {/* <TableCell align="right" sx={{ fontWeight: "700" }}>
                    Percentage
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {fetchedData &&
                  fetchedData.keys.map((each, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="h5">
                          {each?.replace(regrex, "HealthCheck")}
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Typography variant="h5">
                          {parseInt(fetchedData.values[idx]).toLocaleString()}
                          {"("}
                          {Math.round(
                            (fetchedData.values[idx] / totalOfAll5Package) * 100
                          )}
                          {"%"}
                          {")"}
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="right">
                        <Typography
                          sx={{
                            background: "#dfe6e8",
                            padding: "6px 0px",
                            width: "50px",
                            display: "inline-block",
                            borderRadius: "8px",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          {Math.round(
                            (fetchedData.values[idx] / totalOfAll5Package) * 100
                          )}
                          {"%"}
                        </Typography>
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    );
  };

  // const renderJSX = () => {
  //   return (
  //     <div className="mb-4">
  //       <Card>
  //         <Card.Body>
  //           <Card.Title className="topFivePackageButtonsCss">
  //             <div>
  //               {loginData &&
  //               loginData.role === "LIS" &&
  //               clientPreference === "none"
  //                 ? "Report Type Distribution"
  //                 : "Top Five Packages"}
  //             </div>
  //             {/* <div className="topFivePackageButtonsMarginsCss">
  //               <button
  //                 style={{
  //                   color:
  //                     pieDataCurrentMonth && !pieDataPastMonth ? "#fff" : "",
  //                   backgroundColor:
  //                     pieDataCurrentMonth && !pieDataPastMonth ? "#3567D6" : "",
  //                 }}
  //                 onClick={async () => {
  //                   setPieDataCurrentMonth(true);
  //                   setPieDataPastMonth(false);
  //                   !pieDataCurrentMonth &&
  //                     (await currentMonthPackageWiseCounts());
  //                 }}
  //                 className="top5packageButtonCss"
  //               >
  //                 <span className="packageCurrentMonthTextCss"></span>
  //               </button>
  //               <button
  //                 style={{
  //                   color:
  //                     !pieDataCurrentMonth && !pieDataPastMonth ? "#fff" : "",
  //                   backgroundColor:
  //                     !pieDataCurrentMonth && !pieDataPastMonth
  //                       ? "#3567D6"
  //                       : "",
  //                 }}
  //                 onClick={async () => {
  //                   setPieDataCurrentMonth(false);
  //                   setPieDataPastMonth(false);
  //                   await PreviousMonthPackageWiseCounts();
  //                 }}
  //                 className="top5packageButtonCss"
  //               >
  //                 <span className="packagePreviousMonthTextCss"></span>
  //               </button>
  //               <button
  //                 style={{
  //                   display:
  //                     loginData &&
  //                     loginData.role === "admin" &&
  //                     clientPreference === "none"
  //                       ? ""
  //                       : "none",
  //                   color:
  //                     pieDataPastMonth && !pieDataCurrentMonth ? "#fff" : "",
  //                   backgroundColor:
  //                     pieDataPastMonth && !pieDataCurrentMonth ? "#3567D6" : "",
  //                 }}
  //                 onClick={() => {
  //                   setPieDataCurrentMonth(false);
  //                   setPieDataPastMonth(true);
  //                 }}
  //                 className="top5packageButtonCss"
  //               >
  //                 <span className="packagePastMonthTextCss"></span>
  //               </button>
  //             </div> */}
  //           </Card.Title>

  //           <div className="pieContentsResolutionCss">
  //             <div
  //               className={
  //                 top5packagesPercentages.length === 0
  //                   ? ""
  //                   : "pieLeftContentCss"
  //               }
  //               style={{ display: "flex", justifyContent: "center" }}
  //             >
  //               <Card.Subtitle>
  //                 {top5packagesPercentages.length === 0 && !loader ? (
  //                   <span style={{ color: "#7787bc" }}>
  //                     <span>No Data to display</span>
  //                   </span>
  //                 ) : loader ? (
  //                   <Spinner animation="border" size="sm" />
  //                 ) : (
  //                   // <ReactApexChart
  //                   //   options={allTheData.options}
  //                   //   series={allTheData.series}
  //                   //   type="pie"
  //                   //   width={380}
  //                   // />
  //                   renderTable()
  //                 )}
  //               </Card.Subtitle>
  //             </div>

  //             <div className="pieRightContentCssWidth">
  //               {/* Current Month Count (for pie chart left content) */}
  //               <span
  //                 style={{
  //                   display: pieDataCurrentMonth && !pieDataPastMonth ? "" : "",
  //                 }}
  //               >
  //                 {fetchedData &&
  //                   fetchedData.keys.map((each, idx) => (
  //                     <>
  //                       <div key={each - idx} style={{ marginBottom: "2px" }}>
  //                         <div className="pieRightContentLineHeaderCss">
  //                           {each} {"("} {fetchedData.values[idx]} {")"} {""}{" "}
  //                           {Math.round(
  //                             (fetchedData.values[idx] / totalOfAll5Package) *
  //                               100
  //                           )}
  //                           {"%"}
  //                         </div>
  //                         <Line
  //                           className="pieRightContentLineCss"
  //                           percent={Math.round(
  //                             (fetchedData.values[idx] / totalOfAll5Package) *
  //                               100
  //                           )}
  //                           strokeColor={colorsForTop5Packages[idx]}
  //                         />
  //                       </div>
  //                     </>
  //                   ))}
  //               </span>
  //             </div>
  //           </div>
  //         </Card.Body>
  //       </Card>
  //     </div>
  //   );
  // };

  return <>{renderJsxStatus ? renderJSX() : ""}</>;
};

export default TopFivePackageSection;
