import { useEffect, useState } from 'react';

const MaintenancePage = () => {
  const [dummyState, setDummyState] = useState(false);

  window.addEventListener('storage', e => {
    setDummyState(!dummyState);
    window.location.reload();
  });

  // setTimeout(() => window.location.reload(), 200);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh',
        justifyContent: 'center',
      }}
    >
      <img
        style={{ height: '200px' }}
        src='https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/images.png'
        alt='maintenance'
      />
    </div>
  );
};

export default MaintenancePage;
