import { Box, FormControlLabel, RadioGroup, Stack } from "@mui/material";
import Radio from "@mui/material/Radio";
import { useState } from "react";

import ProfileContent from "./contentManagement/ProfileContent";
import ParameterContent from "./contentManagement/ParameterContent";
import VersionManagement from "./contentManagement/VersionManagement";

const ContentManagement = () => {
  const [contentType, setContentType] = useState("profilecontent");
  const [editVersion, setEditVersion] = useState("");

  const renderSpecificPContentType = () => {
    switch (contentType) {
      case "profilecontent":
        return (
          <ProfileContent
            version={editVersion}
            setEditVersion={setEditVersion}
          />
        );
      case "parametercontent":
        return (
          <ParameterContent
            version={editVersion}
            setEditVersion={setEditVersion}
          />
        );
      case "versionmanagement":
        return (
          <VersionManagement
            version={editVersion}
            setEditVersion={setEditVersion}
            setContentType={setContentType}
          />
        );
      default:
        return <ProfileContent />;
    }
  };
  return (
    <Stack>
      <Box pl={2}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={contentType}
          onChange={(e) => setContentType(e.target.value)}
        >
          <FormControlLabel
            value="profilecontent"
            control={<Radio />}
            label="Profile Content"
          />
          <FormControlLabel
            value="parametercontent"
            control={<Radio />}
            label="Parameter Content"
          />
          <FormControlLabel
            value="versionmanagement"
            control={<Radio />}
            label="Version Management"
          />
        </RadioGroup>
      </Box>
      {renderSpecificPContentType()}
    </Stack>
  );
};

export default ContentManagement;
