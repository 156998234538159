import { Box, FormControlLabel, RadioGroup, Stack } from "@mui/material";
import Radio from "@mui/material/Radio";
import { useState } from "react";
import ProfileMapping from "./mapping/ProfileMapping";
import ParameterMapping from "./mapping/ParameterMapping";

const Mapping = () => {
  const [mappingType, setMappingType] = useState("parameterMapping");

  const renderSpecificMappingType = () => {
    switch (mappingType) {
      case "profileMapping":
        return <ProfileMapping />;
      default:
        return <ParameterMapping />;
    }
  };
  return (
    <Stack>
      <Box pl={2}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={mappingType}
          onChange={(e) => setMappingType(e.target.value)}
        >
          <FormControlLabel
            value="parameterMapping"
            control={<Radio />}
            label="Parameter Mapping"
          />
          <FormControlLabel
            value="profileMapping"
            control={<Radio />}
            label="Profile Mapping"
          />
        </RadioGroup>
      </Box>
      {renderSpecificMappingType()}
    </Stack>
  );
};

export default Mapping;
