import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios'
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider } from "react-alert";  
import { useAlert } from "react-alert";

const SignUpAdmin = () => {

  const alert = useAlert();

  const [typeOfNewClient, setTypeOfNewClient] = useState("")
  const [noticeChangeInValue, setNoticeChangeInValue] = useState(true)
  const [expectedJson, setExpectedJson] = useState("")

  const [userId, setUserId] = useState("")
  const [clientId, setClientId] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [org, setOrg] = useState("")
  const [center, setCenter] = useState("")
  const [reportsSubscribed, setReportsSubscribed] = useState(0)
  const [subscriptionType, setSubscriptionType] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [subscriptionStatus, setSubscriptionStatus] = useState("on hold")
  const [showBilling, setShowBilling] = useState(true)
  const [logoUrl, setLogoUrl] = useState("https://res.cloudinary.com/nithish0504/image/upload/v1652856436/niroggyan-logo_pc2fef.svg")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")


  useEffect(() => {
    let currentLocation = window.location.href
    let clientType = currentLocation.substring(currentLocation.lastIndexOf('/') + 1)
    setTypeOfNewClient(clientType)
  }, [])

  const handleRequiredJson = async () => {

    let theJson = {
      "userId": userId,
      "center": "none",
      "displayName": "Niroggyan",
      "logoSrc": "https://res.cloudinary.com/nithish0504/image/upload/v1652856436/niroggyan-logo_pc2fef.svg",
      "org": "none",
      "password": password,
      "startDate": "2022-03-01 00:00:00.000",
      "endDate": "2022-08-30 23:59:59.000",
      "role": "admin",
      "showBilling": false,
      "subscription": "",
      "subscriptionStatus": "live"
    }

    await setExpectedJson(theJson)
    await setNoticeChangeInValue(false)
  }

  const handleFormSubmission = (e) => {
    e.preventDefault()
    if (password === confirmPassword && password !== "" && confirmPassword !== "") {
      sendDataToBackend()
    }
    else {
      alert.error("Passwords do not match!")
    }
  }

  const sendDataToBackend = async () => {
    var config = {
      method: 'POST',
      // url: "",
      url: "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/subscription/signup",
      headers: {
        'Content-Type': 'application/json'
      },
      data: expectedJson
      //data: dummyData
    };

    console.log("what im sending:", expectedJson)

    try {
      const axiosData = await axios(config)
      console.log("inside axiosdata ===> \n\n", axiosData)
      alert.success("Signup Successfull")

    } catch (error) {
      console.log("Error for Admin:", error)
      alert.error("Signup Failed!");
    }
  }

  // react alert template options
  const options = {
    timeout: 5000,
    position: 'bottom left'
  }

  return (
    <>
      <Provider template={AlertTemplate} {...options}>
        <div style={{ color: "#3c72eb", textAlign: "center", fontWeight: "bold" }}>
          {"Signing Up"} {typeOfNewClient.toUpperCase()}
        </div>
        <hr />

        <Form onSubmit={handleFormSubmission}>
          <div style={{ display: "flex", gap: "20px" }}>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon1">{"User ID"}</InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setUserId(e.target.value)
                }}
                placeholder="user id"
                aria-label="userid"
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            {/* <InputGroup className="mb-3">
            <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon2">{"Client Id"}</InputGroup.Text>
            <Form.Control
              // required
              onChange={(e) => {
                setNoticeChangeInValue(true)
                setClientId(e.target.value)
              }}
              placeholder="client id"
              aria-label="clientId"
              aria-describedby="basic-addon2"
            />
          </InputGroup>           */}
          </div>


          <div style={{ display: "flex", gap: "20px" }}>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon13">
                {"Password"}
              </InputGroup.Text>
              <Form.Control
                type="password"
                className='pass_show'
                defaultValue={password}
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setPassword(e.target.value)
                }}
                aria-label="password"
                placeholder="password"
                aria-describedby="basic-addon13"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon14">
                {"Confirm Password"}
              </InputGroup.Text>
              <Form.Control
                type="password"
                defaultValue={confirmPassword}
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true)
                  setConfirmPassword(e.target.value)
                }}
                aria-label="confirmpassword"
                placeholder="retype password"
                aria-describedby="basic-addon14"
              />
            </InputGroup>


          </div>


          <div className='my-3' style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
            <button className='btn btn-info btn-sm' type='button' onClick={handleRequiredJson}>Confirm Changes</button>
            <button disabled={noticeChangeInValue} className='btn btn-sm' style={{ backgroundColor: "#3c72eb", color: "#fff" }} type="submit">Submit</button>
          </div>
        </Form>
      </Provider>
    </>
  )
}

export default SignUpAdmin