import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  NavbarBrand,
  Dropdown,
  Modal,
  Spinner,
  Offcanvas,
} from "react-bootstrap";
import DownloadSvg from "../svgs/DownloadSvg";
import LogoutComponent from "./LogoutComponent";
import ThyrocareIcon from "../svgs/ThyrocareIcon";
import UserSvg from "../svgs/UserSvg";
import "../css/navbarCss.css";
import NiroggyanSvg from "../svgs/NiroggyanSvg";
import axios from "axios";
import { orgCenterData } from "../specs/orgCenterData";
import { Button, FormControl } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import ExploreIcon from "@mui/icons-material/Explore";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Feedback from "./Feedback";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArticleIcon from "@mui/icons-material/Article";
import ComputerIcon from "@mui/icons-material/Computer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RedeemIcon from "@mui/icons-material/Redeem";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { RiEditBoxFill } from "react-icons/ri";
import { SiGoogleanalytics } from "react-icons/si";
import { useAlert } from "react-alert";

//import EditIcon from "@mui/icons-material/Edit";

const NavbarComponent = ({ setNavBarTrigerred }) => {
  const [loginData, setLoginData] = useState("");
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [loadingCenterData, setLoadingCenterData] = useState(false);
  const [orgAndCentersData, setOrgAndCentersData] = useState([]);
  const [selectionBasedCenters, setSelectionBasedCenters] = useState([]);
  const [orgChoosen, setOrgChoosen] = useState("");
  const [centerChoosen, setCenterChoosen] = useState("");
  const [typeOfNewClient, setTypeOfNewClient] = useState("");
  const [activeTab, setActiveTab] = useState("Subscription");
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();

  console.log("loginData", loginData);

  // modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // modal state and functions
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  //notification modal state & functions
  const [showNotification, setShowNotification] = useState(false);
  const handleNoficationOpen = () => setShowNotification(true);
  const handleNoficationClose = () => setShowNotification(false);

  // feedbackmodal
  const [showFeedback, setShowFeedback] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // maintenance page
  const [maintenance, setMaintenance] = useState(false);

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  let logoToUse =
    localStorage.getItem("logoUrl") === "undefined"
      ? "https://res.cloudinary.com/nithish0504/image/upload/v1652856436/niroggyan-logo_pc2fef.svg"
      : JSON.parse(localStorage.getItem("logoUrl"));

  // let loginStatus = JSON.parse(localStorage.getItem("loginDetails"))

  console.log("logo to use: ", logoToUse);
  // console.log("loginStatus\n", loginStatus)

  useEffect(() => {
    const loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    const maintenance = JSON.parse(localStorage.getItem("maintenance"));
    setMaintenance(maintenance);

    if (loginInfo) {
      setLoginData(loginInfo);
    }
  }, [reRenderComponent]);

  // getting orgs and centres
  useEffect(() => {
    const loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    let allData = [];
    if (loginInfo) {
      // for (const property in orgCenterData) {
      //   // console.log(`=> ${property}: ${orgCenterData[property]}`);
      //   let theKeyAndValue = `${property}: ${orgCenterData[property]}`;
      //   allData.push({ theOrg: property, theCenters: orgCenterData[property] });
      // }
      let data = [];
      let Data = {};
      for (let index in loginInfo.clientData) {
        if (loginInfo.clientData[index].org in Data) {
          Data[loginInfo.clientData[index].org].theCenters.push(
            loginInfo.clientData[index].center
          );
        } else {
          Data[loginInfo.clientData[index].org] = {
            theOrg: loginInfo.clientData[index].org,
            theCenters: [loginInfo.clientData[index].center],
          };
        }
      }
      for (let each in Data) {
        data.push(Data[each]);
      }
      console.log("loginInfo.clientData", loginInfo.clientData);
      // console.log(allData);
      // console.log(data);
      setOrgAndCentersData(data);
    }
  }, [reRenderComponent]);

  useEffect(() => {
    switch (location.pathname) {
      case "/subscription":
        setActiveTab("Subscription");
        break;
      case "/analytics":
        setActiveTab("Analytics");
        break;
      case "/support":
        setActiveTab("Technical Support");
        break;
      case "/client-data":
        setActiveTab("Edit Subscription");
        break;
      case "/manualReports":
        setActiveTab("Manual Generation");
        break;
      case "/refer":
        setActiveTab("Refer");
        break;
      case "/configurations/mapping":
        setActiveTab("Configurations");
        break;
      case "/configurations/product-config":
        setActiveTab("Configurations");
        break;
      case "/configurations/content-management":
        setActiveTab("Configurations");
        break;
      default:
        setActiveTab("Choose Org & Center");
    }
  }, [location.pathname]);

  // auto logout on session expiry

  useEffect(() => {
    const startTime = new Date(
      parseInt(localStorage.getItem("sessionStartTime"))
    );
    const timeLeft = startTime - new Date().getTime();

    console.log("timeLeft", startTime, timeLeft);

    if (timeLeft <= 0) {
      logoutHandler();
    } else {
      const timeId = setTimeout(() => {
        logoutHandler();
      }, timeLeft);
      return () => clearTimeout(timeId);
    }
  }, []);

  const feedbackFlag = localStorage.getItem("feedbackShown");
  console.log("feedbackFlag", feedbackFlag);

  // feedback popoup
  useEffect(() => {
    if (loginData.role === "lab" && !feedbackFlag) {
      setTimeout(() => {
        setShowFeedback(true);
        localStorage.setItem("feedbackShown", "true");
      }, 30000);
    }
  }, [loginData]);

  const getCenterData = async (theId) => {
    console.log("getting center data", theId);
    handleShow();

    let endpointToUse;

    if (theId === "trial") {
      endpointToUse = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/counts/?org=${
        loginData ? loginData.org : ""
      }&center=trial`;
    } else {
      endpointToUse = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${
        loginData ? loginData.org : ""
      }&center=${theId}`;
    }

    var config = {
      method: "GET",
      //url: '',
      url: endpointToUse,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (theId === "trial") {
        const axiosData = await axios(config);
        console.log(`center and org data\n`, axiosData);
        localStorage.setItem("trialClientsData", JSON.stringify(axiosData));
        localStorage.setItem("trialClientPreference", JSON.stringify("yes"));
        window.dispatchEvent(new Event("storage"));
        setReRenderComponent(!reRenderComponent);
        setTimeout(() => {
          handleClose();
        }, 1500);
      } else {
        // EXPIRING LOGIN SESSION
        // var datetime = new Date().toLocaleString().replace(',', '')

        const axiosData = await axios(config);
        //console.log(`center data of ${theId}\n`, axiosData)
        localStorage.setItem(
          "homepageData",
          JSON.stringify({ data: axiosData.data })
        );
        localStorage.setItem("clientPreference", JSON.stringify(theId));
        setReRenderComponent(!reRenderComponent);
        window.dispatchEvent(new Event("storage"));
        setTimeout(() => {
          handleClose();
        }, 1500);
        //window.location.reload()
        // localStorage.setItem("centerDetails", JSON.stringify(axiosData.data))
      }
    } catch (error) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      console.log("Center Endpoint Error:\n", error);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="search org"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const CustomMenu2 = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="search center"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const chooseCenterHandler = (theData) => {
    console.log("chooseCenterHandler have:", theData.theOrg);
    setOrgChoosen(theData.theOrg);
    setSelectionBasedCenters(theData.theCenters);
  };

  const customOrgAndCenterApiHandler = async () => {
    handleClose2();
    handleShow();

    let endpointToConsider;

    if (centerChoosen === "trial") {
      endpointToConsider = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/counts/?org=${orgChoosen}&center=trial`;
    } else {
      endpointToConsider = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${orgChoosen}&center=${centerChoosen}`;
    }

    console.log("the center and org endpoint\n", endpointToConsider);

    var config = {
      method: "GET",
      //url: '',
      url: endpointToConsider,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (centerChoosen === "trial") {
        const axiosData = await axios(config);
        console.log(`center and org data\n`, axiosData);
        localStorage.setItem("trialClientsData", JSON.stringify(axiosData));
        localStorage.setItem("trialClientPreference", JSON.stringify("yes"));
        window.dispatchEvent(new Event("storage"));
        setReRenderComponent(!reRenderComponent);
        setTimeout(() => {
          handleClose();
        }, 1500);
      } else {
        // const axiosData = await axios(config);
        // console.log(`center and org data\n`, axiosData);
        // localStorage.setItem(
        //   'homepageData',
        //   JSON.stringify({ data: axiosData.data })
        // );
        localStorage.setItem("clientPreference", JSON.stringify(centerChoosen));
        localStorage.setItem("orgPreference", JSON.stringify(orgChoosen));
        // set clientId of the center selected
        const loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
        const clientId = loginInfo.clientData.filter(
          (each) => each.org === orgChoosen
        )[0].clientId;

        localStorage.setItem("clientIdPreference", JSON.stringify(clientId));
        window.dispatchEvent(new Event("storage"));
        setReRenderComponent(!reRenderComponent);
        window.location.replace("/subscription");
        setTimeout(() => {
          handleClose();

          window.location.reload();
        }, 1500);
        //window.location.reload()
        // localStorage.setItem("centerDetails", JSON.stringify(axiosData.data))
      }
    } catch (error) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      console.log("Center Endpoint Error:\n", error);
    }
  };

  const handleNewClients = (clientRecieved) => {
    console.log("called..");
    setTypeOfNewClient(clientRecieved);
    handleClose2();
    window.open(`/new-client/${clientRecieved}`, "_self");
  };

  console.log("choosen data: ", orgChoosen, centerChoosen);

  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const logoutHandler = () => {
    localStorage.clear();
    localStorage.setItem("myLoginDetails", JSON.stringify(""));
    localStorage.setItem("clientPreference", JSON.stringify("none"));
    localStorage.setItem(
      "logoUrl",
      JSON.stringify(
        "https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/niroggyan-logo.svg"
      )
    );
    window.dispatchEvent(new Event("storage"));
    window.location.replace("/login");
    // window.location.reload()
  };

  const handleNavigation = (text) => {
    console.log("selectedText", text);
    localStorage.setItem("selectedTab", text);

    switch (text) {
      case "Subscription":
        navigate("/subscription");
        break;
      case "Analytics":
        navigate("/analytics");
        break;
      case "Edit Subscription":
        navigate("/client-data");
        break;
      case "Choose Org & Center":
        handleShow2();
        break;
      case "Technical Support":
        navigate("/support");
        break;
      case "Logout":
        logoutHandler();
        break;
      case "Manual Generation":
        navigate("/manualReports");
        break;
      case "Refer":
        navigate("/refer");
      case "Configurations":
        navigate("/configurations");
        break;
    }
  };

  console.log("activeTab", activeTab);

  const adminAndLisData = () => {
    return (
      <>
        {/* Modal Start (for loading center data) */}
        <div>
          <>
            <Modal show={show} onHide={handleClose} backdrop="static" centered>
              <Modal.Body style={{ textAlign: "center" }}>
                <Spinner animation="border" size="sm" /> Loading Data ...
              </Modal.Body>
            </Modal>
          </>
        </div>

        {/* Modal End */}

        {/* Modal Start (for dynamic org and center) */}
        <div>
          <>
            <Modal
              show={show2}
              onHide={handleClose2}
              dialogClassName="custom-modal"
              style={{ top: "80px" }}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  style={{ color: "#190e6c", display: "flex", gap: "12px" }}
                >
                  <div>{"ORG & CENTER"}</div>

                  <div>
                    <Dropdown style={{ position: "relative", top: "-3px" }}>
                      <Dropdown.Toggle
                        variant="info btn-sm"
                        id="dropdown-basic"
                        style={{ fontWeight: "bold" }}
                      >
                        New Client
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleNewClients("lab")}>
                          Add LAB
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleNewClients("lis")}>
                          Add LIS
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleNewClients("admin")}
                        >
                          Add ADMIN
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {/* <button
                                  style={{ marginLeft: "10px", fontWeight: "bold", position: "relative", top: "-3px" }}
                                  className='btn btn-sm btn-info'
                                  onClick={() => window.open("/new-client/", "_self")}
                              >
                                  {"New"} <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                              </button> */}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                {/* Choose Org Dropdown Start */}
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {"Choose Org "}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    as={CustomMenu}
                    style={{ overflow: "scroll", height: "300px" }}
                  >
                    {orgAndCentersData.map((each, idx) => (
                      <Dropdown.Item
                        eventKey={idx}
                        onClick={() => chooseCenterHandler(each)}
                      >
                        {each.theOrg}
                      </Dropdown.Item>
                    ))}
                    {/* <Dropdown.Item eventKey="3" active>
                                      Orange
                                  </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* Choose Org Dropdown End*/}

                {/* Choose Center Dropdown Start */}
                {orgChoosen === "" ? (
                  <span style={{ color: "grey" }}>{"Choose Center"}</span>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                    >
                      {"Choose Center "}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      as={CustomMenu2}
                      style={{ overflow: "scroll", height: "300px" }}
                    >
                      {selectionBasedCenters.map((each, idx) => (
                        <Dropdown.Item
                          eventKey={idx}
                          onClick={() => setCenterChoosen(each)}
                        >
                          {each}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {/* Choose Center Dropdown End */}
              </Modal.Body>

              <Modal.Body style={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "550" }}>{"Org: "}</span>
                <span style={{ color: "#7787bc" }}>{orgChoosen}</span>
                {" | "}
                <span style={{ fontWeight: "550" }}>{"Center: "}</span>
                <span style={{ color: "#7787bc" }}>{centerChoosen}</span>
              </Modal.Body>
              <Modal.Footer
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <a href="/subscription">
                  <Button
                    disabled
                    onClick={() =>
                      localStorage.setItem(
                        "choosenClient",
                        JSON.stringify({
                          org: orgChoosen,
                          center: centerChoosen,
                        })
                      )
                    }
                    variant="outline-success btn-sm d-none"
                  >
                    {"Edit Subscription"}
                  </Button>
                </a>

                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    variant="info btn-sm"
                    onClick={customOrgAndCenterApiHandler}
                  >
                    Fetch Info
                  </Button>
                  <Button variant="primary btn-sm" onClick={handleClose2}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        </div>

        {/* Modal End */}

        <>
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              // paddingTop: "10px",
            }}
            className="d-flex"
          >
            {/* <UserSvg /> */}
            <div
              className="mx-3"
              style={{
                display: loginData && loginData.role === "LIS" ? "" : "none",
              }}
            >
              {/* <DownloadSvg /> */}
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  className="dropdownCss"
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "#ecedef",
                    borderRadius: "20px",
                  }}
                >
                  {"Choose Clients"}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ textAlign: "center" }}>
                  {loginData && loginData.role === "LIS"
                    ? loginData.liveClients.map((each, idx) => (
                        <Dropdown.Item
                          key={idx}
                          onClick={() => getCenterData(each)}
                        >
                          <span>{each}</span>
                        </Dropdown.Item>
                      ))
                    : ""}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div
              className="align-item-center"
              style={{
                marginTop: "0px",
                marginRight: "16px",
                display:
                  loginData && loginData.role === "admin" ? "flex" : "none",
                alignItems: "center",
              }}
            >
              {/* <DownloadSvg /> */}
              {/* <a href="/client-data">
                <Button variant="outline-light btn-sm mx-2">
                  {"Edit Subscription"}
                </Button>
              </a> */}
              {/* <div
                onClick={handleShow2}
                className="dropdownCss"
                id="dropdown-basic"
                style={{
                  position: "relative",
                  // top: '8px',
                  cursor: "pointer",
                  backgroundColor: "trasparent",
                  border: "1px solid #ffffff",
                  // borderRadius: "20px",
                  // padding: "10px",
                }}
              >
                {"Choose Org & Center"}
              </div> */}
              {/* <Button variant="outline-light btn-sm mx-2" onClick={handleShow2}>
                {"Choose Org & Center"}
              </Button> */}
            </div>
          </div>
        </>
      </>
    );
  };

  const notificationModal = () => {
    return (
      <Dialog
        open={showNotification}
        onClose={handleNoficationOpen}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">Notifications</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Here are your recent notifications
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button onClick={handleNoficationClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const sideBarClientData1 = {
    Subscription: <HomeIcon />,
    Analytics: <ExploreIcon />,
  };

  const sideBarClientData2 = {
    Notification: <NotificationsIcon />,
    "Technical Support": <HelpIcon />,
    // "Business Query": <SupportAgentIcon />,
    Logout: <LogoutComponent />,
  };

  const sideBarAdminData1 = {
    Subscription: <HomeIcon />,
    "Edit Subscription": <EditIcon />,
    "Choose Org & Center": <GroupsIcon />,
  };

  const sideBarAdminData2 = {
    "Technical Support": <HelpIcon />,
    Logout: <LogoutComponent />,
  };

  const sideBarData1 =
    loginData && loginData.role === "lab"
      ? sideBarClientData1
      : sideBarAdminData1;

  const sideBarData2 =
    loginData && loginData.role === "lab"
      ? sideBarClientData2
      : sideBarAdminData2;

  const sideNavBar = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            background:
              loginData && loginData.role === "lab"
                ? "linear-gradient(to right, #1976d2 calc(100% - 200px), #ffffff 200px)"
                : "",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {/* <Typography variant="h6" noWrap component="div">
                Client Dashboard
              </Typography> */}
              <Stack
                direction="row"
                alignItems="center"
                gap={7}
                alignSelf="flex-end"
              >
                <Box>{adminAndLisData()}</Box>
                {loginData && loginData.role === "lab" ? (
                  <IconButton onClick={handleNoficationOpen}>
                    <NotificationsIcon
                      sx={{ color: "#fff", position: "relative" }}
                    />
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        display: "inline-block",
                        background: "red",
                        position: "absolute",
                        borderRadius: "50%",
                        top: "4px",
                        right: "4px",
                      }}
                    ></Box>
                  </IconButton>
                ) : (
                  ""
                )}
                {notificationModal()}
                {/* <Typography px={2}>
                  {loginData && loginData.displayName}
                </Typography> */}
                {loginData && loginData.role === "lab" ? (
                  <img
                    src={loginData.logoSrc}
                    alt={loginData.center}
                    height="50px"
                    width="150px"
                    style={{ objectFit: "contain", mixBlendMode: "multiply" }}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <Stack direction="row">
              <img
                alt="niroggyan_logo"
                src="https://niroggyan.s3.ap-south-1.amazonaws.com/websitelogos/Niroggyan_logo.svg"
              />
            </Stack>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Stack justifyContent="space-between" sx={{ height: "100vh" }}>
            <List>
              {Object.keys(sideBarData1).map((text) => (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <Tooltip title={text} placement="right">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => handleNavigation(text)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {sideBarData1[text]}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
            <List>
              {Object.keys(sideBarData2).map((text) => (
                <ListItem key={text} disablePadding sx={{ display: "block" }}>
                  <Tooltip title={text} placement="right">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => handleNavigation(text)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {/* {index === 0 ? (
                          <NotificationsIcon />
                        ) : index === 1 ? (
                          <HelpIcon />
                        ) : index === 2 ? (
                          <SupportAgentIcon />
                        ) : (
                          <LogoutComponent />
                        )} */}
                        {sideBarData2[text]}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Drawer>
      </Box>
    );
  };

  const newMenuBar = () => {
    const loginDetails = JSON.parse(localStorage.getItem("loginDetails"));
    const sideBarClientData = {
      Subscription: <HomeIcon />,
      Analytics: <SiGoogleanalytics size={20} />, // <MenuBookOutlinedIcon />
      "Manual Generation": <ArticleIcon />,
      "Technical Support": <PersonIcon />,
      Configurations: <RiEditBoxFill size={20} />,
      // Refer: <RedeemIcon />,
    };

    const sideBarAdminData = {
      Subscription: <HomeIcon />,
      "Edit Subscription": <EditIcon />,
      "Choose Org & Center": <GroupsIcon />,
      "Manual Generation": <ArticleIcon />,
      "Technical Support": <PersonIcon />,
    };

    const sideBarData =
      loginDetails && loginDetails.role === "admin"
        ? sideBarAdminData
        : sideBarClientData;

    const logoutData = {
      // Refer: <RedeemIcon />,
      // Logout: <LogoutIcon />,
    };

    const manualEntryData = {
      "Manual Generation": <ArticleIcon />,
    };

    const handleScroll = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const prevDate = new Date(
      `${new Date().getFullYear()}-${new Date().getMonth() - 1}-01`
    );
    console.log("prevDate", prevDate);
    const currentMonth = `${
      month[new Date().getMonth()]
    }-${new Date().getFullYear()}`;
    const prevMonth = `${
      month[prevDate.getMonth() + 1]
    }-${prevDate.getFullYear()}`;

    const dateStamp = () => {
      return (
        <Stack
          mt={2}
          sx={{
            display: isExpanded ? "" : "none",
            padding: "16px 24px",
            gap: 2,
            background: "#F9F9FB",
            borderRadius: "10px",
          }}
        >
          <Stack
            direction="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body8">Current Month</Typography>
            <Typography variant="body8">{currentMonth}</Typography>
          </Stack>
          {/* <Box>
            <LinearProgress variant="determinate" value={100} />
          </Box> */}
          {/* <Divider /> */}
          <Stack
            direction="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body8">Previous Month</Typography>
            <Typography variant="body8">{prevMonth}</Typography>
          </Stack>
          <Stack
            direction="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body8" sx={{ color: "#605D67" }}>
              Today
            </Typography>
            <Typography variant="body8">{new Date().toDateString()}</Typography>
          </Stack>
        </Stack>
      );
    };

    return (
      <Box
        px={2}
        py={3}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          height: "100vh",
          width: isExpanded ? "270px" : "90px",
          background: "#fff",
          // overflowY: "auto",
          flexShrink: 0,
          transition: "width 0.3s",
        }}
      >
        <Stack justifyContent="space-between" sx={{ height: "100%" }}>
          <Box flexGrow={1}>
            <Box
              sx={{
                textAlign: "center",
                borderBottom: "1px solid #E2E2E2",
                display: "flex",
                alignItems: "center",
              }}
              ml={1}
              mr={1}
              pb={3}
            >
              <IconButton
                onClick={() => {
                  setIsExpanded(!isExpanded);
                  // handleNavbarToggle();
                  setNavBarTrigerred((prev) => !prev);
                }}
                sx={{ marginRight: "10px" }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: isExpanded ? "" : "none" }}>
                <img
                  alt="niroggyan_logo"
                  src="https://niroggyan.s3.ap-south-1.amazonaws.com/websitelogos/Niroggyan_logo.svg"
                />
              </Box>
            </Box>
            {/* hamburger icon */}
            {/* <Box sx={{ padding: "10px" }}>
              <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                <MenuIcon />
              </IconButton>
            </Box> */}
            <Stack mt={0} gap={1}>
              {Object.keys(sideBarData).map((text, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  gap={2}
                  onClick={() => {
                    handleNavigation(text);
                  }}
                  sx={{
                    padding: "18px",
                    borderRadius: "8px",
                    // background: activeTab === text ? "#CAE9F4" : "",

                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  <Tooltip title={isExpanded ? "" : text} placement="right">
                    <Box
                      sx={{ color: activeTab === text ? "#3567D6" : "#9D9AA4" }}
                    >
                      {sideBarData[text]}
                    </Box>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    sx={{
                      flexGrow: "1",
                      color: activeTab === text ? "#3567D6" : "",
                      display: isExpanded ? "" : "none",
                    }}
                  >
                    {text}
                  </Typography>

                  {loginDetails &&
                  loginDetails.role === "lab" &&
                  ((index === 1 &&
                    !loginDetails.showAnalytics &&
                    text === "Analytics") ||
                    (index === 2 &&
                      !loginDetails?.accessTo?.portal &&
                      text === "Manual Generation")) ? (
                    <Box
                      sx={{
                        textAlign: "center",
                        display: isExpanded ? "" : "none",
                      }}
                      px={2}
                    >
                      <LockIcon />
                    </Box>
                  ) : (
                    ""
                  )}
                </Stack>
              ))}
            </Stack>
            {/* smart report tab */}
            {/* <Typography
          mt={1}
          variant="body2"
          sx={{ fontWeight: 700, padding: "12px 0px 12px 20px" }}
        >
          SMART REPORT
        </Typography>
        <Stack gap={1.5}>
          {Object.keys(manualEntryData).map((text) => (
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              onClick={() => handleNavigation(text)}
              sx={{
                padding: "10px 0px 10px 20px",
                borderRadius: "8px",
                fontWeight: 500,
                cursor: "pointer",
                background: activeTab === text ? "#CAE9F4" : "",
              }}
            >
              <Box>{manualEntryData[text]}</Box>
              <Typography variant="body2" sx={{ flexGrow: "1" }}>
                {text}
              </Typography>
              {loginDetails && !loginDetails?.accessTo?.portal ? (
                <Box sx={{ textAlign: "center" }} px={2}>
                  <LockIcon />
                </Box>
              ) : (
                ""
              )}
            </Stack>
          ))}
        </Stack> */}
            {/* smart report tab */}
            {/* account tab */}
            {/* <Typography
          mt={1}
          variant="body2"
          sx={{ fontWeight: 700, padding: "12px 0px 12px 20px" }}
        >
          ACCOUNT
        </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Stack>
              {Object.keys(logoutData).map((text) => (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  onClick={() => handleNavigation(text)}
                  sx={{
                    padding: "18px",
                    fontWeight: 500,
                    cursor: "pointer",
                    background: "#3567D6",
                    color: "#fff",
                  }}
                >
                  <Box>{logoutData[text]}</Box>
                  <Typography variant="body2" sx={{ color: "#fff" }}>
                    {text}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            {/* account tab */}
            {loginData.role === "lab" ? dateStamp() : ""}
          </Box>
          <Box>{adminAndLisData()}</Box>
        </Stack>
        <Stack gap={2}>
          {loginData.role === "lab" ? (
            <Paper
              sx={{
                zIndex: "999",
                padding: "10px 12px",
                textAlign: "center",
                borderRadius: "5px",
                fontWeight: "700",
                textDecoration: "none",
                textTransform: "uppercase",
                position: "fixed",
                bottom: "80px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowFeedback(true)}
            >
              <FeedbackIcon />
            </Paper>
          ) : (
            ""
          )}
          <Paper
            sx={{
              zIndex: "999",
              padding: "10px 12px",
              textAlign: "center",
              borderRadius: "5px",
              fontWeight: "700",
              textDecoration: "none",
              textTransform: "uppercase",
              position: "fixed",
              bottom: "20px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleScroll}
          >
            <ArrowUpwardIcon />
          </Paper>
        </Stack>
        <Feedback open={showFeedback} setOpen={setShowFeedback} />
      </Box>
    );
  };

  return newMenuBar();

  return (
    <>
      {/* Modal Start (for loading center data) */}
      <div>
        <>
          <Modal show={show} onHide={handleClose} backdrop="static" centered>
            <Modal.Body style={{ textAlign: "center" }}>
              <Spinner animation="border" size="sm" /> Loading Data ...
            </Modal.Body>
          </Modal>
        </>
      </div>

      {/* Modal End */}

      {/* Modal Start (for dynamic org and center) */}
      <div>
        <>
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title
                style={{ color: "#190e6c", display: "flex", gap: "12px" }}
              >
                <div>{"ORG & CENTER"}</div>

                <div>
                  <Dropdown style={{ position: "relative", top: "-3px" }}>
                    <Dropdown.Toggle
                      variant="info btn-sm"
                      id="dropdown-basic"
                      style={{ fontWeight: "bold" }}
                    >
                      New Client
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleNewClients("lab")}>
                        Add LAB
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleNewClients("lis")}>
                        Add LIS
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleNewClients("admin")}>
                        Add ADMIN
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* <button
                                    style={{ marginLeft: "10px", fontWeight: "bold", position: "relative", top: "-3px" }}
                                    className='btn btn-sm btn-info'
                                    onClick={() => window.open("/new-client/", "_self")}
                                >
                                    {"New"} <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                                </button> */}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {/* Choose Org Dropdown Start */}
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  {"Choose Org "}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  as={CustomMenu}
                  style={{ overflow: "scroll", height: "300px" }}
                >
                  {orgAndCentersData.map((each, idx) => (
                    <Dropdown.Item
                      eventKey={idx}
                      onClick={() => chooseCenterHandler(each)}
                    >
                      {each.theOrg}
                    </Dropdown.Item>
                  ))}
                  {/* <Dropdown.Item eventKey="3" active>
                                        Orange
                                    </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>

              {/* Choose Org Dropdown End*/}

              {/* Choose Center Dropdown Start */}
              {orgChoosen === "" ? (
                <span style={{ color: "grey" }}>{"Choose Center"}</span>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {"Choose Center "}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    as={CustomMenu2}
                    style={{ overflow: "scroll", height: "300px" }}
                  >
                    {selectionBasedCenters.map((each, idx) => (
                      <Dropdown.Item
                        eventKey={idx}
                        onClick={() => setCenterChoosen(each)}
                      >
                        {each}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* Choose Center Dropdown End */}
            </Modal.Body>

            <Modal.Body style={{ fontSize: "14px" }}>
              <span style={{ fontWeight: "550" }}>{"Org: "}</span>
              <span style={{ color: "#7787bc" }}>{orgChoosen}</span>
              {" | "}
              <span style={{ fontWeight: "550" }}>{"Center: "}</span>
              <span style={{ color: "#7787bc" }}>{centerChoosen}</span>
            </Modal.Body>
            <Modal.Footer
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <a href="/subscription">
                <Button
                  disabled
                  onClick={() =>
                    localStorage.setItem(
                      "choosenClient",
                      JSON.stringify({ org: orgChoosen, center: centerChoosen })
                    )
                  }
                  variant="outline-success btn-sm d-none"
                >
                  {"Edit Subscription"}
                </Button>
              </a>

              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="info btn-sm"
                  onClick={customOrgAndCenterApiHandler}
                >
                  Fetch Info
                </Button>
                <Button variant="primary btn-sm" onClick={handleClose2}>
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      </div>

      {/* Modal End */}

      <Navbar
        className="navbarMainCss shadow-sm mb-5 bg-white rounded"
        expand="true"
      >
        <Container fluid>
          <Navbar.Brand href="/new-billing-dashboard">
            {/* <img className="navLogoCss" src={imageToUse === undefined ? defaultImage : imageToUse} alt="logo" /> */}
            <img className="navLogoCss" src={logoToUse} alt="logo" />
          </Navbar.Brand>
          {loginData ? (
            <>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav className="me-auto my-2 my-lg-0" navbarScroll />
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                  className="d-flex"
                >
                  {/* <UserSvg /> */}
                  <div
                    className="mx-3"
                    style={{
                      display:
                        loginData && loginData.role === "LIS" ? "" : "none",
                    }}
                  >
                    {/* <DownloadSvg /> */}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="dropdownCss"
                        id="dropdown-basic"
                        style={{
                          backgroundColor: "#ecedef",
                          borderRadius: "20px",
                        }}
                      >
                        {"Choose Clients"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ textAlign: "center" }}>
                        {loginData && loginData.role === "LIS"
                          ? loginData.liveClients.map((each, idx) => (
                              <Dropdown.Item
                                key={idx}
                                onClick={() => getCenterData(each)}
                              >
                                <span>{each}</span>
                              </Dropdown.Item>
                            ))
                          : ""}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div
                    className="align-item-center"
                    style={{
                      marginTop: "0px",
                      marginRight: "16px",
                      display:
                        loginData && loginData.role === "admin"
                          ? "flex"
                          : "none",
                      alignItems: "center",
                    }}
                  >
                    {/* <DownloadSvg /> */}
                    <a href="/client-data">
                      <Button variant="outline-primary btn-sm mx-2">
                        {"Edit Subscription"}
                      </Button>
                    </a>
                    <div
                      onClick={handleShow2}
                      className="dropdownCss"
                      id="dropdown-basic"
                      style={{
                        position: "relative",
                        // top: '8px',
                        cursor: "pointer",
                        backgroundColor: "#ecedef",
                        // borderRadius: "20px",
                        // padding: "10px",
                      }}
                    >
                      {"Choose Org & Center"}
                    </div>
                  </div>
                  <div style={{ marginTop: "0px" }} title="logout">
                    <LogoutComponent />
                  </div>
                </div>
              </Navbar.Collapse>
            </>
          ) : (
            ""
          )}
          {/* {maintenance ? (
            <div title='logout'>
              <LogoutComponent />
            </div>
          ) : (
            ''
          )} */}
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
