import { Label } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ListSubheader,
  Autocomplete,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Chart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import Legend from "./Legend";
import { useMatch } from "react-router-dom";
import {
  CustomBox,
  CustomCountBox,
  CustomGraphBox,
  CustomShadowBox,
} from "../../common/BoxAbstraction";
import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import EmptyView from "../../common/EmptyView";
import CountContainer from "../../common/CountContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CollapsableHeader from "../../common/CollapsableHeader";
import { content, getCurrentWeekDates } from "../utils";
import AbnormalByAgeGender from "./AbnormalByAgeGender";

const AgeGenderAnalysis = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [profileData, setProfileData] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [error, setError] = useState("");
  const [ageGenderwiseData, setAgeGenderwiseData] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProfileData, setFilteredProfileData] = useState([]);
  const [profileListLoader, setProfileListLoader] = useState(false);
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const legendColors = ["#02c96a", "#fc6d5d"];
  const legendLabels = ["Normal", "Abnormal"];

  const getProfileList = async () => {
    setProfileListLoader(true);
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/profile/list`;

    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("setPackageData", data);
      if (data.isSuccess) {
        setProfileData(data.data);
        localStorage.setItem("profileList", JSON.stringify(data.data));
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setProfileListLoader(false);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    const apiUrl = `https://api.niroggyan.com/analytics/smartreport/demographics/counts?query_on=age_gender&profile_name=${selectedProfile}&start=${startDate}&end=${endDate}`;
    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("genderwisedata", data);
      if (data?.data?.length === 0) {
        setError("No data available for the chosen dates.");
      } else {
        setError("");
        setAgeGenderwiseData(data?.data);
      }
    } catch (e) {
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
  }, []);

  useEffect(() => {
    const storedProfileList = JSON.parse(localStorage.getItem("profileList"));
    if (storedProfileList?.length) {
      setProfileData(storedProfileList);
    } else {
      getProfileList();
    }
  }, []);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchData();
  //   }
  // }, [startDate, endDate, selectedProfile]);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (option) => {
    setSelectedMetric(option);
    switch (option) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  const getGraphdata = () => {
    const series = [];
    const chartData = { Male: [], Female: [] };
    ageGenderwiseData?.length &&
      ageGenderwiseData.forEach((each) => {
        chartData["Male"].push(each["Male"]);
        chartData["Female"].push(each["Female"]);
      });
    console.log("chartData", chartData);

    for (let [key, value] of Object.entries(chartData)) {
      series.push({ name: key, data: value });
    }
    console.log("series", series);
    return series;
  };

  const filterGenderWiseData = (gender) => {
    const genderWiseData =
      ageGenderwiseData?.length &&
      ageGenderwiseData.filter((each) => each.gender === gender);
    const chartData = { Normal: [], Abnormal: [] };
    genderWiseData.forEach((each) => {
      // if (each.total_count) chartData["total_count"].push(each.total_count);
      if (each.normal_count) chartData["Normal"].push(each.normal_count);
      if (each.abnormal_count) chartData["Abnormal"].push(each.abnormal_count);
    });
    const dataSeries = [];
    for (let [key, value] of Object.entries(chartData)) {
      dataSeries.push({ name: key, data: value });
    }
    console.log("dataSeries", dataSeries);
    return dataSeries;
  };

  const getAgeCategories = () => {
    const xAxisCategories = ageGenderwiseData.map(
      (each) => Object.values(each)[0]
    );
    console.log("xAxisCategories", xAxisCategories);
    return [...new Set(xAxisCategories)];
  };

  const RenderChart = ({ gender }) => {
    const dataToRender = {
      options: {
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            // columnWidth: "60%",
            dataLabels: {
              total: {
                enabled: true,
              },
            },
          },
        },
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          fontFamily: "IBM Plex Sans",
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: ageGenderwiseData?.length && getAgeCategories(),

          title: {
            text: "Age in years",
            margin: 10,
            style: {
              fontSize: "18px",
              fontWeight: "700",
            },
          },
          tickPlacement: "on",
        },
        legend: {
          show: false,
          position: "bottom",
          offsetY: 10,
        },
        yaxis: {
          title: {
            text: "Total Count",
            style: {
              fontSize: "18px",
              fontWeight: "700",
            },
          },
        },
        colors: ["#02c96a", "#fc6d5d"],
      },
      series:
        ageGenderwiseData?.length && gender === "Male"
          ? filterGenderWiseData("Male")
          : filterGenderWiseData("Female"),
    };
    return (
      <Chart
        options={dataToRender.options}
        series={dataToRender.series}
        type="bar"
        width={"100%"}
        height={320}
      />
    );
  };

  const getTotalAbnormal = (idx) => {
    return (
      ageGenderwiseData?.length &&
      getGraphdata()
        [idx]?.data.map((each) => parseInt(each))
        .reduce((a, b) => a + b)
    );
  };

  const genderWiseCounts = (gender) => {
    const genderWiseData =
      ageGenderwiseData?.length &&
      ageGenderwiseData.filter((each) => each.gender === gender);
    console.log("genderWiseData", genderWiseData);
    const chartData = { total_count: [], normal_count: [], abnormal_count: [] };
    genderWiseData.forEach((each) => {
      if (each.total_count)
        chartData["total_count"].push(parseInt(each.total_count));
      if (each.normal_count)
        chartData["normal_count"].push(parseInt(each.normal_count));
      if (each.abnormal_count)
        chartData["abnormal_count"].push(parseInt(each.abnormal_count));
    });
    return chartData;
  };

  const getSum = (data) => {
    if (data?.length) {
      return data.reduce((a, b) => a + b);
    }
  };

  const getCountData = (gender, label) => {
    if (ageGenderwiseData?.length) {
      return getSum(genderWiseCounts(gender)[label]);
    }
  };

  const handleDataCall = () => {
    if (selectedProfile) {
      fetchData();
    } else {
      setError("Please choose relevant profile");
    }
  };

  const memoizedGraphMale = useMemo(() => {
    return <RenderChart gender="Male" />;
  }, [ageGenderwiseData]);

  const memoizedGraphFemale = useMemo(() => {
    return <RenderChart gender="Female" />;
  }, [ageGenderwiseData]);

  const totalCountMale = getCountData("Male", "total_count");
  const totalNormalCountMale = getCountData("Male", "normal_count");
  const totalAbnormalCountMale = getCountData("Male", "abnormal_count");
  const totalCountFemale = getCountData("Female", "total_count");
  const totalNormalCountFemale = getCountData("Female", "normal_count");
  const totalAbnormalCountFemale = getCountData("Female", "abnormal_count");
  const totalCount =
    (totalCountMale ? totalCountMale : 0) +
    (totalCountFemale ? totalCountFemale : 0);
  const totalNormal =
    (totalNormalCountMale ? totalNormalCountMale : 0) +
    (totalNormalCountFemale ? totalNormalCountFemale : 0);
  const totalAbnormal =
    (totalAbnormalCountMale ? totalAbnormalCountMale : 0) +
    (totalAbnormalCountFemale ? totalAbnormalCountFemale : 0);

  const filterProfileData =
    profileData?.length &&
    profileData.filter((each) =>
      each.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <Box flexGrow={1}>
      <Accordion component="Box">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* <Typography variant="h2" mb={2}>
            Profile Wise Abnormal Count by Age & Gender
          </Typography> */}
          <CollapsableHeader
            title={content.essentialProfiles.title}
            subheading={content.essentialProfiles.subHeading}
            tooltipText={content.essentialProfiles.tooltipText}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#fff" }}>
          <CustomBox>
            <CustomShadowBox justifyContent="flex-end">
              {/* <Typography
          variant="body2"
          sx={{ maxWidth: { lg: "150px", md: "100%" } }}
        >
          Please choose relevant profile
        </Typography> */}
              <Stack direction="row" gap={2} flexWrap="wrap">
                <FormControl
                  size="small"
                  variant="filled"
                  sx={{ minWidth: "200px" }}
                >
                  {/* <InputLabel id="demo-select-small-label">Choose Profile</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedProfile}
              label="Profile"
              onChange={(e) => setSelectedProfile(e.target.value)}
              renderValue={(value) => value || "Select Profile"}
            >
              <ListSubheader>
                <TextField
                  placeholder="search"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </ListSubheader>
              {filteredProfileData.length &&
                filteredProfileData.map((profile, idx) => (
                  <MenuItem key={idx} value={profile}>
                    {profile}
                  </MenuItem>
                ))}
            </Select> */}
                  <Autocomplete
                    id="country-select-demo"
                    // disableClearable
                    disabled={!profileData?.length}
                    value={selectedProfile}
                    sx={{ width: 300 }}
                    options={profileData?.length ? profileData : []}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Profile"
                        variant="filled"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSelectedProfile(newValue);
                    }}
                  />
                </FormControl>

                {/* profile list loader */}

                {profileListLoader && (
                  <Stack alignItems="center" justifyContent="center">
                    <CircularProgress size={20} />
                  </Stack>
                )}

                <FormControl size="small" variant="filled">
                  <InputLabel id="demo-select-small-label">
                    Time Period
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedMetric}
                    label="Date"
                    onChange={(e) => handleSelection(e.target.value)}
                    onClick={(e) => {
                      if (e.target.innerText === "Custom Month") {
                        handleSelection("customMonth");
                      }
                    }}
                  >
                    <MenuItem value="currentWeek">Current Week</MenuItem>
                    <MenuItem value="currentMonth">Current Month</MenuItem>
                    <MenuItem value="previousMonth">Previous Month</MenuItem>
                    <MenuItem value="customMonth">Custom Month</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handleDataCall} disabled={loader}>
                  Get Details
                </Button>
                {customDateModal()}
              </Stack>
            </CustomShadowBox>

            <Stack>
              {loader ? (
                <LoadingView />
              ) : error ? (
                <ErrorView error={error} />
              ) : ageGenderwiseData?.length ? (
                <Box>
                  <Stack my={2}>
                    <Stack direction="row" gap={2}>
                      <CustomCountBox sx={{ background: "#d1d1d1" }}>
                        <Typography variant="body9">
                          Total:{" "}
                          {totalCount ? totalCount.toLocaleString("en-IN") : 0}
                        </Typography>
                      </CustomCountBox>
                      <CustomCountBox sx={{ background: "#79fcbe" }}>
                        <Typography variant="body9">
                          Normal:{" "}
                          {totalNormal
                            ? totalNormal.toLocaleString("en-IN")
                            : 0}{" "}
                          {"("}
                          {totalCount
                            ? Math.round((totalNormal / totalCount) * 100)
                            : 0}
                          {"%)"}
                        </Typography>
                      </CustomCountBox>
                      <CustomCountBox sx={{ background: "#ffc8c2" }}>
                        <Typography variant="body9">
                          Abnormal:{" "}
                          {totalAbnormal
                            ? totalAbnormal.toLocaleString("en-IN")
                            : 0}
                          {"("}
                          {totalCount
                            ? Math.round((totalAbnormal / totalCount) * 100)
                            : 0}
                          {"%)"}
                        </Typography>
                      </CustomCountBox>
                    </Stack>
                  </Stack>
                  <Stack direction="column" gap={2}>
                    <CustomGraphBox>
                      <Stack
                        direction="row"
                        gap={2}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack alignSelf="stretch" flexGrow={2}>
                          <CountContainer
                            total={totalCountMale}
                            normal={totalNormalCountMale}
                            abnormal={totalAbnormalCountMale}
                          />
                        </Stack>
                        <Stack flexGrow={4}>
                          <Typography
                            variant="body10"
                            sx={{ textAlign: "center" }}
                          >
                            Male
                          </Typography>

                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent="flex-end"
                            mb={2}
                            mt={2}
                          >
                            {legendColors.map((each, idx) => (
                              <Legend
                                key={idx}
                                color={each}
                                name={legendLabels[idx]}
                              />
                            ))}
                          </Stack>

                          {/* <RenderChart /> */}
                          <Box sx={{ width: "100%" }}>{memoizedGraphMale}</Box>
                        </Stack>
                      </Stack>
                    </CustomGraphBox>
                    <CustomGraphBox>
                      <Stack
                        alignItems="center"
                        gap={2}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Stack alignSelf="stretch" flexGrow={2}>
                          <CountContainer
                            total={totalCountFemale}
                            normal={totalNormalCountFemale}
                            abnormal={totalAbnormalCountFemale}
                          />
                        </Stack>
                        <Stack flexGrow={4}>
                          <Typography
                            variant="body10"
                            sx={{ textAlign: "center" }}
                          >
                            Female
                          </Typography>

                          <Stack
                            direction="row"
                            gap={2}
                            justifyContent="flex-end"
                            mb={2}
                            mt={2}
                          >
                            {legendColors.map((each, idx) => (
                              <Legend
                                key={idx}
                                color={each}
                                name={legendLabels[idx]}
                              />
                            ))}
                          </Stack>

                          <Box sx={{ width: "100%" }}>
                            {memoizedGraphFemale}
                          </Box>
                        </Stack>
                      </Stack>
                    </CustomGraphBox>
                  </Stack>
                </Box>
              ) : (
                <EmptyView message="Please choose relevant profile" />
              )}
            </Stack>
          </CustomBox>
          <AbnormalByAgeGender />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AgeGenderAnalysis;
