function getStartOfWeek(date, weekStart = 0) {
  // weekStart: 0 for Sunday, 1 for Monday, etc.
  const day = date.getDay(); // 0 for Sunday, 1 for Monday, etc.
  const difference = (day < weekStart ? -7 : 0) + weekStart - day;
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() + difference);
  startOfWeek.setHours(0, 0, 0, 0); // Reset time to the beginning of the day
  return startOfWeek;
}

const formatFromDate = (date) => {
  const dateInFormat = new Date(date);
  const requiredTime = `${dateInFormat.getFullYear()}-${
    dateInFormat.getMonth() >= 9
      ? dateInFormat.getMonth() + 1
      : `0${dateInFormat.getMonth() + 1}`
  }-${
    dateInFormat.getDate() >= 10
      ? dateInFormat.getDate()
      : `0${dateInFormat.getDate()}`
  } 00:00:00.000`;
  // console.log("requiredTime", requiredTime);
  return requiredTime;
};

const formatToDate = (date) => {
  const dateInFormat = new Date(date);
  const requiredTime = `${dateInFormat.getFullYear()}-${
    dateInFormat.getMonth() >= 9
      ? dateInFormat.getMonth() + 1
      : `0${dateInFormat.getMonth() + 1}`
  }-${
    dateInFormat.getDate() >= 10
      ? dateInFormat.getDate()
      : `0${dateInFormat.getDate()}`
  } 23:59:59.999`;
  console.log(requiredTime);
  return requiredTime;
};

// Example usage:
const getCurrentWeekDates = () => {
  const startDate = new Date(); // Any specific date
  const weekStart = 1; // For Monday
  const startOfWeek = formatFromDate(getStartOfWeek(startDate, weekStart));
  const endOfWeek = formatToDate(startDate);

  return {
    startDate: startOfWeek,
    endDate: endOfWeek,
  };
};

const getPastWeekDates = () => {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 7);

  return {
    startDate: formatFromDate(startDate),
    endDate: formatToDate(today),
  };
};

const content = {
  subscription: {
    title: "Subscription Usage Overview",
    subHeading: "",
    tooltipText:
      "This chart provides a detailed breakdown of your total usage, highlighting the top health packages utilised and their respective counts.",
  },
  monthlyReport: {
    title: "Monthly Report Count Analysis",
    subHeading: "Monitor trends and identify patterns in report generation",
    tooltipText: "",
  },
  yearlyReport: {
    title: "Yearly Report Count Analysis",
    subHeading: "Compare your monthly report counts across different years",
    tooltipText:
      "Useful for marketeers, this chart provides a comprehensive view of annual trends, helping you understand seasonal variations and year-over-year growth in report generation.",
  },
  demographicsOverview: {
    title: "Patient Demographics Overview",
    subHeading:
      "Gain insights into your patient demographics with detailed age and gender breakdowns",
    tooltipText:
      "This chart helps you understand the distribution of your patients, enabling more effective healthcare services and targeted marketing. Analyse the total count, age group percentages, and gender-specific data to better cater to your patient population.",
  },
  essentialBiomarkers: {
    title: "Essential Biomarkers Overview",
    subHeading: "Monitor key health indicators across your patient population",
    tooltipText:
      "This chart provides a quick view of the normal versus out-of-range counts for vital biomarkers, helping you identify trends and address health issues effectively. Select specific biomarkers and time periods to get detailed insights and ensure comprehensive patient care.",
  },
  profileParameterTables: {
    title: "Top Abnormal Profile & Biomarker Details",
    subHeading:
      "Identify and analyze the profiles and biomarkers with the highest abnormality rates.",
    tooltipText:
      "The two tables at the top display the total and abnormal counts for each and every test profile and biomarker, while the bottom sections allow you to download detailed report-wise data. Use this information to target critical health issues and enhance patient care strategies effectively.",
  },
  packageAnalysis: {
    title: "Package Analysis",
    subHeading:
      "Abnormal percentage of biomarkers within the selected health checkup package's profiles.",
    tooltipText:
      "This detailed view allows you to see the total counts, abnormality counts, and percentage of abnormalities for the chosen test profile, helping you to identify unexpected trends and reconfigure packages.",
  },
  essentialProfiles: {
    title: "Abnormal Results of Profiles by Demographics",
    subHeading:
      "Track the distribution of abnormal health results across various age groups for both genders. Identify patterns and trends to enhance targeted healthcare strategies for specific populations.",
    tooltipText: "",
  },
  reportTat: {
    title: "Report Generation TAT Analysis",
    subHeading:
      "Monitor the average time taken from sample collection to report generation to ensure timely delivery and improve operational efficiency.",
    tooltipText: "",
  },
};

export { getCurrentWeekDates, getPastWeekDates, content };
