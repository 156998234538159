// NOTE: when adding a new package make sure to remove the slice method from stateOptions
// in itemSelectionScreen.js, REASON => avoiding "Package with all profiles" selection to appear in the page.

// male ref ranges
export const completePackageData = [
  {
    packageName: "all profiles",
    results: [
      {
        profilename: "Vitals",
        investigation: [
          {
            test: "Height",
            low: "",
            high: "",
            unit: "M (Meters)",
            result: "",
            method: "",
          },
          {
            test: "Weight",
            low: "",
            high: "",
            unit: "Kg (Kilograms)",
            result: "",
            method: "",
          },
          {
            test: "Blood pressure",
            low: "",
            high: "",
            unit: "mmHG",
            result: "",
            method: "",
          },
          {
            test: "BMI",
            low: "18.5",
            high: "30",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Body Temperature",
            low: "",
            high: "",
            unit: "°C",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Complete Blood Count (CBC)",
        investigation: [
          {
            test: "Total Leucocyte Count",
            low: "5",
            high: "11.6",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Lymphocyte",
            low: 19.1,
            high: 48.5,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Monocyte",
            low: "4.5",
            high: "12.1",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Granulocytes",
            low: "43",
            high: "73",
            unit: "%",
            result: "",
            method: "",
          },

          {
            test: "Absolute Lymphocytes count",
            low: "1.3",
            high: "4",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Absolute Monocytes count",
            low: "0.3",
            high: "4",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Absolute Granulocytes count",
            low: "2.4",
            high: "7.6",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Haemoglobin",
            low: "11",
            high: "17",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "MCH (Mean Corp Hb)",
            low: "27.5",
            high: "32.4",
            unit: "pg",
            result: "",
            method: "",
          },
          {
            test: "MCHC ( Mean Corp Hb Conc )",
            low: "31.7",
            high: "34.2",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Rbc Count (TRBC)",
            low: "4",
            high: "6.2",
            unit: "* 10^12/L",
            result: "",
            method: "",
          },
          {
            test: "MCV",
            low: "80",
            high: "100",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "RDW (SD)",
            low: "36.2",
            high: "49.7",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Hematocrit (PCV)",
            low: "35",
            high: "55",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Platelet Count",
            low: "156",
            high: "342",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "MPV",
            low: "8",
            high: "12",
            unit: "fL",
            result: "",
            method: "",
          },

          {
            test: "RDW (CV)",
            low: "11",
            high: "16",
            unit: "%",
            result: "",
            method: "",
          },

          // {
          //   test: 'Neutrophil',
          //   low: 40,
          //   high: 70,
          //   unit: '%',
          //   result: '',
          //   method: '',
          // },

          // {
          //   test: 'Eosinophil',
          //   low: '1',
          //   high: '4',
          //   unit: '%',
          //   result: '',
          //   method: '',
          // },
          // {
          //   test: 'Basophil',
          //   low: '0',
          //   high: '1',
          //   unit: '%',
          //   result: '',
          //   method: '',
          // },

          {
            test: "PDW",
            low: "9.9",
            high: "17",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "PCT",
            low: "0.16",
            high: "0.36",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "P-LCR",
            low: "13",
            high: "43",
            unit: "%",
            result: "",
            method: "",
          },
          // {
          //   test: 'Absolute Neutrophils count',
          //   low: '2000',
          //   high: '7000',
          //   unit: '/cumm',
          //   result: '',
          //   method: '',
          // },

          // {
          //   test: 'Absolute Basophils count',
          //   low: '0.01',
          //   high: '0.10',
          //   unit: '10^3 /micro litre',
          //   result: '',
          //   method: '',
          // },
          {
            test: "LIC%",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "LIC#",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "P-LCC",
            low: "",
            high: "",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Immature Granulocytes",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },

          {
            test: "Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Large Unstained Cell",
            low: "0",
            high: "4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Large Unstained Cell",
            low: "0",
            high: "0.4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Reticulocytes Count",
            low: "2",
            high: "5",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Content Haemoglobin (CH)",
            low: "22",
            high: "33",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "G6PD (Quantitative)",
            low: "6.97",
            high: "20",
            unit: "U/G Hb",
            result: "",
            method: "",
          },
          {
            test: "Sickle Cell Solubility Test-Sickling phenomenon",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "ESR",
            low: "0",
            high: "30",
            unit: "mm/hr",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [
          {
            test: "HbA1c",
            low: "",
            high: "",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "HbA1C (IFCC)",
            low: "20",
            high: "42",
            unit: "mmol/mol",
            result: "",
            method: "",
          },
          {
            test: "eAG (Estimated Average Glucose)",
            low: "",
            high: "",
            unit: "mmol/mol",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Random)",
            low: "4.5",
            high: "7.5",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Plasma Glucose (Fasting)",
            low: "74",
            high: "100",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Postprandial)",
            low: "0",
            high: "140",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Random) 75 gm",
            low: "0",
            high: "200",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Random) 100 gm",
            low: "0",
            high: "200",
            unit: "mg/dl",
            result: "",
            method: "",
          },

          {
            test: "Insulin (Random)",
            low: "0",
            high: "25",
            unit: "u U/mL",
            result: "",
            method: "",
          },
          {
            test: "Insulin (Fasting)",
            low: "60",
            high: "110",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "Microalbumin",
            low: "0",
            high: "25",
            unit: "mg/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Lipid Profile",
        investigation: [
          {
            test: "Total Cholesterol",
            low: 0,
            high: 5,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Triglycerides",
            low: 0.49,
            high: 1.7,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "HDL Cholesterol",
            low: 1.04,
            high: 1.55,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "LDL Cholesterol",
            low: 0,
            high: 3.54,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "VLDL Cholesterol",
            low: 6,
            high: 38,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Total / HDL Cholesterol Ratio",
            low: 3.5,
            high: 5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Serum LDL / HDL Cholesterol Ratio",
            low: 2.5,
            high: 3.5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Apo A",
            low: "107",
            high: "205",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Apo B",
            low: "52",
            high: "129",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "APO B : APO A Ratio",
            low: "0",
            high: "0.8",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Creatine-Kinase",
            low: "22",
            high: "198",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "HsCRP",
            low: "0",
            high: "5",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Renal Function Test and Electrolytes",
        investigation: [
          {
            test: "Serum Urea",
            low: "0",
            high: "8.30",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Creatinine",
            low: "62",
            high: "106",
            unit: "µmol/L",
            result: "",
            method: "",
          },

          {
            test: "Serum Sodium",
            low: "135",
            high: "145",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Potassium",
            low: "3.5",
            high: "5.5",
            unit: "mmol/L",
            result: "",
            method: "",
          },

          {
            test: "Serum Chloride",
            low: "98",
            high: "107",
            unit: "mmol/L",
            result: "",
            method: "",
          },

          {
            test: "Blood Urea Nitrogen (BUN)",
            low: "7",
            high: "20",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "BUN : Creatinine ratio",
            low: "10",
            high: "20",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urea : Creatinine ratio",
            low: "10",
            high: "40",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: "",
          },
          {
            test: "Glomerular Filtration Rate",
            low: "15",
            high: "90",
            unit: "mL/min/1.73m",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Urine Routine",
        investigation: [
          {
            test: "Specific Gravity",
            low: 1.005,
            high: 1.025,
            unit: "",
            result: "",
            method: "",
          },
          { test: "pH", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Urinary Calcium",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Glucose in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "URINE SPOT PROTEIN",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "URINE SPOT CREATININE",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urine Spot Microalbumin",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "SPOT PROTEIN & CREATININE RATIO",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "DEPOSITS :",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urine Colour",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Appearance",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "VOLUME",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Pus Cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Epithelial Cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urobilinogen",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Protein",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Ketone",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Acetone",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bile Salts",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Blood",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Nitrite",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Casts",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Crystals",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bacteria",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Yeast cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Amorphous Deposit",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bile Pigment",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bilirubin",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absorbic Acid",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "RBC", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Leukocytes",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Leukocyte Esterase",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Sugar in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Other compounds in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Candida in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urinary Creatinine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Liver Function Test",
        investigation: [
          {
            test: "Serum Bilirubin (Total)",
            low: "2",
            high: "17",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Bilirubin (Direct)",
            low: "0",
            high: "8",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Bilirubin (indirect)",
            low: "0.1",
            high: "1.0",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "SGPT",
            low: "0",
            high: "37",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "SGOT",
            low: "13",
            high: "48",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Alkaline Phosphatase",
            low: "39",
            high: "141",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Gamma (GT)",
            low: "0",
            high: "55",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Total Protein",
            low: "60",
            high: "87",
            unit: "g/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Albumin",
            low: "35",
            high: "52",
            unit: "g/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Globulin",
            low: "1.8",
            high: "3.6",
            unit: "g/dl",
            result: "",
            method: "",
          },
          {
            test: "A/G Ratio",
            low: "1.2",
            high: "2.2",
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "SGOT/SGPT Ratio",
            low: "",
            high: "",
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "LDH Serum",
            low: "0",
            high: "248",
            unit: "U/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Vitamin Profile",
        investigation: [
          {
            test: "VITAMIN B12",
            low: "138",
            high: "",
            unit: "pmol/L",
            result: "",
            method: "",
          },
          {
            test: "25 (OH) Vitamin D",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B9 (Folic Acid)",
            low: "4.6",
            high: "34.8",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B6",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B1",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B2",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin C",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin A",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Serum Folate",
            low: "7",
            high: "",
            unit: "ng/ml",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Thyroid Profile",
        investigation: [
          {
            test: "T3",
            low: "1.23",
            high: "3.07",
            unit: "nmol/L",
            result: "",
            method: "",
          },
          {
            test: "T4",
            low: "66",
            high: "181",
            unit: "nmol/L",
            result: "",
            method: "",
          },
          {
            test: "FT3",
            low: "2.04",
            high: "4.40",
            unit: "pg/ml",
            result: "",
            method: "",
          },
          {
            test: "FT4",
            low: "0.93",
            high: "1.71",
            unit: "ng/dl",
            result: "",
            method: "",
          },
          {
            test: "TSH",
            low: "0.3",
            high: "4.20",
            unit: "mIU/L",
            result: "",
            method: "",
          },
          {
            test: "Anti Thyroglobulin Abs",
            low: "0.9",
            high: "1.1",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Anti-TPO",
            low: "0",
            high: "30",
            unit: "IU/mL",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "COVID RT-PCR & ANTIBODY",
        investigation: [
          {
            test: "COVID Antibody",
            low: "0.8",
            high: "440",
            unit: "cells/uL",
            result: "",
            method: "",
          },
          {
            test: "COVID RT-PCR",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Cycle Threshold N-gene",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "COVID 19 IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "COVID RT-PCR",
        investigation: [
          {
            test: "COVID Antibody",
            low: "0.8",
            high: "440",
            unit: "cells/uL",
            result: "",
            method: "",
          },
          {
            test: "COVID RT-PCR",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Cycle Threshold N-gene",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "COVID 19 IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Immunology Profile",
        investigation: [
          {
            test: "C-reactive protein",
            low: "0",
            high: "6",
            unit: "mg/L",
            result: "",
            method: "",
          },
          {
            test: "H.PYLORI AB",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Coombs Test (Indirect)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Anti DNA Abs.",
            low: "",
            high: "10",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "ANA-Titer",
            low: "",
            high: "10",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Blood Group and rh typing",
        investigation: [
          {
            test: "Blood Group",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Rh Factor",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Anemia Studies",
        investigation: [
          {
            test: "IRON-Serum",
            low: "11.6",
            high: "31.3",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "UIBC-Serum",
            low: "120",
            high: "470",
            unit: "ug/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Iron Binding Capicity",
            low: "220",
            high: "440",
            unit: "mcg/dl",
            result: "",
            method: "",
          },
          {
            test: "Percentage Transferrin Saturation",
            low: "20",
            high: "55",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Transferrin Serum",
            low: "26",
            high: "37",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "Ferritin",
            low: "20",
            high: "300",
            unit: "ng/ML",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Cancer Profile",
        investigation: [
          {
            test: "Prostate-Specific Antigen Free",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Prostate-Specific Antigen Total",
            low: "0",
            high: "4",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "CA 19-9",
            low: "0",
            high: "27",
            unit: "KU/L",
            result: "",
            method: "",
          },
          {
            test: "CA 125",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "CA 15-3",
            low: "",
            high: "25",
            unit: "KU/L",
            result: "",
            method: "",
          },

          {
            test: "Alfa Feto Protein (AFP)",
            low: "",
            high: "9.96",
            unit: "U/ml",
            result: "",
            method: "",
          },
          {
            test: "CA 72-4",
            low: "0",
            high: "6.9",
            unit: "KU/L",
            result: "",
            method: "",
          },
          { test: "CEA", low: "", high: "", unit: "", result: "", method: "" },
        ],
      },
      {
        profilename: "Fertility Profile",
        investigation: [
          {
            test: "Leutinizing Hormone",
            low: "",
            high: "",
            unit: "mIU/mL",
            result: "",
            method: "",
          },
          {
            test: "Estradiol (E2)",
            low: "",
            high: "",
            unit: "pg/mL",
            result: "",
            method: "",
          },
          {
            test: "FSH",
            low: "",
            high: "",
            unit: "mIU/mL",
            result: "",
            method: "",
          },
          {
            test: "Progesterone",
            low: "",
            high: "",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          { test: "AMH", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Estrogen",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Oestradiol",
            low: "",
            high: "",
            unit: "pg/mL",
            result: "",
            method: "",
          },
          { test: "SHBG", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "DHEA-Sulphate",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Testosterone",
            low: "1.61",
            high: "8.41",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "Testosterone(Free)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Androstenedione",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Prolactin",
            low: "4.6",
            high: "21.4",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "Beta HCG",
            low: "",
            high: "",
            unit: "mIU/ml",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "STI / Hepatitis Profile",
        investigation: [
          {
            test: "Rapid Plasma Reagin",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "HIV", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "HBsAg",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HBsAb",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis B envelope antigen (HBeAg)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis B envelope antibody (HBeAb)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis core antibody (HBcAb)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HEP C Antibodies Qualitative",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis B",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "HCV", low: "", high: "", unit: "", result: "", method: "" },
          { test: "HPV", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "RAPID TP(SYPHILIS), VDRL",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HIV AB",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HIV Combo (Ag+Ab)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "VDRL", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Herpes Semplex Virus I IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus I IgG",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus II IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus II IgG",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Chlamydia IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Chlamydia Ag",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Gonorrhoea Ag",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "TPHA (TREPONEMA PALLIDUM)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "High Vaginal Swab",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Cardiac Profile",
        investigation: [
          {
            test: "Creatine-Kinase",
            low: "22",
            high: "198",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "CK-MB",
            low: "0",
            high: "24",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "CK-Total",
            low: "49",
            high: "397",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "TROPONI - I",
            low: "",
            high: "0.05",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "Homocysteine",
            low: "0",
            high: "30",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Myoglobin",
            low: "25",
            high: "75",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          // Myoglobin
        ],
      },
      {
        profilename: "Blood Clotting",
        investigation: [
          {
            test: "PT TEST",
            low: "26",
            high: "45",
            unit: "seconds",
            result: "",
            method: "",
          },
          {
            test: "Thrombin Time",
            low: "26",
            high: "45",
            unit: "",
            result: "",
            method: "",
          },
          //Activated Partial Thromboplastin Time", "D-Dimer"
          {
            test: "Activated Partial Thromboplastin Time",
            low: "23",
            high: "33",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "D-Dimer",
            low: "",
            high: "0.5",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "PT (Control)",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          {
            test: "Mean Normal Prothrombic Time",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          {
            test: "Partial Thromboplastin Time",
            low: "23",
            high: "33",
            result: "",
            unit: "",
            method: "",
          },
          {
            test: "Partial Thromboplastin Time(Control)",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          {
            test: "PT-INR",
            low: "0.87",
            high: "1.25",
            result: "",
            unit: "",
            method: "",
          },
          {
            test: "PT ratio",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          { test: "ISI", low: "", high: "", result: "", unit: "", method: "" },
          {
            test: "Index",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Arthritis",
        investigation: [
          {
            test: "Serum Uric Acid",
            low: "140",
            high: "420",
            unit: "Umol/L",
            result: "",
            method: "",
          },
          {
            test: "RA factor (quantitative)",
            low: "0",
            high: "20",
            unit: "IU/mL",
            result: "",
            method: "",
          },
          {
            test: "ASO (quantitative)",
            low: "0",
            high: "200",
            unit: "IU/mL",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Skeletal Profile",
        investigation: [
          {
            test: "Serum Calcium",
            low: "2.15",
            high: "2.58",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Magnesium",
            low: "0.67",
            high: "0.87",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Phosphorus",
            low: "2.55",
            high: "3.68",
            unit: "mmol/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Pancreatic Profile",
        investigation: [
          {
            test: "Amylase",
            low: "46",
            high: "162",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Lipase",
            low: "8",
            high: "78",
            unit: "IU/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Hormones",
        investigation: [
          {
            test: "Cortisol Serum (p.m)",
            low: "",
            high: "",
            unit: "nmol/L",
            result: "",
            method: "",
          },
        ],
      },
    ],
  },
];

// female ref ranges
export const completePackageDataForFemale = [
  {
    packageName: "all profiles",
    results: [
      {
        profilename: "Vitals",
        investigation: [
          {
            test: "Height",
            low: "",
            high: "",
            unit: "M (Meters)",
            result: "",
            method: "",
          },
          {
            test: "Weight",
            low: "",
            high: "",
            unit: "Kg (Kilograms)",
            result: "",
            method: "",
          },
          {
            test: "Blood pressure",
            low: "",
            high: "",
            unit: "mmHG",
            result: "",
            method: "",
          },
          {
            test: "BMI",
            low: "18.5",
            high: "30",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Body Temperature",
            low: "",
            high: "",
            unit: "°C",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Complete Blood Count (CBC)",
        investigation: [
          {
            test: "Total Leucocyte Count",
            low: "5",
            high: "11.6",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Lymphocyte",
            low: 19.1,
            high: 48.5,
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Monocyte",
            low: "4.5",
            high: "12.1",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Granulocytes",
            low: "43",
            high: "73",
            unit: "%",
            result: "",
            method: "",
          },

          {
            test: "Absolute Lymphocytes count",
            low: "1.3",
            high: "4",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Absolute Monocytes count",
            low: "0.3",
            high: "1.0",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Absolute Granulocytes count",
            low: "2.4",
            high: "7.6",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "Haemoglobin",
            low: "11",
            high: "17",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "MCH (Mean Corp Hb)",
            low: "27.5",
            high: "32.4",
            unit: "pg",
            result: "",
            method: "",
          },
          {
            test: "MCHC ( Mean Corp Hb Conc )",
            low: "31.7",
            high: "34.2",
            unit: "gm/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Rbc Count (TRBC)",
            low: "4",
            high: "6.2",
            unit: "* 10^12/L",
            result: "",
            method: "",
          },
          {
            test: "MCV",
            low: "80",
            high: "100",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "RDW (SD)",
            low: "36.2",
            high: "49.7",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Hematocrit (PCV)",
            low: "35",
            high: "55",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Platelet Count",
            low: "156",
            high: "342",
            unit: "* 10^9/L",
            result: "",
            method: "",
          },
          {
            test: "MPV",
            low: "8",
            high: "12",
            unit: "fL",
            result: "",
            method: "",
          },

          {
            test: "RDW (CV)",
            low: "11",
            high: "16",
            unit: "%",
            result: "",
            method: "",
          },

          // {
          //   test: 'Neutrophil',
          //   low: 40,
          //   high: 70,
          //   unit: '%',
          //   result: '',
          //   method: '',
          // },

          // {
          //   test: 'Eosinophil',
          //   low: '1',
          //   high: '4',
          //   unit: '%',
          //   result: '',
          //   method: '',
          // },
          // {
          //   test: 'Basophil',
          //   low: '0',
          //   high: '1',
          //   unit: '%',
          //   result: '',
          //   method: '',
          // },

          {
            test: "PDW",
            low: "9.9",
            high: "17",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "PCT",
            low: "0.16",
            high: "0.36",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "P-LCR",
            low: "13",
            high: "43",
            unit: "%",
            result: "",
            method: "",
          },
          // {
          //   test: 'Absolute Neutrophils count',
          //   low: '2000',
          //   high: '7000',
          //   unit: '/cumm',
          //   result: '',
          //   method: '',
          // },

          // {
          //   test: 'Absolute Basophils count',
          //   low: '0.01',
          //   high: '0.10',
          //   unit: '10^3 /micro litre',
          //   result: '',
          //   method: '',
          // },
          {
            test: "LIC%",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "LIC#",
            low: "0.4",
            high: "2.2",
            unit: "mg/g",
            result: "",
            method: "",
          },
          {
            test: "P-LCC",
            low: "",
            high: "",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "Immature Granulocytes",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },

          {
            test: "Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Nucleated red blood cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Large Unstained Cell",
            low: "0",
            high: "4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absolute Large Unstained Cell",
            low: "0",
            high: "0.4",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Reticulocytes Count",
            low: "2",
            high: "5",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Content Haemoglobin (CH)",
            low: "22",
            high: "33",
            unit: "fL",
            result: "",
            method: "",
          },
          {
            test: "G6PD (Quantitative)",
            low: "6.97",
            high: "20",
            unit: "U/G Hb",
            result: "",
            method: "",
          },
          {
            test: "Sickle Cell Solubility Test-Sickling phenomenon",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "ESR",
            low: "0",
            high: "30",
            unit: "mm/hr",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Diabetes Monitoring",
        investigation: [
          {
            test: "HbA1c",
            low: "",
            high: "",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "HbA1C (IFCC)",
            low: "20",
            high: "42",
            unit: "mmol/mol",
            result: "",
            method: "",
          },
          {
            test: "eAG (Estimated Average Glucose)",
            low: "",
            high: "",
            unit: "mmol/mol",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Random)",
            low: "4.5",
            high: "7.5",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Plasma Glucose (Fasting)",
            low: "74",
            high: "100",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Postprandial)",
            low: "0",
            high: "140",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Random) 75 gm",
            low: "0",
            high: "200",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Blood Sugar (Random) 100 gm",
            low: "0",
            high: "200",
            unit: "mg/dl",
            result: "",
            method: "",
          },

          {
            test: "Insulin (Random)",
            low: "0",
            high: "25",
            unit: "u U/mL",
            result: "",
            method: "",
          },
          {
            test: "Insulin (Fasting)",
            low: "60",
            high: "110",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "Microalbumin",
            low: "0",
            high: "25",
            unit: "mg/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Lipid Profile",
        investigation: [
          {
            test: "Total Cholesterol",
            low: 0,
            high: 5,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Triglycerides",
            low: 0.49,
            high: 1.7,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "HDL Cholesterol",
            low: 1.04,
            high: 1.55,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "LDL Cholesterol",
            low: 0,
            high: 3.54,
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "VLDL Cholesterol",
            low: 6,
            high: 38,
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Serum Total / HDL Cholesterol Ratio",
            low: 3.5,
            high: 5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Serum LDL / HDL Cholesterol Ratio",
            low: 2.5,
            high: 3.5,
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "Plasma Glucose (Fasting)",
            low: "70",
            high: "110",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Apo A",
            low: "107",
            high: "205",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "Apo B",
            low: "52",
            high: "129",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "APO B : APO A Ratio",
            low: "0",
            high: "0.8",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Creatine-Kinase",
            low: "22",
            high: "198",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "HsCRP",
            low: "0",
            high: "5",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Renal Function Test and Electrolytes",
        investigation: [
          {
            test: "Serum Urea",
            low: "0",
            high: "8.30",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Creatinine",
            low: "62",
            high: "106",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Sodium",
            low: "135",
            high: "145",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Potassium",
            low: "3.5",
            high: "5.5",
            unit: "mmol/L",
            result: "",
            method: "",
          },

          {
            test: "Serum Chloride",
            low: "98",
            high: "107",
            unit: "mmol/L",
            result: "",
            method: "",
          },

          {
            test: "Blood Urea Nitrogen (BUN)",
            low: "7",
            high: "20",
            unit: "mg/dL",
            result: "",
            method: "",
          },
          {
            test: "BUN : Creatinine ratio",
            low: "10",
            high: "20",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urea : Creatinine ratio",
            low: "10",
            high: "40",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Serum Albumin",
            low: "3.2",
            high: "4.6",
            unit: "g/dl",
            result: "",
            method: "",
          },
          {
            test: "Glomerular Filtration Rate",
            low: "15",
            high: "90",
            unit: "mL/min/1.73m",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Urine Routine",
        investigation: [
          {
            test: "Specific Gravity",
            low: 1.005,
            high: 1.025,
            unit: "",
            result: "",
            method: "",
          },
          { test: "pH", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Urinary Calcium",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Glucose in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "URINE SPOT PROTEIN",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "URINE SPOT CREATININE",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urine Spot Microalbumin",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "SPOT PROTEIN & CREATININE RATIO",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "DEPOSITS :",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urine Colour",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Appearance",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "VOLUME",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Pus Cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Epithelial Cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urobilinogen",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Protein",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Ketone",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Acetone",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bile Salts",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Blood",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Nitrite",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Casts",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Crystals",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bacteria",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Yeast cells",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Amorphous Deposit",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bile Pigment",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Bilirubin",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Absorbic Acid",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "RBC", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Leukocytes",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Leukocyte Esterase",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Sugar in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Other compounds in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Candida in Urine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Urinary Creatinine",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Liver Function Test",
        investigation: [
          {
            test: "Serum Bilirubin (Total)",
            low: "2",
            high: "17",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Bilirubin (Direct)",
            low: "0",
            high: "8",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Bilirubin (indirect)",
            low: "0.1",
            high: "1.0",
            unit: "mg/dl",
            result: "",
            method: "",
          },
          {
            test: "SGPT",
            low: "0",
            high: "37",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "SGOT",
            low: "13",
            high: "48",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Alkaline Phosphatase",
            low: "39",
            high: "141",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Gamma (GT)",
            low: "0",
            high: "55",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Total Protein",
            low: "60",
            high: "87",
            unit: "g/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Albumin",
            low: "35",
            high: "52",
            unit: "g/L",
            result: "",
            method: "",
          },
          {
            test: "Serum Globulin",
            low: "1.8",
            high: "3.6",
            unit: "g/dl",
            result: "",
            method: "",
          },
          {
            test: "A/G Ratio",
            low: "1.2",
            high: "2.2",
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "SGOT/SGPT Ratio",
            low: "",
            high: "",
            unit: "Ratio",
            result: "",
            method: "",
          },
          {
            test: "LDH Serum",
            low: "0",
            high: "248",
            unit: "U/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Vitamin Profile",
        investigation: [
          {
            test: "VITAMIN B12",
            low: "138",
            high: "",
            unit: "pmol/L",
            result: "",
            method: "",
          },
          {
            test: "25 (OH) Vitamin D",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B9 (Folic Acid)",
            low: "4.6",
            high: "34.8",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B6",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B1",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin B2",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin C",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Vitamin A",
            low: "20",
            high: "100",
            unit: "ng/ml",
            result: "",
            method: "",
          },
          {
            test: "Serum Folate",
            low: "",
            high: "7",
            unit: "ng/ml",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Thyroid Profile",
        investigation: [
          {
            test: "T3",
            low: "1.23",
            high: "3.07",
            unit: "nmol/L",
            result: "",
            method: "",
          },
          {
            test: "T4",
            low: "66",
            high: "181",
            unit: "nmol/L",
            result: "",
            method: "",
          },
          {
            test: "FT3",
            low: "2.04",
            high: "4.40",
            unit: "pg/ml",
            result: "",
            method: "",
          },
          {
            test: "FT4",
            low: "0.93",
            high: "1.71",
            unit: "ng/dl",
            result: "",
            method: "",
          },
          {
            test: "TSH",
            low: "0.3",
            high: "4.20",
            unit: "mIU/L",
            result: "",
            method: "",
          },
          {
            test: "Anti Thyroglobulin Abs",
            low: "0.9",
            high: "1.1",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Anti-TPO",
            low: "0",
            high: "30",
            unit: "IU/mL",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "COVID RT-PCR & ANTIBODY",
        investigation: [
          {
            test: "COVID Antibody",
            low: "0.8",
            high: "440",
            unit: "cells/uL",
            result: "",
            method: "",
          },
          {
            test: "COVID RT-PCR",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Cycle Threshold N-gene",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "COVID 19 IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "COVID RT-PCR",
        investigation: [
          {
            test: "COVID Antibody",
            low: "0.8",
            high: "440",
            unit: "cells/uL",
            result: "",
            method: "",
          },
          {
            test: "COVID RT-PCR",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Cycle Threshold N-gene",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "COVID 19 IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Immunology Profile",
        investigation: [
          {
            test: "C-reactive protein",
            low: "0",
            high: "6",
            unit: "mg/L",
            result: "",
            method: "",
          },
          {
            test: "H.PYLORI AB",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Coombs Test (Indirect)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Anti DNA Abs.",
            low: "",
            high: "10",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "ANA-Titer",
            low: "",
            high: "10",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Blood Group and rh typing",
        investigation: [
          {
            test: "Blood Group",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Rh Factor",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Anemia Studies",
        investigation: [
          {
            test: "IRON-Serum",
            low: "11.6",
            high: "31.3",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "UIBC-Serum",
            low: "120",
            high: "470",
            unit: "ug/dL",
            result: "",
            method: "",
          },
          {
            test: "Total Iron Binding Capicity",
            low: "220",
            high: "440",
            unit: "mcg/dl",
            result: "",
            method: "",
          },
          {
            test: "Percentage Transferrin Saturation",
            low: "20",
            high: "55",
            unit: "%",
            result: "",
            method: "",
          },
          {
            test: "Transferrin Serum",
            low: "26",
            high: "37",
            unit: "µmol/L",
            result: "",
            method: "",
          },
          {
            test: "Ferritin",
            low: "20",
            high: "300",
            unit: "ng/ML",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Fertility Profile",
        investigation: [
          {
            test: "Leutinizing Hormone",
            low: "",
            high: "",
            unit: "mIU/mL",
            result: "",
            method: "",
          },
          {
            test: "Estradiol (E2)",
            low: "",
            high: "",
            unit: "pg/mL",
            result: "",
            method: "",
          },
          {
            test: "FSH",
            low: "",
            high: "",
            unit: "mIU/mL",
            result: "",
            method: "",
          },
          {
            test: "Progesterone",
            low: "",
            high: "",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          { test: "AMH", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Estrogen",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Oestradiol",
            low: "",
            high: "",
            unit: "pg/mL",
            result: "",
            method: "",
          },
          { test: "SHBG", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "DHEA-Sulphate",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Testosterone",
            low: "",
            high: "0.8",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "Testosterone(Free)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Androstenedione",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Prolactin",
            low: "6.0",
            high: "29.9",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "Beta HCG",
            low: "",
            high: "",
            unit: "mIU/ml",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Cancer Profile",
        investigation: [
          {
            test: "Prostate-Specific Antigen Free",
            low: "",
            high: "",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "Prostate-Specific Antigen Total",
            low: "0",
            high: "4",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "CA 19-9",
            low: "0",
            high: "27",
            unit: "KU/L",
            result: "",
            method: "",
          },
          {
            test: "CA 125",
            low: "0",
            high: "35",
            unit: "U/mL",
            result: "",
            method: "",
          },
          {
            test: "CA 15-3",
            low: "",
            high: "25",
            unit: "KU/L",
            result: "",
            method: "",
          },

          {
            test: "CA 72-4",
            low: "0",
            high: "6.9",
            unit: "KU/L",
            result: "",
            method: "",
          },

          {
            test: "Alfa Feto Protein (AFP)",
            low: "",
            high: "9.96",
            unit: "U/ml",
            result: "",
            method: "",
          },
          { test: "CEA", low: "", high: "", unit: "", result: "", method: "" },
        ],
      },
      {
        profilename: "STI and Hepatitis Profile",
        investigation: [
          {
            test: "Rapid Plasma Reagin",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "HIV", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "HBsAg",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HBsAb",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis B envelope antigen (HBeAg)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis B envelope antibody (HBeAb)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis core antibody (HBcAb)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HEP C Antibodies Qualitative",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Hepatitis B",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "HCV", low: "", high: "", unit: "", result: "", method: "" },
          { test: "HPV", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "RAPID TP(SYPHILIS), VDRL",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HIV AB",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "HIV Combo (Ag+Ab)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          { test: "VDRL", low: "", high: "", unit: "", result: "", method: "" },
          {
            test: "Herpes Semplex Virus I IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus I IgG",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus II IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus II IgG",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Herpes Semplex Virus",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Chlamydia IgM",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Chlamydia Ag",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Gonorrhoea Ag",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "TPHA (TREPONEMA PALLIDUM)",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "High Vaginal Swab",
            low: "",
            high: "",
            unit: "",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Cardiac Profile",
        investigation: [
          {
            test: "Creatine-Kinase",
            low: "22",
            high: "198",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "CK-MB",
            low: "0",
            high: "24",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "CK-Total",
            low: "49",
            high: "397",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "TROPONI - I",
            low: "",
            high: "0.05",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          {
            test: "Homocysteine",
            low: "0",
            high: "30",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "Myoglobin",
            low: "25",
            high: "75",
            unit: "ng/mL",
            result: "",
            method: "",
          },
          // Myoglobin
        ],
      },
      {
        profilename: "Blood Clotting",
        investigation: [
          {
            test: "PT TEST",
            low: "26",
            high: "45",
            unit: "seconds",
            result: "",
            method: "",
          },
          {
            test: "Thrombin Time",
            low: "26",
            high: "45",
            unit: "",
            result: "",
            method: "",
          },
          //Activated Partial Thromboplastin Time", "D-Dimer"
          {
            test: "Activated Partial Thromboplastin Time",
            low: "23",
            high: "33",
            unit: "",
            result: "",
            method: "",
          },
          {
            test: "D-Dimer",
            low: "",
            high: "0.5",
            unit: "",
            result: "",
            method: "",
          },
          {
            name: "PT (Control)",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          {
            name: "Mean Normal Prothrombic Time",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          {
            name: "Partial Thromboplastin Time",
            low: "23",
            high: "33",
            result: "",
            unit: "",
            method: "",
          },
          {
            name: "Partial Thromboplastin Time(Control)",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          {
            name: "PT-INR",
            low: "0.87",
            high: "1.25",
            result: "",
            unit: "",
            method: "",
          },
          {
            name: "PT ratio",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
          { name: "ISI", low: "", high: "", result: "", unit: "", method: "" },
          {
            name: "Index",
            low: "",
            high: "",
            result: "",
            unit: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Arthritis",
        investigation: [
          {
            test: "Serum Uric Acid",
            low: "140",
            high: "420",
            unit: "Umol/L",
            result: "",
            method: "",
          },
          {
            test: "RA factor (quantitative)",
            low: "0",
            high: "20",
            unit: "IU/mL",
            result: "",
            method: "",
          },
          {
            test: "ASO (quantitative)",
            low: "0",
            high: "200",
            unit: "IU/mL",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Skeletal Profile",
        investigation: [
          {
            test: "Serum Calcium",
            low: "2.15",
            high: "2.58",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Magnesium",
            low: "0.67",
            high: "0.87",
            unit: "mmol/L",
            result: "",
            method: "",
          },
          {
            test: "Phosphorus",
            low: "2.55",
            high: "3.68",
            unit: "mmol/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Pancreatic Profile",
        investigation: [
          {
            test: "Amylase",
            low: "46",
            high: "162",
            unit: "U/L",
            result: "",
            method: "",
          },
          {
            test: "Lipase",
            low: "8",
            high: "78",
            unit: "IU/L",
            result: "",
            method: "",
          },
        ],
      },
      {
        profilename: "Hormones",
        investigation: [
          {
            test: "Cortisol Serum (p.m)",
            low: "",
            high: "",
            unit: "nmol/L",
            result: "",
            method: "",
          },
        ],
      },
    ],
  },
];

export const testsList = [
  "Total Cholesterol",
  "HDL Cholesterol",
  "LDL Cholesterol",
  "HDL % of total",
  "Direct LDL Cholestrol",
  "HDL : LDL ratio",
  "LDL : HDL ratio",
  "APO B : APO A Ratio",
  "APO A : APO B Ratio",
  "HsCRP",
  "Apo A",
  "Triglycerides",
  "VLDL",
  "Apo B",
  "Creatine-Kinase",
  "Non - HDL Cholesterol",
  "Total Cholesterol : HDL ratio",
  "Total Bilirubin",
  "Direct Bilirubin",
  "Indirect Bilirubin",
  "SGOT (AST)",
  "SGOT (ALT)",
  "SGPT (ALT)",
  "SGPT (AST)",
  "AST / ALT Ratio",
  "GGT",
  "ALP",
  "LDH",
  "Protein (Total)",
  "Albumin",
  "Globulin",
  "Albumin : Globulin ratio",
  "Albumin / Creatinine ratio",
  "Serum Creatinine",
  "Uric Acid",
  "Blood Urea",
  "Blood Urea Nitrogen (BUN)",
  "Urea : Creatinine ratio",
  "BUN : Creatinine ratio",
  "Microalbumin : Creatinine ratio",
  "TSH",
  "TSH 3RD GENERATION",
  "Ultra TSH",
  "T3 (Triiodothyronine)",
  "T4 (Thyroxine)",
  "Free T3 (Triiodothyronine)",
  "Free T4 (Thyroxine)",
  "Parathyroid Hormone (PTH)",
  "Anti Thyroglobulin Abs",
  "THYROGLOBULIN",
  "Blood Sugar (Fasting)",
  "Amylase",
  "Blood Sugar (Postprandial)",
  "Blood Sugar (Random)",
  "Insulin (Fasting)",
  "Insulin (Postprandial)",
  "Insulin (Random)",
  "CRP",
  "RA factor (quantitative)",
  "RA factor (descriptive)",
  "ASO (quantitative)",
  "Haemoglobin",
  "Iron",
  "TIBC",
  "UIBC",
  "G6PD (Quantitative)",
  "Ferritin",
  "Transferrin Serum",
  "% Saturation Transferrin",
  "RBC count",
  "Haematocrit",
  "MCV",
  "MENTZER INDEX",
  "MCH",
  "MCHC",
  "Rapid Plasma Reagin",
  "Sickle Cell Solubility Test-Sickling phenomenon",
  "Content Haemoglobin (CH)",
  "Total Leukocyte Count",
  "Platelet Count",
  "Mean Platelet Component (MPC)",
  "Mean Platelet Mass (MPM)",
  "MPV",
  "PDW",
  "PDW - SD",
  "Large Unstained Cell",
  "Absolute Large Unstained Cell",
  "Peripheral smear",
  "PCT",
  "Specific Gravity",
  "pH",
  "Urinary Calcium",
  "Sodium",
  "SERUM BICARBONATE(D)",
  "Potassium",
  "Chloride",
  "Calcium",
  "Ionized Calcium",
  "Non Ionized Calcium",
  "Corrected Calcium",
  "Zinc",
  "Phosphorus",
  "Magnesium",
  "HbA1c (Glycosylated Haemoglobin)",
  "HbA1c (mmol/mol)",
  "eAG (Estimated Average Glucose)",
  "Neutrophils",
  "Abs. Neutrophil Count",
  "Segmented Neutrophils",
  "Neutrophil Lymphocyte Ratio",
  "Eosinophils",
  "Abs. Eosinophil Count",
  "Basophils",
  "Abs. Basophil Count",
  "Abs. Lymphocyte Count",
  "Abs. Monocyte Count",
  "Lymphocytes",
  "Polymorphs",
  "RBC Morphology",
  "WBC Morphology",
  "Platelate Morphology",
  "Reticulocytes Count",
  "CORRECTED RETICULOCYTE COUNT",
  "ABSOLUTE RETICULOCYTE COUNT",
  "RETICULOCYTE HEAMOGLOBIN EQUIVELENT",
  "IMMATURE PLATELET FRACTION",
  "Monocytes",
  "Myelocytes",
  "Meta-Myelocytes",
  "Pro-Myelocytes",
  "Atypical Lymphocytes (LUC)",
  "Band Cells",
  "Blast Cells",
  "HDW (Haemoglobin Distribution Width)",
  "RDW",
  "RDW-CV",
  "RDW-SD",
  "ESR",
  "Microalbumin",
  "Glucose in Urine",
  "URINE SPOT PROTEIN",
  "URINE SPOT CREATININE",
  "Urine Spot Microalbumin",
  "SPOT PROTEIN & CREATININE RATIO",
  "DEPOSITS :",
  "Urine Colour",
  "Appearance",
  "VOLUME",
  "Pus Cells",
  "Epithelial Cells",
  "Urobilinogen",
  "Protein",
  "Ketone",
  "Acetone",
  "Phosphates/Urates",
  "Bile Salts",
  "Blood",
  "Nitrite",
  "Casts",
  "Crystals",
  "Bacteria",
  "Yeast cells",
  "Amorphous Deposit",
  "Bile Pigment",
  "Bilirubin",
  "Absorbic Acid",
  "RBC",
  "Leukocytes",
  "Leukocyte Esterase",
  "Sugar in Urine",
  "Other compounds in Urine",
  "Candida in Urine",
  "Vitamin D (25-Hydroxy)",
  "Serum Folate",
  "Vitamin B9",
  "Vitamin B12",
  "Vitamin B1",
  "Vitamin B6",
  "Vitamin B2",
  "Vitamin A",
  "Vitamin E",
  "Vitamin C",
  "RBC(PBS)",
  "WBC(PBS)",
  "Platelet(PBS)",
  "Glomerular Filtration Rate",
  "Glomerular Filtration Rate CKD",
  "Albuminuria",
  "P-LCR",
  "P-LCC",
  "Immature Granulocytes",
  "Abs. Immature Granulocytes",
  "Nucleated red blood cells",
  "Absolute Nucleated red blood cells",
  "Urinary Creatinine",
  "Blood Group",
  "Rh Factor",
  "Rh Factor Quantitative",
  "Prostate-Specific Antigen Free",
  "Prostate-Specific Antigen Total",
  "PT TEST",
  "Thrombin Time",
  "PT (Control)",
  "Mean Normal Prothrombic Time",
  "Partial Thromboplastin Time",
  "Activated Partial Thromboplastin Time",
  "Partial Thromboplastin Time(Control)",
  "PT-INR",
  "PT ratio",
  "ISI",
  "Index",
  "Dengue IgG",
  "Dengue IgM",
  "Dengue NS1",
  "HIV",
  "Typhoid (Widal Test)",
  "Malaria (VIVAX)",
  "Malaria (FALCIPARUM)",
  "Prolactin",
  "Urine for Beta HCG",
  "Serum for Beta HCG",
  "Beta HCG",
  "Pregnancy Test Serum",
  "Leutinizing Hormone",
  "AMH2",
  "Estradiol (E2)",
  "FSH",
  "Progesterone",
  "AMH",
  "Estrogen",
  "Oestradiol",
  "SHBG",
  "17-HydroxyProgesterone",
  "Homocysteine",
  "DHEA-Sulphate",
  "Cortosol Random",
  "Testosterone",
  "Testosterone(Free)",
  "Androstenedione",
  "HBsAb",
  "HBsAg",
  "HBcAb Total",
  "HBcAb IgM",
  "HCV AB",
  "Anti HCV",
  "RAPID TP(SYPHILIS), VDRL",
  "HAV AB IgM",
  "HAV AB",
  "HAV AB Total",
  "CMV AB IgG",
  "CMV AB IgM",
  "CA 19-9",
  "CA 125",
  "CA 15-3",
  "CEA",
  "HIV AB",
  "HIV Combo (Ag+Ab)",
  "VDRL",
  "Herpes Semplex Virus I IgM",
  "Herpes Semplex Virus II IgM",
  "Chlamydia IgM",
  "TPHA (TREPONEMA PALLIDUM)",
  "High Vaginal Swab",
  "Urea Breath Test",
  "Typhidot",
  "WIDAL TEST (SLIDE METHOD)",
  "Gram Stain",
  "Helicobacter Antigen in Stool",
  "Helicobacter Pylori Abs.(IgM)",
  "Helicobacter Pylori Abs.(IgG)",
  "Egg White Allergy",
  "Egg Yolk Allergy",
  "Cow Milk Allergy",
  "Chocolate Allergy",
  "Wheat Flour Allergy",
  "Soy Bean Allergy",
  "Baker's Yeast Allergy",
  "Nut Mix Allergy",
  "Peanut Allergy",
  "Orange Allergy",
  "Strawberry Allergy",
  "Banana Allergy",
  "Mango Allergy",
  "Tomato Allergy",
  "Carrot Allergy",
  "Onion Allergy",
  "Chicken Allergy",
  "Lamb Allergy",
  "Codfish Allergy",
  "Shrimp/Prawn Allergy",
  "HbF",
  "HbA",
  "HbS",
  "HbC",
  "HbD",
  "Unidentified",
  "HbA2",
  "D-Dimer",
  "Lipase",
  "Occult Blood in Stool",
  "Stool Color",
  "Stool Consistency",
  "Stool Odour",
  "Stool Mucus",
  "Visible Blood",
  "Red Blood Cell",
  "Pus Cells in Stool",
  "Polymorphonuclear Leukocytes",
  "Trophozoites",
  "Cysts",
  "OVA",
  "Adult Parasite",
  "Undigested Food",
  "Stool Specimen Type",
  "Organism Isolated",
  "Semen Type",
  "Primary Smear of Semen",
  "Ampicillin",
  "Amoxycillin",
  "Amoxycillin + Clavulanic Acid",
  "Ampicillin + Salbactum",
  "Piperacillin",
  "Ticarcillin",
  "Amikacin",
  "Cotrimoxazole",
  "Tetracycline",
  "Ciprofloxacin",
  "Levofloxacine",
  "Oflaxacine",
  "Imipenem",
  "Meropenem",
  "Piperacillin + Tazobactum",
  "Norfloxacin",
  "Ticarcillin + Clavulanic Acid",
  "Cefazolin",
  "Cefuroxime",
  "Cefoxitin",
  "Cefitriaxone",
  "Cefotaxime",
  "Ceftazidime",
  "Cefoperazone + Sulbactum",
  "Cefepime",
  "Aztreonam",
  "Gentamicin",
  "Appearance of Semen",
  "WBCs",
  "RBCs",
  "Abstinence Period",
  "Collection Time",
  "Analysis Time",
  "Volume of Semen",
  "Liquefaction",
  "pH of Semen",
  "Sperm Count / ml",
  "Sperm Count / ejaculate",
  "Motile",
  "Progressive",
  "Non Progressive",
  "Immotile",
  "Normal Form",
  "Abnormal Form",
  "Abnormal Head",
  "Abnormal Neck 8 midpiece",
  "Abnormal Tail",
  "IgM Level",
  "Varicella Zoster Ab IgG",
  "IgE Total",
  "CK-MB",
  "CK-Total",
  "Troponin I",
  "Covid-19 IgG",
  "COVID 19 IgM",
  "COVID Antibody",
  "COVID RT-PCR",
  "Cycle Threshold N-gene",
  "Cycle Threshold E-gene",
  "Cycle Threshold RDRP",
  "Cycle Threshold Orf 1a-gene",
  "Toxoplasma Ab IgG",
  "Toxoplasma Ab IgM",
  "Rubella Ab IgG",
  "Rubella Ab IgM",
  "Alfa Feto Protein (AFP)",
  "Growth Hormone",
  "Cortisol Serum (p.m)",
  "IL6",
  "Timothy Grass",
  "Cultivated Rye",
  "Alder",
  "Birch",
  "Oak",
  "Olive Tree",
  "Commn Ragweed",
  "Mugwort",
  "Dermatophagoides Pter",
  "Dermatophagoides Farinae",
  "Cockroach, German",
  "Cat",
  "Dog",
  "Horse",
  "Camel",
  "Penicillium Notatum",
  "Caldosporium Herbarium",
  "Aspergillus Fumigatus",
  "Candida Albicans",
  "Alternaria Alternata",
  "Lupus Anticoagulant",
  "Anti Cardiolipin IgM",
  "Anti Cardiolipin IgG",
  "Anti CCP",
  "Anti DNA Abs.",
  "Coombs Test (Indirect)",
  "Anti-Phospholipid (IgG)",
  "Anti-Phospholipid (IgM)",
  "ANA-Titer",
  "Anti Nuclear Antibodies (ANA)",
  "Serum Copper",
  "Aluminium",
  "Antimony",
  "Arsenic",
  "Barium",
  "Beryllium",
  "Bismuth",
  "Cadmium",
  "Caesium",
  "Chromium",
  "Cobalt",
  "Lead",
  "Manganese",
  "Mercury",
  "Molybdenum",
  "Nickel",
  "Selenium",
  "Silver",
  "Strontium",
  "Thallium",
  "Tin",
  "Uranium",
  "Vanadium",
  "Anti-TPO",
  "Anti-Tissue Transglutaminase IgA",
  "TG : HDL Ratio",
  "MYELOBLAST",
  "ABNORMAL CELLS",
  "Lymphoblasts",
  "HEMOPARASITES",
  "LIC%",
  "LIC#",
  "Vitamin B3",
];

export const stiJson = {
  Centre: "pfh",
  WorkOrderID: "",
  LabNo: "",
  LabRefno: "",
  NonSmartTestID: "",
  PanelID: "",
  PanelName: "",
  PName: "",
  Gender: "",
  CollectedDate: "",
  Age: "",
  ReferredBy: "",
  ReportDate: "",
  Received: "",
  FilePath: "",
  MaxID: "",
  Date: "",
  hasPastData: false,
  results: [
    {
      Package_name: "STI",
      Package_book_code: "",
      investigation: [
        {
          test_name: "Dummy",
          test_code: "1234",
          barcodeNo: "",
          SampleType: "",
          SampleCollDate: "",
          SampleRcvDate: "",
          ApprovalDate: "",
          SampleCollBy: "",
          SampleRcvBy: "",
          ApprovedByDoctorID: "",
          IsNabl: "",
          observations: [],
        },
      ],
    },
  ],
  ApprovedDoctorDetails: [
    {
      ApprovedDoctorID: "",
      ApprovedDoctorSign: "",
    },
  ],
  BackGroudImageData: "",
  reportType: "dynamic",
};

export const stiTestsList = [
  "HIV",
  "HBsAg",
  "Hepatitis B",
  "Rapid Plasma Reagin",
  "Herpes Semplex Virus",
  "Chlamydia IgM",
  "HPV",
  "HCV",
];

export const vitalsTestList = [
  {
    test: "Height",
    low: "",
    high: "",
    unit: "CM",
    result: "",
    method: "",
  },
  {
    test: "BP",
    low: "",
    high: "",
    unit: "mmHg",
    result: "",
    method: "",
  },
  {
    test: "Weight",
    low: "",
    high: "",
    unit: "Kg",
    result: "",
    method: "",
  },
  {
    test: "BMI",
    low: "",
    high: "",
    unit: "",
    result: "",
    method: "",
  },
  {
    test: "BODY FAT",
    low: "21",
    high: "40",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "BONE MASS",
    low: "2.4",
    high: "2.6",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "MUSCLE MASS",
    low: "29.1",
    high: "34.7",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "BODY FAT",
    low: "21",
    high: "40",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "BMR",
    low: "0",
    high: "1326",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "VISCERAL FAT",
    low: "0",
    high: "10",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "LEAN BODY MASS",
    low: "56.3",
    high: "68.4",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "BODY FAT MASS",
    low: "13.9",
    high: "23.2",
    unit: "%",
    result: "",
    method: "",
  },
  {
    test: "WATER",
    low: "53",
    high: "67",
    unit: "%",
    result: "",
    method: "",
  },
];
