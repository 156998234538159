import {
  DialogContent,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Invoice from "./Invoice";
import { useState } from "react";

const PackageDetailSection = () => {
  const [open, setOpen] = useState(false);
  const [enquiryPlan, setEnquiryPlan] = useState("");
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  console.log("loginDataSub", loginData, loginData.subscription);

  const handleClose = () => {
    setOpen(false);
  };

  const planDetails = [
    { title: "Starter Plan", description: "Smart Report" },
    { title: "Growth Plan", description: "Smart Report & Add-ons" },
    {
      title: "Pro Plan",
      description: "Smart Report with Viz App & Analytics",
    },
  ];

  const enquiryModal = () => {
    const prefixedMessageUrl = `https://wa.me/917996604381?text=Hello,%20I%20would%20like%20more%20information%20regarding%20the%20${enquiryPlan}.`;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {" "}
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4">Plan Enquiry</Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack gap={2}>
            <Typography variant="body5">
              This will post an enquiry regarding the details of {enquiryPlan}{" "}
              to one of our executives.
            </Typography>
            <a href={prefixedMessageUrl} target="_blank">
              <Button sx={{ alignSelf: "flex-start" }}>Click to proceed</Button>
            </a>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };

  const formatDate = (date) => {
    const modifiedDate = new Date(date);
    const day =
      modifiedDate.getDate() < 10
        ? `0${modifiedDate.getDate()}`
        : modifiedDate.getDate();
    const month =
      modifiedDate.getMonth() + 1 < 10
        ? `0${modifiedDate.getMonth() + 1}`
        : modifiedDate.getMonth() + 1;
    const requiredFormat = `${day}-${month}-${modifiedDate.getFullYear()}`;
    return requiredFormat;
  };

  return (
    <Stack p={3} sx={{ background: "#fff", borderRadius: "8px" }} gap={6}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Plan Details</Typography>
        {loginData && loginData.role === "lab" ? <Invoice /> : ""}
      </Stack>
      {/* <Stack direction="row" gap={2}>
        <Stack
          justifyContent="space-between"
          flexGrow={1}
          sx={{
            height: "315px",

            borderRadius: "20px",
            padding: "32px 24px",
            border: "1px solid #D9D9D9",
            background: "#EBF9EE",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                padding: "6px 8px",
                color: "#fff",
                background: "#27BC48",
                borderRadius: "10px",
              }}
            >
              <Typography variant="body3">Current Plan</Typography>
            </Box>
          </Stack>
          <Typography variant="h6">Starter Plan</Typography>
          <Stack gap={1} direction="row">
            <Typography>Plan details</Typography>
          </Stack>
          <Button variant="custom2">Choose Plan</Button>
        </Stack>
        <Stack
          justifyContent="space-between"
          flexGrow={1}
          sx={{
            height: "315px",
            borderRadius: "20px",
            padding: "32px 24px",
            border: "1px solid #D9D9D9",
          }}
        >
          <Typography variant="h6">Growth Plan</Typography>
          <Stack gap={1} direction="row">
            <Typography>Plan details</Typography>
          </Stack>
          <Button variant="custom2">Choose Plan</Button>
        </Stack>
        <Stack
          justifyContent="space-between"
          flexGrow={1}
          sx={{
            height: "315px",
            borderRadius: "20px",
            padding: "32px 24px",
            border: "1px solid #D9D9D9",
          }}
        >
          <Typography variant="h6">Pro Plan</Typography>
          <Stack gap={1} direction="row">
            <Typography>Plan details</Typography>
          </Stack>
          <Button variant="custom2">Choose Plan</Button>
        </Stack>
      </Stack> */}
      <Stack direction="row" gap={2} flexWrap="wrap">
        {planDetails.map((each, idx) => (
          <Stack
            key={idx}
            flexGrow={1}
            gap={1}
            sx={{
              height: "315px",
              justifyContent: "space-between",
              borderRadius: "20px",
              padding: "32px 24px",
              border: "1px solid #D9D9D9",
              width: "30%",
              background:
                loginData?.subscription === each.title ? "#EBF9EE" : "",
              outline:
                loginData?.subscription === each.title
                  ? "4px solid rgba(39, 188, 72, 0.5)"
                  : "",
            }}
          >
            {loginData?.subscription === each.title ? (
              <Typography
                variant="body3"
                sx={{
                  padding: "6px 8px",
                  color: "#fff",
                  background: "#27BC48",
                  borderRadius: "8px",
                  alignSelf: "start",
                }}
              >
                Current Plan
              </Typography>
            ) : (
              ""
            )}
            <Stack flexGrow={1} justifyContent="space-between">
              <Typography variant="h6">{each.title}</Typography>

              <Stack gap={1} direction="row">
                <Typography>{each.description}</Typography>
              </Stack>
              {loginData?.subscription !== each.title ? (
                <Button
                  variant="custom2"
                  onClick={() => {
                    setOpen(true);
                    setEnquiryPlan(each.title);
                  }}
                >
                  Choose Plan
                </Button>
              ) : (
                <Stack gap={2}>
                  <Typography variant="body5" sx={{ textAlign: "center" }}>
                    Started on: {formatDate(loginData?.startDate)}
                  </Typography>
                  <Typography
                    variant="body5"
                    sx={{
                      background: "#27BC48",
                      borderRadius: "8px",
                      color: "#fff",
                      padding: "8px 0px",
                      textAlign: "center",
                    }}
                  >
                    Ending on: {formatDate(loginData?.endDate)}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {enquiryModal()}
    </Stack>
  );
};

export default PackageDetailSection;
