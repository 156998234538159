import { Stack, Typography } from "@mui/material";

const WIPSection = () => {
  return (
    <Stack sx={{ height: "200px" }} justifyContent="center" alignItems="center">
      <Typography variant="body2" sx={{ color: "#6A6A6A", fontWeight: "bold" }}>
        Work in Progress
      </Typography>
    </Stack>
  );
};

export default WIPSection;
