import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Rating,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";

import LoadingView from "../../common/LoadingView";
import ErrorView from "../../common/ErrorView";
import EmptyView from "../../common/EmptyView";
import { getCurrentWeekDates } from "../utils";

const VizAppReviews = () => {
  const today = new Date().getDate();
  const [selectedMetric, setSelectedMetric] = useState("currentWeek");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [reviewData, setReviewData] = useState("");
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const reviewLabel = ["5 star", "4 star", "3 star", "2 star", "1 star"];

  const handleDataCall = async () => {
    setLoader(true);
    setError("");
    const startEpoc = new Date(startDate).getTime();
    const endEpoc = new Date(endDate).getTime();
    const apiUrl = `https://api.niroggyan.com/analytics/vizapp/feedback?filter_by=date_range&start_epoch=${startEpoc}&end_epoch=${endEpoc}`;
    const idToken = localStorage.getItem("idTokenBilling");

    let options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: idToken,
      },
    };
    try {
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      console.log("reviews", data);
      if (data?.data?.length) {
        setReviewData(data.data);
      } else {
        setError("No feedbacks available");
      }
    } catch (e) {
      console.log("Error", e);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // if (today < 5) {
    //   setStartDate(getPreviousMonthStartEndDates().startDate);
    //   setEndDate(getPreviousMonthStartEndDates().endDate);
    //   setSelectedMetric("previousMonth");
    // } else {
    //   setStartDate(getCurrentMonthStartEndDates().startDate);
    //   setEndDate(getCurrentMonthStartEndDates().endDate);
    //   setSelectedMetric("currentMonth");
    // }
    setStartDate(getCurrentWeekDates().startDate);
    setEndDate(getCurrentWeekDates().endDate);
  }, []);

  // useEffect(() => {
  //   handleDataCall();
  // }, [startDate, endDate, selectedMetric]);

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };

  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() >= 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const customDateModal = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select Custom Date</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box>
              <Typography mb={1}>From Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setStartDate(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography mb={1}>To Date</Typography>
              <input
                className="customDateTag"
                type="date"
                defaultValue={endDate}
                max={maxcalenderDate}
                onChange={(e) => setEndDate(formatToDate(e.target.value))}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save Changes
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSelection = (option) => {
    setSelectedMetric(option);
    switch (option) {
      case "currentWeek":
        setStartDate(getCurrentWeekDates().startDate);
        setEndDate(getCurrentWeekDates().endDate);
        break;
      case "currentMonth":
        setStartDate(getCurrentMonthStartEndDates().startDate);
        setEndDate(getCurrentMonthStartEndDates().endDate);
        break;
      case "previousMonth":
        setStartDate(getPreviousMonthStartEndDates().startDate);
        setEndDate(getPreviousMonthStartEndDates().endDate);
        break;
      case "customMonth":
        setOpen(true);
        break;
    }
  };

  const totalReviews = reviewData?.length ? reviewData[0].totalFeedback : 0;

  return (
    <Box flexGrow={1}>
      <Stack
        mb={2}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h2">Customer Feedback</Typography>

        <Stack
          direction="row"
          gap={2}
          flexWrap="wrap"
          justifyContent="flex-end"
        >
          <FormControl size="small" variant="filled">
            <InputLabel id="demo-select-small-label">Time Period</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedMetric}
              label="Date"
              onChange={(e) => handleSelection(e.target.value)}
              onClick={(e) => {
                if (e.target.innerText === "Custom Month") {
                  handleSelection("customMonth");
                }
              }}
            >
              <MenuItem value="currentWeek">Current Week</MenuItem>
              <MenuItem value="currentMonth">Current Month</MenuItem>
              <MenuItem value="previousMonth">Previous Month</MenuItem>
              <MenuItem value="customMonth">Custom Month</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handleDataCall} disabled={loader}>
            Get Details
          </Button>
          {customDateModal()}
        </Stack>
      </Stack>
      <Stack>
        {loader ? (
          <LoadingView />
        ) : error ? (
          <ErrorView error={error} />
        ) : reviewData ? (
          <Stack gap={2} p={2}>
            <Stack direction="row" gap={2} alignItems="center">
              <Rating
                readOnly
                sx={{
                  "& .MuiRating-iconFilled": {
                    color: "#F9C852",
                  },
                }}
                name="simple-controlled"
                size="large"
                precision={0.5}
                value={parseFloat(reviewData[0].averageFeedback.toFixed(1))}
              />
              <Typography variant="h2">
                {reviewData[0].averageFeedback.toFixed(1)} out of 5{" "}
              </Typography>
            </Stack>
            <Typography variant="h2">
              {reviewData[0].totalFeedback} Total Ratings
            </Typography>
            <Stack gap={2}>
              {reviewLabel.map((each, idx) => (
                <Stack key={idx} direction="row" alignItems="center" gap={2}>
                  <Typography>{each}</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ width: "70%" }}
                  >
                    <Box sx={{ width: "80%", mr: 2 }}>
                      <LinearProgress
                        variant="determinate"
                        value={
                          reviewData[0].scoreCounts[5 - idx]
                            ? Math.round(
                                (reviewData[0].scoreCounts[5 - idx] /
                                  totalReviews) *
                                  100
                              )
                            : 0
                        }
                        sx={{
                          height: 10,
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#F9C852", // Color of the progress bar
                          },
                          "& .MuiLinearProgress-root": {
                            background: "#f0f2f2", // Track color (the background part)
                          },
                        }}
                      />
                    </Box>
                    <Typography>
                      {reviewData[0].scoreCounts[5 - idx]
                        ? Math.round(
                            (reviewData[0].scoreCounts[5 - idx] /
                              totalReviews) *
                              100
                          ) + "%"
                        : "0%"}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        ) : (
          // <span style={{ color: "#7787bc" }}>{"No Data to display"}</span>
          <EmptyView message="Please click get details to load data" />
        )}
      </Stack>
    </Box>
  );
};

export default VizAppReviews;
